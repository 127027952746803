// flow

import { AutoComplete } from 'antd';
import * as mobx from 'mobx';
import React, { Component } from 'react';

import './staff-auto-complete.css';

class StaffAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      value: '',
    };
  }

  componentDidMount() {
    if (this.props.staff) {
      this.setState({ value: this.props.staff.displayName });
      this.props.onChange(this.props.staff, this.props.index);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.staff !== this.props.staff && this.props.staff === null && this.props.type === 'report') {
      this.setState({
        value: ''
      })    
    }
    if (prevProps.data !== this.props.data && this.props.staff === null && this.props.type === 'report') {
      this.setState({
        data: this.props.data
      })    
    }
  }

  onChange = (value) => {
    let filtered = this.props.data?.filter(
      (d) =>
        (d.firstName != null && d.firstName.toLowerCase().startsWith(value.toLowerCase())) ||
        (d.surname != null && d.surname.toLowerCase().startsWith(value.toLowerCase())) ||
        (d.displayName != null && d.displayName.toLowerCase().startsWith(value.toLowerCase()))
    );

    this.setState({
      value: value,
      data: filtered,
    });

    let staff = this.state.data?.map((d) => mobx.toJS(d))
      .find((s) => {
        return s.displayName === value;
      });

    this.props.onChange(staff, this.props.index);
  };

  render() {
    let className = this.props.className ? this.props.className : 'staff-auto-complete';
    return this.props.rebuildState ? (
      <AutoComplete
        placeholder='Type to Search'
        value={
          this.props.rebuildData[this.props.index]
            ? this.props.rebuildData[this.props.index].displayName
            : this.state.value
        }
        onChange={this.onChange}
        className='staff-auto-complete'
        disabled={
          this.props.disabling ? (this.props.staff && this.props.index === 0 ? true : false) : false
        }
      >
        {this.state.data.map((data) => (
          <AutoComplete.Option
            key={data.id}
            value={data.displayName}
          >
            {data.displayName}
          </AutoComplete.Option>
        ))}
      </AutoComplete>
    ) : (
      <AutoComplete
        placeholder='Type to Search'
        data={this.state.data}
        value={this.state.value}
        onChange={this.onChange}
        className={className}
        disabled={
          this.props.type && this.props.disabling ? true : this.props.disabling ? (this.props.staff && this.props.index === 0 ? true : false) : false
        }
      >
        {this.state.data?.map((data) => (
          <AutoComplete.Option
            key={data.id}
            value={data.displayName}
          >
            {data.displayName}
          </AutoComplete.Option>
        ))}
      </AutoComplete>
    );
  }
}

export default StaffAutoComplete;
