// @flow

import React, { Component } from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';

type Props = {
  title: string,
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any,
  disabled: boolean,
  previousLocation: string,
};

class NavButton extends Component<Props> {
  summaryNav() {
    const { navStore } = this.props;
    // Just for staff summary nav to activity
    let state = this.props.state;
    let history = this.props.history;
    const previousLocation: string = history.location?.state?.prevLocation;
    if (previousLocation) {
      navStore.setPreviousLocation(previousLocation);
    }

    let pathName;
    if (state.readOnly) {
      pathName = `/staff/${state.id}/activity/${state.categoryId}/${state.readOnly}`;
    } else {
      pathName = `/staff/${state.id}/activity/${state.categoryId}`;
    }

    if (history && state.id) {
      if (state.readOnly) {
        navStore.setReadOnly(true);
      } else {
        navStore.setReadOnly(false);
      }
      if (history.location.pathname !== pathName) {
        this.props.history.push(`/staff/${state.id}/activity/${state.categoryId}`);
      }
    } else {
      history.push(`/staff/${state}`);
    }
  }

  render() {
    return (
      <Button
        disabled={this.props.disabled}
        icon={<ArrowLeftOutlined />}
        look='outline'
        primary='true'
        onClick={() => this.summaryNav()}
      >
        {this.props.title}
      </Button>
    );
  }
}

export default inject('navStore')(observer(NavButton));
