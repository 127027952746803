import React from 'react';
import moment from 'moment';

const StaffActivityStaffDetails = ({ staffData }) => {
  const filteredData = Object.entries(staffData)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([key, value]) => value != null && key !== 'updateRole' && key !== 'updateDate')
    .map(([key, value]) => ({ title: capitalize(key), value }));

  function capitalize(key) {
    let modifiedKey = key.replace(/staff/g, 'staff ');
    return modifiedKey.charAt(0).toUpperCase() + modifiedKey.slice(1);
  }

  return (
    <div>
      <div className='state-bar-item-line'>
        {filteredData.map(({ title, value }) => (
          <label
            className='state-bar-item'
            key={title}
          >
            <span className='state-bar-item-title-key'>{title}:</span>
            <span className='state-bar-item-title-value'>{value}</span>
          </label>
        ))}
      </div>
      <div>
        {staffData.updateRole && staffData.updateDate && (
          <span className='state-bar-item-modify'>
            Last Modified by {staffData.updateRole} on{' '}
            {moment(staffData.updateDate).format('D/MM/YYYY')}
          </span>
        )}
      </div>
    </div>
  );
};

export default StaffActivityStaffDetails;
