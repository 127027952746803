import { FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { CollapsePanel, DropListButton, MessageLine, SearchReportGrid } from '../../components';
import { PermissionsHelper, StringUtility } from '../../utility';
import moment from 'moment';
import { ReportParameterDto } from '../../dto';

const unitReportTypes = [
  {
    title: 'Unit Summary',
    type: 'unit_summary',
    formats: ['PDF'],
    individualSelction: true,
    userPermissions: [
      {
        userRole: 'bpo',
        openModelStates: 'verified',
        closedModelStates: 'verified',
        rolloverModelStates: [],
        allowMultipleRecords: true,
      },
      {
        userRole: 'mm',
        openModelStates: 'verified',
        closedModelStates: 'verified',
        rolloverModelStates: [],
        allowMultipleRecords: true,
      },
      {
        userRole: 'wm',
        openModelStates: 'verified',
        closedModelStates: 'verified',
        rolloverModelStates: [],
        allowMultipleRecords: true,
      },
      {
        userRole: 'as',
        openModelStates: 'verified',
        closedModelStates: 'verified',
        rolloverModelStates: [],
        allowMultipleRecords: true,
      },
    ],
  },
];

const SummaryReports = (props) => {
  const { Option } = Select;
  const {
    defaultModel,
    owners: ownerDropdownOptions,
    models,
    onReportResult,
    reporting,
    reportSearchStore,
    updateReportingStatus,
    year,
    reportStore,
    viewStore,
    authUserStore,
  } = props;

  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [summaryReporting, setSummaryReporting] = useState(false);
  const [message, setMessage] = useState({ visible: false, type: 'success', text: '' });
  const [owners, setOwners] = useState([]);

  const { year: vsYear, model: vsModel } = viewStore;
  const user = authUserStore.currentUser;

  const allowedUnitReportTypes = useMemo(() => {
    if (!vsYear || !vsModel || !user) {
      return [];
    }
    return unitReportTypes.filter((unitReportType) => {
      const { userPermissions } = unitReportType;
      return PermissionsHelper.reportIsAllowed({
        userPermissions,
        year: vsYear,
        model: vsModel,
        user,
      });
    });
  }, [vsYear, vsModel, user]);

  const ownerIsNotAllOwnersOption = (ownerDropdownOption) => {
    const hasId = ownerDropdownOption?.id != null;
    return hasId;
  };

  useEffect(() => {
    if (ownerDropdownOptions) {
      setOwners(ownerDropdownOptions?.filter(ownerIsNotAllOwnersOption));
    }
  }, [ownerDropdownOptions]);

  useEffect(() => {
    if (message.visible) {
      setTimeout(() => {
        setMessage({ visible: false, type: 'success', text: '' });
      }, 5000);
    }
  }, [message]);

  const getOwnersParam = useCallback(() => {
    let ownersParam = [];
    if (selectedOwner !== 'all') {
      ownersParam.push(selectedOwner);
    } else {
      ownersParam = owners?.filter((d) => d.id).map((d) => d.id);
    }
    return ownersParam;
  }, [owners, selectedOwner]);

  const getDepartmentParam = useCallback(() => {
    const ownersParam = getOwnersParam();
    const selectedOwners = owners.filter((owner) => ownersParam.includes(owner.id));
    return selectedOwners.map((owner) => owner.ownerDepartment?.department?.id).filter(id => id);
  });

  const reportChange = (value) => {
    const reportSelected = allowedUnitReportTypes.find((r) => r.type === value);
    setSelectedReport(reportSelected);
  };

  const onCourseOwnerChange = (value) => {
    reportChange('unit_summary');
    setSelectedOwner(value);
  };

  const onGenerateClick = (fileType) => {
    switch (fileType) {
      case 'PDF':
        onPDF();
        break;
      default:
        console.log('Unknown file type');
        break;
    }
  };

  const onReportRequestResult = useCallback(
    (resultSuccess, reportName, fileType) => {
      if (onReportResult != null) {
        onReportResult(resultSuccess);
      }
      const fileString = `${StringUtility.capitalize(reportName)} (${fileType.toUpperCase()})`;
      const userMessage = resultSuccess
        ? `${fileString} Requested`
        : `Failed to Request ${fileString}`;
      setMessage({
        type: resultSuccess ? 'info' : 'error',
        visible: true,
        msg: userMessage,
      });
      setSummaryReporting(false);
      updateReportingStatus(false);
    },
    [onReportResult, updateReportingStatus]
  );

  const onPDF = useCallback(() => {
    setSummaryReporting(true);
    updateReportingStatus(true);

    const parameters = new ReportParameterDto({
      emailed: true,
      type: 'unit_summary',
      output: 'pdf',
      year: year,
      model: defaultModel.id,
      owner: getOwnersParam(),
      department: getDepartmentParam(),
      courseId: null,
      staffId: null,
    });

    reportStore.getAllocationReportByParameters(parameters, true).then((result) => {
      onReportRequestResult(result.success, 'Unit Summary Report', 'pdf');
    });
  }, [
    updateReportingStatus,
    year,
    defaultModel.id,
    getOwnersParam,
    getDepartmentParam,
    reportStore,
    onReportRequestResult,
  ]);

  const onPageChange = (limit, skip) => {
    this.setState({ paging: { limit, skip } });
    if (this.onValidate(this.state.reportSearch)) {
      this.setState({ spinner: { visible: true, text: 'Loading' } });
      reportSearchStore.getUnitSummaryReport(this.state.reportSearch, limit, skip).then(() => {
        this.setState({ spinner: { visible: false, text: '' } });
      });
    }
  };

  return (
    allowedUnitReportTypes.length > 0 && (
      <CollapsePanel
        expanded={false}
        title='Summary Report'
        tabIndex={1}
      >
        <div className='allocation-reports'>
          <div className='allocation-reports-row'>
            <div className='export-tip'>
              <span className='k-icon k-i-information'></span> As this export may take some time to
              generate, it will be <span className='emphasise'>emailed</span> to you once completed.
            </div>
          </div>
          <div className='allocation-reports-row'>
            <div className='allocation-reports-column'>
              <div className='item-card'>
                <span className='reports-parameter-label'>Model:</span>
                <Select
                  className='reports-parameter-dropdown'
                  onChange={() => console.log('changed model')}
                  disabled={true}
                  defaultValue={defaultModel.id}
                >
                  {models &&
                    models.map((model) => (
                      <Option
                        key={model.id}
                        value={model.id}
                      >
                        {model.model}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className='item-card'>
                <span className='reports-parameter-label'>Owner:</span>
                <Select
                  className='report-drop-down-style'
                  onChange={onCourseOwnerChange}
                  disabled={summaryReporting || !defaultModel}
                  value={selectedOwner}
                  placeholder='Please select'
                >
                  {owners &&
                    owners.map((owner) => (
                      <Option
                        key={owner?.id ?? 'all'}
                        value={owner.id}
                      >
                        {owner.owner}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
            <div className='allocation-reports-column'>
              <div className='item-card'>
                <DropListButton
                  text='Generate'
                  items={
                    !selectedReport
                      ? []
                      : selectedReport.formats
                          .filter((f) => selectedOwner || f === 'XLSX')
                          .map((f) => ({
                            text: `Export to ${f}`,
                            icon: f === 'PDF' ? <FilePdfOutlined /> : <FileTextOutlined />,
                            on: () => onGenerateClick(f),
                          }))
                  }
                  enabled={!reporting && defaultModel != null}
                  spinner={true}
                  spinnerText={'Compiling'}
                  active={reporting}
                  look='outline'
                />
              </div>
            </div>
          </div>
          <MessageLine
            visible={message.visible}
            type={message.type}
            line={message.msg}
          />
          <div>
            <SearchReportGrid
              data={reportSearchStore.unitReportResult}
              onPageChange={onPageChange}
              columns={[
                {
                  field: 'reportName',
                  title: 'Report Name',
                  dataIndex: 'reportName',
                  sorter: (a, b) => a.reportName.localeCompare(b.reportName),
                },
                {
                  field: 'createDate',
                  title: 'Create Date',
                  dataIndex: 'createDate',
                  render: (date) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''),
                  sorter: (a, b) => a.createDate.localeCompare(b.createDate),
                },
              ]}
            />
          </div>
        </div>
      </CollapsePanel>
    )
  );
};

export default inject(
  'courseStore',
  'reportStore',
  'exportStore',
  'viewStore',
  'authUserStore'
)(observer(SummaryReports));
