// @flow

import { makeAutoObservable } from 'mobx';

import { StaffDto, AllocationTypeDto, VariableDto, ActivityDto, WorkShareDto, ProfileCategoryDto, PeriodDto, ModelDto, OfferingDto } from '../dto';
import Api from './api';

export default class ActivityStore {

    typeList: AllocationTypeDto[];
    activityList: ActivityDto[];
    variableList: VariableDto[];
    staffMemberList: StaffDto[];
    workShareList: WorkShareDto[];
    categoryList: ProfileCategoryDto[];
    periodList: PeriodDto[];
    modelList: ModelDto[];
    offeringList: OfferingDto[];

    lastModelIdForTypeList: string;
    lastModelIdForCategoryList: string;
    lastModelIdForStaffMemberList: string;

    constructor() {
        this.staffMemberList = [];
        this.activityList = [];
        makeAutoObservable(this);
    }

    get initilised() {
        return (this.activityList != null && this.workShareList != null && this.typeList != null && this.categoryList != null && this.periodList != null && this.staffMemberList != null);
    }

    /**
     * @deprecated
     */
    async initData(modelId: string, refresh: boolean = false) {

        this.activityList = [];

        Promise.all([
            this.getWorkShares(refresh),
            this.getTypes(modelId, refresh),
            this.getCategories(modelId, refresh),
            this.getPeriods(refresh),
            this.getStaffMembers(modelId, refresh)
        ]);
    }

    // START: Replacement of initData

    async getWorkShares(refresh: boolean = false) {
        return new Promise(async (resolve, reject) => {
            if (!this.workShareList || refresh) {
                this.workShareList = await Api.get('/workshare');
            }
            resolve(this.workShareList);
        })
    }

    async getTypes(modelId: string, refresh: boolean = false) {
        const isModelDifferent = this.lastModelIdForTypeList !== modelId
        return new Promise(async (resolve, reject) => {
            if (!this.typeList || isModelDifferent || refresh) {
                this.typeList = await Api.get(`/types/model/${modelId}`);
                this.lastModelIdForTypeList = modelId;
            }
            resolve(this.typeList);
        })
    }

    async getCategories(modelId: string, refresh: boolean = false) {
        const isModelDifferent = this.lastModelIdForCategoryList !== modelId;
        return new Promise(async (resolve, reject) => {
            if (!this.categoryList || isModelDifferent || refresh) {
                this.categoryList = await Api.get(`/categories/model/${modelId}`);
                this.lastModelIdForCategoryList = modelId;
            }
            resolve(this.categoryList);
        })
    }

    async getPeriods(refresh: boolean = false) {
        return new Promise(async (resolve, reject) => {
            if (!this.periodList || refresh) {
                this.periodList = await Api.get('/periods');
            }
            resolve(this.periodList);
        })
    }

    async getStaffMembers(modelId: string, refresh: boolean = false) {
        const isModelDifferent = this.lastModelIdForStaffMemberList !== modelId;
        return new Promise(async (resolve, reject) => {
            if (!this.staffMemberList || this.staffMemberList.length === 0 || isModelDifferent || refresh) {
                this.staffMemberList = await Api.get(`/staff/model/${modelId}`);
                this.lastModelIdForStaffMemberList = modelId;
            }
            resolve(this.staffMemberList);
        })
    }

    // END: Replacement of initData

    // rewrite init func

    async getModelList(year: number) {
        return new Promise(async (resolve, reject) => {
            this.modelList = await Api.get(`/models/${year}`);
            resolve(this.modelList);
        })
    }

    // ****** Get Offering by course id ******

    async getOfferingsByCourseId(year: number, id: string) {
        return new Promise(async (resolve, reject) => {
            this.offeringList = await Api.get(`/offerings/${year}/course/${id}`);
            resolve(this.offeringList);
        })
    }

    // ****** Get Activtity by type (course / staff) ******

    async getActivitiesForCourse(modelId: string, typeId: string) {
        return new Promise(async (resolve, reject) => {
            this.activityList = await Api.get(`/activities/type/${typeId}/model/${modelId}`);
            resolve(this.activityList);
        })
    }

    async getActivitiesForStaff(categoryId: string, modelId: string, typeId: string) {
        return new Promise(async (resolve, reject) => {
            this.activityList = await Api.get(`/activities/category/${categoryId}/model/${modelId}/type/${typeId}`);
            resolve(this.activityList);
        })
    }

    // ****** Get Variable by type (course / staff) ******

    async getVariablesForCourse(modelId: string, typeId: string) {
        return new Promise(async (resolve, reject) => {
            this.variableList = await Api.get(`/variables/model/${modelId}/type/${typeId}`);
            resolve(this.variableList);
        })
    }

    async getVariablesForStaff(modelId: string, categoryId: string, typeId: string) {
        return new Promise(async (resolve, reject) => {
            this.variableList = await Api.get(`/variables/model/${modelId}/category/${categoryId}/type/${typeId}`);
            resolve(this.variableList);
        })
    }

    // ****** Get Saff By Model Id ******

    async getStaffByModelId(id: string) {
        return new Promise(async (resolve, reject) => {
            this.staffMemberList = await Api.get(`/staff/model/${id}`);
            resolve(this.staffMemberList);
        })
    }

    // ****** Get Course By Staff Model Id ******

    async getCourseByStaffModelId(id: string) {
        return new Promise(async (resolve, reject) => {
            Api.get(`/courses/model/${id}`)
                .then(data => {
                    resolve(data);
                })

        })
    }
}
