/* eslint-disable no-unused-vars */
import { StaffSchema } from '.';
import Joi from 'joi';
import { ConvertToForms } from './shared';
import { StaffStore } from '../mobx';

const store = new StaffStore();

class StaffValidator {
  transformations = {
    naming: {
      id: 'Id',
      code: 'Code',
      firstName: 'First Name',
      surname: 'Last Name',
      displayName: 'Display Name',
      efte: 'FTE',
      teachingPercentage: 'Profile - Teaching',
      researchPercentage: 'Profile - Research',
      citizenshipPercentage: 'Profile - Citizenship',
      clinicalPercentage: 'Profile - Clinical',
      servicePercentage: 'Profile - Service',
      courseCoordinatedCount: 'Course Coordinated Count',
      masseyUserId: 'UserId',
      workProfile: 'Work Profile',
      emailAddress: 'Email',
      sltGroup: 'Slt Group',
      masseyStaffId: 'Staff ID',
      workloadAllocation: 'Workload Allocation',
      model: 'Model',
      department: 'Unit',
      contract: 'Contract',
      staffType: 'Type',
      jobTitle: 'Job Title',
    },
    messages: [
      { regex: 'is not allowed to be empty', message: 'is required' },
      { regex: 'FTE must be less than or equal to 1', message: 'FTE must be between 0.1 and 1.0' },
      {
        regex: 'FTE must be larger than or equal to 0.1',
        message: 'FTE must be between 0.1 and 1.0',
      },
      {
        regex: 'Profile - Teaching must be larger than or equal to 0',
        message: 'Profile - Teaching must be between 0.00 and 1.00',
      },
      {
        regex: 'Profile - Teaching must be less than or equal to 1',
        message: 'Profile - Teaching must be between 0.00 and 1.00',
      },
      {
        regex: 'Profile - Research must be larger than or equal to 0',
        message: 'Profile - Research must be between 0.00 and 1.00',
      },
      {
        regex: 'Profile - Research must be less than or equal to 1',
        message: 'Profile - Research must be between 0.00 and 1.00',
      },
      {
        regex: 'Profile - Citizenship must be larger than or equal to 0',
        message: 'Profile - Citizenship must be between 0.00 and 1.00',
      },
      {
        regex: 'Profile - Citizenship must be less than or equal to 1',
        message: 'Profile - Citizenship must be between 0.00 and 1.00',
      },
      {
        regex: 'Profile - Clinical must be larger than or equal to 0',
        message: 'Profile - Clinical must be between 0.00 and 1.00',
      },
      {
        regex: 'Profile - Clinical must be less than or equal to 1',
        message: 'Profile - Clinical must be between 0.00 and 1.00',
      },
      {
        regex: 'Profile - Service must be larger than or equal to 0',
        message: 'Profile - Service must be between 0.00 and 1.00',
      },
      {
        regex: 'Profile - Service must be less than or equal to 1',
        message: 'Profile - Service must be between 0.00 and 1.00',
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async validate(staff: any): Promise<string[]> {
    const msg: string[] = [];

    const err = await this._validate(staff);
    for (let key in err) {
      let _key = key.split(',')[0];
      const name = _key
        .split(',')[0]
        .split(' ')
        .map((w) => (this.transformations.naming[w] ? this.transformations.naming[w] : w))
        .join(' ');
      let message = err[key].replace(`"${_key}"`, name);
      for (const reg of this.transformations.messages) {
        message = message.replace(reg.regex, reg.message);
      }
      msg.push(message);
    }
    if (
      !err ||
      !Object.keys(err).some((k) =>
        [
          'teachingPercentage',
          'researchPercentage',
          'citizenshipPercentage',
          'clinicalPercentage',
          'servicePercentage',
        ].includes(k)
      )
    ) {
      const total = [
        staff.teachingPercentage,
        staff.researchPercentage,
        staff.servicePercentage,
        staff.citizenshipPercentage,
        staff.clinicalPercentage,
      ].reduce((a, c) => parseFloat(a) + parseFloat(c));
      if (total !== 1 && total !== 0.9999999999999999) {
        msg.push('Profile - Combined total must equal 1.00');
      }
    }

    if (!staff.id) {
      if (
        !err ||
        !Object.keys(err).some((k) =>
          [
            'firstName',
            'surname',
            'masseyStaffId',
            'department',
            'jobTitle',
            'model',
            'emailAddress',
            'efte',
            'teachingPercentage',
            'researchPercentage',
            'citizenshipPercentage',
            'clinicalPercentage',
            'servicePercentage',
          ].includes(k)
        )
      ) {
        const duplicateStaffInModel = await store.getValidateNew(staff);
        if (duplicateStaffInModel) {
          msg.push('Duplicate - Staff member exists for this model');
        } else {
          const duplicateStaffByName = await store.getByName(staff.displayName, staff.model.id);
          if (duplicateStaffByName) {
            msg.push(`Duplicate - Display Name (${staff.displayName}) exists for this model`);
          }
        }
        const duplicateStaffInDifferentModel = await store.getInOtherModels(staff);
        if (duplicateStaffInDifferentModel) {
          msg.push('Duplicate - Staff member exists in another model for this year');
        }
      }
    } else {
      if (
        !err ||
        !Object.keys(err).some((k) =>
          [
            'firstName',
            'surname',
            'masseyStaffId',
            'department',
            'jobTitle',
            'model',
            'emailAddress',
            'efte',
            'teachingPercentage',
            'researchPercentage',
            'citizenshipPercentage',
            'clinicalPercentage',
            'servicePercentage',
          ].includes(k)
        )
      ) {
        const _result = await store.getValidateNew(staff);
        if (_result && _result.id !== staff.id) {
          msg.push('Duplicate - Staff member exists for this model');
        }
      }
    }
    return msg;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _validate(data: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Joi.validate(data, StaffSchema, { convert: true, abortEarly: false }, (errs) => {
        const converted = ConvertToForms(errs);
        resolve(converted);
      });
    });
  }
}

export const Staff = new StaffValidator();
