import React from 'react';
import { inject, observer } from 'mobx-react';
import './userCard.css';

const UserCard = (props) => {
  const userData = props.authUserStore.currentUser;
  const userInitials = (userData.user.firstName[0] + userData.user.surname[0]).toUpperCase();
  const userEmail = userData.user.identifier;
  const userName = `${userData.user.firstName} ${userData.user.surname}`;

  return (
    <div className='header-user-card-wrap'>
      <div className='header-user-circle-frame'>
        <span>{userInitials}</span>
      </div>
      <div className={'header-user-card'}>
        <span className='header-user-card-name'>{userName}</span>
        <span className='header-user-card-email'>{userEmail}</span>
      </div>
    </div>
  );
};

export default inject('authUserStore')(observer(UserCard));
