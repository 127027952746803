import React from 'react';

function TableActionCellView({ view, text, dataItem }) {
  function onViewClick() {
    view(dataItem);
  }

  return (
    <p
      className='grid-link'
      onClick={onViewClick}
    >
      {text}
    </p>
  );
}

export default TableActionCellView;
