// @flow

import { Space, Table } from 'antd';
import React, { Component } from 'react';
import fileDownload from 'js-file-download';
import { PageSettingDto } from '../../dto/clientDto';
import { SortDto, GeneratedReportsDto } from '../../dto';
import { ReportSearchStore } from '../../mobx';
import './search-components.css';

const reportSearchStore = new ReportSearchStore();

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSort: Function,
  columns: Array<{
    field: string,
    title: string,
    width: string,
  }>,
  sortable: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  export: Function,
  exporting: boolean,
  exportable: boolean,
};

type State = {
  pageSetting: PageSettingDto,
  sort: SortDto[],
};

class SearchReportGrid extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageSetting: this.createState(0, 25),
    };
  }

  createState = (skip: number, take: number) => {
    let data = this.props.data.data ? this.props.data : [];
    return {
      items: data === [] ? data : data.data,
      total: data === [] ? data.length : data.total,
      skip: skip,
      pageSize: take,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [25, 50, 75],
        previousNext: true,
      },
    };
  };

  onChange = (pagination, sorter, action) => {
    if (action == 'paginate') {
      this.onPaginate(pagination.current, pagination.pageSize);
    }
    if (action == 'sort') {
      this.onColumnSort(sorter);
    }
  };

  onPaginate = (page, pageSize) => {
    const skip = pageSize * (page - 1);
    this.setState({
      pageSetting: this.createState(skip, pageSize),
    });
    // eslint-disable-next-line react/prop-types
    this.props.onPageChange(pageSize, skip);
  };

  onColumnSort = (sorter) => {
    let sort = [];
    if (sorter.order == 'ascend') {
      sort = [{ field: sorter.column.field, dir: 'asc' }];
    }
    if (sorter.order == 'descend') {
      sort = [{ field: sorter.column.field, dir: 'desc' }];
    }

    this.setState({ sort: sort });
    if (this.props.onSort) {
      this.props.onSort(sort);
    }
  };

  onRequestReport = async (reportName) => {
    const reportSearch: GeneratedReportsDto = {
      id: null,
      reportName: reportName,
      model: null,
      owner: null,
      department: null,
      createDate: null,
    };

    let fileResponse = await reportSearchStore.getReportBlob(reportSearch);
    if (reportSearch?.reportName.slice(-3) === 'pdf') {
      const binaryString = Buffer.from(fileResponse).toString();
      const binaryLen = binaryString.length;

      let bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      fileResponse = new Blob([bytes], { type: 'application/pdf' });
      fileDownload(fileResponse,reportName)
    } else {
    const bufferArray = new Uint8Array(fileResponse.data);

    const contentType = reportName.endsWith('.xlsx')
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : reportName.endsWith('.pdf')
      ? 'application/pdf'
      : 'application/octet-stream';

    const blob = new Blob([bufferArray], { type: contentType });

    fileDownload(blob, reportName);}
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidUpdate(prevProps: any) {
    let data = this.props.data;
    if (prevProps.data !== this.props.data) {
      this.setState({ pageSetting: this.createState(data.skip, data.limit) });
    }
  }

  render() {
    return (
      <div className='card'>
        <div className='card-body'>
          <div className='search-results-header'>
            <div className='search-sub-title'>
              <p>Previous Reports</p>
            </div>
          </div>
          <div className='search-results-grid'>
            <div className='grid-frame'>
              <Table
                columns={this.props.columns.concat({
                  title: '',
                  key: 'actions',
                  width: '140px',
                  render: (text, record) => (
                    <Space size='middle'>
                      <a
                        onClick={() => {
                          this.onRequestReport(record.reportName);
                        }}
                      >
                        {record.reportName.split('.')[1]}
                      </a>
                    </Space>
                  ),
                })}
                rowKey='id'
                dataSource={this.state.pageSetting.items}
                bordered={true}
                size='small'
                tableLayout='fixed'
                pagination={
                  this.state.pageSetting.pageable
                    ? {
                        position: ['bottomLeft'],
                        defaultPageSize: this.state.pageSetting.pageSize,
                        total: this.state.pageSetting.total,
                        showSizeChanger: true,
                      }
                    : false
                }
                onChange={(pagination, filters, sorter, extra) => {
                  this.onChange(pagination, sorter, extra.action);
                }}
                showSorterTooltip={this.props.sortable ? true : false}
                sortDirections={['ascend', 'descend', 'ascend']}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchReportGrid;
