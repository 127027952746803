// flow

import { AutoComplete } from 'antd';
import * as mobx from 'mobx';
import React, { Component } from 'react';
import './course-auto-complete.css';

class CourseAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      value: '',
    };
  }

  componentDidMount() {
    if (this.props.course) {
      this.setState({ value: this.props.course?.course });
      this.props.onChange(this.props.course, this.props.index);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.course !== this.props.course && this.props.course === null) {
      this.setState({
        value: ''
      });
    }
    if (prevProps.data !== this.props.data && this.props.course === null) {
      this.setState({
        data: this.props.data
      });
    }
  }

  onChange = (value) => {
    let updateDataDisplay = this.props.data?.map((d) => ({ ...d, display: `${d.code} ${d.course}` }));

    let filtered = updateDataDisplay?.filter((d) =>
      d?.display?.includes(value),
    );

    this.setState({
      value: value,
      data: filtered,
    });

    let course = filtered?.find((s) => {
       return s.display === value;
    });

    this.props.onChange(course, this.props.index);
  };

  render() {
    return (
      <AutoComplete
        placeholder='Type to Search'
        data={this.state.data}
        value={this.state.value}
        onChange={this.onChange}
        className={this.props.className}
        disabled={this.props.disabling ? true : false}
      >
        {this.state.data?.map((data) => (
          <AutoComplete.Option
            key={data.id}
            value={`${data.code} ${data.course}`}
          >
            {data.display}
          </AutoComplete.Option>
        ))}
      </AutoComplete>
    );
  }
}

export default CourseAutoComplete;
