import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import './admin.css';

type State = {
    version: { name: string, version: string }[],
}

class Version extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.versionStore = this.props.versionStore;
        this.state = {
            version: [],
        }
    }

    async componentDidMount() {
        await this.getVersions();
    }

    async getVersions() {
        const web = { name: 'Web Service', version: this.versionStore.getWeb() };
        const api = { name: 'Api Service', version: (await this.versionStore.getApi()).version, };

        const rv = await this.versionStore.getReporting().catch((_) => { });
        const report = {
            name: 'Reporting Service',
            version: rv ? rv.version : null,
        };

        this.setState({ version: [web, api, report] });
    }

    render() {
        return (
            <div className='version-details'>
                {this.state.version.map((v) => {
                    return (
                        <span
                            key={v.name.split(' ').join('_').toLowerCase()}
                            className={`version${v.version ? '' : ' offline'}`}
                        >
                            {`${v.name}`}{' '}
                            <span className='number'>{`${v.version ? `v${v.version}` : 'offline'}`}</span>
                        </span>
                    );
                })}
            </div>
        );
    }
}

export default inject('versionStore')(observer(Version));