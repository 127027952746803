
// @flow
import { StaffDto } from '../dto';


export default class StaffUtility {


    static getGeneratedDisplayName(staff: StaffDto): string {
        if (!staff || !staff.model || !staff.firstName || !staff.surname || !staff.jobTitle || !staff.department) {
            return '';
        }
        const title = staff.jobTitle.title.replace(/[^0-9a-z-A-Z ]/g, '').replace(/\s+/g, ' ');
        let name = `${staff.firstName} ${staff.surname}-${title}-${staff.department.short}`;

        return name;
    }

    static getGeneratedStaffCode(staff: StaffDto): string {
        if (!staff || !staff.model || !staff.firstName || !staff.surname || !staff.jobTitle || !staff.department) {
            return '';
        }
        const title = staff.jobTitle.title.toLowerCase().replace(/[^0-9a-z-A-Z ]/g, '').replace(/\s+/g, ' ').replace(/ /g, '-').replace(/-+/g, '-');
        const department = staff.department.short.toLowerCase().replace(/[^0-9a-z-A-Z ]/g, '').replace(/\s+/g, ' ').replace(/ /g, '-').replace(/-+/g, '-');
        const name = `${staff.firstName.toLowerCase()}-${staff.surname.toLowerCase()}`;
        const model = staff.model.code.toLowerCase();

        return `${model}_${name}-${title}-${department}`;
    }
}