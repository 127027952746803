// @flow
import React from 'react';

function TableStyleCellDiffByTolerance({ record, field, type }) {
  const fieldToColour = record[field];
  let variance = 0;

  const findEnrolmentBand = (offeringEnrolments) => {
    return (enrolmentBand) => {
      return offeringEnrolments >= enrolmentBand?.enrolmentBandFrom && offeringEnrolments <= enrolmentBand?.enrolmentBandTo;
    };
  };

  const getModelTarget = () => {
    if (type === 'offering') {
      const enrolmentBands = record?.offering?.model?.enrolmentBands;
      const offeringEnrolments = record?.offering?.enrolments;

      if (enrolmentBands != null && offeringEnrolments != null) {
        const matchingEnrolmentBand = enrolmentBands.find(findEnrolmentBand(offeringEnrolments));
        return matchingEnrolmentBand?.offeringTargetHours;
      }
    }
    else if (type === 'staff') {
      return record.target;
    }
  };

  if (type === 'offering') {
    variance = record?.model?.offeringVariance;
  } else if (type === 'staff') {
    const varianceModel = record?.model?.staffVariance;
    const varianceStaffModel = record?.staff?.model?.staffVariance;
    variance = varianceModel ? varianceModel : varianceStaffModel;
    
  }
    
  const getColorFromModelTarget = (modelTarget, variance) => {
    let under = false;
    let over = false;
    const red = '#c40108';
    const black = '#000000';

    if (fieldToColour >= (modelTarget * (1 + variance))) {
      over = true;
    }
    if (fieldToColour <= (modelTarget * (1 - variance))) {
      under = true;
    }

    if (over || under) {
      return red;
    }
    return black;
  };

  const getColor = () => {
    const modelTarget = getModelTarget();
    return getColorFromModelTarget(modelTarget, variance);
  };

  return <span style={{ width: '150px', textAlign: 'right', color: getColor() }}>{fieldToColour}</span>;
}

export default TableStyleCellDiffByTolerance;
