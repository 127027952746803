// @flow

import React, { Component } from 'react';
import { Table, Button } from 'antd';

import { CollapsePanel, GridRemoveDialog, SpinnerBasic } from '../../components';
import { PageSettingDto } from '../../dto/clientDto';
import { SortDto, AllocationDto, AllocationYearDto } from '../../dto';
import './staff-activity.css';
import { AddActivityButton } from '../../components/ui';
import { PermissionsHelper } from '../../utility';
import { inject, observer } from 'mobx-react';

const { Column } = Table;

type Props = {
  editable: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  panelExpanded: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onPageChange: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onPanelSelect: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSort: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDelete: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit: Function,
  sortable: boolean,
  category: string,
  title: string,
  readOnlyView: boolean,
  loading: boolean,
};

type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removals: any[],
  pageSetting: PageSettingDto,
  panelExpanded: boolean,
  dialog: {
    visible: boolean,
    lines: string[],
  },
  sort: SortDto[],
};

class Summary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.authUserStore = props.authUserStore;
    this.viewStore = props.viewStore;
    this.state = {
      data: [],
      removals: [],
      pageSetting: this.createState(0, 25),
      panelExpanded: this.props.panelExpanded,
      dialog: {
        visible: false,
        lines: [],
      },
      sort: [],
      loading: this.props.loading,
    };

    this.onSortChange = this.onSortChange.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageChange = (event: any) => {
    this.setState({
      pageSetting: this.createState(event.page.skip, event.page.take),
    });
    this.props.onPageChange(event.page.take, event.page.skip);
  };

  createState = (skip: number, take: number) => {
    let itemsData;
    const data = this.props.data && this.props.data.data ? this.props.data : [];
    if (data === []) {
      itemsData = data;
    } else if (data.data && data.data.length > 0) {
      const realData = data.data;
      realData.forEach((data, index) => {
        data['key'] = index;
      });
      itemsData = realData;
    }

    return {
      items: itemsData,
      total: data === [] ? data.length : data.total,
      skip: skip,
      pageSize: take,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [25, 50, 75],
        previousNext: true,
      },
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  componentDidMount() {}

  onConfirmDelete = (confirmed: boolean) => {
    this.setState({ dialog: { lines: [], visible: false } });
    if (confirmed) {
      let items = this.state.removals;
      this.setState({ data: this.state.data.slice(), removals: [] }, () => {
        this.props.onDelete(items);
      });
    }
  };

  onDelete(item: AllocationDto) {
    this.setState({
      removals: [item],
      dialog: {
        lines: ['Do you wish to delete the selected allocation?'],
        visible: true,
      },
    });
  }

  onChange = (pagination, sorter, action) => {
    if (action == 'paginate') {
      this.onPaginate(pagination.current, pagination.pageSize);
    }
    if (action == 'sort') {
      this.onColumnSort(sorter);
    }
  };

  onPaginate = (page, pageSize) => {
    const skip = pageSize * (page - 1);
    this.setState({
      pageSetting: this.createState(skip, pageSize),
    });
    this.props.onPageChange(pageSize, skip);
  };

  mapSorter(sorter) {
    if (sorter?.column && sorter?.order) {
      return {
        field: sorter.column.field,
        dir: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
      };
    }

    return null;
  }

  onColumnSort = (sorter) => {
    let sort = [];
    if (Array.isArray(sorter)) {
      sort = sorter?.map(this.mapSorter).filter((s) => s) || [];
    } else if (this.mapSorter(sorter)) {
      sort = [this.mapSorter(sorter)];
    }

    this.setState({ sort });
    if (this.props.onSort) {
      this.props.onSort(sort);
    }
  };

  onEdit = (item: AllocationYearDto) => {
    if (this.props.onEdit) {
      this.props.onEdit(
        item,
        item.activity.type.code,
        this.props.category === 'teaching_supervision' ? 'teaching' : 'activity'
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidUpdate(prevProps: any) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
        data: !this.props.loading ? this.props.data.data.slice(0) : [],
      });
    }

    let data = this.props.data;
    if (prevProps.data !== this.props.data) {
      this.setState({
        pageSetting: this.createState(data.skip, data.limit),
      });
    }
  }

  renderCourseAndOffering(data) {
    if (data.offering) {
      return data.offering.offering;
    } else if (data.course) {
      return data.course.course;
    } else {
      return '';
    }
  }

  renderContent(readOnlyView, category) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    let commandCellComponent = readOnlyView ? (
      ''
    ) : (
      <Column
        width='150px'
        cell={(props) => (
          <>
            <Button
              className='k-primary k-button k-grid-remove-command'
              disabled={this.props.editable ? !this.props.editable : false}
              onClick={() => this.onEdit(props.dataItem)}
              primary='true'
            >
              Edit
            </Button>
            <button
              className='k-button k-grid-remove-command'
              onClick={() => this.onDelete(props.dataItem)}
            >
              Delete
            </button>
          </>
        )}
        sortable={false}
      />
    );

    if (category === 'teaching_supervision') {
      return (
        <Table
          style={{ minHeight: '120px' }}
          rowKey='id'
          dataSource={this.state.pageSetting.items}
          bordered={true}
          size='small'
          tableLayout='fixed'
          pagination={
            this.state.pageSetting.pageable
              ? {
                  position: ['bottomLeft'],
                  defaultPageSize: this.state.pageSetting.pageSize,
                  total: this.state.pageSetting.total,
                  showSizeChanger: true,
                }
              : false
          }
          onChange={(pagination, filters, sorter, extra) => {
            this.onChange(pagination, sorter, extra.action);
          }}
          showSorterTooltip={this.props.sortable ? true : false}
          sortDirections={['ascend', 'descend']}
        >
          <Column
            field='course.code'
            title='Course Code'
            width='120px'
            dataIndex={['course', 'code']}
            sorter={{
              multiple: 7,
            }}
            render={(text, record) => {
              if (record?.course?.id != null) {
                const ref = `#/course/${record.course.id}`;
                return <a className='course-code-num' href={ref}>{text}</a>;
              } else {
                return <a>{text}</a>;
              }
            }}
          />
          <Column
            field='course.course'
            title='Course / Offering'
            width='130px'
            sorter={{
              multiple: 6,
            }}
            render={(text, record) => {
              return this.renderCourseAndOffering(record);
            }}
          />
          <Column
            field='activity.activity'
            title='Activity'
            width='150px'
            dataIndex={['activity', 'activity']}
            sorter={{
              multiple: 5,
            }}
          />
          <Column
            field='unit'
            title='Number'
            width='80px'
            align='right'
            dataIndex='unit'
            sorter={{
              multiple: 4,
            }}
          />
          <Column
            field='variable.variable'
            title='Variable'
            width='80px'
            dataIndex={['variable', 'variable']}
            sorter={{
              multiple: 3,
            }}
          />
          <Column
            field='share.share'
            align='right'
            title='Share'
            width='80px'
            dataIndex={['share', 'share']}
            sorter={{
              multiple: 2,
            }}
          />
          <Column
            field='period.period'
            title='When'
            width='120px'
            dataIndex={['period', 'period']}
            sorter={{
              multiple: 1,
            }}
          />
          <Column
            field='calculated'
            title='Calculated'
            width='85px'
            align='right'
            dataIndex='calculated'
          />
          <Column
            field='hours'
            title='Assigned'
            align='right'
            width='85px'
            dataIndex='hours'
          />
          <Column
            field='notes'
            title='Notes'
            width='120px'
            dataIndex='notes'
          />
          {!readOnlyView &&
            !PermissionsHelper.isRestrictedAccess({
              user: this.authUserStore.currentUser,
              year: this.viewStore.year,
              model: this.viewStore.model,
            }) && (
              <Column
                width='150px'
                render={(text, record) => (
                  <>
                    <Button
                      className='k-primary k-button k-grid-remove-command'
                      disabled={this.props.editable ? !this.props.editable : false}
                      onClick={() => this.onEdit(record)}
                      primary='true'
                    >
                      Edit
                    </Button>
                    <button
                      className='k-button k-grid-remove-command'
                      onClick={() => this.onDelete(record)}
                    >
                      Delete
                    </button>
                  </>
                )}
                sortable={false}
              />
            )}
        </Table>
      );
    } else if (category === 'research_enterprise') {
      return (
        <Table
          style={{ minHeight: '120px' }}
          dataSource={this.state.pageSetting.items}
          bordered={true}
          size='small'
          tableLayout='fixed'
          pagination={
            this.state.pageSetting.pageable
              ? {
                  position: ['bottomLeft'],
                  defaultPageSize: this.state.pageSetting.pageSize,
                  total: this.state.pageSetting.total,
                  showSizeChanger: true,
                }
              : false
          }
          onChange={(pagination, filters, sorter, extra) => {
            this.onChange(pagination, sorter, extra.action);
          }}
          showSorterTooltip={this.props.sortable ? true : false}
          sortDirections={['ascend', 'descend']}
        >
          <Column
            field='activity.activity'
            title='Activity'
            dataIndex={['activity', 'activity']}
            sorter={{
              multiple: 5,
            }}
          />
          <Column
            field='unit'
            title='Number'
            dataIndex='unit'
            width='80px'
            align='right'
            sorter={{
              multiple: 4,
            }}
          />
          <Column
            field='variable.variable'
            title='Variable'
            dataIndex={['variable', 'variable']}
            sorter={{
              multiple: 3,
            }}
          />
          <Column
            field='share.share'
            title='Share'
            width='80px'
            align='right'
            dataIndex={['share', 'share']}
            sorter={{
              multiple: 2,
            }}
          />
          <Column
            field='period.period'
            title='When'
            dataIndex={['period', 'period']}
            sorter={{
              multiple: 1,
            }}
          />
          <Column
            field='calculated'
            title='Calculated'
            width='100px'
            align='right'
            dataIndex='calculated'
          />
          <Column
            field='hours'
            title='Assigned'
            width='100px'
            align='right'
            dataIndex='hours'
          />
          <Column
            field='notes'
            title='Notes'
            dataIndex='notes'
          />
          {!readOnlyView &&
            !PermissionsHelper.isRestrictedAccess({
              user: this.authUserStore.currentUser,
              year: this.viewStore.year,
              model: this.viewStore.model,
            }) && (
              <Column
                width='150px'
                render={(text, record) => (
                  <>
                    <Button
                      className='k-primary k-button k-grid-remove-command'
                      disabled={this.props.editable ? !this.props.editable : false}
                      onClick={() => this.onEdit(record)}
                      primary='true'
                    >
                      Edit
                    </Button>
                    <button
                      className='k-button k-grid-remove-command'
                      onClick={() => this.onDelete(record)}
                    >
                      Delete
                    </button>
                  </>
                )}
                sortable={false}
              />
            )}
        </Table>
      );
    } else if (category === 'academic_citizenship') {
      return (
        <Table
          style={{ minHeight: '120px' }}
          dataSource={this.state.pageSetting.items}
          bordered={true}
          size='small'
          tableLayout='fixed'
          pagination={
            this.state.pageSetting.pageable
              ? {
                  position: ['bottomLeft'],
                  defaultPageSize: this.state.pageSetting.pageSize,
                  total: this.state.pageSetting.total,
                  showSizeChanger: true,
                }
              : false
          }
          onChange={(pagination, filters, sorter, extra) => {
            this.onChange(pagination, sorter, extra.action);
          }}
          showSorterTooltip={this.props.sortable ? true : false}
          sortDirections={['ascend', 'descend']}
        >
          <Column
            field='activity.activity'
            title='Activity'
            dataIndex={['activity', 'activity']}
            sorter={{
              multiple: 5,
            }}
          />
          <Column
            field='unit'
            title='Number'
            dataIndex='unit'
            width='80px'
            align='right'
            sorter={{
              multiple: 4,
            }}
          />
          <Column
            field='variable.variable'
            title='Variable'
            dataIndex={['variable', 'variable']}
            sorter={{
              multiple: 3,
            }}
          />
          <Column
            field='share.share'
            title='Share'
            width='80px'
            align='right'
            dataIndex={['share', 'share']}
            sorter={{
              multiple: 2,
            }}
          />
          <Column
            field='period.period'
            title='When'
            dataIndex={['period', 'period']}
            sorter={{
              multiple: 1,
            }}
          />
          <Column
            field='calculated'
            title='Calculated'
            align='right'
            width='100px'
            dataIndex='calculated'
          />
          <Column
            field='hours'
            title='Assigned'
            width='100px'
            align='right'
            dataIndex='hours'
          />
          <Column
            field='notes'
            title='Notes'
            dataIndex='notes'
          />
          {!readOnlyView &&
            !PermissionsHelper.isRestrictedAccess({
              user: this.authUserStore.currentUser,
              year: this.viewStore.year,
              model: this.viewStore.model,
            }) && (
              <Column
                width='150px'
                render={(text, record) => (
                  <>
                    <Button
                      className='k-primary k-button k-grid-remove-command'
                      disabled={this.props.editable ? !this.props.editable : false}
                      onClick={() => this.onEdit(record)}
                      primary='true'
                    >
                      Edit
                    </Button>
                    <button
                      className='k-button k-grid-remove-command'
                      onClick={() => this.onDelete(record)}
                    >
                      Delete
                    </button>
                  </>
                )}
                sortable={false}
              />
            )}
        </Table>
      );
    } else if (category === 'service') {
      return (
        <Table
          style={{ minHeight: '120px' }}
          dataSource={this.state.pageSetting.items}
          bordered={true}
          size='small'
          tableLayout='fixed'
          pagination={
            this.state.pageSetting.pageable
              ? {
                  position: ['bottomLeft'],
                  defaultPageSize: this.state.pageSetting.pageSize,
                  total: this.state.pageSetting.total,
                  showSizeChanger: true,
                }
              : false
          }
          onChange={(pagination, filters, sorter, extra) => {
            this.onChange(pagination, sorter, extra.action);
          }}
          showSorterTooltip={this.props.sortable ? true : false}
          sortDirections={['ascend', 'descend']}
        >
          <Column
            field='activity.activity'
            title='Activity'
            dataIndex={['activity', 'activity']}
            sorter={{
              multiple: 5,
            }}
          />
          <Column
            field='unit'
            title='Number'
            align='right'
            dataIndex='unit'
            width='80px'
            sorter={{
              multiple: 4,
            }}
          />
          <Column
            field='variable.variable'
            title='Variable'
            dataIndex={['variable', 'variable']}
            sorter={{
              multiple: 3,
            }}
          />
          <Column
            field='share.share'
            title='Share'
            width='80px'
            align='right'
            dataIndex={['share', 'share']}
            sorter={{
              multiple: 2,
            }}
          />
          <Column
            field='period.period'
            title='When'
            dataIndex={['period', 'period']}
            sorter={{
              multiple: 1,
            }}
          />
          <Column
            field='calculated'
            title='Calculated'
            width='100px'
            align='right'
            dataIndex='calculated'
          />
          <Column
            field='hours'
            title='Assigned'
            width='100px'
            align='right'
            dataIndex='hours'
          />
          <Column
            field='notes'
            title='Notes'
            dataIndex='notes'
          />
          {!readOnlyView &&
            !PermissionsHelper.isRestrictedAccess({
              user: this.authUserStore.currentUser,
              year: this.viewStore.year,
              model: this.viewStore.model,
            }) && (
              <Column
                width='150px'
                render={(text, record) => (
                  <>
                    <Button
                      className='k-primary k-button k-grid-remove-command'
                      disabled={this.props.editable ? !this.props.editable : false}
                      onClick={() => this.onEdit(record)}
                      primary='true'
                    >
                      Edit
                    </Button>
                    <button
                      className='k-button k-grid-remove-command'
                      onClick={() => this.onDelete(record)}
                    >
                      Delete
                    </button>
                  </>
                )}
                sortable={false}
              />
            )}
        </Table>
      );
    } else if (category === 'clinical_professional_practice') {
      return (
        <Table
          style={{ minHeight: '120px' }}
          dataSource={this.state.pageSetting.items}
          bordered={true}
          size='small'
          tableLayout='fixed'
          pagination={
            this.state.pageSetting.pageable
              ? {
                  position: ['bottomLeft'],
                  defaultPageSize: this.state.pageSetting.pageSize,
                  total: this.state.pageSetting.total,
                  showSizeChanger: true,
                }
              : false
          }
          onChange={(pagination, filters, sorter, extra) => {
            this.onChange(pagination, sorter, extra.action);
          }}
          showSorterTooltip={this.props.sortable ? true : false}
          sortDirections={['ascend', 'descend']}
        >
          <Column
            field='activity.activity'
            title='Activity'
            dataIndex={['activity', 'activity']}
            sorter={{
              multiple: 5,
            }}
          />
          <Column
            field='unit'
            title='Number'
            align='right'
            dataIndex='unit'
            width='80px'
            sorter={{
              multiple: 4,
            }}
          />
          <Column
            field='variable.variable'
            title='Variable'
            dataIndex={['variable', 'variable']}
            sorter={{
              multiple: 3,
            }}
          />
          <Column
            field='share.share'
            title='Share'
            width='80px'
            align='right'
            dataIndex={['share', 'share']}
            sorter={{
              multiple: 2,
            }}
          />
          <Column
            field='period.period'
            title='When'
            dataIndex={['period', 'period']}
            sorter={{
              multiple: 1,
            }}
          />
          <Column
            field='calculated'
            title='Calculated'
            align='right'
            width='100px'
            dataIndex='calculated'
          />
          <Column
            field='hours'
            align='right'
            title='Assigned'
            width='100px'
            dataIndex='hours'
          />
          <Column
            field='notes'
            title='Notes'
            dataIndex='notes'
          />
          {!readOnlyView &&
            !PermissionsHelper.isRestrictedAccess({
              user: this.authUserStore.currentUser,
              year: this.viewStore.year,
              model: this.viewStore.model,
            }) && (
              <Column
                width='150px'
                render={(text, record) => (
                  <>
                    <Button
                      className='k-primary k-button k-grid-remove-command'
                      disabled={this.props.editable ? !this.props.editable : false}
                      onClick={() => this.onEdit(record)}
                      primary='true'
                    >
                      Edit
                    </Button>
                    <button
                      className='k-button k-grid-remove-command'
                      onClick={() => this.onDelete(record)}
                    >
                      Delete
                    </button>
                  </>
                )}
                sortable={false}
              />
            )}
        </Table>
      );
    }
  }

  onSortChange = (event) => {
    this.setState({ sort: event.sort });
    if (this.props.onSort) {
      this.props.onSort(event.sort);
    }
  };

  onSortChangeAlt = (event) => {
    const sort = event.sort.slice();
    const idx = sort.findIndex((s) => ['Course/offering'].includes(s.field));
    if (idx >= 0) {
      sort.splice(
        idx,
        1,
        { field: 'course.course', dir: sort[idx].dir },
        { field: 'offering.offering', dir: sort[idx].dir }
      );
    }
    this.setState({ sort: event.sort });
    if (this.props.onSort) {
      this.props.onSort(sort);
    }
  };

  onPanelSelect = (e) => {
    if (this.props.onPanelSelect) {
      this.props.onPanelSelect(e);
    }
  };

  render() {
    return (
      <div>
        <CollapsePanel
          expanded={this.state.panelExpanded}
          title={this.props.originalCategory + ' Activities'}
          onSelect={this.onPanelSelect}
          tabIndex={1}
        >
          <div className='grid-frame'>
            {this.state.loading ? (
              <div className='load-frame'>
                <SpinnerBasic />
              </div>
            ) : (
              <div className='upper-panel-button-group'>
                {!this.props.readOnlyView &&
                  !PermissionsHelper.isRestrictedAccess({
                    user: this.authUserStore.currentUser,
                    year: this.viewStore.year,
                    model: this.viewStore.model,
                  }) && (
                    <div className='upper-add-edit-activity-frame'>
                      <AddActivityButton
                        disabled={this.props.loading || !this.props.editable}
                        onEditDialog={this.props.onEdit}
                        type={'staff'}
                        dialog={
                          this.props.category === 'teaching_supervision' ? 'teaching' : 'activity'
                        }
                      />
                    </div>
                  )}
                {this.renderContent(this.props.readOnlyView, this.props.category)}
              </div>
            )}
          </div>
        </CollapsePanel>

        {this.state.dialog.visible && (
          <GridRemoveDialog
            response={this.onConfirmDelete}
            lines={this.state.dialog.lines}
          />
        )}
      </div>
    );
  }
}

export default inject('authUserStore', 'viewStore')(observer(Summary));
