// @flow

import { makeAutoObservable } from 'mobx';
import { RolloverDto } from '../dto';
import Api from './api';

export default class RolloverStore {
  constructor() {
    makeAutoObservable(this);
  }

  async getModelsByYear(year: number) {
    return Api.get(`/models/${year}`);
  }

  async process(rollover: RolloverDto) {
    return new Promise((resolve, reject) => {
      Api.post('/rollover/process', rollover)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }
}
