// @flow
import { makeAutoObservable } from 'mobx';

import { UserDto, UserGroupDto, UserModelGroupDto, SearchUserDto } from '../dto';
import Api from './api';

export default class UserStore {
  userList: UserDto[];
  allUserList: UserDto[];
  userGroupList: UserGroupDto[];
  roles: UserGroupDto[];

  constructor() {
    makeAutoObservable(this);
  }

  async initData() {
    this.userGroupList = await Api.get('/usergroups');
  }

  getUserGroups(active: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(active != null ? `/usergroups?active=${active}` : '/usergroups').then((data) => {
        this.userGroupList = data;
        resolve(data);
      });
    });
  }

  async getModels(year: number) {
    this.models = await Api.get(`/models/${year}`);
    return this.models;
  }

  async checkUserExists(identifier: string) {
    this.unqiqueUser = await Api.get(`/users/identifier/unique/${identifier}`);
    return this.unqiqueUser;
  }

  async checkDuplicateUserExists(id: string, identifier: string) {
    this.uniqueUser = await Api.get(`/users/duplicate/${id}/identifier/${identifier}`);
    return this.uniqueUser;
  }

  async getRoles(active: boolean) {
    this.roles = await Api.get(active != null ? `/usergroups?active=${active}` : '/usergroups');
    return this.roles;
  }

  async getDepartments(year: number, modelId: ?string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      this.departments = Api.get(
        modelId ? `/departments/model/${modelId}` : `/departments/${year}?active=true`
      );
      resolve(this.departments);
    });
  }

  async getAllUsers(active: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(active != null ? `/users?active=${active}` : '/users').then((users) => {
        let data = users.data;
        for (let key in data) {
          data[key].groupName = data[key].group;
          data[key].addedByName =
            (data[key].addedBy.firstName || '') + ' ' + (data[key].addedBy.surname || '');
        }

        this.allUserList = users;
        resolve(users);
      });
    });
  }

  async getUsers(skip = 0, limit = 25, active: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(
        active != null
          ? `/users/page?limit=${limit}&skip=${skip}&active=${active}`
          : `/users/page?limit=${limit}&skip=${skip}`
      ).then((users) => {
        let data = users.data;
        for (let key in data) {
          data[key].groupName = data[key].group;
          data[key].addedByName =
            (data[key].addedBy.firstName || '') + ' ' + (data[key].addedBy.surname || '');
        }

        this.userList = users;
        resolve(users);
      });
    });
  }

  async getAllUsersByModel(id, active) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(active != null ? `/users/model/${id}?active=${active}` : `/users/model/${id}`).then(
        (users) => {
          let data = users.data;
          for (let key in data) {
            data[key].groupName = data[key].userGroup.group;
            data[key].addedByName =
              (data[key].addedBy.firstName || '') + ' ' + (data[key].addedBy.surname || '');
          }

          this.allUserList = users;
          resolve(users);
        }
      );
    });
  }

  async getBPOUsers() {
    const roleOrder = { as: 0, mm: 1, wm: 2, bpo: 3 };
    const bpoUsers = await Api.get('/users/allBPOs');
    console.log('bpoUsers');
    bpoUsers.forEach((user) => {
      const highestGroup = user?.userModelGroups.reduce((highest, group) => {
        const roleValue = roleOrder[group.userGroupCode];
        return roleValue < roleOrder[highest] ? group.userGroupCode : highest;
      });
      user.groupName = highestGroup.userGroupCode;
      user.addedByName = (user?.addedBy?.firstName || '') + ' ' + (user?.addedBy?.surname || '');
    });
    this.allUserList = bpoUsers;
    return bpoUsers;
  }

  onUserClean(data: UserDto) {
    // need remove some items
    delete data.inEdit;
    delete data.isNew;
    delete data.groupName;
    delete data.dateAdded;
    delete data.addedByName;
    return data;
  }

  async addUser(user: UserDto) {
    return new Promise((resolve, reject) => {
      Api.post('/users', user).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async updateUser(user: UserDto) {
    return new Promise((resolve, reject) => {
      Api.put(`/users/${user.id}`, user).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async updateUserModelGroup(data: UserModelGroupDto[]) {
    return new Promise((resolve, reject) => {
      Api.post('/usermodelgroups/update', data).then(
        (d) => {
          resolve(d);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async addUserModelGroup(data: UserModelGroupDto) {
    return new Promise((resolve, reject) => {
      Api.post('/usermodelgroups/multiple', data).then(
        (d) => {
          resolve(d);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  setUserAdd(data: UserDto, skip: number, limit: number) {
    delete data.id;
    let result = this.onUserClean(data);
    Api.post('/users', result).then(() => {
      this.getUsers(skip, limit);
    });
  }

  setUserUpdate(data: UserDto, skip: number, limit: number) {
    data.addedBy = { id: data.addedBy.id };
    data.userGroup = { id: data.userGroup.id };
    let result = this.onUserClean(data);
    Api.put(`/users/${result.id}`, result).then(() => {
      this.getUsers(skip, limit);
    });
  }

  setUserRemove(id: string, skip: number, limit: number) {
    Api.delete(`/users/${id}`).then(() => {
      this.getUsers(skip, limit);
    });
  }

  async delete(id: string) {
    return new Promise((resolve, reject) => {
      Api.delete(`/users/${id}`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async deleteUserModelGroups(id: string) {
    return new Promise((resolve, reject) => {
      Api.put(`/usermodelgroups/remove/${id}`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async bulkUpdateUserStatus(searchFilter: SearchUserDto, userStatus: UserDto) {
    const bulkData = {filter: searchFilter, updateStatus: userStatus?.id};
    return new Promise((resolve, reject) => {
      Api.post('/users/bulkUpdateStatus', bulkData).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
