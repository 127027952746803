import React, { Component } from 'react';

import {
  Header,
  BodyLayout,
  CollapsePanel,
  MessageLine,
  Spinner,
  ConfirmationDialog,
  SpinnerBasic,
  ExportButton,
  SearchReportGrid,
} from '../../components';
import {
  DepartmentDto,
  ModelDto,
  OwnerDto,
  GeneratedReportsDto,
  AllocationYearDto,
  AuthenticatedUserDto,
  StaffDto,
} from '../../dto';
import { inject, observer } from 'mobx-react';
import StaffReports from './staff-reports';
import CourseReports from './course-reports';
import { StringUtility, ViewManager } from '../../utility';
import moment from 'moment';
import './report.css';
import { Select } from 'antd';
import { PermissionsHelper } from '../../utility';
import SummaryReports from './summary-report';

class Report extends Component<{}> {
  constructor(props) {
    super(props);
    this.authUserStore = props.authUserStore;
    this.dictionary = props.dictionary;
    this.exportStore = props.exportStore;
    this.reportSearchStore = props.reportSearchStore;
    this.scheduling = props.scheduling;
    this.searchStore = props.searchStore;
    this.staffStore = props.staffStore;
    this.viewStore = props.viewStore;

    this.state = {
      dialog: { visible: false },
      message: { visible: false },
      spinner: { visible: false, text: '' },
      report: { reporting: false, type: null, course: {}, staff: {} },
      requests: null,
      exporting: false,
      exportYear: null,
      defaultModel: {},
      summary: { loading: false, expanded: false },
      paging: {},
      reportSearch: {},
      model: {},
      loading: false,
      currentUser: null,
      year: null,
      allocatedYears: [],
      summaryReportOwners: [],
      courseReportOwners: [],
      staffReportOwners: [],
    };
  }

  async componentDidMount() {
    const allocatedYears = this.exportStore.allocatedYears;
    const year = this.viewStore.year;
    const model = this.viewStore.model; // TODO TO CHANGE
    const user = this.authUserStore.currentUser;
    if (
      (!model || model?.status?.code === 'import' || year?.status?.code === 'rollover') &&
      !PermissionsHelper.userHasRole(['bpo'], user)
    ) {
      window.location.assign('#/noaccess');
    } else {
      this.setState({
        loading: true,
        currentUser: user,
        userModelGroups: user.userModelGroups,
      });

      this.reportSearchStore.cleanSearchResult();
      this.setState({
        allocatedYears,
      });
      if (allocatedYears.length > 0) {
        const maxExportYear = Math.max.apply(Math, allocatedYears);
        this.setState({ exportYear: maxExportYear });
      }
      this.setState({
        spinner: { visible: true, text: 'Loading' },
      });

      this.getSummary(false, true);
      if (!model) {
        this.dictionary.getModels(year.year).then((models) => {
          this.setState({ year, models, spinner: { visible: false, text: null } });
        });
      } else {
        this.setState({
          year,
          models: [model],
          defaultModel: model,
          report: {
            ...this.state.report,
            course: { model: model.id, owner: null },
            staff: { model: model.id, department: null },
          },
          spinner: { visible: false, text: null },
        });

        const reportSearch: GeneratedReportsDto = {
          id: null,
          reportName: null,
          model: model.id,
          owner: null,
          department: null,
          createDate: null,
          user: this.state.currentUser?.user.id,
        };

        await this.setOwners();

        await this.reportSearchStore.getUnitSummaryReport(
          reportSearch,
          this.state.paging.limit,
          this.state.paging.skip
        );

        await this.reportSearchStore.getCourseAllocationReport(
          reportSearch,
          this.state.paging.limit,
          this.state.paging.skip
        );
        /*.then(() => {
          this.setState({ spinner: { visible: false, text: '' } });
        });*/
        await this.reportSearchStore.getStaffAllocationReport(
          reportSearch,
          this.state.paging.limit,
          this.state.paging.skip
        );
        /* .then(() => {
          this.setState({ spinner: { visible: false, text: '' } });
        });*/
        this.setState({ spinner: { visible: false, text: '' } });
      }
    }
  }

  appendUniqueOnwers(array: OwnerDto[]) {
    return (element: OwnerDto) => {
      const isUnique = !array.map((owner) => owner.code).includes(element.code);
      if (isUnique) {
        array.push({ ...element });
      }
    };
  }

  async getOwnersForManagers() {
    const { model, year } = this.viewStore;
    const { currentUser: user } = this.authUserStore;
    const allAllowedOwners: OwnerDto[] = [];

    if (PermissionsHelper.userHasRole(['bpo', 'mm'], user)) {
      const allowedOwners: OwnerDto[] = await this.searchStore.getOwners(year.year, model?.id);
      allowedOwners.forEach(this.appendUniqueOnwers(allAllowedOwners));
    }
    if (PermissionsHelper.userHasRole('wm', user)) {
      const allowedOwners: OwnerDto[] = await this.searchStore.getOwnersByUserModelCode(
        year.year,
        model.code,
        user.id,
        'wm'
      );
      allowedOwners.forEach(this.appendUniqueOnwers(allAllowedOwners));
    }

    return allAllowedOwners;
  }

  async getOwnersForStaff() {
    const { model, year } = this.viewStore;
    const { currentUser: user } = this.authUserStore;
    let allowedOwners: OwnerDto[] = [];

    if (PermissionsHelper.userHasRole('as', user)) {
      allowedOwners = await this.searchStore.getOwnersByUser(
        year.year,
        model.id,
        user.id,
        { includeAllocations: false }
      );
    }

    return allowedOwners;
  }

  async setOwners() {
    const { model } = this.viewStore;

    this.searchStore.cleanSearchResult();
    this.searchStore.setSingleModel(model);

    const ownersForManagers = await this.getOwnersForManagers();
    const ownersForStaff = await this.getOwnersForStaff();

    const allOwners: OwnerDto[] = [];
    ownersForManagers.forEach(this.appendUniqueOnwers(allOwners));
    ownersForStaff.forEach(this.appendUniqueOnwers(allOwners));

    const summaryReportOwners = [...allOwners];
    const courseReportOwners = [...ownersForManagers];
    const staffReportOwners = [...ownersForManagers];

    if (allOwners?.length > 0) {
      this.setState({
        summaryReportOwners,
        courseReportOwners,
        staffReportOwners,
        spinner: { visible: false, text: null }
      });
    }
  }

  getDepartmentsFromOwners(owners: OwnerDto[]) {
    const departments = owners?.map(owner => owner?.ownerDepartment?.department)?.filter(d => d);
    departments?.unshift({ department: 'All Units', active: true });
    return departments;
  }

  updateSpinner = (visible, text) => {
    this.setState({ spinner: { visible, text } });
  };

  getUnique(array) {
    return Array.from(new Set(array));
  }

  onReportResult = (resultSuccess) => {
    if (this.state.summary.expanded && resultSuccess) {
      this.getSummary(false);
    }
  };

  getSummary = async (indicate = true, expanded) => {
    let _expanded = expanded ? expanded : true;
    this.setState({ summary: { expanded: _expanded, loading: indicate } });
    this.scheduling.getSummary().then((data) => {
      this.setState({
        summary: { expanded: true, loading: false },
        requests: data,
      });
    });
  };

  onExport = () => {
    const { model } = this.viewStore;
    const viaEmail = true;
    const dataExportParams = {
      model: model.id,
    };
    this.setState({ exporting: true });
    this.exportStore
      .getAllocatedYearExport(dataExportParams, this.state.exportYear, viaEmail)
      .then((result) => {
        if (viaEmail) {
          this.setState(
            {
              exporting: false,
              message: {
                task: 'export',
                type: result.success ? 'info' : 'error',
                visible: true,
                msg: result.success
                  ? `${this.state.exportYear} Allocation Export Requested`
                  : `Failed to Request ${this.state.exportYear} Allocation Export`,
              },
            },
            () => {
              setTimeout(() => {
                this.setState({ message: { visible: false } });
              }, 5000);
            }
          );
        } else {
          this.setState({ exporting: false });
        }
      })
      .catch(() => {
        this.setState(
          {
            exporting: false,
            message: {
              task: 'export',
              type: 'error',
              visible: true,
              msg: `An error has occurred while attempting to request the ${this.state.exportYear} Allocation Export`,
            },
          },
          () => {
            setTimeout(() => {
              this.setState({ message: { visible: false } });
            }, 5000);
          }
        );
      });
  };

  onExportChange = (year) => {
    this.setState({ exportYear: year });

    const reportSearch: GeneratedReportsDto = {
      id: null,
      reportName: year.toString(),
      model: null,
      owner: null,
      department: null,
      createDate: null,
    };

    this.reportSearchStore
      .getDataExport(reportSearch, this.state.paging.limit, this.state.paging.skip)
      .then(() => {
        this.setState({ spinner: { visible: false, text: '' } });
      });
  };

  updateReportingStatus = (status) => {
    this.setState({ report: { ...this.state.report, reporting: status } });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleteRequest = async (report: any) => {
    this.setState({
      requests: {
        ...this.state.requests,
        owned: this.state.requests.owned.map((s) =>
          s.id == report.id ? { ...s, state: 'deleting', locked: true } : s
        ),
      },
    });
    this.scheduling.setCancel(report.id).then(() => this.getSummary(false));
  };

  onQueuePanelSelect = async (e) => {
    this.setState({ summary: { ...this.state.summary, expanded: e.expanded } });
    if (e.expanded) {
      this.getSummary(true);
    }
  };

  onPageChange = (limit, skip) => {
    this.setState({ paging: { limit, skip } });
    if (this.onValidate(this.state.reportSearch)) {
      this.setState({ spinner: { visible: true, text: 'Loading' } });
      this.reportSearchStore
        .getCourseAllocationReport(this.state.reportSearch, limit, skip)
        .then(() => {
          this.setState({ spinner: { visible: false, text: '' } });
        });
    }
  };

  onValidate(reportSearch: GeneratedReportsDto) {
    return reportSearch.model != null || reportSearch.owner != null;
  }

  render() {
    const { currentUser: user } = this.authUserStore;
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const { Option } = Select;

    return (
      <div>
        <Header
          navState={'report'}
          subfooter={
            // eslint-disable-next-line no-constant-condition
            false ? (
              <div className='header-message'>
                <span className='k-icon k-i-wrench'></span>The Report page is currently in active
                development
              </div>
            ) : null
          }
        />

        <BodyLayout>
          <SummaryReports
            defaultModel={this.state.defaultModel}
            reportSearchStore={this.reportSearchStore}
            owners={this.state.summaryReportOwners}
            models={this.state.models}
            onReportResult={this.onReportResult}
            reporting={this.state.report.reporting}
            updateReportingStatus={this.updateReportingStatus}
            year={this.state?.year?.year}
          />

          {PermissionsHelper.userHasRole(['bpo', 'mm', 'wm'], user) && this.state.loading && (
            <CourseReports
              defaultModel={this.state.defaultModel}
              reportSearchStore={this.reportSearchStore}
              owners={this.state.courseReportOwners}
              models={this.state.models}
              onReportResult={this.onReportResult}
              reporting={this.state.report.reporting}
              updateReportingStatus={this.updateReportingStatus}
              year={this.state?.year?.year}
            />
          )}
          {PermissionsHelper.userHasRole(['bpo', 'mm', 'wm'], user) && this.state.loading && (
            <StaffReports
              defaultModel={this.state.defaultModel}
              reportSearchStore={this.reportSearchStore}
              departments={this.getDepartmentsFromOwners(this.state.staffReportOwners)}
              models={this.state.models}
              onReportResult={this.onReportResult}
              reporting={this.state.report.reporting}
              updateSpinner={this.updateSpinner}
              updateReportingStatus={this.updateReportingStatus}
              year={this.state?.year?.year}
              currentUserId={this.state.currentUser?.user.id}
            />
          )}
          {this.state.loading && PermissionsHelper.userHasRole('bpo', user) && (
            <CollapsePanel
              expanded={false}
              title='Data Export'
              tabIndex={1}
            >
              <div className='export-allocations'>
                <div className='export-allocations-tip'>
                  <p className='export-tip'>
                    <span className='k-icon k-i-information'></span> Export all allocations by their
                    allocated year. As this export may take some time to generate, it will be{' '}
                    <span className='emphasise'>emailed</span> to you once completed.
                  </p>
                </div>
                <div className='export-allocations-input'>
                  <Select
                    onChange={this.onExportChange}
                    placeholder='Please select'
                    value={this.state.exportYear}
                  >
                    {this.state.allocatedYears &&
                      this.state.allocatedYears.map((year) => (
                        <Option
                          key={year}
                          value={year}
                        >
                          {year}
                        </Option>
                      ))}
                  </Select>
                  <ExportButton
                    on={this.onExport}
                    exporting={this.state.exporting}
                    enabled={!this.state.exporting}
                    text={'Export Allocations'}
                    spinner={true}
                  />
                </div>
                <div>
                  <SearchReportGrid
                    data={this.reportSearchStore.dataExportResult}
                    onPageChange={this.onPageChange}
                    columns={[
                      {
                        field: 'reportName',
                        title: 'Report Name',
                        dataIndex: 'reportName',
                        sorter: (a, b) => a.reportName.localeCompare(b.reportName),
                      },
                      {
                        field: 'createDate',
                        title: 'Create Date',
                        dataIndex: 'createDate',
                        render: (date) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''),
                        sorter: (a, b) => a.createDate.localeCompare(b.createDate),
                      },
                    ]}
                  />
                </div>
                <MessageLine
                  visible={this.state.message.visible && this.state.message.task === 'export'}
                  type={this.state.message.type}
                  line={this.state.message.msg}
                />
              </div>
            </CollapsePanel>
          )}
          {this.state.loading && (
            <CollapsePanel
              expanded={this.state.summary.expanded}
              title='Reports Queue'
              onSelect={this.onQueuePanelSelect}
              tabIndex={2}
            >
              <div className='scheduled-requests'>
                {this.state.summary.loading ? (
                  <div className='load-frame'>
                    <SpinnerBasic />
                  </div>
                ) : (
                  <div>
                    <div className='scheduled-refresh'>
                      <span>
                        <span className='k-icon k-i-refresh'></span>
                        <a
                          className='refresh'
                          onClick={this.getSummary}
                        >
                          refresh
                        </a>
                      </span>
                    </div>
                    <div className='scheduled-total'>
                      <span className='label'>Total Scheduled Requests:</span>
                      <span className='value'>
                        {(this.state.requests && this.state.requests.total) || 0}
                      </span>
                    </div>
                    <div className='scheduled-owned'>
                      <span className='label'>Your Scheduled Requests:</span>
                      <span className='value'>
                        {this.state.requests && this.state.requests.owned
                          ? this.state.requests.owned.length
                          : 0}
                      </span>
                    </div>
                    {this.state.requests &&
                      this.state.requests.owned &&
                      this.state.requests.owned.length > 0 ? (
                      <div className='scheduled-list'>
                        {this.state.requests.owned.map((p) => {
                          return (
                            <div
                              key={p.id}
                              className={`scheduled-item ${p.state.toLowerCase()}`}
                            >
                              <div className='scheduled-item-title'>
                                {`${p.criteria.year} ${p.criteria.title}`}
                                {!p.locked ? (
                                  <span
                                    className={'k-icon k-i-delete'}
                                    onClick={this.onDeleteRequest.bind(this, p)}
                                  ></span>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className='scheduled-item-details'>
                                <div className='scheduled-item-line date'>
                                  <span className='label'>Date:</span>
                                  <span className='value'>
                                    {moment(p.added).format('D-MM-YYYY HH:mma')}
                                  </span>
                                </div>
                                {p.started && (
                                  <div className='scheduled-item-line date'>
                                    <span className='label'>Started:</span>
                                    <span className='value'>
                                      {moment(p.started).format('D-MM-YYYY HH:mma')}
                                    </span>
                                  </div>
                                )}
                                <div
                                  className={`scheduled-item-line state ${p.state.toLowerCase()}`}
                                >
                                  <span className='label'>State:</span>
                                  <span className='value'>{StringUtility.capitalize(p.state)}</span>
                                </div>
                                {p.criteria &&
                                  Object.keys(p.criteria)
                                    .filter((c) => !['title', 'year'].includes(c))
                                    .map((c) => {
                                      return (
                                        <div
                                          key={(
                                            window.crypto.getRandomValues(new Uint8Array(1))[0] /
                                            256
                                          ).toString()}
                                          className={`scheduled-item-line ${c}`}
                                        >
                                          <span className='label'>
                                            {StringUtility.capitalize(c)}:
                                          </span>
                                          <span className='value'>{Array.isArray(p.criteria[c]) ? p.criteria[c].join(', ') : p.criteria[c]}</span>
                                        </div>
                                      );
                                    })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className='scheduled-list-empty'>
                        <span className='k-icon k-i-information'></span>You currently have no
                        pending reports or exports.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </CollapsePanel>
          )}
          {this.state.dialog && this.state.dialog.visible && (
            <ConfirmationDialog
              response={this.state.dialog.callback}
              title={this.state.dialog.title}
              lines={this.state.dialog.lines}
            />
          )}
          {this.state.spinner.visible && (
            <Spinner
              text={this.state.spinner.text}
              lines={this.state.spinner.lines}
            />
          )}
        </BodyLayout>
      </div>
    );
  }
}

export default inject(
  'authUserStore',
  'dictionary',
  'exportStore',
  'reportSearchStore',
  'scheduling',
  'searchStore',
  'staffStore',
  'viewStore'
)(observer(Report));
