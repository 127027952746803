import { FileAddOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

export default function AddActivityButton(props) {
    const { disabled, onEditDialog, type, dialog } = props;

    const onAddActivity = () => {
        onEditDialog(null, type, dialog);
    }

    return (
        <Button
            disabled={disabled}
            onClick={onAddActivity}
            icon={<FileAddOutlined />}
            look={'outline'}
            primary='true'
        >
            Add Activity
        </Button>
    );
}