import React, { useEffect, useState } from "react";

export default function SearchCheckBox(props) {
  const { id, disabled, title, check, onChange } = props;

  const [value, setValue] = useState(check == true);

  const onCheckBoxChange = ({ target }) => {
    if (target.id === 'checkedAllocated' && check) {
      setValue(true);
    } else {
      const newValue = target.type === 'checkbox' ? target.checked : target.value;
      onChange(target);
      setValue(newValue);
    }
  };

  useEffect(() => {
    setValue(check);
  }, [check]);

  return (
    <div className='search-detail-body-check-item'>
      <input
        disabled={disabled}
        type='checkbox'
        id={id}
        className='k-checkbox'
        checked={value}
        onChange={onCheckBoxChange}
      />
      <label
        className='k-checkbox-label'
        htmlFor={id}
      >
        {title}
      </label>
    </div>
  );
}