// @flow

import { Button, Modal } from 'antd';
import { Spinner } from '..';
import React, { Component } from 'react';
import Dropdown from '../ui/drop-down';
import './add-edit-user.css';
import { SearchUserDto } from '../../dto';
import { UserStore } from '../../mobx';

type Props = {
  button: {
    text: string,
    icon: string,
    look: string,
  },
  activeSearch: SearchUserDto,
  searchTotal: number,
  onSearchRefresh: Function,
  onBulkStatusSaved: (usersUpdated: number) => void,
};

type State = {
  visible: boolean,
};

const userStore = new UserStore();
const statuses = [
  { id: 'active', label: 'Active' },
  { id: 'inactive', label: 'Inactive' },
];

class BulkEditUserStatus extends Component<Props, State> {
  onSubmit: () => void;

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      saving: false,
      fromSearchStatus: this.getStatusFromSearch(this.props.activeSearch?.statusCode),
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.activeSearch?.statusCode !== this.props.activeSearch?.statusCode) {
      this.setState({
        fromSearchStatus: this.getStatusFromSearch(this.props.activeSearch?.statusCode),
      });
    }
  }

  getStatusFromSearch(statusCode: string | undefined) {
    return statuses.find((s) => s.id === statusCode);
  }

  onToggleDialog = () => {
    this.setState({
      selectedStatus: this.state.fromSearchStatus,
      visible: !this.state.visible,
    });
  };

  // eslint-disable-next-line no-dupe-class-members
  async onSubmit(event) {
    event.preventDefault();
    let updateUser = null;

    if (this.props?.activeSearch?.statusCode !== this.state?.selectedStatus) {
      this.setState({saving: true});
      updateUser = await userStore.bulkUpdateUserStatus(this.props?.activeSearch, this.state?.selectedStatus);
    }
    if (updateUser > 0){
      this.setState({saving: false, visible: false});
      this.props.onBulkStatusSaved(updateUser);
      setTimeout(this.props.onSearchRefresh, 1000);
    }
  }

  onUserStatusChange = (value) => {
    this.setState({
      selectedStatus: value
    });
  };

  render() {
    const spinnerText = this.state.saving ? 'Saving' : null;
    const showSpinner = this.state.saving;
    return (
      <div className='add-edit-user-frame'>
        <Button
          onClick={this.onToggleDialog}
          icon={this.props.button ? this.props.button.icon || '' : ''}
          look={this.props.button ? this.props.button.look || 'default' : 'default'}
          primary='true'
          disabled={this.props.disabled}
        >
          {this.props.button ? this.props.button.text || '' : ''}
        </Button>
        {this.state.visible && (
            <Modal
              width={'auto'}
              centered
              open={this.state.visible}
              title={'Edit User Status (Multiple)'}
              onCancel={this.onToggleDialog}
              footer={null}
            >
              <div className='add-edit-user-dialog'>
                <form className='form-inline'>
                  <p>
                    This process will update the User Status for all Users in the Search results
                    Table.
                  </p>
                  <p>{this.props?.searchTotal} Users will be updated.</p>
                  <Dropdown
                    title={'User Status'}
                    value={this.state.selectedStatus}
                    data={statuses}
                    onChange={this.onUserStatusChange}
                    fields={{ value: 'id', display: 'label' }}
                  />
                  <div className='form-buttons-frame'>
                    <Button
                      type='submit'
                      onClick={this.onSubmit}
                      className='sub-button save'
                    >
                      Save
                    </Button>
                    <Button
                      type='button'
                      onClick={this.onToggleDialog}
                      className='sub-button cancel'
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>
        )}

        {showSpinner && <Spinner text={spinnerText} />}
      </div>
    );
  }
}

export default BulkEditUserStatus;
