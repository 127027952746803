// @flow

import { Button, Modal } from 'antd';
import { MessageBlock } from '..';
import { inject, observer } from 'mobx-react';
import { Spinner } from '..';
import * as mobx from 'mobx';
import React, { Component } from 'react';
import { PermissionsHelper, onNumbersOnly } from '../../utility';
import { Staff as Validator } from '../../validations';
import {
  StaffDto,
  StaffTypeDto,
  StaffContractDto,
  ModelDto,
  DepartmentDto,
  JobTitleDto,
} from '../../dto';
import { SearchStore, StaffStore } from '../../mobx';
import { StaffUtility } from '../../utility';
import Dropdown from '../ui/drop-down';

import './add-edit-staff.css';

type Props = {
  title: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSubmit: Function,
  staff: StaffDto,
  button: {
    text: string,
    icon: string,
    look: string,
  },
  year: number,
};

type State = {
  rebuildState: boolean,
  visible: boolean,
  failed: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabled: any,
  staff: StaffDto,
  commit: StaffDto,
  messaging: { lines: string[], type: string },
  saving: boolean,
  loading: boolean,
  validating: boolean,
  initialised: boolean,
  initialising: boolean,

  departments: DepartmentDto[],
  titles: JobTitleDto[],
  model: ModelDto,
  types: StaffTypeDto[],
  contracts: StaffContractDto[],
};

const staffStore = new StaffStore();
const searchStore = new SearchStore();
class AddEditStaff extends Component<Props, State> {
  onSubmit: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialogRef: any;

  empty: StaffDto = {
    code: '',
    firstName: '',
    surname: '',
    displayName: '',
    efte: 0,
    teachingPercentage: 0,
    researchPercentage: 0,
    citizenshipPercentage: 0,
    clinicalPercentage: 0,
    servicePercentage: 0,
    courseCoordinatedCount: 0,
    masseyUserId: '',
    workProfile: null,
    jobTitle: null,
    emailAddress: '',
    newToMassey: false,
    model: null,
    sltGroup: 'Unknown',
    department: null,
    masseyStaffId: null,
    contract: null,
    staffType: null,
    workloadAllocation: true,
    year: 0,
  };

  constructor(props) {
    super(props);
    this.authUserStore = this.props.authUserStore;
    this.viewStore = this.props.viewStore;
    this.state = {
      staff: this.props.staff
        ? mobx.toJS(this.props.staff)
        : { ...this.empty, year: this.props.year },
      rebuildState: false,
      visible: false,
      failed: false,
      disabled: {},
      wm: false,
      commit: null,
      messaging: { lines: [], type: '' },
      saving: false,
      loading: false,
      validating: false,
      initialised: false,
      initialising: false,
      departments: [],
      titles: [],
      model: {},
      types: [],
      contracts: [],
    };

    this.dialogRef = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
  }

  get isNotWM() {
    const user = this.authUserStore.currentUser;
    return !PermissionsHelper.userHasRole('wm', user);
  }

  get isAdding() {
    return this.props.staff == null;
  }

  onToggleDialog = () => {
    this.onCleanMessages();
    const staffInfo = this.props.staff ? true : false;
    this.setState({
      disabled: {
        department: !this.props.staff,
        title: this.props.button.text === 'Edit Staff' ? !this.props.staff : staffInfo,
        model: this.props.staff,
      },
      staff: this.props.staff
        ? mobx.toJS(this.props.staff)
        : { ...this.empty, year: this.props.year },
      visible: !this.state.visible,
      initialised: false,
      initialising: false,
      loading: !this.state.initialised && this.props.staff,
    });
  };

  onCleanMessages = () => {
    this.setState({ messaging: { lines: [], type: '' } });
  };

  componentDidMount() {
    this.onCleanMessages();
  }

  async getDepartments(modelId) {
    let departments = [];
    if (PermissionsHelper.userHasRole('wm', this.authUserStore.currentUser)) {
      departments = await searchStore.getDepartmentsByUser(
        this.props.year,
        this.authUserStore.currentUser.id,
        modelId
      );
      this.setState({
        wm: true,
      });
    } else if (this.props.staff) {
      departments = await staffStore.getDepartments(this.props.year, this.props.staff?.model?.id);
    } else {
      departments = await staffStore.getDepartments(this.props.year, modelId);
    }
    this.setState({ departments });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async componentDidUpdate(prevProps, prevState) {
    if (this.dialogRef.current && !this.state.initialised && !this.state.initialising) {
      this.setState({ initialising: true });
      const nodes = document
        .querySelector('.add-edit-staff-dialog')
        .querySelectorAll('button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const focusables = Array.from(nodes).filter((e) => !e.disabled);
      var first = focusables[0];
      var last = focusables[focusables.length - 1];

      first.focus();

      last.onkeydown = (e) => {
        if (e.which === 9 && !e.shiftKey) {
          e.preventDefault();
          first.focus();
        }
      };
      first.onkeydown = (e) => {
        if (e.which === 9 && e.shiftKey) {
          e.preventDefault();
          last.focus();
        }
      };
      const currentModel = this.viewStore.model;
      this.setState({
        model: currentModel,
      });

      this.getDepartments(currentModel?.id);
      this.setState({
        types: (await staffStore.getTypes(this.props.year)).map((d) => mobx.toJS(d)),
        contracts: (await staffStore.getContracts(this.props.year))
          .map((d) => mobx.toJS(d))
          .filter((c) => !['casual'].includes(c.code)),
        titles: this.props.staff
          ? (await staffStore.getTitles(this.props.year, this.props.staff.model.id)).map((d) =>
              mobx.toJS(d)
            )
          : (await staffStore.getTitles(this.props.year, currentModel.id)).map((d) => mobx.toJS(d)),
      });
      this.setState({
        initialised: true,
        initialising: false,
        loading: false,
      });
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  async onSubmit(event) {
    this.setState({ validating: true });

    if (this.props.staff != null) {
      this.empty['id'] = null;
    }

    event.preventDefault();
    this.onCleanMessages();

    const staff = new StaffDto();
    for (const key of Object.keys(this.empty)) {
      if (this.state.staff[key] !== undefined) {
        if (['model', 'department', 'contract', 'staffType', 'jobTitle'].includes(key)) {
          staff[key] = this.state.staff[key] ? { id: this.state.staff[key].id } : undefined;
        } else {
          staff[key] = this.state.staff[key];
        }
      }
    }

    await this.setState({
      staff: { ...this.state.staff, model: this.state.model },
    });

    const unique = await this.getUniqueStaffReferences(this.props.staff, this.state.staff);
    staff.code = unique.code;
    staff.displayName = unique.name;
    staff.model = { id: this.state.model.id };
   
    const msg = await Validator.validate(staff);
    
    this.setState({ validating: false });
    
    if (msg.length > 0) {
      this.setState({
        messaging: {
          lines: msg.filter((m, p, s) => s.indexOf(m) === p),
          type: 'error',
        },
      });
    } else {
      this.onSave(staff);
    }
  }

  async getUniqueStaffReferences(
    original: StaffDto,
    current: StaffDto
  ): { name: string, code: string } {
    let result = original
      ? { name: original.displayName || '', code: original.code || '' }
      : { name: '', code: '' };
    if (
      !original ||
      original.firstName !== current.firstName ||
      original.surname !== current.surname ||
      original.jobTitle.id !== current.jobTitle.id ||
      original.department.id !== current.department.id
    ) {
      result.name = StaffUtility.getGeneratedDisplayName(current);
      result.code = StaffUtility.getGeneratedStaffCode(current);
    }

    return result;
  }

  onSave = (staff: StaffDto) => {
    this.setState({ saving: true });
    if (staff.id) {
      staffStore.update(staff).then((result) => {
        if (result) {
          this.setState({ saving: false });
          this.onToggleDialog();
          if (this.props.onSubmit) {
            this.props.onSubmit(staff, this.props.staff);
          }
        } else {
          this.setState({ saving: false, failed: true });
          setTimeout(() => {
            this.setState({ failed: false });
          }, 8000);
        }
      });
    } else {
      staffStore.add(staff).then((result) => {
        if (result) {
          this.setState({ saving: false });
          this.onToggleDialog();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        } else {
          this.setState({ saving: false, failed: true });
          setTimeout(() => {
            this.setState({ failed: false });
          }, 8000);
        }
      });
    }
  };

  onModelChange = (value) => {
    if (PermissionsHelper.userHasRole('wm', this.authUserStore.currentUser)) {
      searchStore
        .getDepartmentsByUser(this.props.year, this.authUserStore.currentUser.id, value.id)
        .then((data) => {
          this.setState({ departments: data.map((d) => mobx.toJS(d)) });
        });
      this.setState({
        wm: true,
      });
    } else {
      staffStore.getDepartments(this.props.year, value.id).then((data) => {
        this.setState({ departments: data.map((d) => mobx.toJS(d)) });
      });
    }
    staffStore.getTitles(this.props.year, value.id).then((data) => {
      this.setState({ titles: data.map((d) => mobx.toJS(d)) });
    });
    this.setState({
      disabled: {
        ...this.state.disabled,
        department: value == null,
        title: value == null,
      },
      staff: {
        ...this.state.staff,
        model: value,
        department: null,
        jobTitle: null,
      },
    });
  };

  onTitleChange = (value) => {
    this.setState({ staff: { ...this.state.staff, jobTitle: value } });
  };

  onDepartmentChange = (value) => {
    this.setState({ staff: { ...this.state.staff, department: value } });
  };

  onContractChange = (value) => {
    this.setState({ staff: { ...this.state.staff, contract: value } });
  };

  onTypeChange = (value) => {
    this.setState({ staff: { ...this.state.staff, staffType: value } });
  };

  onWorkloadAllocationChange = (value) => {
    this.setState({ staff: { ...this.state.staff, workloadAllocation: value.value } });
  };

  onMasseyIdChange = (event) => {
    this.setState({
      staff: { ...this.state.staff, masseyStaffId: event.target.value },
    });
  };

  onFirstNameChange = (event) => {
    this.setState({
      staff: { ...this.state.staff, firstName: event.target.value },
    });
  };

  onSurnameChange = (event) => {
    this.setState({
      staff: { ...this.state.staff, surname: event.target.value },
    });
  };

  onMasseyUserIdChange = (event) => {
    this.setState({
      staff: { ...this.state.staff, masseyUserId: event.target.value },
    });
  };

  onEmailAddressChange = (event) => {
    this.setState({
      staff: { ...this.state.staff, emailAddress: event.target.value },
    });
  };

  onFTEChange = (event) => {
    this.setState({ staff: { ...this.state.staff, efte: event.target.value } });
  };

  onTeachingChange = (event) => {
    this.setState({
      staff: {
        ...this.state.staff,
        teachingPercentage: event.target.value,
      },
    });
  };

  onResearchChange = (event) => {
    this.setState({
      staff: {
        ...this.state.staff,
        researchPercentage: event.target.value,
      },
    });
  };

  onServiceChange = (event) => {
    this.setState({
      staff: { ...this.state.staff, servicePercentage: event.target.value },
    });
  };

  onCitizenshipChange = (event) => {
    this.setState({
      staff: {
        ...this.state.staff,
        citizenshipPercentage: event.target.value,
      },
    });
  };

  onClinicalChange = (event) => {
    this.setState({
      staff: {
        ...this.state.staff,
        clinicalPercentage: event.target.value,
      },
    });
  };

  addDisabledStyling(isDisabled) {
    if (isDisabled) {
      return 'ant-input-disabled';
    }
    return '';
  }

  get canEditModel() {
    return false;
  }

  get canEditMasseyStaffId() {
    return this.isAdding;
  }

  get canEditName() {
    return this.isNotWM;
  }

  get canEditMasseyUserId() {
    return this.isAdding;
  }

  get canEditEmail() {
    return this.isNotWM;
  }

  render() {
    return (
      <div className='add-edit-staff-frame'>
        <Button
          onClick={this.onToggleDialog}
          icon={this.props.button ? this.props.button.icon || '' : ''}
          look={this.props.button ? this.props.button.look || 'default' : 'default'}
          primary='true'
        >
          {this.props.button ? this.props.button.text || '' : ''}
        </Button>
        {this.state.visible && (
          <div ref={this.dialogRef}>
            <Modal
              width={'auto'}
              centered
              open={this.state.visible}
              title={
                this.props.title ? this.props.title : this.props.staff ? 'Edit Staff' : 'Add Staff'
              }
              onCancel={this.onToggleDialog}
              footer={null}
            >
              <div className='add-edit-staff-dialog'>
                <form className='form-inline'>
                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Model:</span>
                      <input
                        className={`k-text-box ${this.addDisabledStyling(!this.canEditModel)}`}
                        defaultValue={
                          this.state.staff?.model
                            ? this.state.staff.model?.model
                            : this.state.model?.model
                        }
                        disabled={!this.canEditModel}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Staff ID:</span>
                      <input
                        type='text'
                        className={`k-text-box ${this.addDisabledStyling(!this.canEditMasseyStaffId)}`}
                        maxLength='6'
                        value={this.state.staff.masseyStaffId || ''}
                        onChange={this.onMasseyIdChange}
                        disabled={!this.canEditMasseyStaffId}
                        onKeyPress={onNumbersOnly}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>First Name:</span>
                      <input
                        className={`k-text-box ${this.addDisabledStyling(!this.canEditName)}`}
                        value={this.state.staff.firstName}
                        onChange={this.onFirstNameChange}
                        disabled={!this.canEditName}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Last Name:</span>
                      <input
                        className={`k-text-box ${this.addDisabledStyling(!this.canEditName)}`}
                        value={this.state.staff.surname}
                        onChange={this.onSurnameChange}
                        disabled={!this.canEditName}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Username:</span>
                      <input
                        className={`k-text-box ${this.addDisabledStyling(!this.canEditMasseyUserId)}`}
                        value={this.state.staff.masseyUserId}
                        onChange={this.onMasseyUserIdChange}
                        disabled={!this.canEditMasseyUserId}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Email:</span>
                      <input
                        className={`k-text-box ${this.addDisabledStyling(!this.canEditEmail)}`}
                        value={this.state.staff.emailAddress}
                        onChange={this.onEmailAddressChange}
                        disabled={!this.canEditEmail}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>FTE:</span>
                      <input
                        className='k-text-box'
                        maxLength='4'
                        value={this.state.staff.efte}
                        onChange={this.onFTEChange}
                        onKeyPress={onNumbersOnly}
                      />
                    </div>
                  </label>

                  <Dropdown
                    title={'Job Title'}
                    value={this.state.staff.jobTitle}
                    data={this.state.titles}
                    disabled={this.state.disabled.title || this.state.wm}
                    onChange={this.onTitleChange}
                    fields={{ value: 'id', display: 'title' }}
                    prompt={{
                      text: 'Please Select',
                      hidden: true,
                    }}
                  />

                  <Dropdown
                    title={'Unit'}
                    value={this.state.staff.department}
                    data={this.state.departments}
                    disabled={this.state.disabled.unit || this.state.wm}
                    onChange={this.onDepartmentChange}
                    fields={{
                      value: 'id',
                      display: 'department',
                    }}
                    prompt={{
                      text: 'Please Select',
                      hidden: true,
                    }}
                  />

                  <Dropdown
                    title={'Contract'}
                    value={this.state.staff.contract}
                    data={this.state.contracts}
                    disabled={this.state.disabled.contract}
                    onChange={this.onContractChange}
                    fields={{ value: 'id', display: 'contract' }}
                    prompt={{
                      text: 'Please Select',
                      hidden: true,
                    }}
                  />
                  <Dropdown
                    title={'Type'}
                    value={this.state.staff.staffType}
                    data={this.state.types}
                    disabled={this.state.disabled.type || this.state.wm}
                    onChange={this.onTypeChange}
                    fields={{ value: 'id', display: 'type' }}
                    prompt={{
                      text: 'Please Select',
                      hidden: true,
                    }}
                  />
                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field add-edit-profile'>
                      <span>Profile:</span>
                      <div className='add-edit-staff-input-group'>
                        <div className='add-edit-staff-group-input'>
                          <input
                            className='k-text-box'
                            maxLength='4'
                            value={this.state.staff.teachingPercentage}
                            onChange={this.onTeachingChange}
                            onKeyPress={onNumbersOnly}
                          />
                          <span className='group-input-label'>Teaching & Supervision</span>
                        </div>
                        <div className='add-edit-staff-group-input'>
                          <input
                            className='k-text-box'
                            maxLength='4'
                            value={this.state.staff.researchPercentage}
                            onChange={this.onResearchChange}
                            onKeyPress={onNumbersOnly}
                          />
                          <span className='group-input-label'>Research & Enterprise</span>
                        </div>
                        <div className='add-edit-staff-group-input'>
                          <input
                            className='k-text-box'
                            maxLength='4'
                            value={this.state.staff.citizenshipPercentage}
                            onChange={this.onCitizenshipChange}
                            onKeyPress={onNumbersOnly}
                          />
                          <span className='group-input-label'>Academic Citizenship</span>
                        </div>
                        <div className='add-edit-staff-group-input'>
                          <input
                            className='k-text-box'
                            maxLength='4'
                            value={this.state.staff.clinicalPercentage}
                            onChange={this.onClinicalChange}
                            onKeyPress={onNumbersOnly}
                          />
                          <span className='group-input-label'>Clinical Practice</span>
                        </div>
                      </div>
                    </div>
                  </label>
                  <Dropdown
                    title={'Workload Managed'}
                    value={{
                      id: `${this.state.staff.workloadAllocation}`,
                      value: this.state.staff.workloadAllocation,
                      label: this.state.staff.workloadAllocation ? 'Yes' : 'No',
                    }}
                    data={[
                      { id: 'true', value: true, label: 'Yes' },
                      { id: 'false', value: false, label: 'No' },
                    ]}
                    disabled={this.state.disabled.workloadManaged || this.state.wm}
                    onChange={this.onWorkloadAllocationChange}
                    fields={{ value: 'id', display: 'label' }}
                    prompt={{
                      text: 'Please Select',
                      hidden: true,
                    }}
                  />

                  <label
                    className='k-form-error-field'
                    onClick={() =>
                      this.setState({
                        failed: false,
                        messaging: { lines: [], type: '' },
                      })
                    }
                  >
                    {this.state.failed && (
                      <ul className='failed-submit-error-msg'>
                        <li>
                          <span className='k-icon k-i-warning'></span> An error has occured while
                          attempting to submit allocation
                        </li>
                      </ul>
                    )}
                    <MessageBlock
                      visible={this.state.messaging.lines.length > 0}
                      lines={this.state.messaging.lines}
                      type={this.state.messaging.type}
                    ></MessageBlock>
                  </label>

                  <div className='form-buttons-frame'>
                    <Button
                      type='submit'
                      onClick={this.onSubmit}
                      look='outline'
                      className='staff-button save'
                      tabIndex={0}
                    >
                      Save
                    </Button>
                    <Button
                      type='button'
                      onClick={this.onToggleDialog}
                      look='outline'
                      className='staff-button cancel'
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        )}

        {(this.state.saving || this.state.loading || this.state.validating) && (
          <Spinner
            text={this.state.loading ? 'Loading' : this.state.validating ? 'Validating' : 'Saving'}
          />
        )}
      </div>
    );
  }
}

export default inject('authUserStore', 'viewStore')(observer(AddEditStaff));
