// @flow

import { makeAutoObservable } from 'mobx';
import * as querystring from 'query-string';

import { AllocationDto, CourseDto, StaffDto } from '../dto';
import { SortDto, PagerDto } from '../dto';
import Api from './api';

export default class AllocationStore {
  singleAllocation: AllocationDto;
  courseAllocation: CourseDto;
  allocationByCourseType: PagerDto<AllocationDto>;
  allocationByOfferingType: PagerDto<AllocationDto>;
  staffAllocation: StaffDto;

  constructor() {
    makeAutoObservable(this);
  }

  async getByCourseType(
    id: string,
    year: number,
    limit = 25,
    skip = 0,
    active = true,
    sorting: ?(SortDto[])
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      const sort =
        sorting && sorting.length > 0
          ? `&sorting=${querystring.stringify({ param: JSON.stringify(sorting) })}`
          : '';
      Api.get(
        `/allocations/${year}/page/course/${id}?type=course&limit=${limit}&skip=${skip}&active=${active}${sort}`
      ).then((data) => {
        this.allocationByCourseType = data;
        resolve(data);
      }).catch((error) => {
        reject('Unable to get allocations');
      });
    });
  }

  async getByOfferingType(
    id: string,
    year: number,
    limit = 25,
    skip = 0,
    active = true,
    sorting: ?(SortDto[])
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      const sort =
        sorting && sorting.length > 0
          ? `&sorting=${querystring.stringify({ param: JSON.stringify(sorting) })}`
          : '';
      Api.get(
        `/allocations/${year}/page/course/${id}?type=offering&limit=${limit}&skip=${skip}&active=${active}${sort}`
      ).then((data) => {
        this.allocationByCourseType = data;
        resolve(data);
      });
    });
  }

  async getByStaff(
    id: string,
    year: number,
    category: string,
    limit = 25,
    skip = 0,
    active = true,
    sorting: ?(SortDto[])
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      const sort =
        sorting && sorting.length > 0
          ? `&sorting=${querystring.stringify({ param: JSON.stringify(sorting) })}`
          : '';
      Api.get(
        `/allocations/${year}/page/staff/${id}?limit=${limit}&skip=${skip}&category=${category}&active=${active}${sort}`
      ).then((data) => {
        this.staffAllocation = data;
        resolve(data);
      });
    });
  }

  async getLastModifyInfo(modelId: string, staffId: string, categoryId: ?string) {
    if (typeof categoryId === 'undefined') {
      categoryId = 'all';
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/allocations/model/${modelId}/staff/${staffId}/category/${categoryId}`).then(
        (data) => {
          resolve(data);
        }
      );
    });
  }

  async calculate(year: number, id: string) {
    return new Promise((resolve, reject) => {
      Api.get(`/allocations/calculate/${year}/allocation/${id}`)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async calculateByStaff(year: number, id: string) {
    return new Promise((resolve, reject) => {
      Api.get(`/allocations/calculate/${year}/staff/${id}`)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async calculateByOffering(year: number, id: string) {
    return new Promise((resolve, reject) => {
      Api.get(`/allocations/calculate/${year}/offering/${id}`)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async calculateByCourse(year: number, id: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/allocations/calculate/${year}/course/${id}`).then((data) => {
        resolve({ success: true, result: data, error: null });
      });
    });
  }

  async checkDuplicates(allocations: AllocationDto[]) {
    return new Promise((resolve, reject) => {
      Api.post('/allocations/duplicates', allocations)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async add(allocation: AllocationDto) {
    return new Promise((resolve, reject) => {
      Api.post('/allocations', allocation)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async addLinked(allocations: AllocationDto[]) {
    return new Promise((resolve, reject) => {
      Api.post('/allocations/linked', allocations)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async update(allocation: AllocationDto) {
    return new Promise((resolve, reject) => {
      Api.put(`/allocations/${allocation.id}`, allocation)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async updateLinked(allocation: AllocationDto[]) {
    return new Promise((resolve, reject) => {
      Api.put('/allocations/update/linked', allocation)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  remove(id: string) {
    return new Promise((resolve, reject) => {
      Api.delete(`/allocations/${id}`)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  removeLinked(ids: string[]) {
    return new Promise((resolve, reject) => {
      Api.put('/allocations/delete/linked', ids)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }
}
