import React, { Component } from 'react';

import { Header, BodyLayout } from '../../components';
import NoticeBar from '../../components/noticeBar/notice-bar';

class Unauthorized extends Component {
  render() {
    return (
      <div>
        <Header showUser={false} />

        <BodyLayout>
          <NoticeBar
            text={'Sorry, not authorized to view this page. Please contact system admin or BPO.'}
            icon={'warning'}
          />
        </BodyLayout>
      </div>
    );
  }
}

export default Unauthorized;
