import React, { useCallback } from 'react';
import { Button } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';

import './helpFile.css';

const HelpFile = (props) => {
  const getLoadHelpFile = useCallback(async () => {
    const currentModel = props.viewStore.model;
    if (currentModel != null) {
      const reportName = currentModel.code.toUpperCase() + ' Header Help File.pdf';
      const reportSearch = {
        id: 'sys',
        reportName: reportName,
        model: null,
        owner: null,
        department: null,
        createDate: null,
      };

      let fileBuffer = await props.reportSearchStore.getReportBlob(reportSearch);
      const binaryString = Buffer.from(fileBuffer).toString();
      const buffer = Buffer.from(binaryString, 'binary');
      const blob = new Blob([buffer], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    }
  }, [props.reportSearchStore, props.viewStore.model]);

  return (
    <Button
      type='primary'
      onClick={getLoadHelpFile}
      icon={<QuestionOutlined />}
      className='btn-help'
    >
      Get Help
    </Button>
  );
};

export default inject('viewStore', 'reportSearchStore')(observer(HelpFile));
