// @flow
import { TableCommandCellView, TableCommandCellEditDelete } from '..';
import { Table } from 'antd';
import React, { Component } from 'react';
import { PageSettingDto } from '../../dto/clientDto';
import { SortDto } from '../../dto';
import './search-components.css';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSort: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDelete: Function,
  columns: Array<{
    field: string,
    title: string,
    width: string,
  }>,
  sortable: boolean,
  readOnly: boolean,
};

type State = {
  pageSetting: PageSettingDto,
  sort: SortDto[],
  newSearch: boolean,
  currentPage: number,
};

class SearchResultUserGrid extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      newSearch: true,
      pageSetting: this.createState(0, 25),
    };
  }

  createState = (skip: number, take: number) => {
    if (this.state) {
      if ((skip === 0 || skip === undefined) && this.state.newSearch !== undefined) {
        this.setState({
          newSearch: true,
        });
      } else {
        this.setState({
          newSearch: false,
        });
      }
    }
    let data = this.props.data.data ? this.props.data : [];
    return {
      items: data === [] ? data : data.data,
      total: data === [] ? data.length : data.total,
      skip: skip,
      pageSize: take,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [25, 50, 75],
        previousNext: true,
      },
    };
  };

  onChange = (pagination, sorter, action) => {
    if (action == 'paginate') {
      this.onPaginate(pagination.current, pagination.pageSize);
    }
    if (action == 'sort') {
      this.onColumnSort(sorter);
    }
  };

  onPaginate = (page, pageSize) => {
    const skip = pageSize * (page - 1);
    this.setState({
      pageSetting: this.createState(skip, pageSize),
      currentPage: page,
      newSearch: false,
    });
    this.props.onPageChange(pageSize, skip);
  };

  getSort = (sorter) => {
    let sort = [];

    if (!sorter.column) {
      return sort;
    }

    if (Array.isArray(sorter)) {
      sort =
        sorter?.map((s) => {
          return {
            field: s.column.field,
            dir: s.order == 'ascend' ? 'asc' : s.order == 'descend' ? 'desc' : '',
          };
        }) || [];
    } else {
      sort = [
        {
          field: sorter.column.field ?? '',
          dir: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
        },
      ];
    }

    return sort;
  }

  onColumnSort = (sorter) => {
    const sort = this.getSort(sorter);

    this.setState({ sort: sort });
    if (this.props.onSort) {
      this.props.onSort(sort);
    }
  };

  onEdit(data) {
    this.props.onEdit(data);
  }

  onDelete(data) {
    this.props.onDelete(data, this.state.pageSetting);
  }

  componentDidUpdate(prevProps) {
    let data = this.props.data;
    if (prevProps.data !== this.props.data) {
      this.setState({ pageSetting: this.createState(data.skip, data.limit) });
    }
  }

  render() {
    return (
      <div>
        <Table
          columns={this.props.columns.concat({
            title: '',
            key: 'actions',
            width: '140px',
            render: (text, record) =>
              this.props.readOnly ? (
                <TableCommandCellView onEdit={this.onEdit.bind(this)} />
              ) : (
                <TableCommandCellEditDelete
                  onEdit={this.onEdit.bind(this)}
                  onDelete={this.onDelete.bind(this)}
                  dataItem={record}
                />
              ),
          })}
          rowKey={(record) => record.id}
          dataSource={this.state.pageSetting.items}
          bordered={true}
          size='small'
          tableLayout='fixed'
          pagination={
            this.state.pageSetting.pageable
              ? {
                  position: ['bottomLeft'],
                  current: this.state.newSearch ? 1 : this.state.currentPage,
                  defaultPageSize: this.state.pageSetting.pageSize,
                  total: this.state.pageSetting.total,
                  showSizeChanger: true,
                  pageSizeOptions: [10, 20, 25, 50, 100],
                }
              : false
          }
          onChange={(pagination, filters, sorter, extra) => {
            this.onChange(pagination, sorter, extra.action);
          }}
          showSorterTooltip={this.props.sortable ? true : false}
          sortDirections={['ascend', 'descend']}
        />
      </div>
    );
  }
}

export default SearchResultUserGrid;
