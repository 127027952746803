// @flow

import React, { Component } from 'react';

import './message-block.css';

type MessageType = 'warn' | 'warning' | 'info' | 'information' | 'error' | 'success';

type Props = {
  visible: boolean,
  lines: string[],
  type: MessageType,
};

class MessageBlock extends Component<Props> {
  setMessage() {
    return this.props.lines.map((line) => {
      return (
        <li key={line}>
          <span className='k-icon k-i-warning'></span> {line}
        </li>
      );
    });
  }

  render() {
    const _type = this.props.type
      ? this.props.type === 'info'
        ? 'information'
        : this.props.type === 'warn'
        ? 'warning'
        : this.props.type
      : '';

    return (
      this.props.visible && (
        <ul className={`message-frame ${_type}`}>
          {this.setMessage()}
          {this.props.children}
        </ul>
      )
    );
  }
}

export default MessageBlock;
