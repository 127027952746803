import {
  CourseAllocationSchema,
  StaffAllocationSchema,
  StaffTeachingAllocationSchema,
  OfferingAllocationSchema,
} from '.';
import Joi from 'joi';
import { ConvertToForms } from './shared';

class AllocationValidator {
  transformations = {
    naming: {
      id: 'Id',
      code: 'Code',
      model: 'Model',
      traget: 'Target',
      course: 'Course',
      offering: 'Offering',
      type: 'Type',
      activity: 'Activity',
      variable: 'Variable',
      category: 'Category',
      period: 'Work Undertaken',
      number: 'Number',
      staff: 'Staff Member',
      share: 'Share of Work',
      calculated: 'Calculated Hours',
      assigned: 'Assigned Hours',
      note: 'Note',
    },
    messages: [
      { regex: 'is not allowed to be empty', message: 'is required' },
      { regex: 'must be a number', message: 'is required' },
      { regex: 'must be an object', message: 'is required' },
      { regex: 'must not be a sparse array', message: 'is required' },
      { regex: 'length must be at least 1 characters long', message: 'is required' },
      { regex: 'must be a valid GUID', message: 'is required' },
      {
        regex: 'length must be less than or equal to 250 characters long',
        message: 'Note must note exceed 250 characters',
      },
      { regex: 'must be a positive number', message: 'must be greater than 0' },
      { regex: 'position \\d contains a duplicate value', message: 'duplicate' },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async validate(validation: any, selector: string): Promise<string[]> {
    const msg: string[] = [];

    const staff = validation.members.map((m) => m.staff);
    const share = validation.members.map((m) => m.share);
    const calculated = validation.members.map((m) => m.calculated);
    const assigned = validation.members.map((m) => m.assigned);

    const _validation = { ...validation, staff, share, calculated, assigned };
    const err = await this._validate(_validation, selector);

    for (const key in err) {
      const keys = key.split(',');
      const name = keys[0]
        .split(',')[0]
        .split(' ')
        .map((w) => (this.transformations.naming[w] ? this.transformations.naming[w] : w))
        .join(' ');
      const regex = new RegExp(`"${keys.join('"|"')}"`, 'gi');
      let message = err[key].replace(regex, name);

      for (const reg of this.transformations.messages) {
        message = message.replace(new RegExp(reg.regex, 'gi'), reg.message);
      }

      if (message.includes('contains a duplicate value')) {
        message = `Duplicate ${this.transformations.naming[keys[0]]}`;
      }
      msg.push(message);
    }

    const _share = share.map((d) => d.value).reduce((a, b) => a + b, 0);
    if (_share !== 1 && _share !== 0.999999) {
      msg.push(`${this.transformations.naming['share']} must total 100%`);
    }

    for (const member of validation.members) {
      const hours = Number(!member.hours || member.hours === 0 ? member.calculated : member.hours);
      if (!validation.note && hours !== member.calculated) {
        msg.push('A note is required to explain the adjusted assigned hours');
      }
    }

    return msg;
  }

  _validate(data: any, schema: any) {
    return new Promise((resolve, reject) => {
      switch (schema) {
        case 'staff':
          Joi.validate(
            data,
            StaffAllocationSchema,
            { convert: true, abortEarly: false },
            (errs, convertedValues) => {
              resolve(ConvertToForms(errs));
            }
          );
          break;
        case 'course':
          Joi.validate(
            data,
            CourseAllocationSchema,
            { convert: true, abortEarly: false },
            (errs, convertedValues) => {
              resolve(ConvertToForms(errs));
            }
          );
          break;
        case 'offering':
          Joi.validate(
            data,
            OfferingAllocationSchema,
            { convert: true, abortEarly: false },
            (errs, convertedValues) => {
              resolve(ConvertToForms(errs));
            }
          );
          break;
        default:
          Joi.validate(
            data,
            StaffTeachingAllocationSchema,
            { convert: true, abortEarly: false },
            (errs, convertedValues) => {
              resolve(ConvertToForms(errs));
            }
          );
      }
    });
  }
}

export const Allocation = new AllocationValidator();
