import React from 'react';

function TableCommandCellView({ view, dataItem }) {
  function onViewClick() {
    view(dataItem);
  }

  return (
    <button
      className='k-primary k-button k-grid-remove-command'
      onClick={onViewClick}
    >
      View
    </button>
  );
}

export default TableCommandCellView;
