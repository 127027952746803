const CourseOfferingActivity = {
    type: 'Type',

    offering: 'Offering',

    activity: 'Activity',

    variable: 'Variable',

    number: 'Number',

    staffMember: 'Staff Member',

    workShare: 'Share of Work',

    period: 'Work Undertaken',

    note: 'Note',

    id: 'course',
};


const StaffActivity = {
    type: 'Type',

    category: 'Category',

    activity: 'Activity',

    variable: 'Variable',

    number: 'Number',

    staffMember: 'Staff Member',

    workShare: 'Share of Work',

    period: 'Work Undertaken',

    id: 'staff',

    offering: 'Offering',

    note: 'Note',
};

const TeachingActivity = {
    type: 'Type',

    category: 'Category',

    activity: 'Activity',

    variable: 'Variable',

    number: 'Number',

    staffMember: 'Staff Member',

    workShare: 'Share of Work',

    period: 'Work Undertaken',

    id: 'staff',

    course: 'Course',

    offering: 'Offering',

    note: 'Note',
};



const DefaultSelectOption = 'Please Select';

export const DialogContent = { CourseOfferingActivity, StaffActivity, TeachingActivity, DefaultSelectOption };