import React from 'react';

import Main from './router/main';
import './App.css';
import './ant.css';
import '@progress/kendo-theme-default/dist/all.css';

import { RequestInterceptor } from './components/requestInterceptor/requestInterceptor';

import {
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { login, result, error } = useMsalAuthentication('redirect');

  return (
    <div className='App-frame'>
      <AuthenticatedTemplate>
        <RequestInterceptor>
          <Main />
        </RequestInterceptor>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>Redirecting to login ...</p>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
