import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './dashboard.css';
import { PermissionsHelper } from '../../utility';
import { inject, observer } from 'mobx-react';
type Props = {
  url: string,
  title: string,
  refresh: boolean,
};
// eslint-disable-next-line @typescript-eslint/ban-types
class DashboardOptions extends Component<Props> {
  constructor(props) {
    super(props);
    this.authUserStore = props.authUserStore;
    this.viewStore = props.viewStore;
    this.state = {
      isFocused: false,
    };
  }

  canAccessAdminPage() {
    const user = this.authUserStore.currentUser;
    return PermissionsHelper.userHasRole('bpo', user);
  }

  canAccessModelPage() {
    const { model } = this.viewStore;
    const user = this.authUserStore.currentUser;
    return !PermissionsHelper.isRestrictedAccess({
      user,
      model,
      allowedRoles: ['bpo', 'mm'],
    });
  }

  canAccessReportPage() {
    const { model, year } = this.viewStore;
    const user = this.authUserStore.currentUser;

    const yearIsOpenOrClosed = ['open', 'closed'].includes(year.status.code);
    const modelIsVerified = model.status.code === 'verified';

    if (PermissionsHelper.userHasRole(['bpo', 'mm', 'wm'], user)) {
      return true;
    }
    if (PermissionsHelper.userHasRole('as', user) && yearIsOpenOrClosed && modelIsVerified) {
      return true;
    }
    return false;
  }

  allowedNav() {
    switch (this.props.url) {
      case 'admin':
        return this.canAccessAdminPage();
      case 'model':
        return this.canAccessModelPage();
      case 'report':
        return this.canAccessReportPage();
      case 'staff':
      case 'course':
        return true;
      default:
        return false;
    }
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  render() {
    const { isFocused } = this.state;
    const divClassName = `dashboard-option-frame ${isFocused ? 'active' : ''}`;

    return (
      this.allowedNav() && (
        <div className={divClassName}>
          <Link
            to={this.props.url}
            className='dashboard-option-link'
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          >
            <p>{this.props.title}</p>
          </Link>
        </div>
      )
    );
  }
}

export default inject('authUserStore', 'viewStore')(observer(DashboardOptions));
