import React, { Component } from 'react';
import { Button, Col, Modal, Row } from 'antd';

import './grid-command-cells.css';

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  response: Function,
  title: string,
  lines: string,
};
class GridRemoveDialog extends Component<Props> {
  decline = () => {
    this.props.response(false);
  };

  confirm = () => {
    this.props.response(true);
  };

  render() {
    const lines =
      this.props.lines != null ? this.props.lines : ['Do you wish to remove the selected record'];
    const title = this.props.title != null ? this.props.title : 'Please confirm';

    return (
      <div className='grid-remove-dialog'>
        <Modal
          width={'auto'}
          className='grid-remove-dialog'
          open={true}
          footer={null}
          title={title}
          onCancel={this.decline}
          onOk={this.confirm}
          cancelText='No'
          okText='Yes'
          centered
          bodyStyle={{ textAlign: 'center' }}
        >
          {lines.map((line, index) => (
            <div
              className='line'
              key={index}
            >
              {line}
            </div>
          ))}
          <Row>
            <Col span={12}>
              <Button
                type='button'
                onClick={this.confirm}
                look='outline'
                className='save'
                block
              >
                Yes
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type='submit'
                onClick={this.decline}
                look='outline'
                className='cancel'
                block
              >
                No
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default GridRemoveDialog;
