import { spy } from 'mobx';

const log = (event, fontSize) => {
  console.log(`%cSPY: ${event.type} ${event.name}`, `color: cyan; font-size: ${fontSize}`);

  if (event.arguments === undefined) return;
  console.log(
    `%cSPY: ${JSON.stringify(event?.arguments)?.substring(0, 200)}`,
    `color: orange; font-size: ${fontSize}`
  );
};

export const mobxSpy = ({ enable = false, eventName = null, fontSize = '12px' } = {}) => {
  if (!enable) return;

  spy((event) => {
    if (eventName) {
      const regex = new RegExp(eventName, 'i');
      if (!String(event.name).search(regex)) {
        log(event, fontSize);
      }
    } else {
      log(event, fontSize);
    }
  });
};
