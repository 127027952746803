/* eslint-disable @typescript-eslint/no-explicit-any */
//@flow
import axios from 'axios';
import moment from 'moment';
import { version } from '../utility/version';
import { ViewManager } from '../utility';

const ApiHostUrl = process.env.REACT_APP_API_HOST;

export default class Api {
  year: number;

  static url = () => ApiHostUrl;

  static setYear(year: number) {
    Api.year = year;
  }

  static setHeaders(headers) {
    return {
      headers: {
        ...headers,
        year: Api.year,
      },
    };
  }

  static splitVersion(versionString) {
    return versionString
      ? {
          major: versionString.split('.')[0],
          minor: versionString.split('.')[1],
          patch: versionString.split('.')[2],
        }
      : { major: '0', minor: '00', patch: '000' };
  }

  static isWebAppVersionCorrect(response) {
    const webAppVersionFromApi = response.headers['web-app-version'];
    const webAppVersionFromApiObject = this.splitVersion(webAppVersionFromApi);
    const webAppVersionFromLocal = version;
    const webAppVersionFromLocalObject = this.splitVersion(webAppVersionFromLocal);
    const environment = process.env.REACT_APP_ENV;

    if (environment === 'production') {
      if (!webAppVersionFromApi) {
        console.warn(
          'Need to configure WEB_APP_VERSION environment variable on WAT API.\nThis is for comparing WEB versions to check for updates to the WEB App'
        );
        return;
      }

      const localVersionGreaterOrEqual =
        webAppVersionFromLocalObject.major >= webAppVersionFromApiObject.major &&
        webAppVersionFromLocalObject.minor >= webAppVersionFromApiObject.minor &&
        webAppVersionFromLocalObject.patch >= webAppVersionFromApiObject.patch;

      if (localVersionGreaterOrEqual) {
        return ViewManager.updateVersionUpdate({ needsUpdate: false });
      }

      console.warn(
        `Web App Version from API (${webAppVersionFromApi}) doesn't match local version (${webAppVersionFromLocal})`
      );
      return ViewManager.updateVersionUpdate({ needsUpdate: true });
    }
  }

  static get = (url: string, headers: any = {}) => {
    return new Promise((resolve, rejects) => {
      axios
        .get(ApiHostUrl + url, { headers })
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              this.isWebAppVersionCorrect(response);
              resolve(response.data);
              break;
            default:
              rejects(response);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            window.location.assign('#/unauthorized');
          } else {
            rejects(error);
          }
        });
    });
  };

  static post = (url: string, data: any, headers: any = {}) => {
    return new Promise((resolve, rejects) => {
      axios
        .post(ApiHostUrl + url, data, this.setHeaders(headers))
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              resolve(response.data);
              break;
            default:
              rejects(response);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            window.location.assign('#/unauthorized');
          } else {
            rejects(error);
          }
        });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
  static upload = (url: string, data: any, headers: any = {}, onUploadProgress: Function) => {
    return new Promise((resolve, rejects) => {
      axios
        .post(ApiHostUrl + url, data, { ...this.setHeaders(headers), onUploadProgress })
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              resolve(response.data);
              break;
            default:
              rejects(response);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            window.location.assign('#/unauthorized');
          } else {
            rejects(error);
          }
        });
    });
  };

  static put = (url: string, data: any, headers: any = {}) => {
    return new Promise((resolve, rejects) => {
      axios
        .put(ApiHostUrl + url, data, this.setHeaders(headers))
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              resolve(response.data);
              break;
            default:
              rejects(response);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            window.location.assign('#/unauthorized');
          } else {
            rejects(error);
          }
        });
    });
  };

  static delete = (url: string, headers: any = {}) => {
    return new Promise((resolve, rejects) => {
      axios
        .delete(ApiHostUrl + url, this.setHeaders(headers))
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              resolve(response.data);
              break;
            default:
              rejects(response);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            window.location.assign('#/unauthorized');
          } else {
            rejects(error);
          }
        });
    });
  };

  static blobGet = (url: string, headers: any = {}) => {
    return new Promise((resolve, rejects) => {
      axios
        .get(ApiHostUrl + url, { responseType: 'blob', headers })
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              // eslint-disable-next-line no-case-declarations
              const type = response.headers['content-type'].trim().replace(/"/g, '');
              // eslint-disable-next-line no-case-declarations
              const fileName =
                response.headers['content-disposition'] != null
                  ? response.headers['content-disposition']
                      .split(';')[1]
                      .trim()
                      .split('=')[1]
                      .replace(/"/g, '')
                  : moment().format('DD-MM-YYYY');

              resolve({ blob: response.data, fileName, type });
              break;
            default:
              rejects(response);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            window.location.assign('#/unauthorized');
          } else {
            rejects(error);
          }
        });
    });
  };

  static blobPost = (url: string, data: any, headers: any = {}) => {
    return new Promise((resolve, rejects) => {
      axios
        .post(ApiHostUrl + url, data, { responseType: 'blob', headers })
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              // eslint-disable-next-line no-case-declarations
              const type = response.headers['content-type'].trim().replace(/"/g, '');
              // eslint-disable-next-line no-case-declarations
              const fileName =
                response.headers['content-disposition'] != null
                  ? response.headers['content-disposition']
                      .split(';')[1]
                      .trim()
                      .split('=')[1]
                      .replace(/"/g, '')
                  : moment().format('DD-MM-YYYY');

              resolve({ blob: response.data, fileName, type });
              break;
            default:
              rejects(response);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            window.location.assign('#/unauthorized');
          } else {
            rejects(error);
          }
        });
    });
  };

  static exec = (url: string, method: string, data: any = {}, headers: any = {}) => {
    return new Promise((resolve, rejects) => {
      axios({ url, method, data, headers })
        .then((response) => {
          switch (response.status) {
            case 200:
            case 204:
              resolve(response.data);
              break;
            default:
              rejects(response);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            window.location.assign('#/unauthorized');
          } else {
            rejects(error);
          }
        });
    });
  };
}
