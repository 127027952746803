

import { makeObservable } from 'mobx';

import { StaffDto, OfferingDto } from '../dto';
import Api from './api';

export default class OfferingStore {

    coordinators: StaffDto[];


    async delete(id: string) {
        return new Promise((resolve, reject) => {
            Api.put(`/offerings/delete/${id}`).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            })
        })
    }

    get(id: string) {
        return new Promise((resolve, reject) => {
            Api.get(`/offerings/${id}`).then(data => {
                resolve(data);
            })
        })
    }

    async add(offering: OfferingDto) {
        return new Promise((resolve, reject) => {
            Api.post(`/offerings`, offering).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            })
        })
    }

    async update(offering: OfferingDto) {
        return new Promise((resolve, reject) => {
            Api.put(`/offerings/${offering.id}`, offering).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            })
        })
    }


    async getCoordinators(modelId: ?string) {
        return new Promise(async (resolve, reject) => {
            this.coordinators = await Api.get((modelId ? `/staff/model/${modelId}` : '/staff?active=true'));
            resolve(this.coordinators);
        })
    }


}

makeObservable(OfferingStore, {

})
