// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header, BodyLayout, Spinner, CollapsePanel, ConfirmationDialog } from '../../components';
import ManageUsers from './manageUsers';
import './admin.css';
import Year from './year';
import Version from './version';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

type State = {
  loading: boolean,
  dialog: {
    visible: boolean,
    title: string,
    lines: string[],
    // eslint-disable-next-line @typescript-eslint/ban-types
    callback: Function,
  },
  spinner: { visible: boolean, text: string },
};
('');

class Admin extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.userStore = this.props.userStore;
    this.state = {
      loading: true,
      dialog: { visible: false },
      spinner: { visible: false, text: '' },
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    this.userStore.getUsers();
    this.userStore.getUserGroups(true);

    this.setState({ loading: false });
  }

  onDialogChange = (newDialog) => {
    if (this._isMounted) {
      this.setState({ dialog: newDialog });
    }
  };

  onSpinnerChange = (newSpinner) => {
    if (this._isMounted) {
      this.setState({ spinner: newSpinner });
    }
  };

  render() {
    return (
      <div>
        <Header navState={'admin'} />
        {this.state.loading && <Spinner text={'Loading'} />}
        {!this.state.loading && (
          <BodyLayout>
            <CollapsePanel
              expanded={false}
              title='Manage Users'
              tabIndex={1}
            >
              <ManageUsers
                gridData={this.userStore.userList}
                readOnly={false}
              />
            </CollapsePanel>

            <CollapsePanel
              expanded={false}
              title='Manage Academic Years'
              tabIndex={2}
            >
              <Year
                onDialogChange={this.onDialogChange}
                onSpinnerChange={this.onSpinnerChange}
              />
            </CollapsePanel>

            <Version />

            {this.state.dialog && this.state.dialog.visible && (
              <ConfirmationDialog
                response={this.state.dialog.callback}
                title={this.state.dialog.title}
                lines={this.state.dialog.lines}
              />
            )}

            {this.state.spinner.visible && (
              <Spinner
                text={this.state.spinner.text}
                lines={this.state.spinner.lines}
              />
            )}
          </BodyLayout>
        )}
      </div>
    );
  }
}

export default inject('userStore')(observer(Admin));
