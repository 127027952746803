import React, { useState, useEffect } from 'react';
import { MessageConfirmationBlock } from '../../components/message';

const ImportMessageBlock = (props) => {
  const { context, importData } = props;
  const { data, stage } = importData;
  const { onImportCommit, onImportReport, onEnrolmentReport, onImportCancel } = props;

  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(null);
  const [title, setTitle] = useState(null);
  const [lines, setLines] = useState([]);
  const [actions, setActions] = useState([]);

  function cleanMessageBox() {
    setVisible(false);
    setLines([]);
    setActions([]);
  }

  useEffect(() => {
    function cancel() {
      cleanMessageBox();
      if (onImportCancel != null) {
        onImportCancel();
      }
    }

    function commit() {
      cleanMessageBox();
      onImportCommit();
    }

    function report() {
      onImportReport();
    }

    function enrolmentReport() {
      onEnrolmentReport();
    }

    function close() {
      cleanMessageBox();
    }

    switch (stage) {
      case 'clear':
        cleanMessageBox();
        break;
      case 'import_warning':
        setVisible(true);
        setType('warning');
        //setTask('import');
        setTitle('Import Processing Paused');
        setLines(
          data.result
            .sort((a, b) => (a.success == b.success ? 0 : a.success ? -1 : 1))
            .map((d, index) => (
              <span
                key={`${index}`}
                className={`import-line ${d.success ? 'success' : 'warning'}`}
              >
                <span className={`k-icon k-i-${d.success ? 'check' : 'warning'}`}></span>
                {d.message}
              </span>
            ))
        );
        if (context === 'manage_import') {
          setActions([
            { text: 'Cancel Import', callback: () => cancel() },
            { text: 'Continue Import', callback: () => commit() },
            { text: 'Download Report', callback: () => report() },
          ]);
        } else if (context === 'change_file_importer') {
          setActions([
            { text: 'Cancel Import', callback: () => cancel() },
            { text: 'Continue Import', callback: () => commit() },
            { text: 'Download Report', callback: () => report() },
            { text: 'Enrolment Impact Report', callback: () => enrolmentReport() },
          ]);
        }
        break;
      case 'import_error':
        let displayMsg = 'An error has occurred while processing the import';
        if(data?.error?.response?.data) {
          const msg = data?.error?.response?.data;
          if(msg.includes('not in import state')) {
            displayMsg = 'Model State must be at Import to Process Data Changes';
          }
        }
        setVisible(true);
        setType('error');
        //setTask('import');
        setTitle(
          <span className={'import-line error'}>
            <span className={'k-icon k-i-warning'}></span>{displayMsg}
          </span>
        );
        setActions([{ text: 'Close', callback: () => close() }]);
        break;
      case 'commit_success':
        setVisible(true);
        setType('success');
        setTitle(
          <span className={'import-line success'}>
            <span className={'k-icon k-i-check'}></span>Commit Successful
          </span>
        );
        setActions([{ text: 'Close', callback: () => cancel() }]);
        break;
      case 'commit_with_warnings':
        setVisible(true);
        setType('warning');
        setTitle(
          <span className={'import-line warning'}>
            <span className={'k-icon k-i-warning'}></span>Commit Contains Warnings
          </span>
        );
        setActions([{ text: 'Close', callback: () => close() }]);
        break;
      case 'enrolment_report_success':
        setVisible(true);
        setType('success');
        setTitle(
          <span className={'import-line success'}>
            <span className={'k-icon k-i-check'}></span>Enrolment Impact Report Successful
          </span>
        );
        if (context === 'change_file_importer') {
          setActions([
            { text: 'Cancel Import', callback: () => cancel() },
            { text: 'Continue Import', callback: () => commit() },
            { text: 'Download Report', callback: () => report() },
            { text: 'Enrolment Impact Report', callback: () => enrolmentReport() },
          ]);
        }
        break;
      case 'enrolment_report_error':
        setVisible(true);
        setType('error');
        setTitle(
          <span className={'import-line error'}>
            <span className={'k-icon k-i-warning'}></span>Error with Enrolment Impact Report.
          </span>
        );
        setActions([{ text: 'Close', callback: () => cancel() }]);
        break;
        case 'report_error':
          setVisible(true);
          setType('error');
          setTitle(
            <span className={'import-line error'}>
              <span className={'k-icon k-i-warning'}></span>An error has occurred while processing
              the Import Report.
            </span>
          );
          setActions([{ text: 'Close', callback: () => cancel() }]);
        break;
        case 'report_success':
          setVisible(true);
          setType('success');
          setTitle(
            <span className={'import-line success'}>
              <span className={'k-icon k-i-warning'}></span>Import Report Successful
            </span>
          );
          if (context === 'change_file_importer') {
            setActions([
              { text: 'Cancel Import', callback: () => cancel() },
              { text: 'Continue Import', callback: () => commit() },
              { text: 'Download Report', callback: () => report() },
              { text: 'Enrolment Impact Report', callback: () => enrolmentReport() },
            ]);
          }
          break;
    }
  }, [stage, data, context, onImportCommit, onImportReport, onEnrolmentReport, onImportCancel]);

  return (
    <MessageConfirmationBlock
      visible={visible}
      title={title}
      type={type}
      lines={lines}
      actions={actions}
    />
  );
};

export default ImportMessageBlock;
