// @flow

import React, { Component } from 'react';
import { Button } from 'antd';
import { Spinner } from '../spinner';

import './actions.css';
import { LoadingOutlined, PrinterOutlined } from '@ant-design/icons';

type Props = {
  text: string,
  enabled: boolean,
  printing: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  on: Function,
  spinner: boolean,
  spinnerText: string,
};

class PrintButton extends Component<Props> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='print-button-action'>
        <Button
          onClick={this.props.on}
          icon={this.props.printing ? <LoadingOutlined /> : <PrinterOutlined />}
          className='print-button'
          look='outline'
          primary='true'
          disabled={!this.props.enabled || this.props.printing}
        >
          {this.props.text}
        </Button>
        {this.props.spinner && this.props.printing && (
          <Spinner text={this.props.spinnerText || 'Compiling'} />
        )}
      </div>
    );
  }
}

export default PrintButton;
