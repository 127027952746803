import React, { Component } from 'react';

import { Header, BodyLayout } from '../../components';
import NoticeBar from '../../components/noticeBar/notice-bar';


class NotFound extends Component {

    render() {
        return (
            <div>
                <Header navState={'404'} />

                <BodyLayout>
                    <NoticeBar text={'Sorry, this page is not available'} icon={'information'} />
                </BodyLayout>
            </div>
        );
    }
}

export default NotFound;
