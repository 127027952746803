import Joi from 'joi';

const baseUserSchema = Joi.object({
  id: Joi.string().uuid({ version: 'uuidv4' }),
  firstName: Joi.string().max(250).required().trim(),
  surname: Joi.string().max(250).required().trim(),
  identifier: Joi.string()
    .required()
    .trim()
    .email({ tlds: { allow: false } })
    .error((err) => {
      err.forEach((e) => (e.message = 'Username must be valid'));
      return err;
    }),
  email: Joi.string()
    .required()
    .trim()
    .email({ tlds: { allow: false } })
    .error((err) => {
      err.forEach((e) => (e.message = 'Email must be valid'));
      return err;
    }),
  group: Joi.object()
    .keys({
      id: Joi.string().uuid({ version: 'uuidv4' }).required(),
      code: Joi.string().optional(),
    })
    .required(),
  status: Joi.string().valid('active', 'inactive').required(),
}).unknown(true);

const _userModelSchema = Joi.object().keys({
  id: Joi.string().uuid({ version: 'uuidv4' }).required(),
  code: Joi.string().required(),
  year: Joi.object().keys({
    year: Joi.number().required(),
    status: Joi.object()
      .keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
        code: Joi.string().required(),
        status: Joi.string().required(),
      })
      .required(),
  })
  .optional(),
});

export const NonBPOUserSchema = baseUserSchema.keys({
  model: _userModelSchema.required(),
});

export const BPOUserSchema = baseUserSchema;
