// @flow

import * as mobx from 'mobx';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'antd';

import {
  AddEditCourseOfferingActivityDialog,
  AddEditOfferingDialog,
  BodyLayout,
  ConfirmationDialog,
  ErrorOverlay,
  Header,
  MessageLine,
  Spinner,
  StateBar,
} from '../../components';
import {
  ActivityDto,
  AllocationDto,
  AllocationTypeDto,
  AllocationYearDto,
  AuthenticatedUserDto,
  CourseDto,
  ExportDto,
  ModelDto,
  OfferingDto,
  OwnerDepartmentDto,
  PagerDto,
  PeriodDto,
  ProfileCategoryDto,
  ReportDto,
  SearchCourseDto,
  SortDto,
  StaffDto,
  VariableDto,
  WorkshareDto,
} from '../../dto';
import { PermissionsHelper, StringUtility, ViewManager } from '../../utility';
import CourseLevelAllocations from './course-level-allocations';
import OfferingAllocations from './offering-allocations';
import CourseSearch from './search';
import Summary from './summary';
import './course.css';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AddActivityButton } from '../../components/ui';
import NavStore from '../../mobx/nav-store';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
  location: {
    pathname?: string,
  },
  match: {
    params?: {
      id?: string,
    },
  },
  history: {
    push: Function,
    goBack: Function,
  },
  authUserStore: OwnerDepartmentDto,
  courseStore: CourseDto,
  reportStore: ReportDto,
  offeringStore: OfferingDto,
  exportStore: ExportDto,
  navStore: NavStore,
  viewStore: AllocationDto,
  activityStore: ActivityDto[],
  allocationStore: AllocationYearDto[],
};

type State = {
  year: AllocationYearDto,
  userRoleList: string[],
  courseUserRole: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentUser: any,
  course: CourseDto,
  courseList: CourseDto[],
  offeringList: OfferingDto[],
  dictionaries: {
    types: AllocationTypeDto[],
    categories: ProfileCategoryDto[],
    variables: VariableDto[],
    staff: StaffDto[],
    workshares: WorkshareDto[],
    periods: PeriodDto[],
    model: ModelDto,
    initilised: boolean,
  },

  notify: { state: boolean, msg: string, type: string },
  coursePaging: { limit: number, skip: number },
  offeringPaging: { limit: number, skip: number },
  spinner: { visible: boolean, text: string },
  searchLoading: boolean,
  spinnerLoading: boolean,
  readOnlyView: boolean,
  reporting: boolean,
  reportType: 'report' | 'export',
  printexporting: boolean,
  courseAllocations: PagerDto<AllocationDto>,
  offeringAllocations: PagerDto<AllocationDto>,
  offeringSorting: SortDto[],
  courseSorting: SortDto[],
  // eslint-disable-next-line @typescript-eslint/ban-types
  error: { lines: string[], details: string, onClose: Function },
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  dialog: { response: Function, visible: boolean, lines: any[], title: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: { visible: boolean, line: any },
  loadings: {
    allocation: boolean,
    course: boolean,
    offering: boolean,
    staff: boolean,
    hours: boolean,
  },
  expandings: {
    staff: boolean,
    allocation: boolean,
    course: boolean,
    offering: boolean,
  },
  refreshings: { staff: boolean, offering: boolean },
  edit: { visible: boolean, item: AllocationDto, type: string, dialog: string },
  model: ModelDto,
  showCourseSearch: boolean,
  payLoad: SearchCourseDto,
};

class Course extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.authUserStore = this.props.authUserStore;
    this.courseStore = this.props.courseStore;
    this.reportStore = this.props.reportStore;
    this.offeringStore = this.props.offeringStore;
    this.exportStore = this.props.exportStore;
    this.navStore = this.props.navStore;
    this.viewStore = this.props.viewStore;
    this.activityStore = this.props.activityStore;
    this.allocationStore = this.props.allocationStore;

    this.state = {
      test: true,
      year: null,
      userRoleList: [],
      courseUserRole: '',
      currentUser: null,
      course: {},
      courseList: [],
      offeringList: [],
      dictionaries: {
        types: null,
        categories: null,
        variables: null,
        staff: null,
        workshares: null,
        periods: null,
        model: null,
        initilised: false,
      },
      coursePaging: {},
      offeringPaging: {},
      spinner: { visible: false, text: null },
      searchLoading: true,
      spinnerLoading: true,
      readOnlyView: false,
      reporting: false,
      reportType: null,
      courseReload: false,
      courseAllocations: null,
      offeringAllocations: null,
      error: { lines: [] },
      dialog: { response: null, visible: false, lines: [], title: '' },
      notify: { state: false, msg: '', type: '' },
      message: {},
      loadings: {
        allocation: true,
        course: true,
        offering: true,
        staff: true,
        hours: true,
      },
      expandings: {
        staff: false,
        offering: false,
        course: true,
        allocation: true,
      },
      refreshings: { staff: false, offering: false },
      edit: { visible: false, item: null, type: '', dialog: '' },
      model: {},
      showCourseSearch: true,
      payload: null,
      abort: false,
      reloadComponents: {
        course: false,
        offering: false,
        staff: false,
        allocation: false,
      },
      search: {},
      doSort: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { year, model } = this.viewStore;

    const user = this.authUserStore.currentUser;
    let course = {};
    if (model) {
      this.setState({
        model,
      });
    }
    if (year) {
      this.setState({
        year,
      });
    }

    if (this.props.match?.params.id) {
      course = await this.courseStore.fetchCourseDetails(this.props.match?.params.id);
      this.setAllocations(course).then();

      this.setState({ searchLoading: false, spinnerLoading: false, showCourseSearch: false });
    }

    if (this.props.location?.pathname !== '/course') {
      this.showCourseSearch(false);
    } else {
      this.navStore.setPreviousLocation('');
      ViewManager.clearSearches();
      this.showCourseSearch(true);
    }

    this.getUserRoles(user, model, course);

    this.setState({
      spinnerLoading: false,
      currentUser: user?.user,
      readOnlyView: this.isReadOnly(course),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this._isMounted) return;

    if (
      prevProps.location?.pathname !== this.props.location?.pathname &&
      this.props.location?.pathname === '/course'
    ) {
      this.showCourseSearch(true);
    }
    if (
      prevProps.location?.pathname !== this.props.location?.pathname &&
      this.props.location?.pathname !== '/course'
    ) {
      this.showCourseSearch(false);
    }

    if (prevState.year != null && prevState.year !== this.state.year) {
      if (this.props.location?.pathname !== '/course') {
        this.props.history.push('/course');
      }
      this.setState({
        readOnlyView: this.isReadOnly(null),
        dictionaries: { initilised: false },
      });
    }
    const user = this.authUserStore.currentUser;
    const model = this.state.model;
    const course = this.state.course;

    this.getUserRoles(user, model, course);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUnique(array) {
    return Array.from(new Set(array));
  }

  getUserRoles(user: AuthenticatedUserDto, model: ModelDto, course: CourseDto) {
    let courseUserRole = '';
    let roleList = [];

    if (user != null && model != null) {
      user.userModelGroups.forEach((umg) => {
        const userGroupRole = umg.userGroup.code;
        //Look for role for this course, only set if blank or as
        switch (userGroupRole) {
          case 'bpo':
            if (courseUserRole !== 'bpo') {
              courseUserRole = userGroupRole;
            }
            roleList.push('bpo');
            break;
          case 'mm':
            if (umg.modelCode === model.code) {
              if (!['bpo', 'mm'].includes(courseUserRole)) {
                courseUserRole = userGroupRole;
              }
              roleList.push(userGroupRole);
            }
            break;
          case 'wm':
            if (umg.modelCode === model.code) {
              if (
                !['bpo', 'mm', 'wm'].includes(courseUserRole) &&
                umg.departmentCode === course?.owner?.ownerDepartment?.department?.code
              ) {
                courseUserRole = userGroupRole;
              }
              if (
                !['bpo', 'mm', 'wm'].includes(courseUserRole) &&
                Object.keys(course).length === 0
              ) {
                courseUserRole = userGroupRole;
              }
              roleList.push(userGroupRole);
            }
            break;
          case 'as':
            if (umg.modelCode === model.code) {
              if (!['bpo', 'mm', 'wm', 'as'].includes(courseUserRole)) {
                courseUserRole = userGroupRole;
              }
              roleList.push(userGroupRole);
            }
            break;
          default:
            throw new Error('UNKNONW userGroupRole!!!');
        }
      });

      const userRoleList = this.getUnique(roleList);

      if (this._isMounted) {
        if (this.state.courseUserRole !== courseUserRole) {
          this.setState({ courseUserRole });
        }
        if (this.state.userRoleList.toString() !== userRoleList.toString()) {
          this.setState({ userRoleList });
        }
      }
    }
  }

  isReadOnly = (course) => {
    const { model, year } = this.viewStore;
    const user = this.authUserStore.currentUser;
    const departments = [course?.owner?.ownerDepartment?.department];

    return PermissionsHelper.isRestrictedAccess({ user, year, model, departments, course });
  };

  isReadOnlyBanner = (record, current) => (
    <span>
      <strong>Read-Only</strong>. You are viewing a <span className='emphasise'>{record}</span>{' '}
      record but your current Workload Year is set as <span className='emphasise'>{current}</span>.
    </span>
  );

  // course //
  onCoursePageChange = (limit: number, skip: number) => {
    this.setState({
      coursePaging: { limit, skip },
      spinner: { visible: true, text: 'Loading' },
    });
    this.allocationStore
      .getByCourseType(
        this.state.course.id,
        this.state.course.year,
        limit,
        skip,
        true,
        this.state.courseSorting
      )
      .then((data) => {
        this.setState({
          courseAllocations: data,
          spinner: { visible: false, text: '' },
        });
      });
  };

  onCourseSortChange = (sorting) => {
    this.setState({
      courseSorting: sorting,
      spinner: { visible: true, text: 'Loading' },
    });
    this.allocationStore
      .getByCourseType(
        this.state.course.id,
        this.state.course.year,
        this.state.coursePaging.limit,
        this.state.coursePaging.skip,
        true,
        sorting
      )
      .then((allocations) => {
        if (this._isMounted) {
          this.setState({
            courseAllocations: allocations,
            spinner: { visible: false, text: '' },
          });
        }
      });
  };

  showCourseSearch(showCourseSearch) {
    if (showCourseSearch) {
      this.setState({
        showCourseSearch: true,
        searchLoading: true,
        spinnerLoading: false,
        message: {},
        readOnlyView: this.isReadOnly(null),
      });
    } else {
      this.setState({
        spinnerLoading: true,
        showCourseSearch: false,
        searchLoading: false,
      });
    }
  }

  // offering //
  onOfferingPageChange = (limit: number, skip: number) => {
    this.setState({
      offeringPaging: { limit, skip },
      spinner: { visible: true, text: 'Loading' },
    });
    this.allocationStore
      .getByOfferingType(
        this.state.course.id,
        this.state.course.year,
        limit,
        skip,
        true,
        this.state.offeringSorting
      )
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            offeringAllocations: data,
            spinner: { visible: false, text: '' },
          });
        }
      });
  };

  onOfferingSortChange = (sorting) => {
    this.setState({
      offeringSorting: sorting,
      spinner: { visible: true, text: 'Loading' },
    });
    this.allocationStore
      .getByOfferingType(
        this.state.course.id,
        this.state.course.year,
        this.state.offeringPaging.limit,
        this.state.offeringPaging.skip,
        true,
        sorting
      )
      .then((allocations) => {
        if (this._isMounted) {
          this.setState({
            offeringAllocations: allocations,
            spinner: { visible: false, text: '' },
          });
        }
      });
  };

  onOfferingPanelSelected = (e) => {
    this.setState({
      expandings: {
        ...this.state.expandings,
        allocation: e.target && !e.target.props.expanded,
      },
    });
  };

  onOfferingSummaryToggle = (e) => {
    this.setState({
      expandings: {
        ...this.state.expandings,
        offering: e.target && !e.target.props.expanded,
      },
    });
  };

  onEditOffering = async (current: OfferingDto, previous: OfferingDto) => {
    if (
      current.enrolments !== previous.enrolments ||
      current.coordinator.id !== previous.coordinator.id
    ) {
      if (current.enrolments !== previous.enrolments) {
        this.setState({
          edit: { visible: false, item: null, type: '', dialog: '' },
          spinner: { visible: true, text: 'Updating' },
        });
        this.allocationStore
          .calculateByCourse(this.state.year.year, current.course.id)
          .then(() => {
            this.allocationStore.calculateByOffering(this.state.year.year, current.id);
          })
          .then(
            () => {
              if (this._isMounted) {
                this.setState({
                  spinner: { visible: false, text: '' },
                  searchLoading: true,
                });
              }
              this.setAllocations(this.state.course);
            },
            (e) => {
              if (this._isMounted) {
                this.setState({
                  spinner: { visible: false, text: '' },
                  error: {
                    lines: ['An error has occurred while attempting to recalculate allocations.'],
                    exception: e,
                  },
                });
              }
            }
          );
      } else {
        this.setState({ searchLoading: true });
        await this.setAllocations(this.state.course);
      }
    }
  };

  onDeleteOffering = async (offering: OfferingDto) => {
    this.setState({ spinner: { visible: true, text: 'Deleting' } });
    this.offeringStore.delete(offering.id).then(
      () => {
        if (this._isMounted) {
          this.setState({ spinner: { visible: true, text: 'Updating' } });
        }
        this.allocationStore.calculateByCourse(this.state.year.year, this.state.course.id).then(
          () => {
            if (this._isMounted) {
              this.setState({
                spinner: { visible: false, text: '' },
                searchLoading: true,
              });
            }
            this.setAllocations(this.state.course);
          },
          (e) => {
            if (this._isMounted) {
              this.setState({
                spinner: { visible: false, text: '' },
                error: {
                  lines: ['An error has occurred while attempting to recalculate allocations.'],
                  exception: e,
                },
              });
            }
          }
        );
      },
      (e) => {
        if (this._isMounted) {
          this.setState({
            spinner: { visible: false, text: '' },
            error: {
              lines: ['An error has occurred while attempting to delete the offering.'],
              exception: e,
            },
          });
        }
      }
    );
  };

  // allocation //
  setAllocations = async (courseDto: CourseDto) => {
    // Change url:
    let history = this.props.history;
    let pathName = `/course/${courseDto.id}`;
    if (history.location.pathname !== pathName) {
      history.push(pathName);
    }

    let course = await this.courseStore.getCourseById(courseDto.id);

    const readOnlyView = course?.readOnly || this.isReadOnly(course);
    this.setState({
      spinnerLoading: true,
      searchLoading: false,
      course: course,
      message:
        this.state.year.year !== course.year
          ? {
              visible: true,
              line: this.isReadOnlyBanner(course.year, this.state.year.year),
            }
          : {},
      readOnlyView,
      loadings: {
        allocation: true,
        course: true,
        offering: true,
        staff: true,
        hours: true,
      },
    });

    if (!readOnlyView && !this.state.dictionaries.initilised) {
      this.setState({
        dictionaries: {
          variables: (this.activityStore.variableList || []).map((d) => mobx.toJS(d)),
          model: course.model,
          initilised: false,
        },
      });

      this.activityStore.getWorkShares().then((shares) => {
        if (this._isMounted) {
          this.setState({
            dictionaries: {
              ...this.state.dictionaries,
              workshares: (shares || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          });
        }
      });
      this.activityStore.getTypes(course?.model?.id).then((types) => {
        if (this._isMounted) {
          this.setState({
            dictionaries: {
              ...this.state.dictionaries,
              types: (types || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          });
        }
      });
      this.activityStore.getCategories(course?.model?.id).then((categories) => {
        if (this._isMounted) {
          this.setState({
            dictionaries: {
              ...this.state.dictionaries,
              categories: (categories || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          });
        }
      });
      this.activityStore.getPeriods().then((periods) => {
        if (this._isMounted) {
          this.setState({
            dictionaries: {
              ...this.state.dictionaries,
              periods: (periods || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          });
        }
      });
      this.activityStore.getStaffMembers(course.model.id).then((staff) => {
        if (this._isMounted) {
          this.setState({
            dictionaries: {
              ...this.state.dictionaries,
              staff: (staff || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          });
        }
      });
    }

    const fetchCourseDetails = () =>
      this.courseStore.fetchCourseDetails(course.id, course.year).then(() => {
        if (this._isMounted) {
          this.setState({ searchLoading: false, spinnerLoading: false });
        }
      });

    const getByCourseType = () =>
      this.allocationStore
        .getByCourseType(
          course.id,
          course.year,
          this.state.coursePaging.limit,
          this.state.coursePaging.skip,
          true,
          this.state.courseSorting
        )
        .then((ca) => {
          if (this._isMounted) {
            this.setState({
              courseAllocations: ca,
              loadings: { ...this.state.loadings, course: false },
            });
          }
        });

    const fetchStaffInvolvedSummary = () =>
      this.courseStore.fetchStaffInvolvedSummary(course.id, course.year).then(() => {
        if (this._isMounted) {
          this.setState({
            loadings: { ...this.state.loadings, staff: false },
          });
        }
      });

    const fetchCalculatedSummary = () =>
      this.courseStore.fetchCalculatedSummary(course.id, course.year).then(() => {
        if (this._isMounted) {
          this.setState({ loadings: { ...this.state.loadings, hours: false } });
        }
      });

    // TODO: This will usually time out unless cached, takes at least 15 seconds on first hit
    const getByOfferingType = () =>
      this.allocationStore
        .getByOfferingType(
          course.id,
          course.year,
          this.state.offeringPaging.limit,
          this.state.offeringPaging.skip,
          true,
          this.state.offeringSorting
        )
        .then((oa) => {
          if (this._isMounted) {
            this.setState({
              offeringAllocations: oa,
              loadings: { ...this.state.loadings, allocation: false },
            });
          }
        });

    // TODO: This will usually time out unless cached, takes at least 15 seconds on first hit
    const fetchOfferingSummary = () =>
      this.courseStore.fetchOfferingSummary(course.id, course.year).then(() => {
        if (this._isMounted) {
          this.setState({
            loadings: { ...this.state.loadings, offering: false },
          });
        }
      });

    await Promise.all([
      fetchCourseDetails(),
      fetchOfferingSummary(),
      fetchStaffInvolvedSummary(),
      fetchCalculatedSummary(),
      getByCourseType(),
      getByOfferingType(),
    ]);
  };

  onRemoveAllocation = async (items: AllocationDto[]) => {
    this.setState({ searchLoading: true, spinnerLoading: true });
    if (items && items.length > 0) {
      if (items.length > 1) {
        this.allocationStore
          .removeAllocations(items.map((i) => i.id))
          .then((data) => this.onRemoveResult(data));
      } else {
        this.allocationStore.remove(items[0].id).then((data) => this.onRemoveResult(data));
      }
    }
  };

  onSubmitAllocation = async () => {
    this.setState({
      searchLoading: true,
      edit: { visible: false, item: null, type: '', dialog: '' },
    });
    await this.setAllocations(this.state.course);
  };

  onRemoveResult = async (result: { success: boolean, allocation: any, error: any }) => {
    await this.setAllocations(this.state.course);
  };

  // course //
  onCoursePanelSelected = (e) => {
    this.setState({
      expandings: {
        ...this.state.expandings,
        course: e.target && !e.target.props.expanded,
      },
    });
  };

  onReport = (type: string) => {
    this.setState({
      reportType: type,
      dialog: {
        visible: true,
        title: `Please Confirm (Email ${StringUtility.capitalize(type)})`,
        lines: [
          <span
            className='important'
            key='confirmNote'
          >
            As this {type} may take several minutes to generate, it will be emailed to you once
            completed.
          </span>,
          'Do you wish to continue?',
        ],
        response: this.report,
      },
    });
  };

  report = async (confirmed: boolean) => {
    this.setState({
      dialog: { response: null, visible: false, lines: [], title: '' },
    });

    if (confirmed) {
      this.setState({ reporting: true });

      const result =
        this.state.reportType === 'export'
          ? await this.exportStore.getCourseAllocationExport(
              {
                criteria: {
                  code: this.courseStore.course.code,
                  course: this.courseStore.course.course,
                },
                ids: [this.courseStore.course.id],
              },
              this.courseStore.course.year,
              true
            )
          : await this.reportStore.getCourseAllocationReport(
              {
                criteria: {
                  code: this.courseStore.course.code,
                  course: this.courseStore.course.course,
                },
                id: this.courseStore.course.id,
              },
              this.courseStore.course.year,
              true
            );
      this.setState(
        {
          reporting: false,
          notify: {
            type: result.success ? 'info' : 'error',
            state: true,
            msg: result.success
              ? `${StringUtility.capitalize(this.state.reportType)} Requested`
              : `Failed to Request ${StringUtility.capitalize(this.state.reportType)}`,
          },
          reportType: null,
        },
        () => {
          setTimeout(() => {
            if (this._isMounted) {
              this.setState({ notify: { state: false, msg: '' } });
            }
          }, 8000);
        }
      );
    }
  };

  // staff //
  onStaffSummaryToggle = (e) => {
    this.setState({
      expandings: {
        ...this.state.expandings,
        staff: e.target && !e.target.props.expanded,
      },
    });
  };

  get canGenerateAllocationReport() {
    const user: AuthenticatedUserDto = this.authUserStore.currentUser;

    if (PermissionsHelper.userHasRole(['bpo', 'mm'], user)) {
      return true;
    }

    if (PermissionsHelper.userHasRole('wm', user)) {
      const course: CourseDto = this.state.course;
      const courseDepartmentCode = course?.owner?.ownerDepartment?.department?.code;
      const wmDepartmentCodes = user.userModelGroups.flatMap((umg) =>
        umg?.departmentCode == null ? [] : umg.departmentCode
      );

      if (wmDepartmentCodes.includes(courseDepartmentCode)) {
        return true;
      }
    }

    return false;
  }

  // actions //
  goBack = () => {
    const previousSearch: Record<string, String> = ViewManager.getCourseSearch();

    if (previousSearch != null) {
      this.props.history.push('/course');
    } else {
      this.props.history.goBack();
    }
    this.setState({
      searchLoading: true,
      spinnerLoading: false,
    });
  };

  onEditDialog = (item: AllocationDto, type: string, dialog: string) => {
    this.setState({ edit: { visible: true, item, type, dialog } });
  };

  onAddDialog = (type: string) => {
    this.setState({
      edit: { visible: true, item: null, type, dialog: 'allocation' },
    });
  };

  onCancelDialog = () => {
    this.setState({
      edit: { visible: false, item: null, type: '', dialog: '' },
    });
  };

  showSpinner = () => {
    if (this.state.showCourseSearch && !this.state.spinnerLoading) {
      return false;
    }
    if (this.courseStore?.course == null) {
      return true;
    }
    if (this.state.spinnerLoading) {
      return true;
    }
    return this.state.searchLoading;
  };

  get wmDepartmentCodes() {
    const { currentUser } = this.authUserStore;
    const departmentCodes = currentUser?.userModelGroups?.map(umg => umg.departmentCode);
    return departmentCodes;
  }

  render() {
    const showSpinner = this.showSpinner();

    return (
      <div>
        {showSpinner && <Spinner />}
        <Header
          navState={'course'}
          subfooter={
            this.state.message.visible ? (
              <div className='header-message'>{this.state.message.line}</div>
            ) : null
          }
        />
        {this.state?.currentUser &&
          this.state?.year &&
          this.state?.showCourseSearch &&
          !this.state.spinnerLoading && (
            <CourseSearch
              title={'Course Title'}
              type={'course'}
              year={this.state.year.year}
              userRoleList={this.state.userRoleList}
              currentUser={this.state.currentUser}
              onEdit={this.setAllocations}
              showSearch={this.state.showCourseSearch}
              payload={ViewManager.getCourseSearch()}
            />
          )}

        {this.state.error && this.state.error.lines && (
          <ErrorOverlay
            lines={this.state.error.lines}
            exception={this.state.error.exception}
            visible={this.state.error.lines.length > 0}
            onClose={() => window.location.reload(true)}
          />
        )}
        {!this.state.searchLoading && this.courseStore?.course && (
          <BodyLayout>
            <StateBar
              title={'Manage Course & Offering Allocation: '}
              text={this.courseStore.course.course}
              print={() => this.onReport('report')}
              printing={this.state.reporting}
              printable={true}
              export={() => this.onReport('export')}
              exporting={this.state.reporting}
              exportable={true}
              canGenerateAllocationReport={this.canGenerateAllocationReport}
              inCourseTab={true}
              message={
                <MessageLine
                  visible={this.state.notify.state}
                  line={this.state.notify.msg}
                  type={this.state.notify.type}
                  className=''
                  icon={undefined}
                />
              }
              userRole={this.state.courseUserRole}
            >
              <div className='state-bar-item-line'>
                <label className='state-bar-item'>
                  <span className='state-bar-item-title-key'>Course Code:</span>
                  <span className='state-bar-item-title-value'>{this.courseStore.course.code}</span>
                </label>
                {this.courseStore.course.owner != null &&
                  this.courseStore.course.owner.owner != null && (
                    <label className='state-bar-item'>
                      <span className='state-bar-item-title-key'>Owner:</span>
                      <span className='state-bar-item-title-value'>
                        {this.courseStore.course.owner.owner}
                      </span>
                    </label>
                  )}
                <label className='state-bar-item'>
                  <span className='state-bar-item-title-key'>Coordinator:</span>
                  {this.courseStore.course.coordinator && (
                    <span className='state-bar-item-title-value'>
                      {this.courseStore.course.coordinator.firstName +
                        ' ' +
                        this.courseStore.course.coordinator.surname}
                    </span>
                  )}
                </label>
              </div>
              <div className='state-bar-item-line'>
                <label className='state-bar-item'>
                  <span className='state-bar-item-title-key'>Credits:</span>
                  <span className='state-bar-item-title-value'>
                    {this.courseStore.course.credits}
                  </span>
                </label>
                {!isNaN(this.courseStore.course.enrolments) && (
                  <label className='state-bar-item'>
                    <span className='state-bar-item-title-key'>Enrolments:</span>
                    <span className='state-bar-item-title-value'>
                      {this.courseStore.course.enrolments}
                    </span>
                  </label>
                )}
                {!isNaN(this.courseStore.course.numberOfferings) && (
                  <label className='state-bar-item'>
                    <span className='state-bar-item-title-key'>No. Offerings:</span>
                    <span className='state-bar-item-title-value'>
                      {this.courseStore.course.numberOfferings}
                    </span>
                  </label>
                )}
                <label className='state-bar-item'>
                  <span className='state-bar-item-title-key'>Total Hours:</span>
                  {this.state.loadings.hours ? (
                    <Spinner />
                  ) : this.courseStore.calculatedSummary != null &&
                    this.courseStore.calculatedSummary.total != null &&
                    !isNaN(this.courseStore.calculatedSummary.total.hours) ? (
                    <span className='state-bar-item-title-value'>
                      {this.courseStore.calculatedSummary.total.hours}
                    </span>
                  ) : (
                    <span className='state-bar-item-title-value'></span>
                  )}
                </label>
              </div>
            </StateBar>

            <div className='top-button-group'>
              <Button
                style={{ width: '90px' }}
                onClick={this.goBack}
                icon={<ArrowLeftOutlined />}
                look='outline'
                primary='true'
              >
                Go Back
              </Button>
            </div>

            <Summary
              key={(record) => record.offerings}
              expanded={true}
              loading={this.state.loadings.offering}
              data={this.courseStore?.courseOfferingSummary}
              category={'offering'}
              readOnly={this.state.readOnlyView}
              onDelete={this.onDeleteOffering}
              onEdit={(item) => this.onEditDialog(item, 'summary', 'offering')}
              title='Offerings'
              userRole={this.state.courseUserRole}
              onToggle={this.onOfferingSummaryToggle}
            />

            <Summary
              expanded={true}
              loading={this.state.loadings.staff}
              data={this.courseStore.staffInvolvedSummary}
              category={'staff'}
              title='Staff Involved'
              onToggle={this.onStaffSummaryToggle}
              userRole={this.state.courseUserRole}
              units={this.wmDepartmentCodes}
            />

            <CourseLevelAllocations
              editable={this.state.dictionaries.initilised}
              loading={this.state.loadings.course}
              data={this.state.courseAllocations}
              onEdit={(item) => this.onEditDialog(item, 'course', 'allocation')}
              onRemove={this.onRemoveAllocation}
              onPageChange={this.onCoursePageChange}
              onSort={this.onCourseSortChange}
              readOnlyView={this.state.readOnlyView}
              sortable={true}
              panel={{
                title: 'Course Level Allocations',
                expanded: this.state.expandings.course,
                onSelect: this.onCoursePanelSelected,
              }}
              userRole={this.state.courseUserRole}
              units={this.wmDepartmentCodes}
            />
            {!this.state.readOnlyView && this.state.expandings.course && (
              <div className='panel-button-group'>
                <div className='add-edit-activity-frame'>
                  <AddActivityButton
                    disabled={this.state.loadings.course || !this.state.dictionaries.initilised}
                    onEditDialog={this.onEditDialog}
                    type={'course'}
                    dialog={'allocation'}
                  />
                </div>
              </div>
            )}

            <OfferingAllocations
              editable={this.state.dictionaries.initilised}
              loading={this.state.loadings.allocation}
              data={this.state.offeringAllocations}
              onEdit={(item) => this.onEditDialog(item, 'offering', 'allocation')}
              onRemove={this.onRemoveAllocation}
              onPageChange={this.onOfferingPageChange}
              onSort={this.onOfferingSortChange}
              readOnlyView={this.state.readOnlyView}
              sortable={true}
              panel={{
                title: 'Offering Allocations',
                expanded: this.state.expandings.allocation,
                onSelect: this.onOfferingPanelSelected,
              }}
              userRole={this.state.courseUserRole}
              units={this.wmDepartmentCodes}
            />

            {!this.state.readOnlyView && this.state.expandings.allocation && (
              <div className='panel-button-group'>
                <div className='add-edit-activity-frame'>
                  <AddActivityButton
                    disabled={this.state.loadings.allocation || !this.state.dictionaries.initilised}
                    onEditDialog={this.onEditDialog}
                    type={'offering'}
                    dialog={'allocation'}
                  />
                </div>
              </div>
            )}

            <div className='bottom-button-group'>
              <Button
                style={{ width: '90px' }}
                onClick={this.goBack}
                icon={<ArrowLeftOutlined />}
                look='outline'
                primary='true'
              >
                Go Back
              </Button>
            </div>

            {!this.state.readOnlyView &&
              this.state.edit.visible &&
              this.state.edit.dialog === 'allocation' && (
                <AddEditCourseOfferingActivityDialog
                  allocation={this.state.edit.item}
                  year={this.state.course.year}
                  id={this.state.edit.type}
                  course={this.state.course}
                  staff={this.state.edit.type === 'offering' ? this.state.staff : null}
                  data={this.state.dictionaries}
                  onSubmit={this.onSubmitAllocation}
                  onCancel={this.onCancelDialog}
                />
              )}
            {!this.state.readOnlyView &&
              this.state.edit.visible &&
              this.state.edit.dialog === 'offering' && (
                <AddEditOfferingDialog
                  onSubmit={this.onEditOffering}
                  onCancel={this.onCancelDialog}
                  offering={this.state.edit.item}
                />
              )}
            {this.state.dialog.visible && (
              <ConfirmationDialog
                response={this.state.dialog.response}
                title={this.state.dialog.title}
                lines={this.state.dialog.lines}
              />
            )}
          </BodyLayout>
        )}
      </div>
    );
  }
}

export default inject(
  'authUserStore',
  'activityStore',
  'allocationStore',
  'courseStore',
  'offeringStore',
  'reportStore',
  'exportStore',
  'navStore',
  'viewStore'
)(observer(Course));
