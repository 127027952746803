// @flow

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import './header-nav.css';
import { PermissionsHelper } from '../../utility';
import { HeaderNavItem } from './header-nav-item';

type Props = {
  navState: string,
};

class HeaderNav extends Component<Props> {
  constructor(props) {
    super(props);
    this.authUserStore = props.authUserStore;
    this.viewStore = props.viewStore;
  }

  hasAdminAccess() {
    return PermissionsHelper.userHasRole('bpo', this.authUserStore.currentUser);
  }
  hasModelManagerAccess() {
    return PermissionsHelper.userHasRole('mm', this.authUserStore.currentUser);
  }
  canAccessReports() {
    const { model, year } = this.viewStore;
    const user = this.authUserStore.currentUser;

    const yearIsOpenOrClosed = ['open', 'closed'].includes(year.status.code);
    const modelIsVerified = model.status.code === 'verified';

    if (PermissionsHelper.userHasRole(['bpo', 'mm', 'wm'], user)) {
      return true;
    }
    if (PermissionsHelper.userHasRole('as', user) && yearIsOpenOrClosed && modelIsVerified) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className='header-nav-frame'>
        <HeaderNavItem
          to='/'
          title='Dashboard'
          active={false}
        />
        <HeaderNavItem
          to='/course'
          title='Course and Offering Allocations'
          active={this.props.navState === 'course'}
        />
        <HeaderNavItem
          to='/staff'
          title='Staff Allocations'
          active={this.props.navState === 'staff'}
        />
        {this.canAccessReports() && (
          <HeaderNavItem
            to='/report'
            title='Reports'
            active={this.props.navState === 'report'}
          />
        )}
        {(this.hasAdminAccess() || this.hasModelManagerAccess()) && (
          <HeaderNavItem
            to='/model'
            title='Model Management'
            active={this.props.navState === 'model'}
          />
        )}
        {this.hasAdminAccess() && (
          <HeaderNavItem
            to='/admin'
            title='Admin Menu'
            active={this.props.navState === 'admin'}
          />
        )}
      </div>
    );
  }
}

export default inject('authUserStore', 'viewStore')(observer(HeaderNav));
