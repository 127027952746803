// @flow

import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'react-tagsinput/react-tagsinput.css';

import { Button, Input } from 'antd';
import { action } from 'mobx';
import {
  BodyLayout,
  ConfirmationDialog,
  MessageLine,
  SearchCheckBoxList,
  SearchCourseAutoKeyWords,
  SearchDropdown,
  SearchKeyWordsTips,
  SearchResultGrid,
  Spinner,
  TableActionCellView,
} from '../../components';
import {
  CourseDto,
  ExportDto,
  ModelDto,
  OwnerDto,
  ReportDto,
  SearchCourseDto,
  UserDto,
} from '../../dto';
import { AuthUserStore } from '../../mobx';
import { PermissionsHelper, StringUtility, ViewManager } from '../../utility';
import './course.css';

type Props = {
  onEdit: () => void,
  onDelete: () => void,
  year: number,
  showSearch: boolean,
  payload: SearchCourseDto,
  doSort: false,
  authUserStore: AuthUserStore,
  courseStore: CourseDto,
  searchStore: SearchCourseDto,
  reportStore: ReportDto,
  exportStore: ExportDto,
  viewStore: ModelDto,
  userRoleList: Record<string, string>[],
  currentUser: UserDto,
};

type State = {
  checkedTitle: boolean,
  checkedOwner: boolean,
  checkedLevel: boolean,
  checkedCode: boolean,
  checkedSubject: boolean,
  checkedCoordinator: boolean,
  checkedOfferingCoordinator: boolean,
  checkedEmail: boolean,
  model: ModelDto,
  owner: OwnerDto,
  loading: boolean,
  keywords: Array<string>,
  notify: { state: boolean, msg: string, type: string },
  selected: CourseDto & { id: string | number },
  search: SearchCourseDto,
  noCodeResult: boolean,
  emailed: boolean,
  reporting: boolean,
  reportType: 'report' | 'export',
  paging: { limit: number, skip: number },
  spinner: { visible: boolean, text: string },
  dialog: { response: Function, visible: boolean, title: string, lines: any[] },
  userRole: string,
  selectedAllocatedCourse: boolean,
  selectedAllocatedOwner: OwnerDto,
};

class CourseSearch extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.authUserStore = this.props.authUserStore;
    this.courseStore = this.props.courseStore;
    this.searchStore = this.props.searchStore;
    this.reportStore = this.props.reportStore;
    this.exportStore = this.props.exportStore;
    this.viewStore = this.props.viewStore;

    this.state = {
      checkedTitle: true,
      checkedOwner: true,
      checkedLevel: true,
      checkedCode: true,
      checkedSubject: true,
      checkedCoordinator: true,
      checkedOfferingCoordinator: true,
      checkedEmail: true,
      model: {},
      owner: {},
      loading: true,
      keywords: [],
      notify: {},
      selected: undefined,
      noCodeResult: false,
      emailed: false,
      reporting: false,
      reportType: null,
      redirectToCourse: '',
      spinner: { visible: false, text: null },
      dialog: {
        visible: false,
        lines: [],
        title: '',
        response: null,
      },
      paging: {
        limit: 25,
        skip: 0,
      },
      userRole: null,
      selectedAllocatedCourse: false,
      selectedAllocatedOwner: {},
      preLoad: false,
      ownerCode: null,
    };
  }

  async componentDidMount() {
    await this.onPrepopulate();
    this.onCleanNotification();
    await this.onUserRole();

    // if user navigated back from starting search, refetch payload and keep paging setting
    if (this.props.payload) {
      this.setState({ loading: true });
      await this.onPayLoad(this.props.payload);
      this.setState({ loading: false });
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.year !== this.props.year) {
      await this.onPrepopulate();
      this.onStateReset();
    }
    if (prevProps.viewStore.model.id !== this.viewStore.model.id) {
      await this.onPayLoad(this.props.search);
    }
  }

  componentWillUnmount() {
    this.searchStore.cleanSearchResult();
  }

  onKeywordSelectChange = (keywords) => {
    this.setState({ keywords });
  };

  onKeyWordsSearch = async () => {
    this.searchStore.cleanSearchResult();
    this.onCleanNotification(this.state.paging);

    const searchKeywords = this.state.keywords;

    let wmOwnerIds;

    if (this.state.userRole === 'wm' && typeof this.state.owner?.id === 'undefined') {
      wmOwnerIds = this.searchStore.owners.map((d) => d.id).filter((d) => typeof d !== 'undefined');
    }

    const search: SearchCourseDto = {
      keywords: searchKeywords,
      keywordsByType: searchKeywords,
      modelId: this.viewStore.model.id,
      modelCode: this.viewStore.model.code,
      ownerId: this.state.owner?.id,
      searchCode: this.state.checkedCode,
      searchSubject: this.state.checkedSubject,
      searchTitle: this.state.checkedTitle,
      searchOwner: this.state.checkedOwner,
      searchCoordinator: this.state.checkedCoordinator,
      searchOCoordinator: this.state.checkedOfferingCoordinator,
      searchAllocated: this.state.checkedEmail,
      year: this.props.year,
      selectedAllocatedCourse:
        this.state.userRole === 'wm' && typeof this.state.owner.id === 'undefined'
          ? true
          : this.state.selectedAllocatedCourse,
      selectedAllocatedOwner: this.state.selectedAllocatedOwner,
      wmOwnerIds: wmOwnerIds,
    };

    if (this.onValidate(search)) {
      const {
        paging: { limit },
        sorting,
      } = this.state;

      this.setState(
        {
          search: search,
          spinner: { visible: true, text: 'Loading' },
        },
        () => this.saveSearch({ search, paging: { limit, skip: 0 } })
      );

      this.searchStore.getCourseByKeyWords(search, limit, 0, true, sorting).then(() => {
        if (!this.searchStore.searchResult.total) {
          this.setState(
            {
              spinner: { visible: false, text: '' },
              notify: {
                type: 'warning',
                state: true,
                msg: 'Could not find any results matching this search request',
              },
            },
            () => {
              setTimeout(() => {
                this.onCleanNotification();
              }, 5000);
            }
          );
        } else {
          this.setState({
            spinner: { visible: false, text: '' },
            ownerCode: this.state.owner.code,
          });
        }
      });
    } else {
      this.setState(
        {
          spinner: { visible: false, text: '' },
          notify: {
            type: 'error',
            state: true,
            msg: 'Please either select or enter at least one filter or keyword',
          },
        },
        () => {
          setTimeout(() => {
            this.onCleanNotification();
          }, 5000);
        }
      );
    }
  };

  onPageOrSort = (limit, skip, sorting) => {
    const { search } = this.state;
    const queryParams = [this.state.search, limit, skip, true];
    sorting && queryParams.push(sorting);

    const saveParams = {
      search,
      paging: { limit, skip },
    };
    sorting && ([saveParams.sorting] = [sorting]);

    if (this.onValidate(search)) {
      this.setState({
        spinner: { visible: true, text: 'Loading' },
        ...saveParams,
      });

      this.searchStore
        .getCourseByKeyWords(this.state.search, limit, skip, true, sorting && sorting)
        .then(() => {
          this.setState({ spinner: { visible: false, text: null, saveParams } }, () =>
            this.saveSearch(saveParams)
          );
        });
    }
  };

  onPageChange = (limit, skip) => {
    this.onPageOrSort(limit, skip, null);
  };

  onSort = (sorting, limit, skip) => {
    this.onPageOrSort(limit, skip, sorting);
  };

  async onUserRole() {
    if (this.props.userRoleList.length === 1 && this.props.userRoleList[0] === 'as') {
      const userCode = this.props.currentUser.email;
      this.setState({
        checkedTitle: false,
        checkedOwner: false,
        checkedCode: false,
        checkedSubject: false,
        keywords: [userCode],
        userRole: 'as',
      });
      await this.onKeyWordsSearch();
    }
    if (
      this.props.userRoleList.some((ur) => {
        return ur === 'wm';
      })
    ) {
      this.setState({
        userRole: 'wm',
      });
    }
  }

  async getAllAllowedOwners() {
    const { model, year } = this.viewStore;
    const { currentUser: user } = this.authUserStore;
    const allAllowedOwners = [];

    this.searchStore.cleanSearchResult();
    this.searchStore.setSingleModel(model);

    if (PermissionsHelper.userHasRole(['bpo', 'mm'], user)) {
      const allowedOwners = await this.searchStore.getOwners(year.year, model?.id);
      allowedOwners.forEach((allowedOwner) =>
        this.appendUniqueOnwers(allAllowedOwners, allowedOwner)
      );
    }
    if(PermissionsHelper.userHasRole(['wm', 'as'], user)) {
      const allowedOwners = await this.searchStore.getOwnersByUser(year.year, model.id, user.id);
      allowedOwners.forEach(allowedOwner => this.appendUniqueOnwers(allAllowedOwners, allowedOwner));
    }
    action((allAllowedOwners) => (this.searchStore.owners = allAllowedOwners));

    return allAllowedOwners;
  }

  async onPayLoad(payload) {
    const { search, paging, sorting } = payload;
    if (search?.ownerId) {
      const owners = await this.getAllAllowedOwners();
      const owner = owners.find((o) => o.id === payload.ownerId);
      this.setState({ owner });
    }
    if (search?.keywordsByType.length > 0) {
      this.setState({
        keywords: search.keywordsByType,
      });
    }
    this.setState({
      checkedOwner: search?.searchOwner,
      checkedTitle: search?.searchTitle,
      checkedCode: search?.searchCode,
      checkedSubject: search?.searchSubject,
      checkedCoordinator: search?.searchCoordinator,
      checkedOfferingCoordinator: search?.searchOCoordinator,
      checkedEmail: search?.searchAllocated,
      search: search,
    });

    await this.searchStore.getCourseByKeyWords(search, paging?.limit, paging?.skip, true, sorting);

    this.setState({
      paging: {
        limit: paging?.limit,
        skip: paging?.skip,
      },
    });
  }

  onCleanNotification = () => {
    const { paging } = this.state;
    this.setState({
      notify: { state: false, msg: '', type: '' },
      paging: { ...paging, skip: 0 },
    });
  };

  appendUniqueOnwers(array: OwnerDto[], element: OwnerDto) {
    const isUnique = !array.map((owner) => owner.code).includes(element.code);
    if (isUnique) {
      array.push(element);
    }
  }

  async onPrepopulate() {
    const { model } = this.viewStore;
    const allAllowedOwners = await this.getAllAllowedOwners();

    this.setState({
      model,
      owner: allAllowedOwners[0],
      loading: false,
    });
  }

  onCheckBoxChange = (target) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;

    const { id } = target;

    this.setState({ [id]: value });
  };

  onOwnerChange = (target) => {
    // TODO: check the setting of selectedAllocatedOwner
    this.setState({ selectedAllocatedOwner: {} });
    if (!target.value.active) {
      this.setState({ selectedAllocatedCourse: true, selectedAllocatedOwner: target.value });
    } else {
      this.setState({ selectedAllocatedCourse: false });
    }
    this.setState({ owner: target.value });
    this.saveSearch({ ownerId: target.value.id });
  };

  onValidate(search: SearchCourseDto) {
    return (
      search?.year &&
      (search?.modelId ||
        search.ownerId?.length > 0 ||
        ((search.searchCode ||
          search.searchSubject ||
          search.searchTitle ||
          search.searchOwner ||
          search.searchCoordinator) &&
          search.keywords?.length > 0))
    );
  }

  onStateReset = () => {
    this.setState({
      checkedTitle: true,
      checkedOwner: true,
      checkedLevel: true,
      checkedCode: true,
      checkedSubject: true,
      checkedCoordinator: true,
      checkedOfferingCoordinator: true,
      checkedEmail: true,
      model: {},
      owner: {},
      loading: true,
      keywords: [],
      notify: {},
      selected: undefined,
      search: {},
      noCodeResult: false,
      emailed: false,
      reporting: false,
      reportType: null,
      redirectToCourse: '',
      spinner: { visible: false, text: null },
      dialog: {
        visible: false,
        lines: [],
        title: '',
        response: null,
      },
      paging: {},
      userRole: null,
      selectedAllocatedCourse: false,
      selectedAllocatedOwner: {},
    });
  };

  getOwnersParam = () => {
    let ownersParam = [];
    if (this.state.owner?.owner !== 'all') {
      ownersParam.push(this.state.owner?.owner);
    } else {
      ownersParam = this.searchStore.owners?.filter((d) => d.id).map((d) => d.id);
    }
    return ownersParam;
  };

  onEdit = (data) => {
    this.props.onEdit(data);
  };

  onDelete = (data, paging) => {
    this.setState({
      dialog: {
        lines: [
          <span key='dialog1'>
            You are about to delete the course{' '}
            <span className='important'>
              {data.code} - {data.course}
            </span>
            , all its offerings and allocations.
          </span>,
          <span
            className='important'
            key='dialog2'
          >
            Once deleted this data cannot be recovered.
          </span>,
          'Do you wish to continue?',
        ],
        visible: true,
        title: 'Please Confirm',
        response: this.delete,
        type: 'delete',
      },
      selected: data,
      paging: { limit: paging.pageSize, skip: paging.skip },
    });
  };

  onReport = (type: string) => {
    this.setState({
      dialog: {
        lines: [
          <span
            className='important'
            key='dialog'
          >
            As this {type} may take some time to generate, it will be emailed to you once completed.
          </span>,
          'Do you wish to continue?',
        ],
        visible: true,
        title: `Please Confirm (Email ${StringUtility.capitalize(type)})`,
        response: this.report,
      },
      reportType: type,
      emailed: true,
    });
  };

  delete = (confirmed: boolean) => {
    const { search, paging, sorting } = this.state;

    this.setState({
      dialog: {
        response: null,
        type: '',
        visible: false,
        lines: [],
        title: '',
      },
    });
    if (confirmed) {
      this.setState({ spinner: { visible: true, text: 'Deleting Course' } });
      this.courseStore.delete(this.state.selected?.id).then(() => {
        this.setState({
          selected: undefined,
          spinner: { visible: true, text: 'Loading' },
        });
        this.searchStore
          .getCourseByKeyWords(search, paging.limit, paging.skip, true, sorting)
          .then(() => {
            this.setState({ spinner: { visible: false, text: '' } });
          });
      });
    }
  };

  report = (confirmed: boolean) => {
    const viaEmail = this.state.emailed;

    this.setState({
      dialog: { response: null, visible: false, lines: [], title: '' },
      emailed: false,
    });
    if (confirmed) {
      this.setState({ reporting: true });

      this.searchStore.getCourseByKeyWordsRaw(this.state.search).then(async (results) => {
        const criteria = {
          keywords: this.state.keywords.length > 0 ? this.state.keywords.join(', ') : undefined,
          model: this.state.model.model,
          owner: this.getOwnersParam(),
          year: this.props.year,
        };

        const result =
          this.state.reportType === 'export'
            ? await this.exportStore.getCourseAllocationExport(
                { criteria, ids: results.data },
                this.props.year,
                viaEmail
              )
            : await this.reportStore.getCourseAllocationSummaryReport(
                { criteria, ids: results.data },
                this.props.year,
                viaEmail
              );

        if (viaEmail) {
          this.setState(
            {
              reporting: false,
              reportType: null,
              notify: {
                type: result.success ? 'info' : 'error',
                state: true,
                msg: result.success
                  ? `${StringUtility.capitalize(this.state.reportType)} Requested`
                  : `Failed to Request ${StringUtility.capitalize(this.state.reportType)}`,
              },
            },
            () => {
              setTimeout(() => {
                this.onCleanNotification();
              }, 5000);
            }
          );
        } else {
          this.setState({ reporting: false, reportType: null });
        }
      });
    }
  };

  get canGenerateAllocationReport() {
    const user = this.authUserStore.currentUser;
    return PermissionsHelper.userHasRole(['bpo', 'mm'], user);
  }

  saveSearch = (values) => {
    ViewManager.updateSession(ViewManager._courseSearchSessionKey, { ...values });
  };

  onCourseCodeSearch = (code) => {
    this.setState({
      noCodeResult: false,
      spinner: { visible: true, text: 'Loading' },
    });
    this.searchStore.getCourseByCode(this.props.year, Number(code)).then((data) => {
      this.setState({
        noCodeResult: data.length === 0,
        spinner: { visible: false, text: '' },
      });
    });
  };

  onKeyWordsChange = (keywords) => {
    this.setState({ keywords });
  };

  onCourseCodeValidation = (data) => {
    return !isNaN(data);
  };

  render() {
    return (
      <div>
        {this.state.loading && <Spinner text={'Loading'} />}
        {!this.state.loading && this.props.showSearch && (
          <BodyLayout>
            <div className='search-detail-body'>
              <div className='search-detail-body-header'>
                <div className='search-title'>
                  <p>Search Details</p>
                </div>
              </div>
              <div className='search-detail-body-content'>
                <div className='search-detail-body-dropdown-list'>
                  <div className='card-item'>
                    <p className='search-sub-title'>Model:</p>
                    <Input
                      className='model-item'
                      value={this.state.model?.model}
                      disabled={true}
                    ></Input>
                  </div>

                  <SearchDropdown
                    id={'Owner'}
                    title={'Course Owner'}
                    onChange={this.onOwnerChange}
                    data={this.searchStore.owners}
                    value={this.state.owner}
                  />

                  <div className='card-item'>
                    <SearchCourseAutoKeyWords
                      disabled={this.state.userRole === 'as'}
                      defaultValue={this.state.keywords.join(';')}
                      onKeywordSelectChange={this.onKeywordSelectChange}
                    />
                    <Button
                      className='search-button'
                      primary='true'
                      look='outline'
                      onClick={this.onKeyWordsSearch}
                    >
                      Search
                    </Button>
                  </div>
                  <SearchKeyWordsTips />
                </div>
                {this.props.userRoleList.length === 1 && this.props.userRoleList[0] === 'as' && (
                  <SearchCheckBoxList
                    checkBoxList={[
                      {
                        id: 'checkedCoordinator',
                        title: 'Course Coordinator',
                        check: this.state.checkedCoordinator,
                      },
                      {
                        id: 'checkedOfferingCoordinator',
                        title: 'Offering Coordinator',
                        check: this.state.checkedOfferingCoordinator,
                      },
                      {
                        id: 'checkedEmail',
                        title: 'Staff Email',
                        check: this.state.checkedEmail,
                      },
                    ]}
                    onCheckBoxChange={this.onCheckBoxChange}
                    prompt={'Search Keywords in the following'}
                  />
                )}
                {!(this.props.userRoleList.length === 1 && this.props.userRoleList[0] === 'as') && (
                  <SearchCheckBoxList
                    checkBoxList={[
                      { id: 'checkedCode', title: 'Course Code', check: this.state.checkedCode },
                      {
                        id: 'checkedSubject',
                        title: 'Subject Code',
                        check: this.state.checkedSubject,
                      },
                      { id: 'checkedTitle', title: 'Course Name', check: this.state.checkedTitle },
                      { id: 'checkedOwner', title: 'Course Owner', check: this.state.checkedOwner },
                      {
                        id: 'checkedCoordinator',
                        title: 'Course Coordinator',
                        check: this.state.checkedCoordinator,
                      },
                      {
                        id: 'checkedOfferingCoordinator',
                        title: 'Offering Coordinator',
                        check: this.state.checkedOfferingCoordinator,
                      },
                      {
                        id: 'checkedEmail',
                        title: 'Staff Email',
                        check: this.state.checkedEmail,
                      },
                    ]}
                    onCheckBoxChange={this.onCheckBoxChange}
                    prompt={'Search Keywords in the following'}
                  />
                )}
              </div>

              <div>
                {this.state.notify.state ? (
                  <MessageLine
                    visible={this.state.notify.state}
                    line={this.state.notify.msg}
                    type={this.state.notify.type}
                  />
                ) : null}
              </div>
            </div>
            <SearchResultGrid
              data={this.searchStore.searchResult}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              onPageChange={this.onPageChange}
              columns={[
                {
                  field: 'code',
                  title: 'Course Code',
                  dataIndex: 'code',
                  action: 'view',
                  width: '130px',
                  sorter: {
                    multiple: 1,
                  },
                  render: (text, record) => (
                    <TableActionCellView
                      view={this.onEdit}
                      text={text}
                      dataItem={record}
                    />
                  ),
                },
                {
                  field: 'course',
                  title: 'Course Name',
                  dataIndex: 'course',
                  action: 'view',
                  sorter: {
                    multiple: 2,
                  },
                  render: (text, record) => (
                    <TableActionCellView
                      view={this.onEdit}
                      text={text}
                      dataItem={record}
                    />
                  ),
                },
                {
                  field: 'owner.owner',
                  title: 'Course Owner',
                  dataIndex: ['owner', 'owner'],
                  sorter: {
                    multiple: 3,
                  },
                },
                {
                  field: 'coordinator.displayName',
                  title: 'Course Coordinator',
                  dataIndex: ['coordinator', 'displayName'],
                  sorter: {
                    multiple: 4,
                  },
                },
                {
                  field: 'model.model',
                  title: 'Model Title',
                  dataIndex: ['model', 'model'],
                  sorter: {
                    multiple: 5,
                  },
                },
              ]}
              sortable={true}
              onSort={this.onSort}
              export={() => this.onReport('export')}
              exporting={this.state.reporting}
              print={() => this.onReport('report')}
              canGenerateAllocationReport={this.canGenerateAllocationReport}
              printing={this.state.reporting}
              userRole={this.state.userRole}
              sorting={ViewManager.getCourseSearch()?.sorting}
            />

            {this.state.spinner && this.state.spinner.visible && (
              <Spinner text={this.state.spinner.text ? this.state.spinner.text : 'Loading'} />
            )}
            {this.state.dialog.visible && (
              <ConfirmationDialog
                response={this.state.dialog.response}
                title={this.state.dialog.title}
                lines={this.state.dialog.lines}
              />
            )}
          </BodyLayout>
        )}
      </div>
    );
  }
}

export default inject(
  'authUserStore',
  'courseStore',
  'searchStore',
  'reportStore',
  'exportStore',
  'viewStore',
  'searchStore'
)(observer(CourseSearch));
