//@flow
import { ModelDto, DepartmentDto, JobTitleDto, StaffTypeDto, StaffContractDto } from '.';

export default class StaffDto {

    id: string;

    code: string;

    firstName: string;

    surname: string;

    displayName: string;

    efte: number;

    teachingPercentage: number;

    researchPercentage: number;

    citizenshipPercentage: number;

    clinicalPercentage: number;

    servicePercentage: number;

    courseCoordinatedCount: number;

    masseyUserId: string;

    workProfile: string;

    jobTitle: JobTitleDto;

    emailAddress: string;

    newToMassey: boolean = false;

    model: ModelDto;
        
    sltGroup: string;

    department: DepartmentDto;

    masseyStaffId: string;

    contract: StaffContractDto;

    staffType: StaffTypeDto;

    workloadAllocation: boolean;

    year: number;

    placeholder: boolean;

    dateAdded: Date;

    active: boolean;

    dateUpdated: Date;

    user: UserDto;
}
