import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const HeaderNavItem = (props) => {
  const [selected, setSelected] = useState(false);

  const handleFocus = () => {
    setSelected(true);
  };
  const handleBlur = () => {
    setSelected(false);
  };

  let className = 'header-nav-item';

  if (props.active) {
    className += ' header-nav-active';
  }

  if (selected) {
    className += ' header-nav-selected';
  }

  return (
    <div className={className}>
      <Link
        to={props.to}
        className='header-nav-link'
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {props.title}
      </Link>
    </div>
  );
};
