import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import './staff';
import './staff.css';

function formatDecimalString(myNumber) {
  if (typeof myNumber === 'number') {
    return parseFloat(myNumber.toFixed(1));
  } else if (typeof myNumber === 'string') {
    return parseFloat(parseFloat(myNumber).toFixed(1));
  }
  console.log('Unexpected value: ', myNumber);
  return '???';
}

function TeachingSummary(props) {
  const { data } = props;
  const { Column } = Table;
  const [nonCourseData, setNonCourseData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  useEffect(() => {
    const tempNonCourseData = [];
    const tempCourseData = [];
    let tempCourseDataTotal = null;
    let tempNonCourseDataTotal = {
      activity: { activity: 'TOTAL' },
      calculated: 0,
      hours: 0,
      hoursS1: 0,
      hoursS2: 0,
      hoursSS: 0,
      key: 'total',
    };
    if (data != null) {
      data.forEach((d) => {
        if (d.activity != null && d.key !== 'total') {
          tempNonCourseData.push(d);
        } else if (d.course != null && d.key !== 'total') {
          tempCourseData.push(d);
        } else {
          tempCourseDataTotal = d;
        }
      });
      tempNonCourseData.forEach((d) => {
        tempNonCourseDataTotal.calculated += d.calculated;
        tempNonCourseDataTotal.hours += d.hours;
        tempNonCourseDataTotal.hoursS1 += d.hoursS1;
        tempNonCourseDataTotal.hoursS2 += d.hoursS2;
        tempNonCourseDataTotal.hoursSS += d.hoursSS;
      });
      tempNonCourseDataTotal.calculated = formatDecimalString(tempNonCourseDataTotal.calculated);
      tempNonCourseDataTotal.hours = formatDecimalString(tempNonCourseDataTotal.hours);
      tempNonCourseDataTotal.hoursS1 = formatDecimalString(tempNonCourseDataTotal.hoursS1);
      tempNonCourseDataTotal.hoursS2 = formatDecimalString(tempNonCourseDataTotal.hoursS2);
      tempNonCourseDataTotal.hoursSS = formatDecimalString(tempNonCourseDataTotal.hoursSS);
      tempNonCourseData.push(tempNonCourseDataTotal);
      tempCourseDataTotal.calculated = formatDecimalString(
        tempCourseDataTotal.calculated - tempNonCourseDataTotal.calculated
      );
      tempCourseDataTotal.hours = formatDecimalString(
        tempCourseDataTotal.hours - tempNonCourseDataTotal.hours
      );
      tempCourseDataTotal.hoursS1 = formatDecimalString(
        tempCourseDataTotal.hoursS1 - tempNonCourseDataTotal.hoursS1
      );
      tempCourseDataTotal.hoursS2 = formatDecimalString(
        tempCourseDataTotal.hoursS2 - tempNonCourseDataTotal.hoursS2
      );
      tempCourseDataTotal.hoursSS = formatDecimalString(
        tempCourseDataTotal.hoursSS - tempNonCourseDataTotal.hoursSS
      );
      tempCourseData.push(tempCourseDataTotal);
    }
    setNonCourseData(tempNonCourseData);
    setCourseData(tempCourseData);
  }, [data]);

  return (
    <div>
      {nonCourseData.length > 0 && (
        <div>
          <p className='teaching-summary-label'>Staff Activities</p>
          <Table
            dataSource={nonCourseData}
            bordered={true}
            size='small'
            tableLayout='fixed'
            pagination={false}
          >
            <Column
              field='activity.activity'
              title='Activity'
              key='Activity'
              dataIndex={['activity', 'activity']}
            />
            <Column
              field='hoursS1'
              title='S1'
              key='Course S1'
              width='120px'
              align='right'
              dataIndex='hoursS1'
            />
            <Column
              field='hoursS2'
              title='S2'
              key='Course S2'
              width='120px'
              align='right'
              dataIndex='hoursS2'
            />
            <Column
              field='hoursSS'
              title='SS'
              key='Course SS'
              width='120px'
              align='right'
              dataIndex='hoursSS'
            />
            <Column
              field='calculated'
              title='Calculated'
              align='right'
              key='Course Calculated'
              width='140px'
              dataIndex='calculated'
            />
            <Column
              field='hours'
              title='Assigned'
              key='Course Assigned'
              width='140px'
              align='right'
              dataIndex='hours'
            />
          </Table>
        </div>
      )}

      {courseData.length > 0 && (
        <div>
          <p className='teaching-summary-label'>Course and Offering Activities</p>
          <Table
            dataSource={courseData}
            bordered={true}
            size='small'
            tableLayout='fixed'
            pagination={false}
          >
            <Column
              field='course.code'
              title='Course Code'
              key='Course Code'
              width='130px'
              dataIndex={['course', 'code']}
              render={(text, record) => {
                if (record?.course?.id != null) {
                  const ref = `#/course/${record.course.id}`;
                  return (
                    <a
                      className='course-code-num'
                      href={ref}
                    >
                      {text}
                    </a>
                  );
                } else {
                  return text;
                }
              }}
            />
            <Column
              field='course.course'
              title='Course'
              key='Course'
              dataIndex={['course', 'course']}
            />
            <Column
              field='hoursS1'
              title='S1'
              key='Course S1'
              width='120px'
              align='right'
              dataIndex='hoursS1'
            />
            <Column
              field='hoursS2'
              title='S2'
              key='Course S2'
              width='120px'
              align='right'
              dataIndex='hoursS2'
            />
            <Column
              field='hoursSS'
              title='SS'
              key='Course SS'
              width='120px'
              align='right'
              dataIndex='hoursSS'
            />
            <Column
              field='calculated'
              title='Calculated'
              align='right'
              key='Course Calculated'
              width='140px'
              dataIndex='calculated'
            />
            <Column
              field='hours'
              title='Assigned'
              key='Course Assigned'
              width='140px'
              align='right'
              dataIndex='hours'
            />
          </Table>
        </div>
      )}
    </div>
  );
}

export default TeachingSummary;
