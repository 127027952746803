// @flow

import React, { Component } from 'react';
import { AutoComplete, Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';

import { Spinner } from '..';
import { MessageBlock } from '..';
import { OfferingStore } from '../../mobx';
import { OfferingDto, StaffDto } from '../../dto';
import { Offering as Validator } from '../../validations';
import './add-edit-offering.css';

type Props = {
  title: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSubmit: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function,
  offering: OfferingDto,
};

type State = {
  rebuildState: boolean,
  failed: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabled: any,
  offering: OfferingDto,
  messaging: { lines: string[], type: string },
  note: string,
  saving: boolean,
  loading: boolean,
  validating: boolean,
  initialised: boolean,
  initialising: boolean,
  coordinators: StaffDto[],
  coordinator: { data: StaffDto[], value: string },
};

const offeringStore = new OfferingStore();

class AddEditOfferingDialog extends Component<Props, State> {
  onSubmit: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialogRef: any;

  empty: OfferingDto = {
    year: null,
    id: null,
    offering: '',
    owner: null,
    deliveryMode: null,
    deliverySite: null,
    period: null,
    credits: 0,
    outcomes: 0,
    level: 0,
    levelAggregate: null,
    location: 0,
    courseCode: null,
    course: null,
    efts: null,
    enrolments: 0,
    courseType: null,
    coordinator: null,
    model: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      offering: this.props.offering ? mobx.toJS(this.props.offering) : this.empty,
      rebuildState: false,
      failed: false,
      disabled: {},
      messaging: { lines: [], type: '' },
      saving: false,
      loading: false,
      validating: false,
      initialised: false,
      initialising: false,
      coordinators: [],
      coordinator: {},
    };

    this.dialogRef = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCancel = () => {
    this.onCleanError();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onNumbersOnly = (e) => {
    const code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57) && code !== 46) {
      e.preventDefault();
    }
  };

  onCleanError = () => {
    if (this._isMounted) {
      this.setState({ messaging: { lines: [], type: '' } });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.onCleanError();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async componentDidUpdate(prevProps, prevState) {
    if (this.dialogRef.current && !this.state.initialised && !this.state.initialising) {
      this.setState({
        initialising: true,
        loading: true,
        disabled: {
          offering: this.props.offering,
        },
      });
      const nodes = document
        .querySelector('.add-edit-offering-dialog')
        .querySelectorAll('button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const focusables = Array.from(nodes).filter((e) => !e.disabled);
      var first = focusables[0];
      var last = focusables[focusables.length - 1];

      first.focus();

      last.onkeydown = (e) => {
        if (e.which === 9 && !e.shiftKey) {
          e.preventDefault();
          first.focus();
        }
      };
      first.onkeydown = (e) => {
        if (e.which === 9 && e.shiftKey) {
          e.preventDefault();
          last.focus();
        }
      };

      if (this.props.offering) {
        offeringStore.get(this.props.offering.id).then((offering) => {
          if (this._isMounted) {
            this.setState({ offering: mobx.toJS(offering) });
          }
        });
      }

      const _coordinators = this.props.offering
        ? (await offeringStore.getCoordinators(this.props.offering.model.id)).map((d) =>
            mobx.toJS(d)
          )
        : [];
      this.setState({
        coordinators: _coordinators,
        coordinator: {
          data: _coordinators,
          value:
            this.props.offering && this.props.offering.coordinator
              ? this.props.offering.coordinator.displayName
              : '',
        },
      });

      this.setState({
        initialised: true,
        initialising: false,
        loading: false,
      });
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  async onSubmit(event) {
    this.setState({ validating: true });

    event.preventDefault();
    this.onCleanError();

    const offering = new OfferingDto();
    offering.year = this.state.offering.year;
    for (const key of Object.keys(this.empty)) {
      if (this.state.offering[key] !== undefined) {
        if (['owner', 'period', 'course', 'courseType', 'coordinator', 'model'].includes(key)) {
          offering[key] = this.state.offering[key]
            ? { id: this.state.offering[key].id }
            : undefined;
        } else {
          offering[key] = this.state.offering[key];
        }
      }
    }

    const msg = await Validator.validate(offering);
    if (this._isMounted) {
      this.setState({ validating: false });
    }
    if (msg.length > 0) {
      if (this._isMounted) {
        this.setState({
          messaging: {
            lines: msg.filter((m, p, s) => s.indexOf(m) === p),
            type: 'error',
          },
        });
      }
    } else {
      this.onSave(offering);
    }
  }

  onSave = (offering: OfferingDto) => {
    this.setState({ saving: true });
    if (offering.id) {
      offeringStore.update(offering).then((result) => {
        if (result) {
          this.onCleanError();
          if (this._isMounted) {
            this.setState({
              saving: false,
              disabled: { offering: this.props.offering },
              offering: this.props.offering ? mobx.toJS(this.props.offering) : this.empty,
              initialised: false,
              initialising: false,
            });
          }

          if (this.props.onSubmit) {
            this.props.onSubmit(mobx.toJS(offering), mobx.toJS(this.props.offering));
          }
        } else {
          if (this._isMounted) {
            this.setState({ saving: false, failed: true });
          }
          setTimeout(() => {
            if (this._isMounted) {
              this.setState({ failed: false });
            }
          }, 8000);
        }
      });
    } else {
      offeringStore.add(offering).then((result) => {
        if (result) {
          this.onCleanError();
          if (this._isMounted) {
            this.setState({
              saving: false,
              disabled: { offering: this.props.offering },
              offering: this.props.offering ? mobx.toJS(this.props.offering) : this.empty,
              initialised: false,
              initialising: false,
            });
          }
          if (this.props.onSubmit) {
            this.props.onSubmit(offering);
          }
        } else {
          if (this._isMounted) {
            this.setState({ saving: false, failed: true });
          }
          setTimeout(() => {
            if (this._isMounted) {
              this.setState({ failed: false });
            }
          }, 8000);
        }
      });
    }
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onOfferingChange = (event) => {
    this.setState({
      offering: { ...this.state.offering, offering: event.target.value },
    });
  };

  onCoordinatorChange = (value) => {
    let filtered = this.state.coordinators
      .filter(
        (d) =>
          (d.firstName != null && d.firstName.toLowerCase().startsWith(value.toLowerCase())) ||
          (d.surname != null && d.surname.toLowerCase().startsWith(value.toLowerCase())) ||
          (d.displayName != null && d.displayName.toLowerCase().startsWith(value.toLowerCase()))
      )
      .sort(function(a, b) {
        return a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0;
      });

    this.setState({
      coordinator: {
        data: filtered,
        value: value,
      },
    });

    const coordinator = this.state.coordinator.data
      .map((d) => mobx.toJS(d))
      .find((s) => {
        return s.displayName === value;
      });

    this.setState({
      offering: { ...this.state.offering, coordinator: coordinator },
    });
  };

  onEnrolmentsChange = (event) => {
    this.setState({
      offering: { ...this.state.offering, enrolments: event.target.value },
    });
  };

  render() {
    return (
      <div className='add-edit-offering-frame'>
        {!this.state.loading && (
          <div ref={this.dialogRef}>
            <Modal
              width={'auto'}
              centered
              open={!this.state.loading}
              footer={null}
              title={
                this.props.title
                  ? this.props.title
                  : this.props.offering
                  ? 'Edit Offering'
                  : 'Add Offering'
              }
              onCancel={this.onCancel}
            >
              <div className='add-edit-offering-dialog'>
                <form className='form-inline'>
                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Offering:</span>
                      <input
                        type='text'
                        className='k-text-box'
                        value={this.state.offering.offering || ''}
                        onChange={this.onOfferingChange}
                        disabled={this.state.disabled.offering}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Coordinator:</span>
                      <AutoComplete
                        placeholder='Type to Search'
                        value={this.state.coordinator.value}
                        onChange={this.onCoordinatorChange}
                        className='staff-auto-complete'
                      >
                        {this.state.coordinator.data &&
                          this.state.coordinator.data.map((data) => (
                            <AutoComplete.Option
                              key={data.id}
                              value={data.displayName}
                            >
                              {data.displayName}
                            </AutoComplete.Option>
                          ))}
                      </AutoComplete>
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Enrolments:</span>
                      <input
                        type='text'
                        className='k-text-box'
                        value={this.state.offering.enrolments}
                        onChange={this.onEnrolmentsChange}
                        onKeyPress={this.onNumbersOnly}
                      />
                    </div>
                  </label>

                  <label
                    className='k-form-error-field'
                    onClick={() =>
                      this.setState({
                        failed: false,
                        messaging: { lines: [], type: '' },
                      })
                    }
                  >
                    {this.state.failed && (
                      <ul className='failed-submit-error-msg'>
                        <li>
                          <span className='k-icon k-i-warning'></span> An error has occured while
                          attempting to submit allocation
                        </li>
                      </ul>
                    )}
                    <MessageBlock
                      visible={this.state.messaging.lines.length > 0}
                      lines={this.state.messaging.lines}
                      type={this.state.messaging.type}
                    ></MessageBlock>
                  </label>

                  <div className='form-buttons-frame'>
                    <Button
                      type='submit'
                      onClick={this.onSubmit}
                      look='outline'
                      className='offer-button save'
                    >
                      Save
                    </Button>
                    <Button
                      type='button'
                      onClick={this.onCancel}
                      look='outline'
                      className='offer-button cancel'
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        )}

        {(this.state.saving || this.state.loading || this.state.validating) && (
          <Spinner
            text={this.state.loading ? 'Loading' : this.state.validating ? 'Validating' : 'Saving'}
          />
        )}
      </div>
    );
  }
}

export default observer(AddEditOfferingDialog);
