// @flow

import FileDownload from 'js-file-download';
import { makeObservable } from 'mobx';
import Api from './api';

export default class UploadStore {
  async upload(upload: { type: string, file: File, formData: object }, onUploadProgress: Function) {
    const data = new FormData();
    if (upload.formData) {
      for (const key in upload.formData) {
        data.append(key, upload.formData[key]);
      }
    }
    data.append('file', upload.file);

    return new Promise((resolve, reject) => {
      Api.upload(
        `/uploads/${upload.type}`,
        data,
        { 'Content-Type': 'multipart/form-data' },
        onUploadProgress
      )
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async getUploadTypes(refresh = false) {
    return new Promise(async (resolve, reject) => {
      if (this.types == null || refresh) {
        this.types = await Api.get('/uploads/types');
      }
      resolve(this.types);
    });
  }
}

makeObservable(UploadStore, {});
