// @flow

import { any } from 'joi';
import React, { Component } from 'react';
import CheckBox from './checkbox';

import './checkbox-list.css';

type Props = {
    items: { id: any, title: string, className: string }[],
    onCheckChange: Function,
    prompt: string,
    emptyText: string,
    disabled: boolean
};

class CheckBoxList extends Component<Props> {

    onCheckChange = (target: any) => this.props.onCheckChange(target);

    render() {

        const checkBox = this.props.items.map((val) => {
            return <CheckBox key={val.id} id={val.id} title={val.title} checked={val.checked} className={val.className} disabled={this.props.disabled} onChange={this.onCheckChange} />;
        });


        return (
            <div className='checkbox-list-check-list'>
                {this.props.prompt && <p className='checkbox-list-prompt'>{this.props.prompt}</p>}
                {this.props.emptyText && this.props.items.length == 0 ? <div className='checkbox-list-empty'>{this.props.emptyText}</div> : checkBox}
            </div>
        );
    }
}

export default CheckBoxList;