import {
  AllocationYearDto,
  AuthenticatedUserDto,
  CourseDto,
  DepartmentDto,
  ModelDto,
  StaffDto,
  UserModelGroupDto,
} from '../dto';

interface reportUserPermissions {
  userRole: string;
  openModelStates: string | string[];
  closedModelStates: string | string[];
  rolloverModelStates: string | string[];
  singleRecordsOnly: boolean;
}

export default class PermissionsHelper {
  static modelStates = {
    import: 'import',
    verified: 'verified',
    validation: 'validation',
    open4discussion: 'open4discussion',
    open4review: 'open4review',
    all: ['import', 'verified', 'validation', 'open4discussion', 'open4review'],
  };

  static yearStates = {
    open: 'open',
    closed: 'closed',
    rollover: 'pending',
    all: ['open', 'closed', 'pending'],
  };

  /**
   * Returns true if the user has any of the roles in the allowedRoles array
   * @param {*} allowedRoles Either single role (group) code or array of role (group) codes
   * @param {*} user User object containing the userModelGroups array
   * @returns boolean
   */
  static userHasRole(allowedRoles: string | string[], user: AuthenticatedUserDto): boolean {
    return this.userModelGroupHasRole(allowedRoles, user?.userModelGroups);
  }

  /**
   * Returns true if the user has permissions to view a model given a model code
   * @param {*} modelCode A model code
   * @param {*} user User object containing the userModelGroups array
   * @returns boolean
   */
  static userCanAccessModel(modelCode: string, user: AuthenticatedUserDto): boolean {
    return this.userModelGroupCanAccessModel(modelCode, user?.userModelGroups);
  }

  /**
   * Returns true if the user has permissions to view a department given a model code and department code
   * @param {*} modelCode A model code
   * @param {*} departmentCodes A department code array
   * @param {*} user User object containing the userModelGroups array
   * @returns boolean
   */
  static userCanAccessDepartment(
    modelCode: string,
    departmentCodes: string[],
    user: AuthenticatedUserDto
  ): boolean {
    return this.userModelGroupCanAccessDepartments(
      modelCode,
      departmentCodes,
      user?.userModelGroups
    );
  }

  /**
   * Returns true if the supplied userGroupArray contians any of the roles in the allowedRoles array
   * @param {*} allowedRoles Either single role (group) code or array of role (group) codes
   * @param {*} userModelGroups User model groups array
   * @returns boolean
   */
  static userModelGroupHasRole(
    allowedRoles: string | string[],
    userModelGroups: UserModelGroupDto[]
  ): boolean {
    if (userModelGroups == null) {
      return false;
    }
    return userModelGroups.some((umg) => this.isUserGroupAllowed(allowedRoles, umg));
  }

  /**
   * Returns true if the supplied userGroupArray contains a userGroup with permissions to access a model
   * @param {*} modelCode A model code
   * @param {*} userModelGroups User model groups array
   * @returns boolean
   */
  static userModelGroupCanAccessModel(
    modelCode: string,
    userModelGroups: UserModelGroupDto
  ): boolean {
    if (modelCode == null || userModelGroups == null) {
      return false;
    }

    return userModelGroups.some((umg) => {
      if (this.isUserGroupAllowed('bpo', umg)) {
        return true;
      } else if (this.isUserGroupAllowed(['mm', 'wm', 'as'], umg)) {
        return umg.modelCode === modelCode;
      } else {
        return false;
      }
    });
  }

  /**
   * Returns true if the supplied userGroupArray contains a userGroup with permissions to access a department
   * @param {*} modelCode A model code
   * @param {*} departmentCodes A department code array
   * @param {*} userModelGroups User model groups array
   * @returns boolean
   */
  static userModelGroupCanAccessDepartments(
    modelCode: string,
    departmentCodes: string[],
    userModelGroups: UserModelGroupDto
  ): boolean {
    if (departmentCodes == null || modelCode == null || userModelGroups == null) {
      return false;
    }

    return userModelGroups.some((umg) => {
      switch (umg.userGroupCode) {
        case 'bpo':
          return true;
        case 'mm':
          return umg.modelCode === modelCode;
        case 'wm':
          return departmentCodes.includes(umg.departmentCode);
        case 'as':
          return false;
        default:
          throw new Error('Unexpected UserGroupCode in Users UserModelGroup');
      }
    });
  }

  /**
   * Returns true if the supplied userModelGroup contians any of the roles in the allowedRoles array
   * @param {*} allowedRoles Either single role (group) code or array of role (group) codes
   * @param {*} userModelGroup Single User model group
   * @returns boolean
   */
  static isUserGroupAllowed(allowedRoles: string | string[], userModelGroup: UserModelGroupDto) {
    if (typeof allowedRoles === 'string') {
      return userModelGroup.userGroupCode === allowedRoles;
    } else if (Array.isArray(allowedRoles)) {
      return allowedRoles.some((allowedRole) => {
        return userModelGroup.userGroupCode === allowedRole;
      });
    }
  }

  /**
   * Returns true if the year supplied is either closed or if the course or staff supplied
   * is not in the supplied year
   * @param {*} year The current year
   * @param {*} course Course object containing the current course being viewed
   * @param {*} staff Staff object containing the current staff being viewed
   * @returns boolean
   */
  static yearIsInvalid(params: {
    year: AllocationYearDto,
    course: ?CourseDto,
    staff: ?StaffDto,
  }): boolean {
    const { year, course, staff, closed } = params;

    if (year?.closed || closed) {
      return true;
    }
    if (staff && staff.year) {
      return staff.year !== year.year;
    }
    if (course && course.year) {
      return course.year !== year.year;
    }
    return false;
  }

  /**
   * Returns true if the supplied model's status is the same as the model-status-code supplied
   * @param {*} model Model object containing the current model
   * @param {*} modelStatusCodes Either single model status code or array of model status codes
   * @returns boolean
   */
  static hasModelStatusCode(model: ModelDto, modelStatusCodes: string | string[]): boolean {
    if (model == null || modelStatusCodes == null) {
      return false;
    }
    if (modelStatusCodes === 'all') {
      const isValidModelState = this.modelStates.all.includes(model.status?.code);
      return isValidModelState;
    }
    if (Array.isArray(modelStatusCodes)) {
      return modelStatusCodes.some((modelStatusCode) =>
        this.hasModelStatusCode(model, modelStatusCode)
      );
    }
    return model.status?.code === modelStatusCodes;
  }

  /**
   * Returns true if the supplied model's status is in 'validation'
   * @param {*} model Model object containing the current model
   * @returns boolean
   */
  static modelIsValidation(model: ModelDto): boolean {
    return this.hasModelStatusCode(model, 'validation');
  }

  /**
   * Returns true if user does not have edit permissions for course page
   * @param {*} user User object containing the userModelGroups array
   * @param {*} year Year object containing the current year
   * @param {*} model Model object containing the current model
   * @param {*} departments A department array
   * @param {*} course Course object containing the current course being viewed
   * @param {*} staff Staff object containing the current staff being viewed
   * @returns boolean
   */
  static isRestrictedAccess(params: {
    user: AuthenticatedUserDto,
    year: AllocationYearDto,
    model: ModelDto,
    departments: DepartmentDto[],
    course: CourseDto,
    staff: StaffDto,
    allowedRoles: string[] | string,
  }): boolean {
    const { user, model, departments, allowedRoles } = params;
    const modelCode = model?.code;
    const departmentCodes = departments?.filter((d) => d?.code != null).map((d) => d?.code);

    if (this.userHasRole('wm', user) && this.modelIsValidation(model)) {
      return true;
    }

    let canAccess = false;

    if (departmentCodes == null) {
      canAccess = this.userCanAccessModel(modelCode, user);
    } else {
      canAccess = this.userCanAccessDepartment(modelCode, departmentCodes, user);
    }

    const yearIsInvalid = this.yearIsInvalid(params);

    if (yearIsInvalid) {
      return true;
    }

    if (allowedRoles) {
      return !this.userHasRole(allowedRoles, user);
    }

    return !canAccess;
  }

  /**
   * Returns an arrow function that filters through report user permissions,
   * and returns permissions relevant to this user
   * @param {*} user The current user
   * @returns Function
   */
  static filterUserPermissions(user: AuthenticatedUserDto): Function {
    return (userPermission: reportUserPermissions) => {
      return this.userHasRole(userPermission.userRole, user);
    };
  }

  /**
   * Returns either a single or multiple model status codes that the user
   * is allowed to access something in when the year is open
   * @param {*} userPermission The userPermission being examined
   * @returns string | string[]
   */
  static mapOpenModelStates(userPermission: reportUserPermissions): string | string[] {
    return userPermission.openModelStates;
  }

  /**
   * Returns either a single or multiple model status codes that the user
   * is allowed to access something in when the year is closed
   * @param {*} userPermission The userPermission being examined
   * @returns string | string[]
   */
  static mapClosedModelStates(userPermission: reportUserPermissions): string | string[] {
    return userPermission.closedModelStates;
  }

  /**
   * Returns either a single or multiple model status codes that the user
   * is allowed to access something in when the year is rollover
   * @param {*} userPermission The userPermission being examined
   * @returns string | string[]
   */
  static mapRolloverModelStates(userPermission: reportUserPermissions): string | string[] {
    return userPermission.rolloverModelStates;
  }

  /**
   * Returns true if user has access to generate a report
   * @param {*} userPermissions The userPermissions for the given report, contains allowed year, model and userRole conditions
   * @param {*} year The current year being viewed
   * @param {*} model The current model being viewed
   * @param {*} user The current user
   * @returns boolean
   */
  static reportIsAllowed(params: {
    userPermissions: reportUserPermissions[],
    year: AllocationYearDto,
    model: ModelDto,
    user: AuthenticatedUserDto,
  }): boolean {
    const { userPermissions: rawPermissions, year, model, user } = params;
    const userPermissions = rawPermissions.filter(this.filterUserPermissions(user));

    if (year.status.code === this.yearStates.open) {
      const allowedOpenModelStates = userPermissions.map(this.mapOpenModelStates);
      const isAllowed = this.hasModelStatusCode(model, allowedOpenModelStates);
      return isAllowed;
    }
    if (year.status.code === this.yearStates.closed) {
      const allowedClosedModelStates = userPermissions.map(this.mapClosedModelStates);
      const isAllowed = this.hasModelStatusCode(model, allowedClosedModelStates);
      return isAllowed;
    }
    if (year.status.code === this.yearStates.rollover) {
      const allowedRolloverModelStates = userPermissions.map(this.mapRolloverModelStates);
      const isAllowed = this.hasModelStatusCode(model, allowedRolloverModelStates);
      return isAllowed;
    }
    return false;
  }
}
