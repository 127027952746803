// @flow

import React, { Component } from 'react';


import './state-bar.css';

type Props = {
    children: any,
}

class StateBarRow extends Component<Props> {

    render() {
        return (
            <div className="state-bar-row">
                {this.props.children}
            </div>
        );
    }
}

export default StateBarRow;
