// @flow

import React, { Component } from 'react';


import './state-bar.css';

type Props = {}

class StateBarMessage extends Component<Props> {

    render() {
        return (
            <div className="state-bar-message">
                {this.props.children}
            </div>
        );
    }
}

export default StateBarMessage;
