import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import { ActionButton, ConfirmationDialog, UploadDrop, Spinner } from '../../components';
// import { MessageConfirmationBlock } from '../../components/message';
import { StringUtility } from '../../utility';
import { inject, observer } from 'mobx-react';
import ImportMessageBlock from './import-message-block';

const ManageImport = (props) => {
  const [typeSelected, setTypeSelected] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [doingImport, setDoingImport] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const [result, setResult] = useState(null);
  const [dialog, setDialog] = useState(null);

  const [importData, setImportData] = useState({
    stage: '',
    data: null,
  });

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { canImport, setMessageEvent, viewStore } = props;
  const importTasks = ['insert', 'update', 'delete'];
  const importStore = props.importStore;
  const uploadStore = props.uploadStore;
  
  const uploadEnabled = canImport && uploadFile != null && !isProcessing && !doingImport;

  function onTypeChange(selectedType) {
    setTypeSelected(selectedType);
    setUploadFile(null);
    setProgress(0);
  }

  function onUploadAdd(file) {
    setUploadFile(file);
    setProgress(0);
    setResult(null);
  }

  function onUploadRemove() {
    setUploadFile(null);
    setProgress(0);
    setResult(null);
    setDoingImport(false);
    setImportData({
      stage: 'clear',
      data: null,
    });
  }

  async function execUpload() {
    setIsProcessing(true);
    setDoingImport(true);
    const response = await uploadStore.upload(
      {
        type: typeSelected,
        file: uploadFile,
        formData: {
          modelId: viewStore.model.id,
        },
      },
      (progress) => {
        const percentage = Math.round((100 * progress.loaded) / progress.total);
        setProgress(percentage);
      }
    );
    try {
      setResult({
        success: response.success,
        message: `${response.message}: ${JSON.stringify(response.result)}`,
        viewable: true,
      });
      setMessageEvent({
        visible: true,
        type: 'information',
        msg: `${response.success ? 'Successfully uploaded and processed' : 'Failed to upload'} ${
          uploadFile.name
        }`,
      });
      if (response.success) {
        onUploadSuccess();
      }
    } catch (error) {
      setResult({ success: false, message: JSON.stringify(error) });
      setMessageEvent({
        visible: true,
        type: 'error',
        msg: `Failed to upload ${uploadFile.name}`,
      });
    } finally {
      setIsProcessing(false);
    }
  }

  function onUploadSuccess() {
    setDialog({
      visible: true,
      title: 'Please Confirm',
      lines: [
        <span key='dataChangeConfirm'>
          Please confirm you wish to continue the import process for the{' '}
          <span className='important'>{`${viewStore.model.year.year} -  ${viewStore.model.model}`}</span>
        </span>,
        <span
          key='dataChangeCanEdit'
          className='caution'
        >
          This process can add, edit and delete records.
        </span>,
        <span
          key='dataChangeNote'
          className='caution'
        >
          The following tasks will be applied against existing{' '}
          {`${viewStore.model.year.year} -  ${viewStore.model.model}`} records;
        </span>,
        <div
          key='dataChangeList'
          className='dialog-import-task-list'
        >
          {
            <ol>
              {importTasks.map((task, index) => {
                return (
                  <li key={index}>
                    <span>
                      <span className='caution upper'>{task}</span>
                    </span>
                  </li>
                );
              })}
            </ol>
          }
        </div>,
        'Do you wish to continue?',
      ],
      callback: (confirmed) => {
        setDialog(null);
        if (confirmed) {
          setIsProcessing(true);
          importDataChange();
        } else {
          setDoingImport(false);
          setProgress(0);
        }
      },
    });
  }

  async function importDataChange() {
    const dataImport = { modelId: viewStore.model.id, types: [typeSelected], tasks: importTasks };
    try {
      const response = await importStore.importForModel(dataImport);
      setImportData({
        stage: 'import_warning',
        data: response,
      });
    } catch (error) {
      setImportData({
        stage: 'import_error',
        data: error,
      });
    } finally {
      setIsProcessing(false);
    }
  }

  const onImportCommit = useCallback(async() => {
    setIsProcessing(true);
    const response = await importStore.commit(viewStore.model.id);
    try {
      if (response.success) {
        const stage = response.result.warnings ? 'commit_with_warnings' : 'commit_success';
        setImportData({
          stage,
          data: response,
        });
      } else {
        setImportData({
          stage: 'commit_failed',
          data: response,
        });
      }
    } catch (error) {
      setImportData({
        stage: 'commit_failed',
        data: error,
      });
    } finally {
      setIsProcessing(false);
    }
  }, [importStore, viewStore.model.id]);

  const onImportReport = useCallback(() => {
    importStore.report().catch((e) => {
      setImportData({
        stage: 'report_error',
        data: e,
      });
    });
  }, [importStore]);

  const onImportCancel = useCallback(() => {
    setImportData({
      stage: 'clear',
      data: null,
    });
    setProgress(0);
    setResult(null);
    setDoingImport(false);
    setUploadFile(null);
  }, []);

  function onUpload() {
    setImportData({
      stage: 'clear',
      data: null,
    });
    setDialog({
      title: 'Please Confirm',
      lines: [
        <span key='uploadNote'>
          Please confirm you wish to execute the{' '}
          <span className='important'>
            {StringUtility.capitalize(typeSelected.split('_').join(' '), ['and'])}
          </span>{' '}
          import.
        </span>,
      ],
      callback: (confirmed) => {
        setDialog(null);
        if (confirmed) {
          execUpload();
        }
      },
    });
  }

  return (
    <div className='management-column upload'>
      <div className='item-card title'>
        <div className='title-label'>Import</div>
      </div>
      <div className='item-card management-tip'>
        <p>
          <span className={'k-icon k-i-information'}></span>
          To be able to import staff/courses, the chosen model must have a status of Import. Update
          WAT data to reflect changes for a new academic year. Upload staff and course and offering
          spreadsheets, starting with the Staff spreadsheet. The file will initially be validated,
          and then you will have the option to continue or stop the process and make changes. See
          Help file for more information.
        </p>
      </div>
      <div className='item-card'>
        <span className='management-label'>Type:</span>
        <Select
          //disabled={!canImport}
          className='management-dropdown type'
          value={typeSelected}
          onChange={onTypeChange}
          disabled={props.disabled}
          options={[
            {
              value: 'staff',
              label: 'Staff',
            },
            {
              value: 'courses_and_offerings',
              label: 'Courses and Offerings',
            },
          ]}
        ></Select>
      </div>
      <div>
        {typeSelected != null && (
          <div className='item-card upload'>
            <UploadDrop
              hint={'Drag and Drop file here, or Click to Select'}
              note={
                <span>
                  Only <span className='emphasise'>CSV</span> files allowed with a max size of{' '}
                  <span className='emphasise'>50MB</span>
                </span>
              }
              width={500}
              height={100}
              maxSize={52428800}
              allows={['csv']}
              uploadIcon={'k-icon k-i-upload'}
              removeIcon={'k-icon k-i-delete'}
              file={uploadFile}
              onAdd={onUploadAdd}
              onRemove={onUploadRemove}
              progress={progress}
              result={result}
              disabled={props.disabled}
            />
          </div>
        )}
      </div>

      <div className='item-card management-action'>
        <ActionButton
          on={onUpload}
          className='management-button upload'
          text={'Import'}
          disabled={props.disabled}
          enabled={uploadEnabled}
        />
      </div>
      {dialog && (
        <ConfirmationDialog
          response={dialog.callback}
          title={dialog.title}
          lines={dialog.lines}
        />
      )}
      <ImportMessageBlock
        importData={importData}
        context='manage_import'
        onImportCommit={onImportCommit}
        onImportReport={onImportReport}
        onImportCancel={onImportCancel}
      />
      {isProcessing && <Spinner text='Processing - Please wait' />}
    </div>
  );
};

export default inject('importStore', 'uploadStore', 'viewStore')(observer(ManageImport));
