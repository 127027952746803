//@flow

import { ExportOutput, ExportType } from '../types';

export default class ExportParameterDto {
  output: ExportOutput;

  type: ExportType;

  model: ?string;

  owner: ?(string[]);

  department: ?(string[]);

  year: ?number;

  emailed: ?boolean;

  staffId: ?string;

  courseId: ?string;

  constructor(
    params: {
      emailed: boolean,
      type: ExportType,
      output: ExportOutput,
      year: number,
      model: ?string,
      owner: ?(string[]),
      department: ?(string[]),
      staffId: ?string,
      courseId: ?string,
    } = {}
  ) {
    this.emailed = params ? params.emailed : false;
    this.type = params ? params.type : null;
    this.output = params ? params.output : 'xlsx';
    this.year = params ? params.year : null;
    this.model = params ? params.model : null;
    this.owner = params ? params.owner : null;
    this.department = params ? params.department : null;
    this.staffId = params ? params.staffId : null;
    this.courseId = params ? params.courseId : null;
  }
}
