// @flow

import React, { Component } from 'react';
import {
  CollapsePanel,
  GridRemoveDialog,
  SpinnerBasic,
  TableActionCellStaff,
  TableStyleCellDiffByTolerance,
} from '../../components';
import * as mobx from 'mobx';

import './course';
import { Table, Button } from 'antd';

const { Column } = Table;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  title: string,
  category: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDelete?: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit?: Function,
  readOnly?: boolean,
  loading: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onToggle: Function,
  expanded: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userRole: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  units?: any,
};

type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selected: any,
  dialog: {
    visible: boolean,
    lines: [],
  },
};

class Summary extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      offering: null,
      dialog: {
        visible: false,
        lines: [],
      },
      loading: this.props.loading,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
    if (this.props.data?.lines.length > 0) {
      this.props.data.lines.forEach((data, index) => {
        data['key'] = index;
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit = (item: any) => {
    if (this.props.onEdit) {
      this.props.onEdit(item);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete = (item: any) => {
    this.setState({
      selected: mobx.toJS(item),
      dialog: {
        lines: [
          `You are about to delete the offering  ${item.offering}  and all its allocations.`,
          'Once deleted this data cannot be recovered.',
          'Do you wish to continue?',
        ],
        visible: true,
      },
    });
  };

  onConfirmDelete = (confirmed: boolean) => {
    this.setState({ dialog: { lines: [], visible: false } });
    if (confirmed) {
      if (this.props.onDelete) {
        this.props.onDelete(this.state.selected);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onToggle = (e: any) => {
    if (this.props.onToggle != null) {
      this.props.onToggle(e);
    }
  };

  rolesCanView = (roles: Array<string>) => {
    return roles.includes(this.props.userRole);
  };

  get canDelete() {
    const allowedToDelete = ['bpo', 'mm'];
    return allowedToDelete.includes(this.props.userRole);
  }

  renderContent() {
    if (this.props.category === 'offering') {
      return (
        <div>
          <Table
            dataSource={this.props.data.lines}
            bordered={true}
            size='small'
            tableLayout='fixed'
            pagination={false}
          >
            <Column
              field='period.period'
              title='Period'
              dataIndex={['period', 'period']}
            />
            <Column
              field='offering.deliverySite'
              title='Campus'
              width='80px'
              dataIndex={['offering', 'deliverySite']}
            />
            <Column
              field='offering.deliveryMode'
              title='Mode'
              width='80px'
              dataIndex={['offering', 'deliveryMode']}
            />
            <Column
              field='staff.displayName'
              title='Offering Coordinator'
              dataIndex={['staff', 'displayName']}
            />
            <Column
              field='enrolments'
              title='Enrolments'
              width='100px'
              align='right'
              dataIndex='enrolments'
            />
            <Column
              field='hours'
              title='Hours'
              align='right'
              width='100px'
              dataIndex='hours'
              render={(text, record) => (
                <TableStyleCellDiffByTolerance
                  record={record}
                  field={'hours'}
                  type={'offering'}
                />
              )}
            />
            {!this.props.readOnly && (
              <Column
                width='150px'
                render={(text, record) => (
                  <>
                    <Button
                      className='k-primary k-button k-grid-edit-command'
                      onClick={() => this.onEdit(record.offering)}
                      primary='true'
                    >
                      Edit
                    </Button>
                    {this.canDelete && (
                      <button
                        className='k-button k-grid-remove-command'
                        onClick={() => this.onDelete(record.offering)}
                      >
                        Delete
                      </button>
                    )}
                  </>
                )}
              />
            )}
          </Table>
        </div>
      );
    } else if (this.props.category === 'staff') {
      return (
        <Table
          dataSource={this.props.data?.lines}
          bordered={true}
          size='small'
          tableLayout='fixed'
          pagination={false}
        >
          {this.rolesCanView(['bpo', 'mm', 'wm']) && (
            <Column
              field='staff.displayName'
              title='Name'
              dataIndex={['staff', 'displayName']}
              render={(text, record) => (
                <TableActionCellStaff
                  staff={record?.staff}
                  inDifferentUnit={
                    this.rolesCanView(['wm']) &&
                    !this.props.units?.includes(record?.staff?.department?.code)
                  }
                />
              )}
            />
          )}
          {this.rolesCanView(['as']) && (
            <Column
              field='staff.displayName'
              title='Name'
              dataIndex={['staff', 'displayName']}
            />
          )}
          <Column
            field='hoursS1'
            title='S1'
            width='100px'
            dataIndex='hoursS1'
            align='right'
          />
          <Column
            field='hoursS2'
            title='S2'
            width='100px'
            dataIndex='hoursS2'
            align='right'
          />
          <Column
            field='hoursSS'
            title='SS'
            width='100px'
            dataIndex='hoursSS'
            align='right'
          />
          <Column
            field='calculated'
            title='Calculated'
            width='100px'
            dataIndex='calculated'
            align='right'
          />
          <Column
            field='hours'
            title='Assigned'
            width='100px'
            align='right'
            dataIndex='hours'
          />
        </Table>
      );
    }
  }

  render() {
    return (
      <div>
        <CollapsePanel
          expanded={this.props.expanded != null ? this.props.expanded : true}
          title={this.props.title}
          className='panel-bar-item'
          onSelect={this.onToggle}
          tabIndex={1}
        >
          <div className='grid-frame'>
            {this.state.loading ? (
              <div className='load-frame'>
                <SpinnerBasic />
              </div>
            ) : (
              this.renderContent()
            )}
          </div>
        </CollapsePanel>

        {this.props.category === 'offering' && this.state.dialog.visible && (
          <GridRemoveDialog
            response={this.onConfirmDelete}
            lines={this.state.dialog.lines}
          />
        )}
      </div>
    );
  }
}

export default Summary;
