import React, { Component } from 'react';

import { Header, BodyLayout, Spinner, StateBar } from '../../components';
import ManageModelStatus from './manage-model-status';
import ManageDataChange from './manage-data-change';
import ManageRolloverAndImport from './manage-rollover-and-import';
import { DepartmentDto, ModelDto, OwnerDto, AllocationYearDto } from '../../dto';
import { inject, observer } from 'mobx-react';
import { PermissionsHelper } from '../../utility';
import './model.css';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

type State = {
  spinner: { visible: boolean, text: string },
  models: ModelDto[],
  owners: OwnerDto[],
  departments: DepartmentDto[],
  year: AllocationYearDto,
};

class Model extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.dictionary = this.props.dictionary;
    this.authUserStore = this.props.authUserStore;
    this.viewStore = this.props.viewStore;
    this.isBPO = PermissionsHelper.userHasRole(['bpo'], this.authUserStore.currentUser);
    this.isMM = PermissionsHelper.userHasRole(['mm'], this.authUserStore.currentUser);
    this.state = {
      loading: true,
      spinner: { visible: false, text: '' },
      model: {},
      year: '',
      userModelGroups: [],
      unauthorized: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({
      spinner: { visible: true, text: 'Loading' },
    });

    const year = this.viewStore.year;
    const model = this.viewStore.model;

    const userData = this.authUserStore.currentUser;
    this.setState({ userModelGroups: userData.userModelGroups });
    if (!model) {
      this.dictionary
        .getModels(year.year)
        .then((models) => {
          this.setState({ year, models, spinner: { visible: false, text: null } });
        })
        .catch((err) => {
          console.error(err);
          this.setState({ year, models: [], spinner: { visible: false, text: null } });
        });
    } else {
      this.setState({
        year,
        models: [model],
        model: model,
        spinner: { visible: false, text: null },
      });
      this.dictionary.getOwners(this.state.year.year, model.id).then((owners) => {
        if (this._isMounted) {
          this.setState({ owners, spinner: { visible: false, text: null } });
        }
      });
      this.dictionary.getDepartments(this.state.year.year, model.id).then((departments) => {
        if (this._isMounted) {
          this.setState({ departments, spinner: { visible: false, text: null } });
        }
      });
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return this.state.loading || this.state.unauthorized ? (
      <div></div>
    ) : (
      <div>
        <Header
          navState={'model'}
          subfooter={
            // eslint-disable-next-line no-constant-condition
            false ? (
              <div className='header-message'>
                <span className='k-icon k-i-wrench'></span>The Report page is currently in active
                development
              </div>
            ) : null
          }
        />

        <BodyLayout>
          <StateBar
            title={'Manage Model: '}
            text={this.state.model.model}
          >
            <div className='state-bar-item-line'>
              <label className='state-bar-item'>
                <span className='state-bar-item-first-column-title-key'>Annual Target Hours:</span>
                <span className='state-bar-item-title-value'>{this.state.model.target}</span>
              </label>
              <label className='state-bar-item'>
                <span className='state-bar-item-second-column-title-key'>Model State:</span>
                <span className='state-bar-item-title-value'>
                  {this.viewStore.model.status.status}
                </span>
              </label>
            </div>
            <div className='state-bar-item-line'>
              <label className='state-bar-item'>
                <span className='state-bar-item-first-column-title-key'>Staff Hours Variance:</span>
                <span className='state-bar-item-title-value'>
                  {this.state.model.staffVariance * 100}%
                </span>
              </label>
              <label className='state-bar-item'>
                <span className='state-bar-item-second-column-title-key'>
                  Offering Hours Variance:
                </span>
                <span className='state-bar-item-title-value'>
                  {this.state.model.offeringVariance * 100}%
                </span>
              </label>
            </div>
          </StateBar>

          {(this.isBPO || this.isMM) && <ManageModelStatus model={this.state.model} />}

          {this.isBPO && <ManageRolloverAndImport model={this.viewStore.model}  year={this.state.year} />}

          {this.isBPO && <ManageDataChange model={this.state.model}  year={this.state.year} />}

          {this.state.spinner.visible && (
            <Spinner
              text={this.state.spinner.text}
              lines={this.state.spinner.lines}
            />
          )}
        </BodyLayout>
      </div>
    );
  }
}

export default inject('dictionary', 'authUserStore', 'viewStore')(observer(Model));
