// @flow

import { AllocationYearDto, ModelDto, SearchCourseDto, SearchStaffDto } from '../dto';

export default class ViewManager {

  static _yearAllocationSessionKey = 'wat_allocation_year';
  static _modelAllocationSessionKey = 'wat_allocation_model';
  static _courseSearchSessionKey = 'wat_course_search';
  static _staffSearchSessionKey = 'wat_staff_search';
  static _versionUpdateSessionKey = 'wat_version_update';
  static _invalidUserAttemptedRefreshSessionKey = 'wat_invalid_user_refresh_attempt';

  static getAllocationYear(): AllocationYearDto {
    return this.getSession(this._yearAllocationSessionKey);
  }

  static getAllocationModel(): ModelDto {
    return this.getSession(this._modelAllocationSessionKey);
  }

  static getCourseSearch(): SearchCourseDto {
    return this.getSession(this._courseSearchSessionKey);
  }

  static getStaffSearch(): SearchStaffDto {
    return this.getSession(this._staffSearchSessionKey);
  }

  static getVersionUpdate() {
    return this.getSession(this._versionUpdateSessionKey);
  }

  static hasInvalidUserAttemptedRefresh() {
    const userHasAttemptedRefresh = this.getSession(this._invalidUserAttemptedRefreshSessionKey);
    if(userHasAttemptedRefresh?.hasAttempted) {
      return true;
    }
    return false;
  }

  static setAllocationYear(year: AllocationYearDto): AllocationYearDto {
    return this.setSession(this._yearAllocationSessionKey, year);
  }

  static setAllocationModel(model: ModelDto): ModelDto {
    return this.setSession(this._modelAllocationSessionKey, model);
  }

  static setCourseSearch(courseSearch: SearchCourseDto) {
    return this.setSession(this._courseSearchSessionKey, courseSearch);
  }

  static setStaffSearch(staffSearch: SearchStaffDto) {
    return this.setSession(this._staffSearchSessionKey, staffSearch);
  }

  static setVersionUpdate(versionUpdate) {
    return this.setSession(this._versionUpdateSessionKey, versionUpdate);
  }

  static setInvalidUserAttemptedRefresh(hasAttempted: boolean) {
    return this.setSession(this._invalidUserAttemptedRefreshSessionKey, { hasAttempted });
  }

  static updateVersionUpdate(versionUpdate) {
    return this.updateSession(this._versionUpdateSessionKey, versionUpdate);
  }

  static removeAllocationYear() {
    this.removeSession(this._yearAllocationSessionKey);
  }

  static removeAllocationModel() {
    this.removeSession(this._modelAllocationSessionKey);
  }

  static removeCourseSearch() {
    this.removeSession(this._courseSearchSessionKey);
  }

  static removeStaffSearch() {
    this.removeSession(this._staffSearchSessionKey);
  }

  static removeInvalidUserAttemptedRefresh() {
    this.removeSession(this._invalidUserAttemptedRefreshSessionKey)
  }

  static clearSearches() {
    this.removeCourseSearch();
    this.removeStaffSearch();
  }

  static setSession(keyword: string, stuff: any) {
    if (stuff) {
      if (typeof stuff === 'object') {
        sessionStorage.setItem(keyword, JSON.stringify(stuff));
      } else {
        console.error('Set Session: Invalid json object, instead got', typeof stuff);
        return null;
      }
    }
    return stuff
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getSession(keyword: string) {
    let stuff = sessionStorage.getItem(keyword);
    if (!stuff || !isNaN(stuff)) {
      return null;
    } else {
      try {
        const obj = JSON.parse(stuff);
        if(typeof obj === 'string') {
          console.error('Get Session: Invalid json object, found string');
          return null;
        } else {
          return obj;
        }
      } catch (e) {
        console.error('Error parsing session storage item: ', e);
        return null;
      }
    }
  }

  static updateSession(keyword: string, update: {}) {
    if (typeof update !== 'object') {
      console.error('Error updating session storage item, update value was not object for: ' + keyword);
      return null;
    }

    const currentValue = this.getSession(keyword);
    
    if (currentValue == null) {
      return this.setSession(keyword, update);
    }

    this.setSession(keyword, { ...currentValue, ...update });
  }

  static removeSession(keyword: string) {
    sessionStorage.removeItem(keyword);
  }
}
