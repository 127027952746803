// @flow

import { Button } from 'antd';
import React, { Component } from 'react';

import './message-confirmation-block.css';

//type MessageConfirmationBlockType = 'warn' | 'warning' | 'info' | 'information' | 'error' | 'success';

type Props = {
  visible: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lines: any[],
  // eslint-disable-next-line no-undef
  type: MessageLineType,
  className: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  actions: { callback: Function, text: string, class: string }[],
};

class MessageConfirmationBlock extends Component<Props> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  respond = (callback: Function) => callback();

  getType() {
    return this.props.type
      ? this.props.type === 'info'
        ? 'information'
        : this.props.type === 'warn'
        ? 'warning'
        : this.props.type
      : '';
  }

  render() {
    const lines = this.props.lines != null ? this.props.lines : [''];
    const actions = this.props.actions != null ? this.props.actions : [];

    return (
      this.props.visible && (
        <div
          className={`message-confirmation-block ${this.getType()} ${
            this.props.className ? this.props.className : ''
          }`}
        >
          {this.props.title && (
            <div className='message-confirmation-block-title'>{this.props.title}</div>
          )}
          <div className='message-confirmation-block-content'>
            {lines.map((line, index) => (
              <div
                className='line'
                key={index}
              >
                {line}
              </div>
            ))}
          </div>
          <div className='message-confirmation-block-action'>
            {actions.map((action, index) => (
              <Button
                onClick={() => action.callback()}
                key={index}
                className={`action-button ${action.text.toLowerCase().trim().replaceAll(' ', '_')}`}
                look={'outline'}
                primary='true'
              >
                {action.text}
              </Button>
            ))}
          </div>
        </div>
      )
    );
  }
}

export default MessageConfirmationBlock;
