// @flow

import * as mobx from 'mobx';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { DropListButton } from '../../components';
import { ExportParameterDto, SearchCourseDto, SearchStaffDto } from '../../dto';
import { FileTextOutlined } from '@ant-design/icons';
import StaffActivityStaffDetails from './staff-activity-staff-details';

import {
  Header,
  BodyLayout,
  NavButton,
  StateBar,
  Spinner,
  AddEditStaff,
  AddEditStaffActivityDialog,
  AddEditStaffTeachingActivityDialog,
} from '../../components';
import {
  StaffDto,
  SummaryDto,
  AllocationDto,
  SortDto,
  ProfileCategoryDto,
  AllocationYearDto,
  AllocationTypeDto,
  VariableDto,
  WorkShareDto,
  PeriodDto,
  ModelDto,
} from '../../dto';
import Overall from './overall';
import Summary from './summary';
import './staff-activity.css';
import { Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import moment from 'moment';
import { PermissionsHelper } from '../../utility';
import { AddActivityButton } from '../../components/ui';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  match: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any,
};

type State = {
  year: AllocationYearDto,
  category: ProfileCategoryDto,
  loading: boolean,
  refreshing: boolean,
  updating: boolean,
  staff: StaffDto,
  paging: { limit: number, skip: number },
  dictionaries: {
    types: AllocationTypeDto[],
    categories: ProfileCategoryDto[],
    variables: VariableDto[],
    staff: StaffDto[],
    workshares: WorkShareDto[],
    periods: PeriodDto[],
    model: ModelDto,
  },
  summaryData: SummaryDto,
  activitesExpanded: boolean,
  reporting: boolean,
  sorting: SortDto[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: { visible: boolean, line: any },
  edit: { visible: boolean, item: AllocationDto, type: string, dialog: string },
  loadings: { summary: boolean, allocation: boolean },
  model: ModelDto,
};

class StaffActivity extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.activityStore = this.props.activityStore;
    this.allocationStore = this.props.allocationStore;
    this.authUserStore = this.props.authUserStore;
    this.exportStore = this.props.exportStore;
    this.navStore = this.props.navStore;
    this.reportStore = this.props.reportStore;
    this.searchStore = this.props.searchStore;
    this.staffStore = this.props.staffStore;
    this.viewStore = this.props.viewStore;

    this.state = {
      year: null,
      category: {},
      refreshing: false,
      updating: false,
      loading: true,
      staff: {},
      dictionaries: {
        types: null,
        categories: null,
        variables: null,
        staff: null,
        workshares: null,
        periods: null,
        model: null,
      },
      paging: {},
      summaryData: {},
      readOnlyView: false,
      activitesExpanded: true,
      reporting: false,
      message: {},
      edit: { visible: false, item: null, type: '', dialog: '' },
      loadings: { summary: false, allocation: false },
      originalCategory: null,
      updateDate: null,
      updateRole: null,
    };
  }

  isReadOnly = async () => {
    const { model, year } = this.viewStore;
    const staffId = this.props.match.params.staffId;
    const user = this.authUserStore.currentUser;

    const departments = await this.searchStore.getDepartmentsByUser(year.year, user.id, model.id);
    const staff = await this.staffStore.get(staffId);

    if (departments == null) {
      return PermissionsHelper.isRestrictedAccess({
        user,
        year,
        model,
        departments: staff.departments,
        staff,
      });
    } else {
      const search: SearchStaffDto = {
        keywordsByType: [staff?.emailAddress ?? staff?.firstName],
        modelId: model.id,
        modelCode: model.code,
        departmentCodes: departments.filter((d) => d.code != null).map((d) => d.code),
        searchEmail: true,
        searchName: true,
        year: year.year,
      };

      const staffResults = await this.searchStore.getStaffByKeyWords(
        search,
        100,
        0,
        true,
        null,
        false
      ); //TODO limit is hardcoded. Should change
      const staffResult = staffResults.data.filter((s) => s.id === staff.id)[0];
      return staffResult?.readOnly ?? true;
    }
  };

  isReadOnlyBanner = (record, current) => (
    <span>
      <strong>Read-Only</strong>. You are viewing a <span className='emphasise'>{record}</span>{' '}
      record but your current Workload Year is set as <span className='emphasise'>{current}</span>.
    </span>
  );

  async getStaff(modelId) {
    this.setState({ loadings: { summary: true, allocation: true } });

    let staffResult = null;

    if (Object.keys(this.props.match.params).length !== 0) {
      let params = this.props.match.params;
      const categoryResult = await this.staffStore.getCategoryById(params.categoryId);
      this.setSummaryData(params.staffId, categoryResult.id, categoryResult.year);
      this.getAllocation(params.staffId, categoryResult.code, categoryResult.year);

      staffResult = await this.staffStore.get(params.staffId);
      const isReadOnly = await this.isReadOnly();
      this.setState((prevState) => {
        return {
          loading: false,
          staff: staffResult,
          category: categoryResult,
          title: categoryResult.category.split(' ')[0],
          originalCategory: categoryResult.category,
          message:
            prevState.year && prevState.year?.year !== staffResult.year
              ? {
                  visible: true,
                  line: this.isReadOnlyBanner(staffResult.year, prevState.year?.year),
                }
              : {},
          readOnlyView: isReadOnly,
        };
      });
      if (!this.state.readOnlyView) {
        this.getDictionaries(staffResult);
      }

      if (staffResult) {
        await this.getLastModifyInfo(modelId, staffResult.id, categoryResult.id);
      }
    }

    return staffResult;
  }

  getAllocation(id, category, year) {
    this.allocationStore
      .getByStaff(
        id,
        year,
        category,
        this.state.paging.limit,
        this.state.paging.skip,
        true,
        this.state.sorting
      )
      .then(() => {
        this.setState((prevState) => {
          return { loadings: { ...prevState.loadings, allocation: false } };
        });
      });
  }

  getDictionaries(staff) {
    if (!this.state.dictionaries.initilised) {
      this.setState({
        dictionaries: {
          variables: (this.activityStore.variableList || []).map((d) => mobx.toJS(d)),
          model: staff.model,
          initilised: false,
        },
      });

      this.activityStore.getWorkShares().then((shares) => {
        this.setState((prevState) => {
          return {
            dictionaries: {
              ...prevState.dictionaries,
              workshares: (shares || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          };
        });
      });
      this.activityStore.getTypes(staff.model.id).then((types) => {
        this.setState((prevState) => {
          return {
            dictionaries: {
              ...prevState.dictionaries,
              types: (types || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          };
        });
      });
      this.activityStore.getCategories(staff.model.id).then((categories) => {
        this.setState((prevState) => {
          return {
            dictionaries: {
              ...prevState.dictionaries,
              categories: (categories || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          };
        });
      });
      this.activityStore.getPeriods().then((periods) => {
        this.setState((prevState) => {
          return {
            dictionaries: {
              ...prevState.dictionaries,
              periods: (periods || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          };
        });
      });
      this.activityStore.getStaffMembers(staff.model.id).then((staff) => {
        this.setState((prevState) => {
          return {
            dictionaries: {
              ...prevState.dictionaries,
              staff: (staff || []).map((d) => mobx.toJS(d)),
              initilised: this.activityStore.initilised,
            },
          };
        });
      });
    }
  }

  onPageChange = (limit, skip) => {
    this.setState({ refreshing: true, paging: { limit, skip } });
    this.allocationStore
      .getByStaff(
        this.state.staff.id,
        this.state.staff.year,
        this.state.category.code,
        limit,
        skip,
        true,
        this.state.sorting
      )
      .then(() => {
        this.setState({ refreshing: false });
      });
  };

  onSortChange = (sorting) => {
    this.setState({ sorting, refreshing: true });
    this.allocationStore
      .getByStaff(
        this.state.staff.id,
        this.state.staff.year,
        this.state.category.code,
        this.state.paging.limit,
        this.state.paging.skip,
        true,
        sorting
      )
      .then(() => {
        this.setState({ refreshing: false });
      });
  };

  setSummaryData(id, category, year) {
    this.staffStore.getOverallSummary(id, category, year).then((data) => {
      this.setState((prevState) => {
        return {
          summaryData: data,
          loadings: { ...prevState.loadings, summary: false },
        };
      });
    });
  }

  async componentDidMount() {
    const { model, year } = this.viewStore;
    this.setState({ year, model });
    await this.getStaff(model.id);
  }

  async getLastModifyInfo(modelId, staffId, categoryId) {
    const updateDate = await this.allocationStore.getLastModifyInfo(modelId, staffId, categoryId);
    this.setState({
      updateDate: updateDate?.lastUpdateDate,
      updateRole: updateDate?.userRole,
    });
  }

  getUnique(array) {
    return Array.from(new Set(array));
  }

  onRemoveAllocation = (items: AllocationDto[]) => {
    this.setState({ loadings: { summary: true, allocation: true } });
    if (items && items.length > 0) {
      if (items.length > 1) {
        this.allocationStore
          .removeAllocations(items.map((i) => i.id))
          .then((data) => this.onRemoveResult(data));
      } else {
        this.allocationStore.remove(items[0].id).then((data) => this.onRemoveResult(data));
      }
    }
  };

  onStaffSubmit = (present, previous) => {
    this.setState({ updating: true });
    if (
      present.efte !== previous.efte ||
      present.teachingPercentage !== previous.teachingPercentage ||
      present.researchPercentage !== previous.researchPercentage ||
      present.citizenshipPercentage !== previous.citizenshipPercentage ||
      present.clinicalPercentage !== previous.clinicalPercentage ||
      present.servicePercentage !== previous.servicePercentage
    ) {
      this.allocationStore.calculateByStaff(this.state.year.year, present.id).then(() => {
        Promise.all([
          this.staffStore.get(present.id).then((data) => {
            this.setState({ staff: data });
          }),
          this.staffStore
            .getOverallSummary(present.id, this.state.category.id, this.state.year.year)
            .then((summary) => {
              this.setState((prevState) => {
                return {
                  summaryData: summary,
                  title: prevState.category.category.split(' ')[0],
                  originalCategory: prevState.category.category,
                };
              });
            }),

          this.allocationStore.getByStaff(
            this.state.staff.id,
            this.state.category.year,
            this.state.category.code,
            this.state.paging.limit,
            this.state.paging.skip,
            true,
            this.state.sorting
          ),
        ]).then(() => {
          this.setState({ updating: false });
        });
      });
    } else {
      Promise.all([
        this.staffStore.get(present.id).then((data) => {
          this.setState({ staff: data });
        }),
        this.staffStore
          .getOverallSummary(present.id, this.state.category.id, this.state.year.year)
          .then((summary) => {
            this.setState((prevState) => {
              return {
                summaryData: summary,
                title: prevState.category.category.split(' ')[0],
                originalCategory: prevState.category.category,
                updating: false,
              };
            });
          }),
      ]).then(() => {
        this.setState({ updating: false });
      });
    }
  };

  onRemoveResult = () => {
    // Reload all grid data after remove
    this.getAllocation(this.state.staff.id, this.state.category.code, this.state.staff.year);
    this.setSummaryData(this.state.staff.id, this.state.category.id, this.state.staff.year);
    this.getDictionaries(this.state.staff);
  };

  onEditDialog = (item: AllocationDto, type: string, dialog: string) => {
    this.setState({ edit: { visible: true, item, type, dialog } });
  };

  onCancelDialog = () => {
    this.setState({
      edit: { visible: false, dialog: '', type: '', item: null },
    });
  };

  onSubmitAllocation = async () => {
    this.setState({
      edit: { visible: false, dialog: '', type: '', item: null },
    });
    const { model } = this.viewStore;
    this.getStaff(model.id);
    this.getAllocation(this.state.staff.id, this.state.category.code, this.state.staff.year);
  };

  onPanelSelect = (e) => {
    this.setState({
      activitesExpanded: e.target && !e.target.props.expanded,
    });
  };

  onReport = (type: string) => {
    this.setState({ reporting: true });
    switch (type) {
      case 'report':
        this.reportStore
          .getStaffAllocationSummaryReport(this.state.staff.id, this.state.staff.year)
          .then(() => {
            this.setState({ reporting: false });
          });
        break;
      case 'export':
      default:
        this.exportStore
          .getStaffIdAllocationExport(this.state.staff.id, this.state.staff.year)
          .then(() => {
            this.setState({ reporting: false });
          });
    }
  };

  onStaffChangeReport = () => {
    this.setState({ reporting: true });
    const parameters = new ExportParameterDto({
      emailed: false,
      type: 'staff_change_report',
      output: 'xlsx',
      year: this.state.year.year,
      model: this.state.model?.id,
      courseId: null,
      department: [this.state.staff?.department.id],
      staffId: this.state.staff.id,
    });

    this.exportStore.getAllocationExportByParameters(parameters, false).then((result) => {
      this.setState({ reporting: false });
    });
  };

  get isPlaceholder() {
    return this.state.staff.placeholder;
  }

  render() {
    return (
      <div>
        <Header
          navState={'staff'}
          subfooter={
            this.state.message.visible ? (
              <div className='header-message'>{this.state.message.line}</div>
            ) : null
          }
        />

        {(this.state.loading || this.state.refreshing) && <Spinner text={'Loading'} />}

        {this.state.updating && <Spinner text={'Updating'} />}

        {!this.state.loading && (
          <BodyLayout>
            <StateBar
              title={`Manage Staff ${this.state.originalCategory} Allocation: `}
              text={`${this.state.staff.firstName}  ${this.state.staff.surname}`}
              print={() => this.onReport('report')}
              printing={this.state.reporting}
              export={() => this.onReport('export')}
              exporting={this.state.reporting}
              readOnlyView={this.state.readOnlyView}
              canGenerateAllocationReport={true}
              inStaffTab={true}
              actions={
                <>
                  {!this.state.readOnlyView &&
                  !PermissionsHelper.isRestrictedAccess({
                    year: this.viewStore.year,
                    model: this.viewStore.model,
                    user: this.authUserStore.currentUser,
                  }) &&
                  !this.isPlaceholder ? (
                    <div className='state-bar-additional-actions'>
                      <AddEditStaff
                        onSubmit={this.onStaffSubmit}
                        staff={this.state.staff}
                        button={{
                          look: 'outline',
                          text: 'Edit Staff',
                        }}
                        year={this.state.staff.year}
                      />
                    </div>
                  ) : null}
                  <DropListButton
                    text='Generate Staff Change Report'
                    items={[
                      {
                        text: 'Export to XLSX',
                        icon: <FileTextOutlined />,
                        on: this.onStaffChangeReport,
                      },
                    ]}
                    enabled={true}
                    spinner={true}
                    spinnerText={'Compiling'}
                    active={false}
                    look='outline'
                  />
                </>
              }
            >
              <StaffActivityStaffDetails
                staffData={{
                  role: this.state.staff.jobTitle.title,
                  unit: this.state.staff.department.department,
                  profile: this?.state?.staff?.workProfile,
                  staffFTE: this?.state?.staff?.efte.toFixed(2),
                  updateRole: this?.state?.updateRole,
                  updateDate: this?.state?.updateDate,
                }}
              />
            </StateBar>

            <div className='top-button-group'>
              <NavButton
                url={'/staff'}
                state={this.state.staff.id}
                title={'Go Back'}
                // eslint-disable-next-line react/prop-types
                history={this.props.history}
                previousLocation={this.props.match.params?.previousLocation}
              />
            </div>

            <Overall
              data={this.state.summaryData}
              category={this.state.title}
              title='Summary'
              loading={this.state.loadings.summary}
            />

            <Summary
              editable={this.state.dictionaries.initilised}
              panelExpanded={this.state.activitesExpanded}
              onPanelSelect={this.onPanelSelect}
              data={mobx.toJS(this.allocationStore.staffAllocation)}
              category={this.state.category.code}
              title={this.state.category.category.split(' ')[0]}
              originalCategory={this.state.category.category}
              onDelete={this.onRemoveAllocation}
              onEdit={this.onEditDialog}
              onPageChange={this.onPageChange}
              readOnlyView={this.state.readOnlyView}
              sortable={true}
              onSort={this.onSortChange}
              loading={this.state.loadings.allocation}
            />
            {!this.state.readOnlyView &&
              this.state.activitesExpanded &&
              !PermissionsHelper.isRestrictedAccess({
                user: this.authUserStore.currentUser,
                year: this.viewStore.year,
                model: this.viewStore.model,
              }) && (
                <div className='panel-button-group'>
                  <div className='add-edit-activity-frame'>
                    <AddActivityButton
                      disabled={
                        this.state.loadings.allocation || !this.state.dictionaries.initilised
                      }
                      onEditDialog={this.onEditDialog}
                      type={'staff'}
                      dialog={
                        this.state.category.code === 'teaching_supervision'
                          ? 'teaching'
                          : 'activity'
                      }
                    />
                  </div>
                </div>
              )}

            <div className='bottom-button-group'>
              <NavButton
                url={'/staff'}
                state={this.state.staff.id}
                title={'Go Back'}
                // eslint-disable-next-line react/prop-types
                history={this.props.history}
                previousLocation={this.props.match.params?.previousLocation}
              />
            </div>

            {!this.state.readOnlyView &&
              this.state.edit.visible &&
              this.state.edit.dialog === 'activity' && (
                <AddEditStaffActivityDialog
                  allocation={this.state.edit.item}
                  year={this.state.staff.year}
                  id={this.state.edit.type}
                  staff={this.state.staff}
                  data={this.state.dictionaries}
                  onSubmit={this.onSubmitAllocation}
                  onCancel={this.onCancelDialog}
                  category={this.state.category.code}
                />
              )}

            {!this.state.readOnlyView &&
              this.state.edit.visible &&
              this.state.edit.dialog === 'teaching' && (
                <AddEditStaffTeachingActivityDialog
                  allocation={this.state.edit.item}
                  year={this.state.staff.year}
                  id={this.state.edit.type}
                  staff={this.state.staff}
                  data={this.state.dictionaries}
                  onSubmit={this.onSubmitAllocation}
                  onCancel={this.onCancelDialog}
                  category={this.state.category.code}
                />
              )}
          </BodyLayout>
        )}
      </div>
    );
  }
}

export default inject(
  'activityStore',
  'allocationStore',
  'authUserStore',
  'exportStore',
  'navStore',
  'reportStore',
  'searchStore',
  'staffStore',
  'viewStore'
)(observer(StaffActivity));
