// @flow

import React, { Component } from 'react';

import './message-line.css';

type MessageLineType = 'warn' | 'warning' | 'info' | 'information' | 'error' | 'success';

type Props = {
  visible: boolean,
  line?: string,
  type: MessageLineType,
  className?: string,
  icon?: string,
};

class MessageLine extends Component<Props> {
  getType() {
    return this.props.type
      ? this.props.type === 'info'
        ? 'information'
        : this.props.type === 'warn'
        ? 'warning'
        : this.props.type
      : '';
  }

  getLine() {
    if (this.props.type || this.props.icon) {
      if (this.props.icon) {
        return (
          <p>
            <span className={`k-icon k-i-${this.props.icon}`}></span> {this.props.line}
          </p>
        );
      } else {
        let icon = this.props.type
          ? this.props.type === 'error' ||
            this.props.type === 'warn' ||
            this.props.type === 'warning'
            ? 'warning'
            : this.props.type === 'success'
            ? 'check'
            : 'information'
          : 'information';
        return (
          <p>
            <span className={`k-icon k-i-${icon}`}></span> {this.props.line}
          </p>
        );
      }
    } else {
      return <p>{this.props.line}</p>;
    }
  }

  render() {
    return (
      this.props.visible && (
        <div
          className={`message-line ${this.getType()} ${
            this.props.className ? this.props.className : ''
          }`}
        >
          {this.getLine()}
        </div>
      )
    );
  }
}

export default MessageLine;
