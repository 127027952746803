import React, { useEffect, useState, useCallback } from 'react';
import { Select } from 'antd';
import { CollapsePanel, MessageLine, DropListButton, SearchReportGrid } from '../../components';
import { FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { AuthenticatedUserDto, CourseDto, ExportParameterDto, ReportParameterDto, StaffDto } from '../../dto';
import { PermissionsHelper, StringUtility } from '../../utility';
import { CourseAutoComplete } from '../../components';
import moment from 'moment';

const courseReportTypes = [
  {
    title: 'Course and Offering Allocation',
    type: 'course_allocation',
    formats: ['XLSX', 'PDF'],
    individualSelction: true,
    userPermissions: [
      {
        userRole: 'bpo',
        openModelStates: 'all',
        closedModelStates: 'all',
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'mm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'wm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: true,
      },
    ],
  },
  {
    title: 'Course and Offering Validation',
    type: 'course_data_validation',
    formats: ['XLSX'],
    individualSelction: false,
    userPermissions: [
      {
        userRole: 'bpo',
        openModelStates: 'all',
        closedModelStates: 'all',
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'mm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
    ],
  },
  {
    title: 'Course and Offering Bulk Review',
    type: 'course_bulk_review',
    formats: ['XLSX'],
    individualSelction: false,
    userPermissions: [
      {
        userRole: 'bpo',
        openModelStates: 'all',
        closedModelStates: 'all',
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'mm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'wm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
    ],
  },
];

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const reportTypesWithNoAllOption = ['course', 'course_summary'];

const CourseReports = (props) => {
  const { Option } = Select;
  const {
    defaultModel,
    owners,
    models,
    onReportResult,
    reporting,
    reportSearchStore,
    updateReportingStatus,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    updateSpinner,
    year,
    authUserStore,
    courseStore,
    staffStore,
    exportStore,
    reportStore,
    viewStore,
  } = props;

  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [courseReporting, setCourseReporting] = useState(false);
  const [message, setMessage] = useState({ visible: false, type: 'success', text: '' });
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courses, setCourses] = useState(null);
  const [canReport, setCanReport] = useState(false);

  const allowedCourseReportTypes = courseReportTypes.filter((courseReportType) => {
    const { year, model } = viewStore;
    const { userPermissions } = courseReportType;
    const user = authUserStore.currentUser;

    return PermissionsHelper.reportIsAllowed({ userPermissions, year, model, user });
  });

  useEffect(() => {
    if (message.visible) {
      setTimeout(() => {
        setMessage({ visible: false, type: 'success', text: '' });
      }, 5000);
    }
  }, [message]);

  useEffect(() => {
    setCanReport(canRunReport());
  }, [selectedReport, selectedOwner, selectedCourse]);

  const getReportSelected = (type) => {
    return allowedCourseReportTypes.find((r) => r.type === type);
  };

  const getUserPermissionsByUser = (userPermissions, user) => {
    return userPermissions.find((userPermission) =>
      PermissionsHelper.userHasRole(userPermission.userRole, user)
    );
  };

  const canRunReport = () => {
    const reportTypeIsEmpty = selectedReport?.type == null;
    const ownerIsEmpty = selectedOwner == null;
    const courseIsEmpty = selectedCourse == null;

    if (reportTypeIsEmpty) {
      return false;
    }
    if (ownerIsEmpty) {
      return false;
    }

    const { currentUser: user } = authUserStore;
    const { userPermissions } = getReportSelected(selectedReport?.type);
    const { singleRecordsOnly } = getUserPermissionsByUser(userPermissions, user);

    if (courseIsEmpty && singleRecordsOnly) {
      return false;
    }
    return true;
  };

  const getOwnersParam = useCallback(() => {
    let ownersParam = [];
    if (selectedOwner !== 'all') {
      ownersParam.push(selectedOwner);
    } else {
      ownersParam = owners?.filter((d) => d.id).map((d) => d.id);
    }
    return ownersParam;
  }, [owners, selectedOwner]);

  const onReportChange = (value) => {
    const reportSelected = getReportSelected(value);
    setSelectedReport(reportSelected);
    setSelectedCourse(null);
  };

  const getCoursesByOwnerCode = async (ownerCode) => {
    const ownerCodes = [];

    if (ownerCode) {
      ownerCodes.push(ownerCode);
    } else {
      const allOwnerCodes = owners.filter((o) => o.code).map((o) => o.code);
      ownerCodes.push(...allOwnerCodes);
    }

    return await courseStore.getCoursesByOwnerCodes({ ownerCodes, year });
  };

  const addUniqueCourseToArray = (courseArray: CourseDto[]) => {
    return (newCourse: CourseDto) => {
      if (!courseArray.find(courseFromArray => courseFromArray.id === newCourse.id)) {
        courseArray.push(newCourse);
      }
    }
  };

  const getAllowedCoursesForASUser = async (selectedOwnerCode) => {
    const { code: modelCode, year: { year } } = viewStore.model;
    const authUser: AuthenticatedUserDto = authUserStore.currentUser;
    const allowedCourses: CourseDto[] = [];

    const staffRecords: StaffDto[] = await staffStore.getByMasseyId(year, authUser.identifier);
    const staffIds = staffRecords
      .filter(staff => staff.model.code === modelCode)
      .map(staff => staff.id);
    const uniqueStaffIds = [...new Set(staffIds)];

    for (const staffId of uniqueStaffIds) {
      const allowedCoursesFromStaffId = await courseStore.getCoursesByStaffId(staffId);
      allowedCoursesFromStaffId.forEach(addUniqueCourseToArray(allowedCourses));
    }

    return allowedCourses
      .filter(course => course.owner.code === selectedOwnerCode);
  };

  const getAllowedCoursesByOwnerCode = async (selectedOwnerCode) => {
    const authUser: AuthenticatedUserDto = authUserStore.currentUser;

    if (PermissionsHelper.userHasRole(['bpo', 'mm'], authUser)) {
      return await getCoursesByOwnerCode(selectedOwnerCode);
    }

    if (PermissionsHelper.userHasRole('wm', authUser)) {
      const WMUserModelGroups = authUser.userModelGroups.filter(umg => umg.userGroupCode === 'wm');
      const hasWMPermissionForOwner = WMUserModelGroups.some(umg => umg.departmentCode === selectedOwnerCode);

      if (hasWMPermissionForOwner) {
        return await getCoursesByOwnerCode(selectedOwnerCode);
      }
    }

    return getAllowedCoursesForASUser(selectedOwnerCode);
  };

  const getAllowedCoursesForAllOwners = async () => {
    const authUser = authUserStore.currentUser;

    if (PermissionsHelper.userHasRole(['bpo', 'mm'], authUser)) {
      // Leaving ownerCode as undefined, it will interpret this as being "All Owners"
      return await getCoursesByOwnerCode();
    }

    const allowedCourses = [];
    for (const owner of owners) {
      const allowedCoursesFromOwner = await getAllowedCoursesByOwnerCode(owner.code);
      allowedCoursesFromOwner.forEach(addUniqueCourseToArray(allowedCourses));
    }
    return allowedCourses;
  };

  const getAllowedCourses = async (selectedOwner) => {
    if (selectedOwner === 'all') {
      return await getAllowedCoursesForAllOwners();
    }

    const selectedOwnerCode = owners.find((d) => d.id === selectedOwner)?.code;

    return await getAllowedCoursesByOwnerCode(selectedOwnerCode)
  };

  const onCourseOwnerChange = async (value) => {
    setCourses(null);
    setSelectedCourse(null);
    setSelectedOwner(value);

    const allowedCourses = await getAllowedCourses(value);
    setCourses(allowedCourses);
  };

  const onGenerateClick = (fileType) => {
    switch (fileType) {
      case 'XLSX':
        onXLSX();
        break;
      case 'PDF':
        onPDF();
        break;
      default:
        console.log('Unknown file type');
        break;
    }
  };

  const onCourseChange = (value) => {
    setSelectedCourse(null);
    if (value && value.id) {
      setSelectedCourse(value.id);
    }
  };

  const onReportRequestResult = useCallback(
    (resultSuccess, reportName, fileType) => {
      if (onReportResult != null) {
        onReportResult(resultSuccess);
      }
      const fileString = `${StringUtility.capitalize(reportName)} (${fileType.toUpperCase()})`;
      const userMessage = resultSuccess
        ? `${fileString} Requested`
        : `Failed to Request ${fileString}`;
      setMessage({
        type: resultSuccess ? 'info' : 'error',
        visible: true,
        msg: userMessage,
      });
      setCourseReporting(false);
      updateReportingStatus(false);
    },
    [onReportResult, updateReportingStatus]
  );

  const onXLSX = useCallback(async () => {
    setCourseReporting(true);
    updateReportingStatus(true);

    const parameters = new ExportParameterDto({
      emailed: true,
      type: selectedReport?.type,
      output: 'xlsx',
      year: year,
      model: defaultModel.id,
      owner: getOwnersParam(),
      courseId: selectedCourse,
      staffId: null,
    });

    exportStore.getAllocationExportByParameters(parameters, true).then((result) => {
      onReportRequestResult(result.success, selectedReport?.title, 'xlsx');
    });
  }, [
    updateReportingStatus,
    selectedReport?.type,
    selectedReport?.title,
    year,
    defaultModel.id,
    getOwnersParam,
    selectedCourse,
    exportStore,
    onReportRequestResult,
  ]);

  const onPDF = useCallback(() => {
    setCourseReporting(true);
    updateReportingStatus(true);

    const parameters = new ReportParameterDto({
      emailed: true,
      type: selectedReport?.type,
      output: 'pdf',
      year: year,
      model: defaultModel.id,
      owner: getOwnersParam(),
      courseId: selectedCourse,
      staffId: null,
    });

    reportStore.getAllocationReportByParameters(parameters, true).then((result) => {
      onReportRequestResult(result.success, selectedReport?.title, 'pdf');
    });
  }, [
    updateReportingStatus,
    selectedReport?.type,
    selectedReport?.title,
    year,
    defaultModel.id,
    getOwnersParam,
    selectedCourse,
    reportStore,
    onReportRequestResult,
  ]);

  const onPageChange = (limit, skip) => {
    this.setState({ paging: { limit, skip } });
    if (this.onValidate(this.state.reportSearch)) {
      this.setState({ spinner: { visible: true, text: 'Loading' } });
      reportSearchStore.getCourseAllocationReport(this.state.reportSearch, limit, skip).then(() => {
        this.setState({ spinner: { visible: false, text: '' } });
      });
    }
  };

  return (
    allowedCourseReportTypes.length > 0 && (
      <CollapsePanel
        expanded={false}
        title='Course and Offering Reports'
        tabIndex={1}
      >
        <div className='allocation-reports'>
          <div className='allocation-reports-row'>
            <div className='export-tip'>
              <span className='k-icon k-i-information'></span> As this export may take some time to
              generate, it will be <span className='emphasise'>emailed</span> to you once completed.
            </div>
          </div>
          <div className='allocation-reports-row'>
            <div className='allocation-reports-column'>
              <div className='item-card'>
                <span className='reports-parameter-label'>Report:</span>
                <Select
                  className='report-drop-down-style'
                  onChange={onReportChange}
                  value={selectedReport?.type}
                  placeholder='Please select'
                  defaultValue={undefined}
                >
                  {allowedCourseReportTypes.map((report) => (
                    <Option
                      key={report.type}
                      value={report.type}
                    >
                      {report.title}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className='item-card'>
                <span className='reports-parameter-label'>Model:</span>
                <Select
                  className='reports-parameter-dropdown'
                  onChange={() => console.log('{this.onCourseModelChange)}')}
                  disabled={true}
                  value={defaultModel.id}
                >
                  {models &&
                    models.map((model) => (
                      <Option
                        key={model.id}
                        value={model.id}
                      >
                        {model.model}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className='item-card'>
                <span className='reports-parameter-label'>Owner:</span>
                <Select
                  className='report-drop-down-style'
                  onChange={onCourseOwnerChange}
                  disabled={courseReporting || !defaultModel}
                  value={selectedOwner}
                  placeholder='Please select'
                >
                  {owners &&
                    owners.map((owner) => (
                      <Option
                        key={owner?.id ?? 'all'}
                        value={owner.id}
                      >
                        {owner.owner}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className='item-card'>
                <span className='reports-parameter-label'>Course:</span>
                <CourseAutoComplete
                  className='reports-parameter'
                  data={courses}
                  onChange={onCourseChange}
                  disabling={
                    reporting ||
                    defaultModel == null ||
                    selectedOwner == null ||
                    selectedReport?.type != 'course_allocation'
                  }
                  course={selectedCourse}
                  placeholder='Please select'
                />
              </div>
            </div>
            <div className='allocation-reports-column'>
              <div className='item-card'>
                <DropListButton
                  text='Generate'
                  items={
                    !selectedReport
                      ? []
                      : selectedReport.formats
                          .filter((f) => selectedCourse || f === 'XLSX')
                          .map((f) => ({
                            text: `Export to ${f}`,
                            icon: f === 'PDF' ? <FilePdfOutlined /> : <FileTextOutlined />,
                            on: () => onGenerateClick(f),
                          }))
                  }
                  enabled={!reporting && defaultModel != null && canReport}
                  spinner={true}
                  spinnerText={'Compiling'}
                  active={reporting && courseReporting}
                  look='outline'
                />
              </div>
            </div>
          </div>
          <MessageLine
            visible={message.visible}
            type={message.type}
            line={message.msg}
          />
          <div>
            <SearchReportGrid
              data={reportSearchStore.courseReportResult}
              onPageChange={onPageChange}
              columns={[
                {
                  field: 'reportName',
                  title: 'Report Name',
                  dataIndex: 'reportName',
                  sorter: (a, b) => a.reportName.localeCompare(b.reportName),
                },
                {
                  field: 'createDate',
                  title: 'Create Date',
                  dataIndex: 'createDate',
                  render: (date) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''),
                  sorter: (a, b) => a.createDate.localeCompare(b.createDate),
                },
              ]}
            />
          </div>
        </div>
      </CollapsePanel>
    )
  );
};

export default inject(
  'authUserStore',
  'courseStore',
  'staffStore',
  'exportStore',
  'reportStore',
  'viewStore'
)(observer(CourseReports));
