import React, { Component } from 'react';

import './search-components.css';
import { Select } from 'antd';

class SearchDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {},
    };
  }

  onChange = (value) => {
    const changeId = this.setId();
    const target = this.props.data.find((data) => data[changeId] === value);
    this.props.onChange({ value: target, props: this.props });
  };

  setId() {
    let id;
    if (this.props.id === 'Unit') {
      id = 'department';
    } else {
      id = this.props.id.toLowerCase().replace(/ /gi, '_');
    }
    return id;
  }

  render() {
    const renderId = this.setId();
    const selectedValue = this.props.value ? this.props.value[renderId] : '';

    return (
      <div className='card-item'>
        <p className='search-sub-title'>{this.props.title ? this.props.title : this.props.id}:</p>
        <Select
          id={renderId}
          onChange={this.onChange}
          className='dropdown-style'
          value={selectedValue}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder ?? ''}
          >
          {this.props.data &&
            this.props.data.map((data) => (
              <Select.Option
                key={data[renderId]}
                value={data[renderId]}
              >
                {data[renderId]}
              </Select.Option>
            ))}
        </Select>
      </div>
    );
  }
}
export default SearchDropdown;
