import React, { Component } from 'react';

import './search-components.css';

class SearchKeyWordsTips extends Component {
  render() {
    return (
      <p className='search-tips'>
        <span className='k-icon k-i-information'></span> Separate each keyword by a{' '}
        <span className='emphasise'>semicolon</span>.
      </p>
    );
  }
}

export default SearchKeyWordsTips;
