// flow

import React, { Component } from 'react';
import { Select } from 'antd';
import './select-box.css';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  data: string[],
  index?: number,
  rebuildState?: boolean,
  rebuildData?: Array,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selected: any,
  fields: {
    id: string,
    display: string,
    value: string | string[],
    key: string,
  },
  prompt: {
    text: string,
    hidden: boolean,
  },
};

class SelectBox extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      rebuildState: this.props.rebuildState,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const result = this.props.data.find(
      (d) => (this.props.fields.id ? d[this.props.fields.id] : d.id) == e
    );
    //ensure we send back null and not undefined if no found.
    const _value = result ? result : null;
    if (this.props.index == undefined) {
      this.setState({ value: _value });
      this.props.onChange(_value);
    } else {
      // For staffMember and workShare which need index
      this.setState({ value: _value });
      this.props.onChange(_value, this.props.index);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data != this.props.data) {
      this.setState({ value: this.props.value });
    }
    if (prevProps.value != this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const options = this.props.data.map((data) => {
      return (
        <Select.Option
          value={data[this.props.fields.value]}
          key={data[this.props.fields.key ? this.props.fields.key : this.props.fields.value]}
        >
          {Array.isArray(this.props.fields.display)
            ? this.props.fields.display.map((d) => data[d]).join(' ')
            : data[this.props.fields.display]}
        </Select.Option>
      );
    });

    return (
      <div>
        {!this.props.rebuildState && (
          <Select
            value={
              this.state.value
                ? this.props.fields.id
                  ? this.state.value[this.props.fields.id]
                  : this.state.value.id
                : ''
            }
            onChange={this.onChange}
            className='select-box'
            disabled={this.props.disabled}
          >
            {this.props.prompt != null && (
              <Select.Option
                className='select-box-option-dufault'
                hidden={this.props.prompt.hidden != null ? this.props.prompt.hidden : true}
                value={''}
              >
                {this.props.prompt.text}
              </Select.Option>
            )}
            {options}
          </Select>
        )}
        {this.props.rebuildState && (
          <Select
            value={
              this.props.rebuildData[this.props.index]
                ? this.props.fields.id
                  ? this.props.rebuildData[this.props.index][this.props.fields.id]
                  : this.props.rebuildData[this.props.index].id
                : ''
            }
            onChange={this.onChange}
            className='select-box'
          >
            {this.props.prompt != null && (
              <Select.Option
                className='select-box-option-dufault'
                hidden={this.props.prompt.hidden != null ? this.props.prompt.hidden : true}
                value={''}
              >
                {this.props.prompt.text}
              </Select.Option>
            )}
            {options}
          </Select>
        )}
      </div>
    );
  }
}

export default SelectBox;
