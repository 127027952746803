import React, { useState, useEffect } from 'react';
import { CollapsePanel, MessageLine, ActionButton } from '../../components';
import { inject, observer } from 'mobx-react';
import ViewManager from '../../utility/view';
import { Select } from 'antd';
import './model.css';

function ManageModelStatus(props) {
  const { model } = props;
  const [modelStatusSelected, setModelStatusSelected] = useState(null);
  const [yearStatus, setyearStatus] = useState(null);
  const [modelUpdateAllowed, setModelUpdateAllowed] = useState(false);
  const [modelUpdating, setModelUpdating] = useState(false);
  const [modelStatuses, setModelStatuses] = useState([]);
  const [message, setMessage] = useState({ visible: false, type: '', msg: '' });

  useEffect(() => {
    const getModelStatuses = async () => {
      const modelManageStore = props.modelManageStore;
      const modelStatusesResponse = await modelManageStore.getModelStatuses();
      if (modelStatusesResponse) {
        setModelStatuses(modelStatusesResponse);
      }
    };
    getModelStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (model.status) {
      setModelStatusSelected(model.status.id);
    }
    if (model.year) {
      setyearStatus(model.year.status.status);
    }
  }, [model]);

  useEffect(() => {
    if (yearStatus === 'Open') {
      setModelUpdateAllowed(true);
    }
  }, [yearStatus]);

  function onModelStatusChange(selectedStatusId) {
    setModelStatusSelected(selectedStatusId);
    if (yearStatus === 'Open') {
      setModelUpdateAllowed(true);
    }
  }

  async function updateModelFunc() {
    const modelManageStore = props.modelManageStore;
    const modelUpdate = { ...model, status: modelStatusSelected };
    const updateStatusResponse = await modelManageStore.setModelStatusUpdate(
      modelUpdate.id,
      modelUpdate
    );
    if (updateStatusResponse != null) {
      ViewManager.setAllocationModel(updateStatusResponse);
      return true;
    } else {
      return false;
    }
  }

  async function onUpdateModel() {
    setModelUpdating(true);
    const response = await updateModelFunc();
    setMessage({
      visible: true,
      type: response ? 'success' : 'error',
      msg: `${response ? 'Successfully updated Model' : 'Failed to update Model'}`,
    });
    setModelUpdating(false);
    setModelUpdateAllowed(false);
    cleanMessage();
    window.location.reload();
  }

  function cleanMessage() {
    setTimeout(() => {
      setMessage({ visible: false, type: '', msg: '' });
    }, 5000);
  }

  return (
    <CollapsePanel
      expanded={false}
      title='Manage Model Status'
      tabIndex={1}
    >
      <div className='management-block'>
        <div className='management-row'>
          <div className='management-column right-seperator'>
            <div className='item-card title'>
              <div className='title-label'>Update</div>
            </div>
            <div className='item-card management-tip'>
              <p>
                <span className={'k-icon k-i-information'}></span> Manage the model status.
              </p>
            </div>
            <div className='item-card'>
              <span className='management-label'>Model:</span>
              <Select
                className='management-dropdown model'
                value={model.id}
                disabled={true}
              >
                <Select.Option
                  key={model.id}
                  value={model.id}
                >
                  {model.model}
                </Select.Option>
              </Select>
            </div>
            <div className='item-card'>
              <span className='management-label'>Status:</span>
              <Select
                className='model-drop-down-style'
                onChange={onModelStatusChange}
                value={modelStatusSelected}
                disabled={!modelUpdateAllowed}
              >
                {modelStatuses &&
                  modelStatuses.map((modelStatus) => (
                    <Select.Option
                      key={modelStatus.id}
                      value={modelStatus.id}
                    >
                      {modelStatus.status}
                    </Select.Option>
                  ))}
              </Select>
            </div>

            <div>
              {yearStatus !== 'Open' && (
                <div className='item-card'>
                  <span className='quick-note status-change'>
                    To update this model status first set the year to Open.
                  </span>
                </div>
              )}
            </div>

            <div className='item-card management-action'>
              <ActionButton
                on={onUpdateModel}
                className='management-button'
                text={'Update'}
                enabled={modelUpdateAllowed && !modelUpdating}
              />
            </div>
          </div>
        </div>
        <MessageLine
          visible={message.visible}
          type={message.type}
          line={message.msg}
        />
      </div>
    </CollapsePanel>
  );
}

export default inject('modelManageStore')(observer(ManageModelStatus));
