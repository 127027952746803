import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from '../ui';
import { Button, Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import { PermissionsHelper } from '../../utility';
import { useHistory } from 'react-router-dom';

const YearModelSelector = (props) => {
  const { showYearEditor, onYearEditClose, isCancellable = true } = props;

  const allocationYear = props.viewStore.year;

  const userIsBPO = PermissionsHelper.userHasRole('bpo', props.authUserStore.currentUser);

  const [selectedYear, setSelectedYear] = useState(allocationYear);
  const [selectedModel, setSelectedModel] = useState(null);
  const [modelOptions, setModelOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [canSave, setCanSave] = useState(false);
  const [hasDefaultModel, setHasDefaultModel] = useState(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [cancellable, setCancellable] = useState(isCancellable);

  const history = useHistory();

  useEffect(() => {
    const getYears = async() => {
      const years = await props.viewStore.getYears();
      setYearOptions(years);
    };
    const getYear = async() => {
      const year = await props.viewStore.getCurrentYear();
      setSelectedYear(year);
    };
    getYears();
    getYear();
  }, [props.viewStore]);

  useEffect(() => {
    const handleYearChange = async() => {
      await getModelOptionsForUser();
    };

    if (selectedYear) {
      handleYearChange();
    }
  }, [getModelOptionsForUser, selectedYear]);

  const findDefaultSelectedModel = (model) => {
    const { model: currentModel } = props.viewStore;

    if (selectedModel) {
      return model.code === selectedModel.code;
    }
    return model.code === currentModel.code;
  };

  const getModelOptionsForUser = useCallback(async() => {
    setCanSave(false);
    if (userIsBPO) {
      const models = await props.dictionary.getModels(selectedYear.year);
      const newModelForSelect = models?.find(findDefaultSelectedModel);
      setCanSave(true);
      setModelOptions(models);
      setSelectedModel(newModelForSelect);
      if (newModelForSelect != null) {
        setHasDefaultModel(true);
      } else {
        setHasDefaultModel(false);
      }
    } else {
      const userModelGroup = await props.viewStore.getUserModel(
        props.authUserStore.currentUser.id,
        selectedYear.year
      );
      if (!userModelGroup) {
        setCanSave(false);
        setModelOptions([]);
        setSelectedModel(null);
      } else {
        const { models } = userModelGroup;
        const newModelForSelect = models?.find(findDefaultSelectedModel);
        setCanSave(true);
        setModelOptions(userModelGroup.models);
        setSelectedModel(newModelForSelect);
        if (newModelForSelect != null) {
          setHasDefaultModel(true);
        } else {
          setHasDefaultModel(false);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.authUserStore.currentUser.id,
    props.dictionary,
    props.viewStore,
    selectedModel?.code,
    selectedYear.year,
    userIsBPO,
  ]);

  const handleCancel = () => {
    onYearEditClose();
  };

  const handleYearEditChange = async(value) => {
    setSelectedYear(value);
  };

  const handleYearEditCommit = async() => {
    const success = await props.viewStore.updateYearAndModel(selectedYear, selectedModel);
    if (success) {
      onYearEditClose();
      history.push('/dashboard');
    }
  };

  const handleModelChange = (value) => {
    if (value != null) {
      setSelectedModel(value);
      setCanSave(true);
      setHasDefaultModel(true);
    }
  };

  const getCanChangeYear = () => {
    return canSave;
  };

  const getCanChangeModel = () => {
    return canSave && userIsBPO;
  };

  return (
    <div className='allocation-year-editor-frame'>
      <Modal
        width={'auto'}
        open={showYearEditor}
        title={'Select Year and/or Model'}
        centered
        maskClosable={cancellable}
        closable={cancellable}
        onCancel={handleCancel}
        footer={null}
      >
        <div className='allocation-year-editor-dialog'>
          <form className='form-inline'>
            <div className='item-card'>
              <Dropdown
                title={'Year'}
                value={selectedYear}
                data={yearOptions}
                fields={{
                  id: 'year',
                  key: 'year',
                  value: 'year',
                  display: 'year',
                }}
                onChange={handleYearEditChange}
                prompt={{
                  text: 'Please Select',
                  hidden: true,
                }}
                disabled={!getCanChangeYear()}
              />

              <label style={{ flexDirection: 'column' }}>
                <div className='k-form-field'>
                  <span>Status:</span>
                  <span className={`allocaton-year-status ${selectedYear.status.code}`}>
                    {selectedYear.status.status}
                  </span>
                </div>
              </label>
            </div>
            <div className='item-card'>
              <Dropdown
                className='model-dropdown'
                title={'Model'}
                value={selectedModel}
                data={modelOptions}
                fields={{
                  id: 'id',
                  key: 'id',
                  value: 'id',
                  display: 'model',
                }}
                onChange={handleModelChange}
                disabled={!getCanChangeModel()}
                prompt={{
                  text: 'Please Select',
                  hidden: true,
                }}
              />
            </div>
            <div className='form-buttons-frame'>
              <Button
                type='submit'
                onClick={handleYearEditCommit}
                look='outline'
                className='header-button save'
                disabled={!canSave || !hasDefaultModel}
              >
                Save
              </Button>
              <Button
                type='button'
                onClick={handleCancel}
                look='outline'
                className='header-button cancel'
                disabled={!cancellable}
              >
                Cancel
              </Button>           
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default inject('authUserStore', 'viewStore', 'dictionary')(observer(YearModelSelector));
