// @flow

import React, { Component } from 'react';


import './state-bar.css';

type Props = {
    title: string,
    info: string,
    children: any,
}

class StateBarDetails extends Component<Props> {

    render() {
        return (
            <div className="state-bar-details">
                <div className="state-bar-title">
                    <span className="state-bar-title-key">{this.props.title}</span><span className="state-bar-title-value">{this.props.info}</span>
                </div>
                <div className="state-bar-body">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default StateBarDetails;
