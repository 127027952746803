// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header, BodyLayout, Spinner } from '../../components';
import DashboardOptions from './dashboard-options';
import './dashboard.css';

const OPTIONS = [
  { url: 'course', title: 'Course and Offering Allocations' },
  { url: 'staff', title: 'Staff Allocations' },
  { url: 'report', title: 'Reports' },
  { url: 'model', title: 'Model Management' },
  { url: 'admin', title: 'Admin Menu' },
];

type State = {
  loading: Boolean,
  refresh: Boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
class Dashboard extends Component<State> {
  constructor(props) {
    super(props);

    this.authUserStore = props.authUserStore;
    this.viewStore = props.viewStore;

    this.state = {
      loading: true,
      refresh: false
    };
  }

  async componentDidMount() {
    this.setState({ loading: false });
  }

  setRefresh = () => {
    this.setState(prevState => {
      return { refresh: !prevState.refresh };
    });
  }

  render() {
    return this.state.loading ? (
      <Spinner text={'Loading...'} />
    ) : (
      <div>
        <Header navState={''} setRefresh={this.setRefresh}/>

        <BodyLayout>
          <div className='dashboard-frame'>
            <span className='dashboard-option-prompt'>Please choose from the following:</span>
            <div>
              {OPTIONS.map((value) => (
                <DashboardOptions
                  key={value.url}
                  url={value.url}
                  title={value.title}
                  refresh={this.state.refresh}
                />
              ))}
            </div>
          </div>
        </BodyLayout>
      </div>
    );
  }
}

export default inject('authUserStore', 'viewStore')(observer(Dashboard));
