//@flow
import Api from './api';
import { makeAutoObservable } from 'mobx';
import FileDownload from 'js-file-download';
import { ReportParameterDto } from '../dto';

export default class ReportStore {
  constructor() {
    makeAutoObservable(this);
  }

  async getDownload(params: { id: string, userId: string }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      Api.blobPost(`/reports/download`, { id: params.id, user: params.userId })
        .then((data) => {
          const blob = new Blob([data.blob], { type: data.type });
          FileDownload(blob, data.fileName, data.mine);
          resolve({ success: true });
        })
        .catch((e) => {
          if(e.response?.status === 404) {
            e.message = 'User not authorized to view this download or the download does not exist';
          }
          reject({ success: false, error: e, message: e.message});
        });
    });
  }

  async getStaffAllocationSummaryReport(id: string, year: number, emailed = false) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobGet(`/reports/${year}/staff/${id}`)
          .then((data) => {
            const blob = new Blob([data.blob], { type: data.type });
            FileDownload(blob, data.fileName, data.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.get(`/reports/${year}/staff/${id}?emailed=true`).then(() => {
          resolve({ success: true });
        });
      }
    });
  }

  async getStaffAllocationReport(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { criteria: any, ids: string[] },
    year: number,
    emailed = false
  ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobPost(`/reports/${year}/summary`, data)
          .then((response) => {
            const blob = new Blob([response.blob], { type: response.type });
            FileDownload(blob, response.fileName, response.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.post(`/reports/${year}/staff/summary?emailed=true`, data).then(() => {
          resolve({ success: true });
        });
      }
    });
  }

  async getCourseAllocationReport(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { criteria: any, id: string },
    year: number,
    emailed = false
  ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobPost(`/reports/${year}/course/id/${data.id}`, data.criteria || {})
          .then((response) => {
            const blob = new Blob([response.blob], { type: response.type });
            FileDownload(blob, response.fileName, response.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.post(`/reports/${year}/course/id/${data.id}?emailed=true`, data.criteria || {}).then(
          () => {
            resolve({ success: true });
          }
        );
      }
    });
  }

  async getCourseIdAllocationReport(id: string, year: number, emailed = false) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobGet(`/reports/${year}/course/${id}`)
          .then((data) => {
            const blob = new Blob([data.blob], { type: data.type });
            FileDownload(blob, data.fileName, data.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.get(`/reports/${year}/course/${id}?emailed=true`).then(() => {
          resolve({ success: true });
        });
      }
    });
  }

  async getCourseAllocationSummaryReport(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { criteria: any, ids: string[] },
    year: number,
    emailed = false
  ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobPost(`/reports/${year}/course/summary`, data)
          .then((response) => {
            const blob = new Blob([response.blob], { type: response.type });
            FileDownload(blob, response.fileName, response.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.post(`/reports/${year}/course/summary?emailed=true`, data).then(() => {
          resolve({ success: true });
        });
      }
    });
  }

  async getAllocationReportByParameters(params: ReportParameterDto, emailed = false) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobPost(`/reports/${params.year}/parameters`, params)
          .then((data) => {
            const blob = new Blob([data.blob], { type: data.type });
            FileDownload(blob, data.fileName, data.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.post(`/reports/${params.year}/parameters`, params).then(() => {
          resolve({ success: true });
        });
      }
    });
  }
}
