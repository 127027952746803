// @flow

import { Table } from 'antd';
import React, { Component } from 'react';
import {
  DropListButton,
  ExportButton,
  PrintButton,
  TableCommandCellEditDelete,
  TableCommandCellView,
} from '..';

import { SortDto } from '../../dto';
import { PageSettingDto } from '../../dto/clientDto';

import { FileTextOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { PermissionsHelper } from '../../utility';
import './search-components.css';
import { AuthUserStore, ViewStore } from '../../mobx';
import { objCompare } from '../../utility/object';

type Props = {
  viewStore: ViewStore,
  authUserStore: AuthUserStore,
  data: Record<string, string>[],
  onEdit: () => void,
  onSort: () => void,
  onDelete: () => void,
  columns: Array<{
    field: string,
    title: string,
    width: string,
  }>,
  print: () => void,
  sortable: boolean,
  printing: boolean,
  printable: boolean,
  export: () => void,
  exporting: boolean,
  exportable: boolean,
  canGenerateAllocationReport: boolean,
  readOnly: boolean,
  onPageChange: () => void,
  sorting: SortDto[],
};

type State = {
  pageSetting: PageSettingDto,
  sort: SortDto[],
  current: number,
};

class SearchResultGrid extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.authUserStore = this.props.authUserStore;
    this.viewStore = this.props.viewStore;

    this.state = {
      pageSetting: {
        items: [],
      },
    };
  }

  get PdfEnabled() {
    return true;
  }

  componentDidMount() {
    const data = this.props?.data;
    if (typeof data.total === 'undefined') return;
    this.setState({ ...this.createState(data.skip, data.limit) });
  }

  componentDidUpdate(prevProps?: Record<string, string>) {
    const data = this.props?.data;
    if (typeof data.total === 'undefined') return;
    if (!objCompare(prevProps.data, data)) {
      this.setState({ ...this.createState(data.skip, data.limit) });
    }
  }

  createState = (skip: number, take: number) => {
    const { data, total, length } = this.props.data;
    return {
      pageSetting: {
        items: data,
        total: total ?? length ?? 0,
        skip,
        pageSize: take,
      },
      current: skip && take ? skip / take + 1 : 1,
    };
  };

  onChange = (pagination, sorter, action) => {
    if (action === 'paginate') {
      this.onPaginate(pagination.current, pagination.pageSize);
    }
    if (action === 'sort') {
      this.onColumnSort(sorter);
    }
  };

  onPaginate = (page, pageSize) => {
    const skip = pageSize * (page - 1);
    this.setState({
      ...this.createState(skip, pageSize),
      current: page,
    });
    this.props.onPageChange(pageSize, skip);
  };

  onColumnSort = (sorter, init = false) => {
    let sort;
    if (Array.isArray(sorter)) {
      sort =
        sorter?.map((s) => {
          return {
            field: s.column.field,
            dir: s.order === 'ascend' ? 'asc' : s.order === 'descend' ? 'desc' : '',
          };
        }) || [];
    } else {
      sort = [
        {
          field: sorter.column?.field ?? '',
          dir: sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : '',
        },
      ];
    }

    if (this.props.onSort) {
      this.props.onSort(sort, this.state.pageSetting.pageSize, this.state.pageSetting.skip);
    }
  };

  onEdit(data) {
    this.props.onEdit(data);
  }

  onDelete(data) {
    this.props.onDelete(data, this.state.pageSetting);
  }

  render() {
    const tableColumns = this.props.columns.concat({
      title: '',
      key: 'actions',
      width: '140px',
      render: (text, record) =>
        PermissionsHelper.isRestrictedAccess({
          user: this.authUserStore.currentUser,
          model: this.viewStore.model,
          year: this.viewStore.year,
          allowedRoles: ['bpo', 'mm', 'wm'],
        }) ||
        record.readOnly === true ||
        record.readOnly == null ? (
          <TableCommandCellView
            view={this.onEdit.bind(this)}
            dataItem={record}
          />
        ) : (
          <TableCommandCellEditDelete
            onEdit={this.onEdit.bind(this)}
            onDelete={
              PermissionsHelper.userHasRole(['bpo', 'mm'], this.authUserStore.currentUser)
                ? this.onDelete.bind(this)
                : null
            }
            dataItem={record}
          />
        ),
    });

    const {
      pageSetting: { items, pageSize, total },
      current,
    } = this.state;

    this.props?.sorting?.forEach((sort) => {
      const sortField = sort.field;
      const sortColumn = tableColumns.find((column) => column.field === sortField);
      if (sortColumn) {
        sortColumn.defaultSortOrder = sort.dir === 'asc' ? 'ascend' : 'descend';
      }
    });

    return (
      <div className='card'>
        <div className='card-body'>
          <div className='search-results-header'>
            <div className='search-title'>
              <p>Search Results</p>
            </div>
            {this.props.export != null &&
              this.props.print == null &&
              this.state.pageSetting.total > 0 && (
                <ExportButton
                  on={this.props.export}
                  exporting={this.props.exporting}
                  enabled={
                    !this.props.exporting &&
                    (this.props.exportable || this.props.exportable == null)
                  }
                  text={'Export Summary'}
                  spinner={true}
                />
              )}
            {this.props.print != null &&
              this.props.export == null &&
              this.state.pageSetting.total > 0 && (
                <PrintButton
                  on={this.props.print}
                  printing={this.props.printing}
                  enabled={
                    !this.props.printing && (this.props.printable || this.props.printable == null)
                  }
                  text={'Print Summary'}
                  spinner={true}
                  spinnerText={'Compiling Report'}
                />
              )}
            {this.props.print != null &&
              this.props.export != null &&
              this.props.canGenerateAllocationReport &&
              this.state.pageSetting.total > 0 && (
                <DropListButton
                  text='Generate Report'
                  items={[
                    {
                      text: 'Export to XLSX',
                      icon: <FileTextOutlined />,
                      on: this.props.export,
                    },
                  ]}
                  enabled={
                    (!this.props.printing &&
                      (this.props.printable || this.props.printable == null)) ||
                    (!this.props.exporting &&
                      (this.props.exportable || this.props.exportable == null))
                  }
                  spinner={true}
                  spinnerText={'Compiling Report'}
                  active={this.props.exporting || this.props.printing}
                  look='outline'
                />
              )}
          </div>
          <div className='search-results-grid'>
            <div className='grid-frame'>
              <Table
                columns={tableColumns}
                rowKey={(record) => record.id}
                dataSource={items}
                bordered={true}
                size='small'
                tableLayout='fixed'
                pagination={{
                  current,
                  defaultCurrent: 1,
                  defaultPageSize: 25,
                  pageSize: pageSize,
                  pageSizeOptions: [10, 20, 25, 50, 100],
                  position: ['bottomLeft'],
                  showSizeChanger: true,
                  total: total,
                  hideOnSinglePage: total < 10,
                }}
                onChange={(pagination, filters, sorter, extra) => {
                  this.onChange(pagination, sorter, extra.action);
                }}
                showSorterTooltip={this.props.sortable ? true : false}
                sortDirections={['ascend', 'descend']}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('authUserStore', 'viewStore')(observer(SearchResultGrid));
