import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import {
  AuthUserStore,
  NavStore,
  ViewStore,
  DictionaryStore,
  VersionStore,
  ReportSearchStore,
  ActivityStore,
  AllocationStore,
  CourseStore,
  OfferingStore,
  SearchStore,
  UserStore,
  YearStore,
  ImportStore,
  UploadStore,
  ModelManageStore,
  RolloverStore,
  StaffStore,
  ReportStore,
  ExportStore,
  ScheduledReportingStore,
} from './mobx';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const navStore = new NavStore();
const authUserStore = new AuthUserStore();
const viewStore = new ViewStore();
const dictionary = new DictionaryStore();
const versionStore = new VersionStore();
const reportSearchStore = new ReportSearchStore();
const activityStore = new ActivityStore();
const allocationStore = new AllocationStore();
const courseStore = new CourseStore();
const offeringStore = new OfferingStore();
const searchStore = new SearchStore();
const userStore = new UserStore();
const yearStore = new YearStore();
const importStore = new ImportStore();
const uploadStore = new UploadStore();
const modelManageStore = new ModelManageStore();
const rolloverStore = new RolloverStore();
const staffStore = new StaffStore();
const reportStore = new ReportStore();
const exportStore = new ExportStore();
const scheduling = new ScheduledReportingStore();

const watStores = {
  authUserStore,
  navStore,
  viewStore,
  dictionary,
  versionStore,
  reportSearchStore,
  activityStore,
  allocationStore,
  courseStore,
  offeringStore,
  searchStore,
  userStore,
  yearStore,
  importStore,
  uploadStore,
  modelManageStore,
  rolloverStore,
  staffStore,
  reportStore,
  exportStore,
  scheduling,
};

ReactDOM.render(
  <div>
    <MsalProvider instance={msalInstance}>
      <Provider {...watStores}>
        <HashRouter basename='/'>
          <App />
        </HashRouter>
      </Provider>
    </MsalProvider>
  </div>,
  document.getElementById('root')
);
registerServiceWorker();
