import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SelectBox, SpinnerBasic } from '..';
import './drop-down.css';

type Props = {
  title: string,
  value: any,
  data: any[],
  onChange: Function,
  disabled: boolean,
  category: string,
  fields: {
    id: string,
    display: string,
    value: string | string[],
    key: string,
  },
  prompt: {
    text: string,
    hidden: boolean,
  },
  link: {
    path: string,
  },
};

type State = {
  value: any,
  data: any,
  loading: boolean,
};

class Dropdown extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      data: this.props.data,
      loading: this.props.loading != null ? this.props.loading : false,
    };
  }

  onChange = (value) => {
    this.props.onChange(value);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loading != this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  render() {
    return (
      <label style={{ flexDirection: 'column' }}>
        <div className='dropdown-form-field k-form-field '>
          <span>
            {this.props.title}:{this.state.loading && <SpinnerBasic />}
            {this.props.link && this.props.link.path && (
              <Link
                className='linked-allocation-view'
                to={this.props.link.path}
              >
                <span className='k-icon k-i-hyperlink-open-sm'></span>
              </Link>
            )}
          </span>
          <SelectBox
            value={this.props.value}
            data={this.props.data}
            onChange={this.onChange}
            fields={this.props.fields}
            disabled={this.props.disabled}
            prompt={this.props.prompt}
          />
        </div>
      </label>
    );
  }
}

export default Dropdown;
