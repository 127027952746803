//@flow
import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { version } from '../utility/version';

const ApiHostUrl = process.env.REACT_APP_API_HOST;
const ReportingHostUrl = process.env.REACT_APP_REPORTING_HOST;

export default class VersionStore {
  reportVersion = null;

  constructor() {
    this.initData();
    makeAutoObservable(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  initData = async () => {};

  getWeb() {
    return version;
  }

  async getApi() {
    return new Promise((resolve, reject) => {
      axios
        .get(ApiHostUrl + '/service/version', {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getReporting(refresh = false) {
    return new Promise((resolve, reject) => {
      if (this.reportVersion == null || refresh) {
        axios
          .get(ReportingHostUrl + '/service/version', {})
          .then((response) => {
            this.reportVersion = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(this.reportVersion);
      }
    });
  }
}
