import { Spin } from 'antd';
import React, { Component } from 'react';

import './spinner.css';

class SpinnerBasic extends Component {
  render() {
    return <Spin />;
  }
}

export default SpinnerBasic;
