import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function TableActionCellStaff({ staff, inDifferentUnit }) {
  const location = useLocation();

  return (
    <>
      {!inDifferentUnit ? (
        <Link
          className='grid-link'
          to={{
            pathname: `/staff/${staff.id}`,
            state: { staffId: staff.id, prevLocation: location.pathname },
          }}
        >
          {staff.displayName}
        </Link>
      ) : (
        <p>{staff.displayName}</p>
      )}
    </>
  );
}

export default TableActionCellStaff;
