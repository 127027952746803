import React, { Component } from 'react';
import { Button, Select } from 'antd';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line no-undef
import './search-components.css';

class SearchUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedUsers: [],
    };
  }

  async componentDidMount() {
    const { userStore } = this.props;
    if (this.props.modelId == null) {
      await userStore.getBPOUsers();
    } else {
      await userStore.getAllUsersByModel(this.props.modelId);
    }
    const sortedUsers = userStore.allUserList
      ? userStore.allUserList.slice().sort(function (a, b) {
          return a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0;
        })
      : [];

    this.setState({
      sortedUsers,
    });
  }

  onKeyWordsChange = (tags) => {
    this.props.onChange(tags);
  };

  setSelectedItems = (values) => {
    this.props.onChange(values);
  };

  onUserCodeSearch = () => {
    this.props.onSearch();
  };

  render() {
    const filteredOptions = this.state.sortedUsers.filter((o) => !this.props.value?.includes(o));
    return (
      <div className='search-keywords-tags-input card-item'>
        <p className='search-sub-title'>User:</p>
        <Select
          allowClear
          mode='tags'
          disabled={this.props.disabled}
          placeholder='Select user'
          onChange={this.onKeyWordsChange}
          style={selectStyle}
          filterOption={(inputValue, option) =>
            option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
          }
          showSearch
          value={this.props.value}
        >
          {filteredOptions.map((option) => (
            <Select.Option key={option.id}>
              {option.firstName} {option.surname}
            </Select.Option>
          ))}
        </Select>
        <Button
          className='search-button'
          primary='true'
          look='outline'
          onClick={this.onUserCodeSearch}
        >
          Search
        </Button>
      </div>
    );
  }
}
const selectStyle = { width: '300px', height: '35px', borderRadius: 3, borderColor: '#bbc2cc' };

export default inject('userStore')(observer(SearchUser));
