import { LogLevel } from '@azure/msal-browser';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
// Only needed if you need to support the redirect flow in Firefox incognito
const isFirefox = firefox > 0;

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    // eslint-disable-next-line no-undef
    clientId: process.env.REACT_APP_WEB_CLIENT_ID,
    // eslint-disable-next-line no-undef
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
// By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
};

// Add here the endpoints and scopes for the web API you would like to use.
export const apiConfig = {
  // eslint-disable-next-line no-undef
  uri: process.env.REACT_APP_API_HOST,
  // eslint-disable-next-line no-undef
  scopes: [`api://${process.env.REACT_APP_API_CLIENT_ID}/access_as_user`],
};

// Scopes you add here will be used to request a token from Azure AD to be used for accessing a protected resource.
export const tokenRequest = {
  scopes: [...apiConfig.scopes],
};
