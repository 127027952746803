// @flow

import React, { Component } from 'react';
import { observer } from 'mobx-react';

import './upload-drop.css';


type Props = {
    file: File,
    onAdd: Function,
    onRemove: Function,
    note: string,
    hint: string,
    height: Number,
    width: Number,
    className: string,
    uploadIcon: string,
    removeIcon: String,
    allows: string[],
    disabled: boolean,
    progress: number,
    maxSize: number,
    result: { success: boolean, message: string, viewable: boolean },
}

class UploadDrop extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = { validation: this.check(this.props.file), file: this.props.file, progress: this.props.progress || 0, result: this.props.result, viewresult: false }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.progress != this.props.progress) {
            this.setState({ progress: this.props.progress });
        }

        if (prevProps.result != this.props.result) {
            this.setState({ result: this.props.result });
        }

        if (prevProps.file != this.props.file && this.props.file == null && prevState.file == this.state.file) {
            this.setState({ validation: { allowed: true }, file: null, progress: 0, result: null, viewresult: false });
        }
    }

    check = (file) => {
        if (file) {
            if (this.props.allows) {
                if (!this.props.allows.includes((file.name.split('.').pop() || '').trim().toLowerCase())) {
                    return { allowed: false, message: 'Invalid File Type' };
                }
            }
            if (this.props.maxSize) {
                if (file.size > this.props.maxSize) {
                    return { allowed: false, message: `File size exceeds ${this.formatBytes(this.props.maxSize)}` };
                }
            }
        }
        return { allowed: true, message: null };
    }

    onClick = (event) => {
        this.fileRef.value = null;
        this.fileRef.click();
    }

    onFileChange = (event) => {
        const file = event.target.files[0];
        const validation = this.check(file);
        this.setState({ file, validation, progress: 0, result: null, viewresult: false });
        if (this.props.onAdd) {
            this.props.onAdd(validation.allowed ? file : null);
        }
    }

    onDrop = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.dropRef.classList.remove('over');

        const file = event.dataTransfer.files[0];
        const validation = this.check(file);
        this.setState({ file, validation });
        if (this.props.onAdd) {
            this.props.onAdd(validation.allowed ? file : null);
        }
    }

    onDragEnter = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.dropRef.classList.add('over');
        event.dataTransfer.dropEffect = 'copy';
    }

    onDragLeave = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.dropRef.classList.remove('over');
    }

    onDragOver = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.dropRef.classList.add('over');
        event.dataTransfer.dropEffect = 'copy';
    }

    onRemove = () => {
        this.setState({ file: null, validation: { allowed: true }, progress: 0, result: null, viewresult: false });
        this.fileRef.value = null;
        if (this.props.onRemove) {
            this.props.onRemove();
        }
    }

    onToggleResult = () => {
        this.setState({ viewresult: !this.state.viewresult });
    }

    formatBytes = (bytes, decimals = 2) => {
        if (bytes == 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    progressText = () => {
        return this.state.result && !this.state.result.success ? 'Failed' : this.state.progress >= 100 ? 'Uploaded' : this.state.progress > 0 ? 'Uploading' : 'Pending';
    }


    render() {

        const styling = {
            width: this.props.width ? `${this.props.width}px` : '400px',
            height: this.props.height ? `${this.props.height}px` : '100px'
        };

        return (
            <div className={`upload-drop${this.props.className ? ` ${this.props.className}` : ''}`}>
                <div className="upload-drop-area" ref={ref => this.dropRef = ref} style={styling}
                    onClick={this.onClick}
                    onDragOver={this.onDragOver}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}

                    onDrop={this.onDrop}
                >
                    <input type="file" ref={ref => this.fileRef = ref} onChange={this.onFileChange} className="upload-drop-input" />
                    <div className="upload-drop-inner">
                        {this.props.uploadIcon && <span className={`upload-drop-icon ${this.props.uploadIcon}`}></span>}
                        {this.props.hint && <span className="upload-drop-hint">{this.props.hint}</span>}
                        {this.props.note && <span className="upload-drop-note">{this.props.note}</span>}
                        {!this.state.validation.allowed && <div className="upload-valid">{this.state.validation.message}</div>}
                    </div>
                </div>

                {this.state.file != null &&
                    <div>
                        <div className="upload-details" style={{ width: styling.width }}>
                            <div className="upload-detail-lines">
                                <div className="upload-detail-line upload-name">{this.state.file.name}</div>
                                <div className="upload-detail-line upload-size">{this.formatBytes(this.state.file.size)}</div>
                            </div>
                            <div className={`upload-remove${!this.state.validation.allowed || (this.state.result && !this.state.result.success) ? ' error' : ''}`} title={'Remove'} onClick={this.onRemove}>
                                {this.props.removeIcon ? <span className={`upload-remove-icon ${this.props.removeIcon}`}></span> : 'x'}
                            </div>
                        </div>
                        <div>
                            {this.state.validation.allowed &&
                                <div className={`upload-progress${this.state.result ? (!this.state.result.success ? ' upload-error' : ' upload-success') : ''}`}>
                                    <div className="upload-progress-status">
                                        <span className="progress-status-note">{this.progressText()}</span>
                                        <span className="progress-status-precentage">{`${this.state.progress}%`}</span>
                                    </div>
                                    <div className="upload-progress-state">
                                        <div className="upload-progress-line" style={{ width: `${this.state.progress}%` }}></div>
                                    </div>
                                </div>
                            }
                            {(this.state.validation.allowed && (this.state.result && this.state.result.viewable)) &&
                                <div className="upload-result-outer" style={{ width: styling.width }}>
                                    <div className={`upload-result-link ${this.state.result.success ? 'success' : 'error'}`}>
                                        <span className="result-link" onClick={this.onToggleResult}>{this.state.viewresult ? 'Hide' : 'View'} {this.state.result.success ? 'Result' : 'Error'}</span>
                                    </div>
                                    {this.state.viewresult &&
                                        <div className="upload-result-content">{this.state.result.message}</div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default observer(UploadDrop);
