import React, { useCallback, useEffect, useState } from 'react';

import { CollapsePanel, MessageLine, DropListButton, SearchReportGrid } from '../../components';
import { PermissionsHelper, StringUtility } from '../../utility';
import { Select } from 'antd';
import { FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import { AuthenticatedUserDto, ExportParameterDto, ReportParameterDto, StaffDto } from '../../dto';
import { inject, observer } from 'mobx-react';
import { StaffAutoComplete } from '../../components';
import { StaffStore } from '../../mobx';
import moment from 'moment';

const staffReportTypes = [
  {
    title: 'Staff Workload Allocation',
    type: 'staff_allocation',
    formats: ['XLSX', 'PDF'],
    userPermissions: [
      {
        userRole: 'bpo',
        openModelStates: 'all',
        closedModelStates: 'all',
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'mm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'wm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
    ],
  },
  {
    title: 'Staff Data Validation',
    type: 'staff_data_validation',
    formats: ['XLSX'],
    userPermissions: [
      {
        userRole: 'bpo',
        openModelStates: 'all',
        closedModelStates: 'all',
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'mm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
    ],
  },
  {
    title: 'Staff Bulk Review',
    type: 'staff_bulk_review',
    formats: ['XLSX'],
    userPermissions: [
      {
        userRole: 'bpo',
        openModelStates: 'all',
        closedModelStates: 'all',
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'mm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'wm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
    ],
  },
  {
    title: 'Staff Change',
    type: 'staff_change_report',
    formats: ['XLSX'],
    userPermissions: [
      {
        userRole: 'bpo',
        openModelStates: 'all',
        closedModelStates: 'all',
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'mm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
      {
        userRole: 'wm',
        openModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        closedModelStates: ['open4review', 'validation', 'open4discussion', 'verified'],
        rolloverModelStates: [],
        singleRecordsOnly: false,
      },
    ],
  },
];

const StaffReports = (props) => {
  const { Option } = Select;
  const {
    defaultModel,
    departments,
    models,
    onReportResult,
    reporting,
    reportSearchStore,
    updateReportingStatus,
    updateSpinner,
    year,
    currentUserId,
    authUserStore,
    exportStore,
    reportStore,
    staffStore,
    viewStore,
  } = props;

  const [selectedStaffReport, setSelectedStaffReport] = useState(null);
  const [selectedStaffDepartment, setSelectedStaffDepartment] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [staffs, setStaffs] = useState(null);
  const [staffReporting, setStaffReporting] = useState(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [paging, setPaging] = useState({ limit: 10, skip: 0 });
  const [message, setMessage] = useState({ visible: false, type: 'success', text: '' });
  const [canReport, setCanReport] = useState(false);

  const allowedStaffReportTypes = staffReportTypes.filter((staffReportType) => {
    const { year, model } = viewStore;
    const { userPermissions } = staffReportType;
    const user = authUserStore.currentUser;

    return PermissionsHelper.reportIsAllowed({ userPermissions, year, model, user });
  });

  useEffect(() => {
    if (message.visible) {
      setTimeout(() => {
        setMessage({ visible: false, type: 'success', text: '' });
      }, 5000);
    }
  }, [message]);

  useEffect(() => {
    setCanReport(canRunReport());
  }, [selectedStaffReport, selectedStaffDepartment, selectedStaff]);

  const getReportSelected = (type) => {
    return allowedStaffReportTypes.find((r) => r.type === type);
  };

  const getUserPermissionsByUser = (userPermissions, user) => {
    return userPermissions.find((userPermission) =>
      PermissionsHelper.userHasRole(userPermission.userRole, user)
    );
  };

  const canRunReport = () => {
    const reportTypeIsEmpty = selectedStaffReport?.type == null;
    const departmentIsEmpty = selectedStaffDepartment == null;
    const staffIsEmpty = selectedStaff == null;

    if (reportTypeIsEmpty) {
      return false;
    }

    if (departmentIsEmpty) {
      return false;
    }

    const { currentUser: user } = authUserStore;
    const { userPermissions } = getReportSelected(selectedStaffReport?.type);
    const { singleRecordsOnly } = getUserPermissionsByUser(userPermissions, user);

    if (staffIsEmpty && singleRecordsOnly) {
      return false;
    }
    return true;
  };

  const onReportTypeChange = (value) => {
    const reportSelected = allowedStaffReportTypes.find((r) => r.type === value);
    setSelectedStaffReport(reportSelected);
    setSelectedStaff(null);
  };

  const getStaffByDepartmentCode = async (departmentCode) => {
    const departmentCodes = [];

    if (departmentCode) {
      departmentCodes.push(departmentCode);
    } else {
      const allDepartmentCodes = departments.filter((d) => d.code).map((d) => d.code);
      departmentCodes.push(...allDepartmentCodes);
    }

    return await staffStore.getStaffsByDepartmentCodes({ departmentCodes, year });
  };

  const addUniqueStaffToArray = (staffArray: StaffDto[]) => {
    return (newStaff: StaffDto) => {
      if (!staffArray.find((staffFromArray) => staffFromArray.id === newStaff.id)) {
        staffArray.push(newStaff);
      }
    };
  };

  const getAllowedStaffByDepartmentCode = async (selectedDepartmentCode) => {
    const authUser: AuthenticatedUserDto = authUserStore.currentUser;

    if (PermissionsHelper.userHasRole(['bpo', 'mm'], authUser)) {
      return await getStaffByDepartmentCode(selectedDepartmentCode);
    }

    if (PermissionsHelper.userHasRole('wm', authUser)) {
      const WMUserModelGroups = authUser.userModelGroups.filter(
        (umg) => umg.userGroupCode === 'wm'
      );
      const hasWMPermissionForDepartment = WMUserModelGroups.some(
        (umg) => umg.departmentCode === selectedDepartmentCode
      );

      if (hasWMPermissionForDepartment) {
        return await getStaffByDepartmentCode(selectedDepartmentCode);
      }
    }

    return [];
  };

  const getAllowedStaffForAllDepartments = async () => {
    const authUser = authUserStore.currentUser;

    if (PermissionsHelper.userHasRole(['bpo', 'mm'], authUser)) {
      // Leaving departmendCode as undefined, it will interpret this as being "All Units"
      return await getStaffByDepartmentCode();
    }

    const allowedStaff = [];
    for (const department of departments) {
      const allowedStaffRecordsFromDepartment = await getAllowedStaffByDepartmentCode(
        department.code
      );
      allowedStaffRecordsFromDepartment.forEach(addUniqueStaffToArray(allowedStaff));
    }
    return allowedStaff;
  };

  const getAllowedStaff = async (selectedDepartment) => {
    if (selectedDepartment === 'all') {
      return await getAllowedStaffForAllDepartments();
    }

    const selectedDepartmentCode = departments.find((d) => d.id === selectedDepartment)?.code;

    return await getAllowedStaffByDepartmentCode(selectedDepartmentCode);
  };

  const onStaffDepartmentChange = async (value) => {
    setStaffs(null);
    setSelectedStaff(null);
    setSelectedStaffDepartment(value);

    const allowedStaffs = await getAllowedStaff(value);
    setStaffs(allowedStaffs);
  };

  const onStaffChange = (value) => {
    setSelectedStaff(null);
    if (value) {
      setSelectedStaff(value.id);
    }
  };

  const onGenerateClick = (fileType) => {
    switch (fileType) {
      case 'XLSX':
        onXLSX();
        break;
      case 'PDF':
        onPDF();
        break;
      default:
        console.log('Unknown file type');
        break;
    }
  };

  const getDepartmentsParam = useCallback(() => {
    let departmentsParam = [];
    if (selectedStaffDepartment && selectedStaffDepartment !== 'all') {
      departmentsParam.push(selectedStaffDepartment);
    } else {
      departmentsParam = departments?.filter((d) => d.id).map((d) => d.id);
    }
    return departmentsParam;
  }, [departments, selectedStaffDepartment]);

  const onReportRequestResult = useCallback(
    (resultSuccess, reportName, fileType) => {
      if (onReportResult != null) {
        onReportResult(resultSuccess);
      }
      const fileString = `${StringUtility.capitalize(reportName)} (${fileType.toUpperCase()})`;
      const userMessage = resultSuccess
        ? `${fileString} Requested`
        : `Failed to Request ${fileString}`;
      setMessage({
        type: resultSuccess ? 'info' : 'error',
        visible: true,
        msg: userMessage,
      });
      setStaffReporting(false);
      updateReportingStatus(false);
    },
    [onReportResult, updateReportingStatus]
  );

  const onXLSX = useCallback(async () => {
    setStaffReporting(true);
    updateReportingStatus(true);

    const parameters = new ExportParameterDto({
      emailed: true,
      type: selectedStaffReport?.type,
      output: 'xlsx',
      year: year,
      model: defaultModel.id,
      courseId: null,
      department: getDepartmentsParam(),
      staffId: selectedStaff,
    });

    exportStore
      .getAllocationExportByParameters(parameters, true)
      .then((result) => {
        onReportRequestResult(result.success, selectedStaffReport?.title, 'xlsx');
      })
      .catch((err) => {
        console.error(err);
        onReportRequestResult(false, selectedStaffReport?.title, 'xlsx');
      });
  }, [
    updateReportingStatus,
    selectedStaffReport?.type,
    selectedStaffReport?.title,
    year,
    defaultModel.id,
    getDepartmentsParam,
    selectedStaff,
    exportStore,
    onReportRequestResult,
  ]);

  const onPDF = useCallback(() => {
    setStaffReporting(true);
    updateReportingStatus(true);

    const parameters = new ReportParameterDto({
      emailed: true,
      type: selectedStaffReport?.type,
      output: 'pdf',
      year: year,
      model: defaultModel.id,
      department: getDepartmentsParam(),
      staffId: selectedStaff,
      courseId: null,
    });

    reportStore.getAllocationReportByParameters(parameters, true).then((result) => {
      onReportRequestResult(result.success, selectedStaffReport?.title, 'pdf');
    });
  }, [
    updateReportingStatus,
    selectedStaffReport?.type,
    selectedStaffReport?.title,
    year,
    defaultModel.id,
    getDepartmentsParam,
    selectedStaff,
    reportStore,
    onReportRequestResult,
  ]);

  const onPageChange = useCallback(
    (limit, skip) => {
      const onValidate = () => {
        return defaultModel != null || selectedStaffDepartment != null;
      };

      setPaging({ limit, skip });
      if (onValidate()) {
        updateSpinner(true, 'Loading');
        const reportSearch = {
          id: null,
          reportName: null,
          model: defaultModel.id,
          owner: null,
          department: null,
          createDate: null,
          user: currentUserId,
        };
        reportSearchStore.getStaffAllocationReport(reportSearch, limit, skip).then(() => {
          console.log('should update spinner');
          updateSpinner(false, '');
        });
      }
    },
    [defaultModel, selectedStaffDepartment, updateSpinner, currentUserId, reportSearchStore]
  );

  return (
    allowedStaffReportTypes.length > 0 && (
      <CollapsePanel
        expanded={false}
        title='Staff Reports'
        tabIndex={1}
      >
        <div className='allocation-reports'>
          <div className='allocation-reports-row'>
            <div className='export-tip'>
              <span className='k-icon k-i-information'></span> As this export may take some time to
              generate, it will be <span className='emphasise'>emailed</span> to you once completed.
            </div>
          </div>
          <div className='allocation-reports-row'>
            <div className='allocation-reports-column'>
              <div className='item-card'>
                <span className='reports-parameter-label'>Report:</span>
                <Select
                  className='report-drop-down-style'
                  onChange={onReportTypeChange}
                  value={selectedStaffReport?.type}
                  placeholder='Please select'
                  defaultValue={undefined}
                >
                  {allowedStaffReportTypes.map((report) => (
                    <Option
                      key={report.type}
                      value={report.type}
                    >
                      {report.title}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className='item-card'>
                <span className='reports-parameter-label'>Model:</span>
                <Select
                  className='reports-parameter-dropdown'
                  disabled={true}
                  value={defaultModel?.id}
                >
                  {models &&
                    models.map((model) => (
                      <Option
                        key={model.id}
                        value={model.id}
                      >
                        {model.model}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className='item-card'>
                <span className='reports-parameter-label'>Unit:</span>
                <Select
                  className='report-drop-down-style'
                  onChange={onStaffDepartmentChange}
                  disabled={reporting || defaultModel == null}
                  value={selectedStaffDepartment}
                  placeholder='Please select'
                >
                  {departments &&
                    departments.map((department) => (
                      <Option
                        key={department?.id ?? 'all'}
                        value={department?.id ?? 'all'}
                      >
                        {department.department}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className='item-card'>
                <span className='reports-parameter-label'>Staff:</span>
                <StaffAutoComplete
                  className='reports-parameter'
                  data={staffs}
                  onChange={onStaffChange}
                  disabling={
                    reporting ||
                    defaultModel == null ||
                    selectedStaffDepartment == null ||
                    (selectedStaffReport?.type != 'staff_allocation' &&
                      selectedStaffReport?.type != 'staff_change_report')
                  }
                  staff={selectedStaff}
                  placeholder='Please select'
                  type='report'
                />
              </div>
            </div>
            <div className='allocation-reports-column'>
              <div className='item-card'>
                <DropListButton
                  text='Generate'
                  items={
                    !selectedStaffReport
                      ? []
                      : selectedStaffReport.formats
                          .filter((f) => selectedStaff || f === 'XLSX')
                          .map((f) => ({
                            text: `Export to ${f}`,
                            icon: f === 'PDF' ? <FilePdfOutlined /> : <FileTextOutlined />,
                            on: () => onGenerateClick(f),
                          }))
                  }
                  enabled={!reporting && defaultModel != null && canReport}
                  spinner={true}
                  spinnerText={'Compiling'}
                  active={reporting && staffReporting}
                  look='outline'
                />
              </div>
            </div>
          </div>
          <MessageLine
            visible={message.visible}
            type={message.type}
            line={message.msg}
          />
          <div>
            <SearchReportGrid
              data={reportSearchStore.staffReportResult}
              onPageChange={onPageChange}
              columns={[
                {
                  field: 'reportName',
                  title: 'Report Name',
                  dataIndex: 'reportName',
                  sorter: (a, b) => a.reportName.localeCompare(b.reportName),
                },
                {
                  field: 'createDate',
                  title: 'Create Date',
                  dataIndex: 'createDate',
                  render: (date) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''),
                  sorter: (a, b) => a.createDate.localeCompare(b.createDate),
                },
              ]}
            />
          </div>
        </div>
      </CollapsePanel>
    )
  );
};

export default inject(
  'authUserStore',
  'exportStore',
  'reportStore',
  'staffStore',
  'viewStore'
)(observer(StaffReports));
