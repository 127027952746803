import React, { Component } from 'react';

import './body-layout.css';

class BodyLayout extends Component {
  render() {
    return (
      <div className="Layout-frame">
        {this.props.children}
      </div>
    );
  }
}

export default BodyLayout;
