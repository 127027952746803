import { OfferingSchema } from '.';
import Joi from 'joi';
import { ConvertToForms } from './shared';


class OfferingValidator {


    transformations = {
        naming: {
            id: 'Id',
            coordinator: 'Coordinator',
            enrolments: 'Enrolments'
        },
        messages: [
            { regex: 'is not allowed to be empty', message: 'is required' },
            { regex: 'Enrolments must be larger than or equal to 0', message: 'Enrolments must be greater than 0' }
        ]
    }

    async validate(offering: any): Promise<string[]> {
        const msg: string[] = [];

        const err = await this._validate(offering);
        for (let key in err) {
            let _key = key.split(',')[0]
            const name = _key.split(',')[0].split(' ').map((w) => (this.transformations.naming[w] ? this.transformations.naming[w] : w)).join(' ');
            let message = err[key].replace(`"${key}"`, name);
            for (const reg of this.transformations.messages) {
                message = message.replace(reg.regex, reg.message);
            }
            msg.push(message);
        }

        return msg;
    }

    _validate(data: any) {
        return new Promise((resolve, reject) => {
            Joi.validate(data, OfferingSchema, { convert: true, abortEarly: false }, (errs, convertedValues) => {
                const converted = ConvertToForms(errs);
                resolve(converted)
            });
        });
    }
}

export const Offering = new OfferingValidator();