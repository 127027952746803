// @flow

import { makeAutoObservable } from 'mobx';
import Api from './api';
export default class DictionaryStore {
  constructor() {
    makeAutoObservable(this);
  }

  async getModels(year: number) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      try {
        this.models = await Api.get(`/models/${year}`);
        resolve(this.models);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getOwners(year: number, modelId: ?string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      let allOwners = { owner: 'All Owners', active: true };
      this.owners = await Api.get(
        modelId ? `/owners/model/${modelId}` : `/owners/${year}?active=true`
      );
      this.owners.unshift(allOwners);
      resolve(this.owners);
    });
  }

  async getDepartments(year: number, modelId: ?string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      let allDepartments = { department: 'All Units', code: 'all', active: true };
      this.departments = await Api.get(
        modelId ? `/departments/model/${modelId}` : `/departments/${year}?active=true`
      );
      this.departments.unshift(allDepartments);
      resolve(this.departments);
    });
  }
}
