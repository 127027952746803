import React, { useState, useEffect } from 'react';
import ChangeFileImporter from './change-file-importer';
import {
  CollapsePanel,
  MessageLine,
  ConfirmationDialog,
} from '../../components';
import { MessageConfirmationBlock } from '../../components/message';
import { inject, observer } from 'mobx-react';
import './model.css';

const IMPORT_TASKS = ['insert', 'update', 'delete'];

const ManageDataChange = (props) => {
  const { model, year } = props;
  const importStore = props.importStore;
  const yearStore = props.yearStore;
  const modelManageStore = props.modelManageStore;
  const [openYears, setOpenYears] = useState([]);

  const [message, setMessage] = useState({ visible: false, type: '', msg: '' });
  const [messageBlock, setMessageBlock] = useState({
    visible: false,
    title: null,
    lines: [],
    actions: [],
  });
  const [dialog, setDialog] = useState(null);

  const [yearSelected, setYearSelected] = useState(null);
  const [typeSelected, setTypeSelected] = useState(null);
  const [tasksSelected, setTasksSelected] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState(0);

  const canImport = yearSelected != null && typeSelected != null && tasksSelected.length > 0;

  console.log('yearSelected: ', yearSelected);
  console.log('typeSelected: ', typeSelected);
  console.log('importTasks: ', tasksSelected.length);
  console.log('canImport: ', canImport);

  useEffect(() => {
    const getOpenYears = async () => {
      const yearsResponse = await modelManageStore.getOpenYears();
      if (yearsResponse) {
        setOpenYears(yearsResponse);
      }
    };
    getOpenYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onImportYearChange(selectedYear) {
    console.log('selectedYear: ', selectedYear);
    setYearSelected(selectedYear);
  }

  function onTypeChange(selectedType) {
    setTypeSelected(selectedType);
    setUploadFile(null);
    setProgress(0);
  }

  function onSetMessageEvent(message) {
    setMessage(message);
    cleanMessage();
  }

  /*function onImportTaskChange(target) {
    const tasks = [...tasksSelected];
    const ref = String(target.id).replace('import_task_', '');
    const currentTaskIndex = tasks.indexOf(ref);
    if (target.checked) {
      if (currentTaskIndex === -1) {
        tasks.push(ref);
      }
    } else {
      tasks.splice(currentTaskIndex, 1);
    }
    setTasksSelected(tasks);
  }*/

  function onImportClicked() {
    setMessageBlock({ visible: false, title: null, lines: [], actions: [] });
    setDialog({
      visible: true,
      title: 'Please Confirm',
      lines: [
        <span key='dataChangeConfirm'>
          Please confirm you wish to execute the import process for the{' '}
          <span className='important'>{`${yearSelected} ${typeSelected}`}</span>
        </span>,
        <span
          key='dataChangeNote'
          className='caution'
        >
          The following task{tasksSelected.length > 1 ? 's' : ''} will be applied against existing{' '}
          {yearSelected} records;
        </span>,
        <div
          key='dataChangeList'
          className='dialog-import-task-list'
        >
          <ol>
            {tasksSelected.map((task, index) => {
              return (
                <li key={index}>
                  <span>
                    <span className='caution upper'>{task}</span>
                  </span>
                </li>
              );
            })}
          </ol>
        </div>,
        'Do you wish to continue?',
      ],
      callback: (confirmed) => {
        setDialog(null);
        if (confirmed) {
          setInProgress(true);
          importDataChange();
        }
      },
    });
  }

  async function importDataChange() {
    const dataImport = { modelId: model.id, types: [typeSelected], tasks: importTasks };
    const importing = {
      types: [typeSelected],
      tasks: [tasksSelected],
      year: yearSelected,
      modelId: model.id,
    };
    const response = await importStore.import(importing);
    try {
      setMessageBlock(getMessageBlock('import_warning', response));
      if (response.success) {
        onImportCommit();
      } else {
        setMessageBlock(getMessageBlock('import_warning', response));
      }
    } catch (error) {
      setMessageBlock(getMessageBlock('import_error', error));
    }
  }

  async function onImportCommit() {
    const response = await importStore.commit();
    console.log('response: ', response);
    try {
      if (response.success) {
        setMessageBlock(
          getMessageBlock(
            response.result.warnings ? 'commit_with_warnings' : 'commit_success',
            response
          )
        );
      } else {
        setMessageBlock(getMessageBlock('commit_failed', response));
      }
    } catch (error) {
      setMessageBlock(getMessageBlock('commit_failed', error));
    }
  }

  async function onImportRecalculate() {
    const response = await importStore.recalculate();
    try {
      if (response.success) {
        setMessageBlock(
          getMessageBlock(
            response.result.warnings ? 'recalculate_with_warnings' : 'recalculate_complete',
            response
          )
        );
      } else {
        setMessageBlock(getMessageBlock('recalculate_failed', response));
      }
    } catch (error) {
      setMessageBlock(getMessageBlock('recalculate_failed', error));
    }
  }

  function onImportReport() {
    importStore.report().catch((e) => {
      setMessageBlock(getMessageBlock('report_error', e));
    });
  }

  async function onImportComplete() {
    const response = await importStore.complete({ status: 'open', modelId: model.id });
    setInProgress(false);
    try {
      if (response.success) {
        await yearStore.getYears(true, true);
        setMessage({
          visible: true,
          type: 'success',
          msg: `The ${yearSelected} Import Completed Successfully`,
        });
        cleanMessage();
      } else {
        setMessage({
          visible: true,
          type: 'error',
          msg: `An error has occurred while completing close-off of the ${yearSelected} import`,
        });
        cleanMessage();
      }
    } catch (error) {
      setMessage({
        visible: true,
        type: 'error',
        msg: `An error has occurred while completing close-off of the ${yearSelected} import`,
      });
      cleanMessage();
    }
  }

  async function onImportCancel(silent) {
    await importStore.cancel();
    setInProgress(false);
    try {
      if (!silent) {
        setMessage({
          visible: true,
          type: 'information',
          msg: `The ${yearSelected} Import has been Cancelled`,
        });
        cleanMessage();
      }
    } catch (error) {
      setMessage({
        visible: true,
        type: 'error',
        msg: `An error has occurred while cancelling the ${yearSelected} Import`,
      });
      cleanMessage();
    }
  }

  function cleanMessage() {
    setTimeout(() => {
      setMessage({ visible: false, type: '', msg: '' });
    }, 5000);
  }

  function onUploadAdd() {
    return true;
  }

  function onUploadRemove() {
    return true;
  }

  function getMessageBlock(stage, data) {
    const report = () => {
      onImportReport();
    };
    const complete = () => {
      setMessageBlock({ visible: false, lines: [], actions: [] });
      onImportComplete();
    };
    const cancel = (silent) => {
      setMessageBlock({ visible: false, lines: [], actions: [] });
      onImportCancel(silent);
    };
    const commit = () => {
      setMessageBlock({ visible: false, lines: [], actions: [] });
      onImportCommit();
    };
    const close = () => {
      setMessageBlock({ visible: false, lines: [], actions: [] });
    };
    const recalculate = () => {
      setMessageBlock({ visible: false, lines: [], actions: [] });
      onImportRecalculate();
    };
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const error = (data) => {
      const value = data.error ? data.error : data;
      setMessageBlock({
        ...messageBlock,
        lines: [
          ...(messageBlock.lines || []),
          <div
            className='error-content'
            key='error-content'
          >{`${value}`}</div>,
        ],
      });
    };

    console.log('STAGE: ', stage);
    switch (stage) {
      case 'recalculate_complete':
        return {
          visible: true,
          type: 'success',
          task: 'import',
          title: (
            <span className={'import-line success'}>
              <span className={'k-icon k-i-check'}></span>Successfully completed the {yearSelected}{' '}
              Allocation Recalculation
            </span>
          ),
          actions: [
            { text: 'Complete', callback: () => complete() },
            { text: 'Download Report', callback: () => report() },
          ],
        };
      case 'recalculate_with_warnings':
        return {
          visible: true,
          type: 'warning',
          task: 'import',
          title: (
            <span className={'import-line success'}>
              <span className={'k-icon k-i-warning'}></span>
              {data.message}
            </span>
          ),
          lines: [
            <span
              className={'import-line success'}
              key='success-content'
            >
              Total of {data.result.processed} allocations required recalculating,{' '}
              {data.result.failed} failed to updated
            </span>,
          ],
          actions: [
            { text: 'Complete', callback: () => complete() },
            { text: 'Download Report', callback: () => report() },
          ],
        };
      case 'recalculate_failed':
        return {
          visible: true,
          type: 'error',
          title: 'Failed to Recalculate Allocations',
          task: 'import',
          lines: [
            <span
              className={'import-line error'}
              key='failed-content'
            >
              <span className={'k-icon k-i-warning'}></span>An error has occurred while attempting
              to recalculate the {yearSelected} allocations
            </span>,
          ],
          actions: [
            { text: 'Close', callback: () => cancel(true) },
            { text: 'Attempt Again', callback: () => recalculate() },
          ],
        };
      case 'commit_with_warnings':
        return {
          visible: true,
          type: 'success',
          task: 'import',
          title: (
            <span className={'import-line success'}>
              <span className={'k-icon k-i-warning'}></span>
              {data.message}
            </span>
          ),
          lines: [
            <span
              className={'import-line success'}
              key='suc-content'
            >
              Total of {data.result.total} records,{' '}
              {data.result.noaction > 0 ? `${data.result.noaction} with no action required, ` : ''}
              {data.result.processed} processed successfully and {data.result.invalid} invalid
              records ignored
            </span>,
          ],
          actions: [
            { text: 'Recalculate Allocations', callback: () => recalculate() },
            { text: 'Download Report', callback: () => report() },
          ],
        };
      case 'commit_success':
        return {
          visible: true,
          type: 'success',
          task: 'import',
          title: (
            <span className={'import-line success'}>
              <span className={'k-icon k-i-check'}></span>
              {data.message}
            </span>
          ),
          actions: [
            { text: 'Recalculate Allocations', callback: () => recalculate() },
            { text: 'Download Report', callback: () => report() },
          ],
        };
      case 'commit_failed':
        return {
          visible: true,
          type: 'error',
          title: 'Failed to Commit',
          task: 'import',
          lines: [
            <span
              className={'import-line error'}
              key='fail-content'
            >
              <span className={'k-icon k-i-warning'}></span>An error has occurred while attempting
              to commit the {yearSelected} Import
            </span>,
          ],
          actions: [
            { text: 'Close', callback: () => cancel(true) },
            { text: 'Attempt Again', callback: () => commit() },
          ],
        };
      case 'import_warning':
        return {
          visible: true,
          type: 'warning',
          task: 'import',
          title: 'Import Processing Paused',
          lines: data.result
            .sort((a, b) => (a.success == b.success ? 0 : a.success ? -1 : 1))
            .map((d, index) => (
              <span
                key={`${index}`}
                className={`import-line ${d.success ? 'success' : 'warning'}`}
              >
                <span className={`k-icon k-i-${d.success ? 'check' : 'warning'}`}></span>
                {d.message}
              </span>
            )),
          actions: [
            { text: 'Cancel Import', callback: () => cancel(false) },
            { text: 'Continue Import', callback: () => commit() },
            { text: 'Download Report', callback: () => report() },
          ],
        };
      case 'import_error':
        return {
          visible: true,
          type: 'error',
          task: 'import',
          title: (
            <span className={'import-line error'}>
              <span className={'k-icon k-i-warning'}></span>An error has occurred while processing
              the {yearSelected} Import
            </span>
          ),
          actions: [{ text: 'Close', callback: () => close() }],
        };
      default:
        return {
          visible: true,
          type: 'error',
          task: 'import',
          title: (
            <span className={'import-line error'}>
              <span className={'k-icon k-i-warning'}></span>An error has occurred while compiling
              the {yearSelected} import report
            </span>
          ),
          actions: [{ text: 'Close', callback: () => close() }],
        };
    }
  }

  return (
    <div>
      <CollapsePanel
        expanded={false}
        title='Manage Data Changes'
        tabIndex={1}
      >
        <div className='management-block'>
          <div className='management-row'>
            <div className='management-column right-seperator import'>
              <div className='item-card management-tip fixed-width'>
                <p>
                  <span className={'k-icon k-i-information'}></span>
                  This option allows you to update/add/delete information in the database. It should
                  only be used once the Rollover/Import process has been completed. Please refer to
                  the Help manual for scenarios and how to use the options. Incorrect use may result
                  in data being deleted.
                </p>
              </div>

              <div className='item-card upload'>
                <div>
                  <ChangeFileImporter
                    selectTask={true}
                    model={model}
                    setMessageEvent={onSetMessageEvent}
                    courseOnlyImport={true}
                    year={year}
                  />
                </div>
              </div>

              <MessageConfirmationBlock
                visible={messageBlock.visible}
                title={messageBlock.title}
                type={messageBlock.type}
                lines={messageBlock.lines}
                actions={messageBlock.actions}
              />
              <MessageLine
                visible={message.visible}
                type={message.type}
                line={message.msg}
              />
            </div>
          </div>
        </div>
      </CollapsePanel>
      {dialog && (
        <ConfirmationDialog
          response={dialog.callback}
          title={dialog.title}
          lines={dialog.lines}
        />
      )}
    </div>
  );
};
export default inject('importStore', 'yearStore', 'modelManageStore')(observer(ManageDataChange));
