import { NonBPOUserSchema, BPOUserSchema } from '.';
import Joi from 'joi';
import { ConvertToForms } from './shared';
import { UserStore } from '../mobx';
const userStore = new UserStore();

class UserValidator {
  transformations = {
    naming: {
      id: 'Id',
      firstName: 'First Name',
      surname: 'Last Name',
      identifier: 'Username',
      email: 'Email',
      model: 'Model',
      group: 'Role',
      status: 'Status',
    },
    messages: [{ regex: 'is not allowed to be empty', message: 'is required' }],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async validate(user: any): Promise<string[]> {
    const msg: string[] = [];

    const err = await this._validate(user);
    for (let key in err) {
      let _key = key.split(',')[0];
      const name = _key
        .split(',')[0]
        .split(' ')
        .map((w) => (this.transformations.naming[w] ? this.transformations.naming[w] : w))
        .join(' ');
      let message = err[key].replace(`"${_key}"`, name);
      for (const reg of this.transformations.messages) {
        message = message.replace(reg.regex, reg.message);
      }
      msg.push(message);
    }
    if (user?.group?.code === 'wm' && (!user?.departments || user.departments.length === 0)) {
      msg.push('Workload Manager must be assigned to at least one unit');
    }
    if (msg.length) {
      return msg;
    }
    if (user?.identifier && !user?.id) {
      const userExists = await userStore.checkUserExists(user.identifier);
      if (userExists) {
        msg.push('User already exists in the system');
        return msg;
      }
    }
    if (user?.identifier && user?.id) {
      const userExists = await userStore.checkDuplicateUserExists(user.id, user.identifier);
      if (userExists) {
        msg.push('User with same username already exists in the system');
        return msg;
      }
    }
    return msg;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _validate(data: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const userGroupCode = data?.group?.code;
    const isBPO = userGroupCode === 'bpo';

    const UserSchema = isBPO ? BPOUserSchema : NonBPOUserSchema;

    return new Promise((resolve, reject) => {
      Joi.validate(data, UserSchema, { convert: true, abortEarly: false }, (errs) => {
        const converted = ConvertToForms(errs);
        resolve(converted);
      });
    });
  }
}

export const User = new UserValidator();
