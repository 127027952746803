// @flow

import React, { Component } from 'react';
import { Spinner } from '../spinner';

import './actions.css';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';

type Props = {
  text: string,
  enabled: boolean,
  active: boolean,
  icon: string,
  items: {
    text: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    on: Function,
    icon: string,
    disabled: boolean,
  },
  spinner: boolean,
  spinnerText: string,
};

class DropListButton extends Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      dropdownVisible: false,
    };
    this.handleTabFocus = this.handleTabFocus.bind(this);
    this.handleTabBlur = this.handleTabBlur.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleTabFocus() {
    this.setState({ isFocused: true });
  }

  handleTabBlur() {
    this.setState({ isFocused: false });
  }

  handleKeyPress() {
    if (event.key === 'Enter' && this.props.enabled) {
      event.preventDefault();
      this.triggerDropdown();
    }
  }

  triggerDropdown = () => {
    this.setState((prevState) => ({
      dropdownVisible: !prevState.dropdownVisible,
    }));
  };

  render() {
    const isFocused = this.state.isFocused;
    const buttonClassNames = `drop-list-button ${isFocused ? 'focused' : ''}`;

    const menu = (
      <Menu>
        {this.props.items.map((item, index) => (
          <Menu.Item
            icon={item.icon}
            key={index}
            disabled={item.disabled ? item.disabled : false}
            onClick={item.on}
          >
            {item.text}
          </Menu.Item>
        ))}
      </Menu>
    );
    return (
      <div
        className='drop-list-button-action'
        tabIndex={0}
        onFocus={this.handleTabFocus}
        onBlur={this.handleTabBlur}
        onKeyDown={this.handleKeyPress}
        onClick={this.props.enabled ? this.triggerDropdown : null}
      >
        <Dropdown
          overlay={menu}
          className={buttonClassNames}
          disabled={!this.props.enabled || this.props.active}
          open={this.state.dropdownVisible}
          onOpenChange={this.triggerDropdown}
        >
          <a>
            <Space>
              {this.props.active ? (
                <LoadingOutlined />
              ) : this.props.icon ? (
                this.props.icon
              ) : (
                <DownloadOutlined />
              )}
              {this.props.text}
            </Space>
          </a>
        </Dropdown>
        {this.props.spinner && this.props.active && (
          <Spinner text={this.props.spinnerText || 'Compiling'} />
        )}
      </div>
    );
  }
}

export default DropListButton;
