import joi from 'joi';


export const OfferingSchema = joi.object().keys({
    id: joi.string().uuid({ version: 'uuidv4' }),

    offering: joi.string().max(250).required(),

    owner: joi.object().keys({
        id: joi.string().uuid({ version: 'uuidv4' }).required()
    }),

    deliveryMode: joi.string().max(8).required(),

    deliverySite: joi.string().max(250).required(),

    period: joi.object().keys({
        id: joi.string().uuid({ version: 'uuidv4' }).required()
    }),

    credits: joi.number(),

    outcomes: joi.number(),

    level: joi.number(),

    levelAggregate: joi.string().max(250).allow([null]).optional(),

    location: joi.number(),

    courseCode: joi.string().max(250),

    course: joi.object().keys({
        id: joi.string().uuid({ version: 'uuidv4' }).required()
    }),

    efts: joi.number(),

    enrolments: joi.number(),

    courseType: joi.object().keys({
        id: joi.string().uuid({ version: 'uuidv4' }).required()
    }),

    coordinator: joi.object().keys({
        id: joi.string().uuid({ version: 'uuidv4' }).required()
    }).required(),

    pbrf: joi.string().max(250).allow([null]).optional(),

    model: joi.object().keys({
        id: joi.string().uuid({ version: 'uuidv4' }).required()
    }),

    dateAdded: joi.date(),

    active: joi.boolean()
}).unknown(true);



