import { makeAutoObservable } from 'mobx';

export default class NavStore {
  constructor() {
    makeAutoObservable(this);
  }

  _previousLocation = '';

  setPreviousLocation(location) {
    this._previousLocation = location;
  }

  getPreviousLocation() {
    return this._previousLocation;
  }

  _readOnly = false;

  setReadOnly(readOnly) {
    this._readOnly = readOnly;
  }

  getReadOnly() {
    return this._readOnly;
  }
}
