import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import './search-components.css';

const SearchCourseAutoKeyWords = (props) => {
  const { disabled, onKeywordSelectChange, defaultValue } = props;
  const [inputedKeywords, setInputedKeywords] = useState(defaultValue);

  useEffect(() => {
    if(inputedKeywords === '') {
      setInputedKeywords(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    const searchWords = [];
    if (inputedKeywords != null) {
      const lowerSearchValue = inputedKeywords.trim().toLowerCase();
      if (lowerSearchValue !== '') {
        const lowerKeywords = lowerSearchValue.replace("'", "''");
        let words;
        words = lowerKeywords.split(';');
        words.forEach((word) => {
          word = word.trim();
          if (word.length > 0) {
            searchWords.push(word);
          }
        });
      }
    }
    onKeywordChangeEvent(searchWords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputedKeywords]);

  async function onTextChange(keywords) {
    setInputedKeywords(keywords.target.value);
  }

  async function onKeywordChangeEvent(keywords) {
    onKeywordSelectChange(keywords);
  }

  return (
    <div className='search-keywords-tags-input card-item'>
      <p className='search-sub-title'>Keywords:</p>
      <Input
        className='search-input'
        value={inputedKeywords}
        onChange={onTextChange}
        disabled={disabled}
      ></Input>
    </div>
  );
};
export default SearchCourseAutoKeyWords;
