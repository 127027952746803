// @flow

import { makeAutoObservable, runInAction, toJS } from 'mobx';

import {
  DepartmentDto,
  ModelDto,
  OwnerDto,
  SearchCourseDto,
  SearchResultDto,
  SearchStaffDto,
  SearchUserDto,
  SortDto,
  StaffContractDto,
  StaffTypeDto,
} from '../dto';
import Api from './api';
import * as querystring from 'query-string';
import { mobxSpy } from './util';

mobxSpy({ enable: false, eventName: 'searchResult', fontSize: '14px' });

export default class SearchStore {
  searchResult: SearchResultDto = {};
  userSearchResult: SearchResultDto;
  models: ModelDto[];
  owners: OwnerDto[];
  departments: DepartmentDto[];
  types: StaffTypeDto[];
  contracts: StaffContractDto[];
  staffTypesMap: Map<number, StaffTypeDto[]>;
  contractsMap: Map<number, StaffContractDto[]>;

  allDepartments = { department: 'All Units', active: true };

  constructor() {
    this.staffTypesMap = new Map();
    this.contractsMap = new Map();
    this.cleanSearchResult();
    makeAutoObservable(this);
  }

  setSearchResults(searchResult: SearchResultDto) {
    this.searchResult = searchResult;
  }

  cleanSearchResult() {
    this.searchResult = {};
    this.userSearchResult = {};
  }

  setSingleModel(model: ModelDto) {
    this.models = [model];
  }

  async getModels(year: number) {
    // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-unused-vars
    return new Promise(async (resolve, reject) => {
      if (year) {
        this.models = await Api.get(`/models/${year}`);
        resolve(this.models);
      } else {
        resolve(null);
      }
    });
  }

  async getOwners(year: number, modelId: ?string) {
    // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-unused-vars
    return new Promise(async (resolve, reject) => {
      let allOwners = { owner: 'All Owners', active: true };
      this.owners = await Api.get(
        modelId ? `/owners/model/${modelId}` : `/owners/${year}?active=true`
      );
      this.owners.unshift(allOwners);
      resolve(this.owners);
    });
  }

  async getOwnersByUser(
    year: number,
    modelId: string,
    userId: string,
    {
      includeAllocations = true,
      active = true
    }: {
      includeAllocations?: Boolean;
      active?: Boolean;
    } = {}
  ) {
    // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-unused-vars
    return new Promise(async (resolve, reject) => {
      let allOwners = { owner: 'All Owners', active: true };
      this.owners = await Api.get(
        `/owners/user/${userId}/year/${year}/model/${modelId}?active=${active}&includeAllocations=${includeAllocations}`
      );
      this.owners.unshift(allOwners);
      resolve(this.owners);
    });
  }

  async getOwnersByModelCode(year: number, modelCode: ?string) {
    // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-unused-vars
    return new Promise(async (resolve, reject) => {
      let allOwners = { owner: 'All Owners', active: true };
      this.owners = await Api.get(
        modelCode ? `/owners/modelCode/${modelCode}` : `/owners/${year}?active=true`
      );
      this.owners.unshift(allOwners);
      resolve(this.owners);
    });
  }

  async getOwnersByUserModelCode(
    year: number,
    modelCode: ?string,
    userId: string,
    userRole: string
  ) {
    // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-unused-vars
    return new Promise(async (resolve, reject) => {
      let allOwners = { owner: 'All Owners', active: true };
      this.owners = await Api.get(
        `/owners/userRole/${userRole}/user/${userId}/year/${year}/modelCode/${modelCode}`
      );
      this.owners.unshift(allOwners);
      resolve(this.owners);
    });
  }

  async getTypes(year: number) {
    const hasYear = this.staffTypesMap.has(year);
    if (!hasYear) {
      let allTypes = { type: 'All Types', active: true };
      let types = await Api.get(`/stafftypes/${year}`);
      types.unshift(allTypes);
      this.staffTypesMap.set(year, types);
    }
    this.types = Array.from(this.staffTypesMap.get(year)).map((i) => {
      return toJS(i);
    });
    return this.types;
  }

  async getContracts(year: number) {
    // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-unused-vars
    const hasYear = this.contractsMap.has(year);
    if (!hasYear) {
      let allContracts = { contract: 'All Contracts', active: true };
      let contracts = await Api.get(`/contracts/${year}`);
      contracts.unshift(allContracts);
      this.contractsMap.set(year, contracts);
    }
    this.contracts = Array.from(this.contractsMap.get(year)).map((i) => {
      return toJS(i);
    });
    return this.contracts;
  }

  async getRoles(active: boolean) {
    let allRoles = { group: 'All', code: 'all', active: true };
    this.roles = await Api.get(active != null ? `/usergroups?active=${active}` : '/usergroups');
    this.roles.unshift(allRoles);
    return this.roles;
  }

  async getDepartments(year: number, modelId: ?string) {
    // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-unused-vars
    return new Promise(async (resolve, reject) => {
      if (year || modelId) {
        const departmentUrl = modelId
          ? `/departments/model/${modelId}`
          : `/departments/${year}?active=true`;
        this.departments = await Api.get(departmentUrl);
        this.departments.unshift(this.allDepartments);
        resolve(this.departments);
      } else {
        resolve([]);
      }
    });
  }

  async getDepartmentsByUser(year: number, userId: string, modelId: ?string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      this.departments = await Api.get(
        modelId
          ? `/departments/user/${userId}/model/${modelId}`
          : `/departments/user/${userId}/year/${year}?active=true`
      );
      this.departments.unshift(this.allDepartments);
      resolve(this.departments);
    });
  }

  async getDepartmentsByModelCode(year: number, modelCode: ?string) {
    // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-unused-vars
    return new Promise(async (resolve, reject) => {
      if (year || modelCode) {
        const departmentUrl = modelCode
          ? `/departments/modelCode/${modelCode}`
          : `/departments/${year}?active=true`;
        this.departments = await Api.get(departmentUrl);
        this.departments.unshift(this.allDepartments);
        resolve(this.departments);
      } else {
        resolve([]);
      }
    });
  }

  async getDepartmentsByUserModelCode(year: number, userId: string, modelCode: ?string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      this.departments = await Api.get(
        modelCode
          ? `/departments/user/${userId}/modelCode/${modelCode}`
          : `/departments/user/${userId}/year/${year}?active=true`
      );
      resolve(this.departments);
    });
  }

  async getUserByKeyWords(
    data: SearchUserDto,
    limit = 25,
    skip = 0,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sorting: ?(SortDto[])
  ) {
    const sort =
      sorting && sorting.length > 0
        ? `&sorting=${querystring.stringify({ param: JSON.stringify(sorting) })}`
        : '';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.post(`/search/user?limit=${limit}&skip=${skip}${sort}`, data).then((data) => {
        const codeOrder = ['bpo', 'mm', 'wm', 'as'];
        let users = data?.data;
        for (let key in users) {
          const user = users[key];
          user.addedByName = (user.addedBy.firstName || '') + ' ' + (user.addedBy.surname || '');
          user.departmentNames = user?.userModelGroups
            ?.map((umg) => umg.departments)
            .map((department) => department.department)
            .join(', ');
          // need to sort the user model groups by the code order
          const sortedUserModelGroups = user.userModelGroups.sort((a, b) => {
            const aIndex = codeOrder.indexOf(a.userGroup.code);
            const bIndex = codeOrder.indexOf(b.userGroup.code);
            return aIndex - bIndex;
          });
          const highestRoleUserModelGroup = sortedUserModelGroups[0];
          user.model = highestRoleUserModelGroup.model;

          const departmentsToFilter = [];
          user.userModelGroups.forEach((umg) => {
            if (umg.userGroup.code === highestRoleUserModelGroup.userGroup.code) {
              umg.departments.forEach((dept) => {
                if (!departmentsToFilter.some((dep) => dep.department === dept.department)) {
                  departmentsToFilter.push(dept);
                }
              });
            }
          });

          let departments = departmentsToFilter.filter((x) => !!x);

          user.departments =
            departments.length > 0 && departments[0] !== null ? departments : undefined;
          user.departmentNames =
            departments.length > 0 && departments[0] !== null
              ? departments?.map((d) => d?.department).toString()
              : undefined;
          let groups = user.userModelGroups.map((ug) => ug.userGroup);
          let groupsUniqueValue = groups.filter(
            (elem, index) => groups.findIndex((obj) => obj.code === elem.code) === index
          );
          user.groupName = groupsUniqueValue.map((g) => g.group).toString();
          user.group = groupsUniqueValue.length > 0 ? groupsUniqueValue[0] : undefined;
        }
        this.userSearchResult = data;
        resolve(data);
      });
    });
  }

  async getUserByKeyWordsRaw(data: SearchUserDto, active = true) {
    return await Api.post(`/search/user/raw?active=${active}`, data);
  }

  async getStaffByKeyWords(
    data: SearchStaffDto,
    limit = 25,
    skip = 0,
    active = true,
    sorting: ?(SortDto[]),
    storeResults = true
  ) {
    const sort =
      sorting && sorting.length > 0
        ? `&sorting=${querystring.stringify({ param: JSON.stringify(sorting) })}`
        : '';
    if (typeof data === 'object' && Object.keys(data).length !== 0) {
      const searchResult = await Api.post(
        `/search/staff?limit=${limit}&skip=${skip}&active=${active}${sort}`,
        data
      );

      if (storeResults) {
        runInAction(() => {
          this.searchResult = searchResult;
        });
      }
      return searchResult;
    }
  }

  async getStaffByKeyWordsRaw(data: SearchStaffDto, active = true) {
    return await Api.post(`/search/staff/raw?active=${active}`, data);
  }

  async getStaffByMasseyUserId(year: number, code: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/${year}/staff/massey/user/id/${code}`).then((data) => {
        let result = {
          data: data,
          limit: 25,
          skip: 0,
          total: data.length,
        };
        this.searchResult = result;
        resolve(data);
      });
    });
  }

  async getCourseByKeyWords(
    data: SearchCourseDto,
    limit = 25,
    skip = 0,
    active = true,
    sorting: ?(SortDto[])
  ) {
    const sort =
      sorting && sorting.length > 0
        ? `&sorting=${querystring.stringify({ param: JSON.stringify(sorting) })}`
        : '';
    const searchResult = await Api.post(
      `/search/course?limit=${limit}&skip=${skip}&active=${active}${sort}`,
      data
    );

    this.setSearchResults(searchResult);

    return searchResult;
  }

  async getCourseByKeyWordsRaw(data: SearchCourseDto, active = true) {
    return await Api.post(`/search/course/raw?active=${active}`, data);
  }

  async getCourseByCode(year: number, code: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/${year}/courses/code/${code}`).then((data) => {
        if (Object.keys(data).length === 0) {
          let result = { data: [], limit: 25, skip: 0, total: 0 };
          this.searchResult = result;
          resolve([]);
        } else {
          let result = { data: [data], limit: 25, skip: 0, total: [data].length };
          this.searchResult = result;
          resolve([data]);
        }
      });
    });
  }
}
