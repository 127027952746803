import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AllocationDto, PagerDto, PageSettingDto, UserDto } from '../../dto';
import { SortDto } from '../../dto';
import {
  GridRemoveDialog,
  CollapsePanel,
  TableActionCellStaff,
  SpinnerBasic,
} from '../../components';
import './course.css';
import { Table, Button } from 'antd';

const { Column } = Table;

type Props = {
  editable: boolean,
  data: PagerDto<AllocationDto>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onPageChange: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onRemove: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onGridSort: Function,
  readOnlyView: boolean,
  sortable: boolean,
  panel: {
    title: string,
    expanded: boolean,
    // eslint-disable-next-line @typescript-eslint/ban-types
    onSelect: Function,
  },
  loading: boolean,
  currentUser: UserDto,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userRole: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  units: any,
};

type State = {
  data: PagerDto<AllocationDto>,
  removals: AllocationDto[],
  pageSetting: PageSettingDto,
  panelExpanded: boolean,
  dialog: {
    visible: boolean,
    lines: string[],
  },
  edit: {
    visible: boolean,
    item: AllocationDto,
  },
  sort: SortDto[],
  loading: boolean,
};
let currentUserStore;

class OfferingAllocations extends Component<Props, State> {
  constructor(props) {
    super(props);
    currentUserStore = this.props.authUserStore;
    this.state = {
      data: [],
      removals: [],
      panelExpanded: this.props.panel.expanded,
      pageSetting: this.createState(0, 25),
      dialog: {
        visible: false,
        lines: [],
      },
      sort: [],
      loading: this.props.loading,
      acadUser: true,
    };
  }

  onPageChange = (event) => {
    this.setState({
      pageSetting: this.createState(event.page.skip, event.page.take),
    });
    this.props.onPageChange(event.page.take, event.page.skip);
  };

  createState = (skip, take) => {
    const data = this.props.data && this.props.data.data ? this.props.data : [];
    if (data.data?.length > 0) {
      data.data.forEach((data, index) => {
        data['key'] = index;
      });
    }

    return {
      items: data === [] ? data : data.data,
      total: data === [] ? data.length : data.total,
      skip: skip,
      pageSize: take,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [25, 50, 75],
        previousNext: true,
      },
    };
  };

  onChange = (pagination, sorter, action) => {
    if (action == 'paginate') {
      this.onPaginate(pagination.current, pagination.pageSize);
    }
    if (action == 'sort') {
      this.onColumnSort(sorter);
    }
  };

  onPaginate = (page, pageSize) => {
    const skip = pageSize * (page - 1);
    this.setState({
      pageSetting: this.createState(skip, pageSize),
    });
    this.props.onPageChange(pageSize, skip);
  };

  mapSorter(sorter) {
    if (sorter?.column && sorter?.order) {
      return {
        field: sorter.column.field,
        dir: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
      };
    }

    return null;
  }

  onColumnSort = (sorter) => {
    let sort = [];
    if (Array.isArray(sorter)) {
      sort = sorter?.map(this.mapSorter).filter((s) => s) || [];
    } else if (this.mapSorter(sorter)) {
      sort = [this.mapSorter(sorter)];
    }

    this.setState({ sort });
    if (this.props.onSort) {
      this.props.onSort(sort);
    }
  };

  componentDidMount() {
    this.setState({ redirectToStaff: false });
    this.getCurrentUser();
  }

  onRemove(item: AllocationDto) {
    // TODO: REMOVAL_OF_LINKED: The handling of multiple deletions will be allocated to future development phases.
    // To implement replace the code within this method with that which is commented out.

    /*
            this.setState({
                removals: (item.linked ? [item, ...item.linked] : [item]), 
                dialog: { 
                    lines: (item.linkId ? ['Do you wish to delete the selected allocation?'] : ['Do you wish to delete the selected allocation?']), 
                    visible: true 
                } 
            });
        */

    this.setState({
      removals: [item],
      dialog: {
        lines: ['Do you wish to delete the selected allocation?'],
        visible: true,
      },
    });
  }
  onConfirmRemove = (confirmed: boolean) => {
    this.setState({ dialog: { lines: [], visible: false } });
    if (confirmed) {
      let items = this.state.removals;
      this.setState({ data: this.state.data.slice(), removals: [] }, () => {
        this.props.onRemove(items);
      });
    }
  };

  onEdit = (item: AllocationDto) => {
    this.props.onEdit(item);
  };

  onPanelSelect = (event) => {
    if (this.props.panel.onSelect) {
      this.props.panel.onSelect(event);
    }
  };

  update(data, item, remove) {
    let updated;
    let index = data.findIndex((p) => p === item || (item.id && p.id === item.id));
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    } else {
      let id = 1;
      data.forEach((p) => {
        id = Math.max(p.id + 1, id);
      });
      updated = Object.assign({}, item, { id: id });
      data.unshift(updated);
      index = 0;
    }

    if (remove) {
      data = data.splice(index, 1);
    }

    return data[index];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
        data: !this.props.loading ? this.props.data.data.slice(0) : [],
      });
    }
    let data = this.props.data;
    if (prevProps.data !== this.props.data) {
      this.setState({
        pageSetting: this.createState(data.skip, data.limit),
      });
    }
  }

  getCurrentUser() {
    const nonASRoles = ['bpo', 'mm', 'wm'];
    currentUserStore.getCurrentUser().then((currentUser) => {
      if (
        currentUser.userModelGroups.some((umg) => {
          return nonASRoles.includes(umg.userGroup.code);
        })
      ) {
        this.setState({ acadUser: false });
      }
    });
  }

  rolesCanView = (roles: Array<string>) => {
    return roles.includes(this.props.userRole);
  };

  render() {
    return (
      <div>
        <CollapsePanel
          expanded={this.state.panelExpanded}
          title={this.props.panel.title}
          onSelect={this.onPanelSelect}
          tabIndex={1}
        >
          <div className='grid-frame'>
            {this.state.loading ? (
              <div className='load-frame'>
                <SpinnerBasic />
              </div>
            ) : (
              <Table
                onItemChange={this.itemChange}
                dataSource={this.state.pageSetting.items}
                bordered={true}
                size='small'
                tableLayout='fixed'
                pagination={
                  this.state.pageSetting.pageable
                    ? {
                        position: ['bottomLeft'],
                        defaultPageSize: this.state.pageSetting.pageSize,
                        total: this.state.pageSetting.total,
                        showSizeChanger: true,
                      }
                    : false
                }
                onChange={(pagination, filters, sorter, extra) => {
                  this.onChange(pagination, sorter, extra.action);
                }}
                showSorterTooltip={this.props.sortable ? true : false}
                sortDirections={['ascend', 'descend']}
              >
                <Column
                  field='offering.offering'
                  title='Allocation'
                  key='Allocation'
                  dataIndex={['offering', 'offering']}
                  sorter={{
                    multiple: 8
                  }}
                />
                <Column
                  field='activity.activity'
                  title='Activity'
                  key='Activity'
                  dataIndex={['activity', 'activity']}
                  sorter={{
                    multiple: 7
                  }}
                />
                <Column
                  field='unit'
                  title='Number'
                  key='Number'
                  align='right'
                  width='80px'
                  dataIndex='unit'
                  sorter={{
                    multiple: 6
                  }}
                />
                <Column
                  field='variable.variable'
                  title='Variable'
                  key='Variable'
                  width='100px'
                  dataIndex={['variable', 'variable']}
                  sorter={{
                    multiple: 5
                  }}
                />

                {this.rolesCanView(['as']) && (
                  <Column
                    field='staff.displayName'
                    title='Staff Member'
                    key='Staff Member'
                    dataIndex={['staff', 'displayName']}
                    sorter={{
                      multiple: 4
                    }}
                  />
                )}
                {this.rolesCanView(['bpo', 'mm', 'wm']) && (
                  <Column
                    field='staff.displayName'
                    title='Staff Member'
                    key='Staff Member'
                    dataIndex={['staff', 'displayName']}
                    sorter={{
                      multiple: 3
                    }}
                    render={(text, record) => {
                      if (!this.state.acadUser) {
                        return (
                          <TableActionCellStaff
                            staff={record?.staff}
                            inDifferentUnit={
                              this.rolesCanView(['wm']) &&
                              !this.props.units?.includes(record?.staff?.department?.code)
                                ? true
                                : false
                            }
                          />
                        );
                      }
                      return text;
                    }}
                  />
                )}
                <Column
                  field='share.share'
                  title='Share'
                  key='Share'
                  align='right'
                  width='80px'
                  dataIndex={['share', 'share']}
                  sorter={{
                    multiple: 2
                  }}
                />
                <Column
                  field='period.period'
                  title='When'
                  key='When'
                  dataIndex={['period', 'period']}
                  sorter={{
                    multiple: 1
                  }}
                />
                <Column
                  field='calculated'
                  title='Calculated'
                  key='Calculated'
                  width='85px'
                  align='right'
                  dataIndex='calculated'
                />
                <Column
                  field='hours'
                  title='Assigned'
                  key='Assigned'
                  width='85px'
                  align='right'
                  dataIndex='hours'
                />
                <Column
                  field='notes'
                  title='Notes'
                  key='Notes'
                  dataIndex='notes'
                />
                {!this.props.readOnlyView && (
                  <Column
                    width='150px'
                    render={(text, record) => (
                      <>
                        <Button
                          className='k-primary k-button k-grid-edit-command'
                          primary='true'
                          disabled={this.props.editable ? !this.props.editable : false}
                          onClick={() => this.onEdit(record)}
                        >
                          Edit
                        </Button>
                        <button
                          className='k-button k-grid-remove-command'
                          onClick={() => this.onRemove(record)}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  />
                )}
              </Table>
            )}
          </div>
        </CollapsePanel>

        {this.state.dialog.visible && (
          <GridRemoveDialog
            response={this.onConfirmRemove}
            lines={this.state.dialog.lines}
          />
        )}
      </div>
    );
  }
}

export default inject('authUserStore')(observer(OfferingAllocations));
