import Joi from 'joi';


export const StaffTeachingAllocationSchema = Joi.object().keys({

    course: Joi.object().keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
    }).optional(),

    offering: Joi.object().keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
    }).optional(),

    type: Joi.object().keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
    }).required(),

    activity: Joi.object().keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),

        model: Joi.object().keys({
            id: Joi.string().uuid({ version: 'uuidv4' }).required(),

            code: Joi.string().trim().min(1).max(50).required(),

            model: Joi.string().trim().min(1).max(50).required(),

            target: Joi.number().required()
        }),
    }).required(),

    variable: Joi.object().keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
    }).required(),

    category: Joi.object().keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),

        model: Joi.object().keys({
            id: Joi.string().uuid({ version: 'uuidv4' }).required(),

            code: Joi.string().trim().min(1).max(50).required(),

            model: Joi.string().trim().min(1).max(50).required(),

            target: Joi.number().required()
        }),
    }).optional(),

    period: Joi.object().keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
    }).required(),

    number: Joi.number().positive().required(),

    staff: Joi.array().items(Joi.object().keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
    }).unknown()).unique('id'),

    share: Joi.array().items(Joi.object().keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
    }).unknown()),

    calculated: Joi.array().items(Joi.number().min(0).optional()).optional(),

    assigned: Joi.array().items(Joi.number().min(0).optional()).optional(),

    note: Joi.string().trim().allow('').max(250).optional(),
}).unknown();

