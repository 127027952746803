import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';
import { Spinner } from '..';
import { SelectBox, MessageBlock, StaffAutoComplete } from '..';
import Dropdown from '../ui/drop-down';
import HelpDocument from '../help';
import { ActivityStore, AllocationStore } from '../../mobx';
import {
  AllocationTypeDto,
  WorkShareDto,
  StaffDto,
  ActivityDto,
  VariableDto,
  PeriodDto,
  ProfileCategoryDto,
  OfferingDto,
  AllocationDto,
  ModelDto,
  CourseDto,
} from '../../dto';
import { Allocation as Validator } from '../../validations';
import { DialogContent } from '.';
import './add-edit-activity.css';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { numericInputHandler, onNumbersOnly } from '../../utility';

type Props = {
  title: string,
  year: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSubmit: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function,
  allocation: AllocationDto,
  data: {
    types: AllocationTypeDto[],
    categories: ProfileCategoryDto[],
    variables: VariableDto[],
    staff: StaffDto[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    workshares: any[],
    periods: PeriodDto[],
    model: ModelDto,
  },
  course: CourseDto,
  staff: StaffDto,
  category: string,
};

type State = {
  rebuildState: boolean,
  visible: boolean,
  type: AllocationTypeDto,
  category: ProfileCategoryDto,
  activity: ActivityDto,
  variable: VariableDto,
  number: number,
  typeList: AllocationTypeDto[],
  members: {
    staff: StaffDto,
    share: WorkShareDto,
    calculated: number,
    assigned: number,
    hours: number,
  }[],
  activityList: ActivityDto[],
  variableList: VariableDto[],
  period: PeriodDto,
  failed: boolean,
  offering: OfferingDto,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabled: any,
  course: CourseDto,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  commits: any[],
  note: string,
  noted: boolean,
  saving: boolean,
  loading: boolean,
  initialised: boolean,
  initialising: boolean,
  messaging: { lines: string[], type: string },
  numberReadOnly: boolean,
  ignoreDuplicateWarning: boolean,
  fileName: string,
};

const activityStore = new ActivityStore();
const allocationStore = new AllocationStore();

class AddEditStaffActivityDialog extends Component<Props, State> {
  // Flow handle functions
  onUpdateForm: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialogRef: any;

  constructor(props) {
    super(props);
    this.state = {
      rebuildState: false,
      visible: false,
      type: null,
      typeList: [],
      category: null,
      activity: null,
      offering: null,
      course: null,
      activityList: [],
      variableList: [],
      variable: null,
      number: 0,
      members: [],
      period: null,
      commits: [],
      disabled: {},
      messaging: { lines: [], type: '' },
      value: '',
      noted: false,
      note: '',
      failed: false,
      saving: false,
      loading: false,
      initialised: false,
      initialising: false,
      numberReadOnly: false,
      ignoreDuplicateWarning: false,
      fileName: null,
    };

    this.dialogRef = React.createRef();
    this.onUpdateForm = this.onUpdateForm.bind(this);
    this.onUpdateDuplicateForm = this.onUpdateDuplicateForm.bind(this);
  }

  onCancel = () => {
    this.onCleanMessage();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onCleanMessage = () => {
    this.setState({ messaging: { lines: [], type: '' } });
  };

  onPopulate = () => {
    if (this.props.allocation) {
      const _members = [
        {
          staff: this.props.allocation.staff,
          share: this.props.allocation.share,
          calculated: this.props.allocation.calculated,
          assigned: this.props.allocation.assigned,
          hours: this.props.allocation.hours,
        },
      ];
      if (this.props.allocation.linked) {
        _members.push(
          ...this.props.allocation.linked.map((s) => {
            return {
              staff: s.staff,
              share: s.share,
              calculated: s.calculated,
              assigned: s.assigned,
              hours: s.hours,
            };
          })
        );
      }

      this.setState({
        typeList: null,
        type: this.props.allocation.activity.type,
        rebuildState: false,
        commits: [],
        members: _members,

        number: this.props.allocation.unit,
        period: this.props.allocation.period,
        note: this.props.allocation.notes || '',
        activity: this.props.allocation.activity,
        course: this.props.allocation.course,
        offering: this.props.allocation.offering,
        variable: this.props.allocation.variable,
        fileName: this.props.data.model.code.toUpperCase() + ' NumberVariable Help File.pdf',
      });
      this.onVariableChange(this.props.allocation.variable, this.props.allocation.unit);
    } else {
      const percent100 = this.props.data.workshares.find((w) => w.value === 1);
      this.setState({
        typeList: [],
        number: 0,
        members: [
          {
            staff: this.props.staff,
            share: percent100,
            calculated: 0,
            assigned: 0,
            hours: 0,
          },
        ],
        period: null,
        rebuildState: false,
        note: '',
        activity: null,
        course: null,
        offering: null,
        variable: null,
        fileName: this.props.data.model.code.toUpperCase() + ' NumberVariable Help File.pdf',
      });
    }
  };

  componentDidMount() {
    this.onCleanMessage();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.dialogRef.current && !this.state.initialised && !this.state.initialising) {
      this.setState({
        initialising: true,
        disabled: {
          staffDisabling: true,
          category: true,
          variable: true,
        },
        loading: true,
      });

      const nodes = document
        .querySelector('.add-edit-activity-dialog')
        .querySelectorAll('button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const focusables = Array.from(nodes).filter((e) => !e.disabled);
      var first = focusables[0];
      var last = focusables[focusables.length - 1];

      last.onkeydown = (e) => {
        if (e.which === 9 && !e.shiftKey) {
          e.preventDefault();
          first.focus();
        }
      };
      first.onkeydown = (e) => {
        if (e.which === 9 && e.shiftKey) {
          e.preventDefault();
          last.focus();
        }
      };

      first.focus();

      const category = this.props.data.categories.find((c) => c.code === this.props.category);
      const type = this.props.data.types.find((t) => t.code === 'staff');

      activityStore
        .getActivitiesForStaff(category.id, this.props.staff.model.id, type.id)
        .then((activities) => {
          this.setState({
            category: category,
            type: type,
            activityList: activities.map((d) => mobx.toJS(d)),
          });

          activityStore
            .getVariablesForStaff(
              this.props.staff.model.id,
              this.state.category.id,
              this.state.type.id
            )
            .then((variables) => {
              this.onPopulate();

              this.setState({
                variableList: variables.map((d) => mobx.toJS(d)),
                initialised: true,
                initialising: false,
                loading: false,
              });
            });
        });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onCategoryChange = (value) => {};

  onActivityChange = (value) => {
    this.setState({ activity: value, category: value.category });

    const _variable = this.state.variableList
      .map((v) => mobx.toJS(v))
      .find((d) => d.id === value.variable.id);
    this.onVariableChange(_variable);
  };

  onVariableChange = (value, numberValue) => {
    this.setState({ variable: value });

    switch (value ? value.code : null) {
      case 'student': {
        switch (this.state.type ? this.state.type.code : null) {
          case 'offering':
            this.setState({
              number: this.state.offering ? this.state.offering.enrolments : 0,
              numberReadOnly: true,
            });
            break;
          case 'course':
            this.setState({
              number: this.props.course ? this.props.course.enrolments : 0,
              numberReadOnly: true,
            });
            break;
          default:
            this.setState({ number: 0, numberReadOnly: true });
        }
        break;
      }
      case 'one_off':
        this.setState({
          number: 1,
          numberReadOnly: true,
        });
        break;
      default:
        this.setState({ number: numberValue ?? 0, numberReadOnly: false });
    }
  };

  onNumberChange = (e) => {
    this.setState({ number: e.target.value });
  };

  onStaffMemberChange = (value, index) => {
    const _members = this.state.members;
    _members[index].staff = value;
    this.setState({ members: _members });
  };

  onWorkShareChange = (value, index) => {
    const _members = this.state.members;
    _members[index].share = value;
    this.setState({ members: _members });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onCalculatedChange = (value, index) => {};

  onAssignedLeave = (value, index) => {
    //const assigned = this.state.members.map((m) => m.assigned).reduce((a, c) => a + c);
    const hours = this.state.members
      .map((m) => Number(!m.hours || m.hours === 0 ? m.calculated : m.hours))
      .reduce((a, c) => a + c);
    const calculated = this.state.members.map((m) => m.calculated).reduce((a, c) => a + c);

    if (!value) {
      this.onAssignedChange(this.state.members[index].calculated, index);
    }

    this.setState({
      messaging: {
        lines:
          hours !== calculated ? ['A note is required to explain the adjusted assigned hours'] : [],
        type: hours !== calculated ? 'info' : '',
        visible: hours !== calculated,
      },
    });
  };

  onAssignedChange = (value: number, index: number) => {
    const _members = this.state.members;
    _members[index].hours = value;

    this.setState({ members: _members });
  };

  onNoteChange = (e) => {
    this.setState({ note: e.target.value, noted: true });
  };

  onPeriodChange = (value) => {
    this.setState({ period: value });
  };

  onAddMoreStaff = () => {
    const percent100 = this.props.data.workshares.find((w) => w.value === 1);
    this.setState({
      members: [
        ...this.state.members,
        {
          staff: null,
          share: percent100,
          hours: 0,
          calculated: 0,
          assigned: 0,
        },
      ],
    });
  };

  onRemoveStaff = (index: number) => {
    const _members = this.state.members;
    _members.splice(index, 1);
    this.setState({ members: _members, rebuildState: true });
  };

  async validateForm() {
    const validation = {
      type: { id: this.state.type.id },
      category: { id: this.state.category.id },
      activity: this.state.activity
        ? { id: this.state.activity.id, model: this.state.activity.model }
        : undefined,
      variable: this.state.variable ? { id: this.state.variable.id } : undefined,
      number: this.state.number,
      members: this.state.members,
      period: this.state.period ? { id: this.state.period.id } : undefined,
      note: this.state.note,
      noted: this.state.noted,
    };

    const msg = await Validator.validate(validation, 'staff');

    if (msg.length > 0) {
      this.setState({
        messaging: {
          lines: msg.filter((m, p, s) => s.indexOf(m) === p),
          type: 'error',
        },
      });
      return false;
    }

    return true;
  }

  // eslint-disable-next-line no-dupe-class-members
  async onUpdateForm(event) {
    event.preventDefault();
    this.onCleanMessage();
    const validateResult = await this.validateForm();

    if (validateResult) {
      const allocations: AllocationDto[] = this.state.members.map((member, i) => {
        const allocation = {
          type: { id: this.state.type.id },
          category: { id: this.state.category.id },
          activity: this.state.activity
            ? {
                id: this.state.activity.id,
                model: this.state.activity.model,
                type: this.state.activity.type,
              }
            : undefined,
          variable: this.state.variable ? { id: this.state.variable.id } : undefined,
          unit: Number(this.state.number),
          staff: { id: member.staff.id },
          share: { id: member.share.id },
          calculated: member.calculated,
          assigned: Number(
            member.hours ? (member.hours == member.calculated ? 0 : member.hours) : 0
          ),
          period: this.state.period ? { id: this.state.period.id } : undefined,
          notes: this.state.note ? this.state.note : '',
          year: this.props.year,
          enrolments: 0,
          offerings: 0,
        };

        if (this.props.allocation) {
          const allocates = this.props.allocation.linked
            ? [this.props.allocation, ...this.props.allocation.linked]
            : [this.props.allocation];
          // If no result for staff.id must be an update to the staff members. Get the details of the first or only else set undefined to flag as new addition
          const allocate =
            allocates.find((a) => a.staff.id === member.staff.id) ||
            (i === 0 ? allocates[0] : undefined);
          if (allocate) {
            allocation.id = allocate.id;
            allocation.linkId = allocate.linkId ? allocate.linkId : undefined;
            allocation.enrolments = allocate.enrolments ? allocate.enrolments : 0;
            allocation.offerings = allocate.offerings ? allocate.offerings : 0;

            //Even though set above, if this is an update ensure any note entered does not overide over staff. So set to undefined
            //TODO REVIEW_IF_REQUIRED
            //allocation.notes = (this.props.staff.id == staff.id ? this.state.note : undefined);
          }
        }

        return allocation;
      });

      if (!duplicateExists?.result) {
        this.setState({
          loading: true,
        });
      }

      const duplicateExists = await allocationStore.checkDuplicates(allocations);
      if (duplicateExists.result && !this.state.ignoreDuplicateWarning) {
          this.setState({
            loading: false,
            messaging: {
              lines: ['A duplicate record exists. Are you sure you want to save this activity?'],
              type: 'warning',
            },
          });
        return;
      }

      if (allocations?.length > 0) {
        this.onSave(allocations);
      }
    }
  }

  async onUpdateDuplicateForm(event) {
    this.setState({ ignoreDuplicateWarning: true });
    await this.onUpdateForm(event);
  }

  onCancelDuplicateForm = () => {
    this.setState({
      failed: false,
      messaging: { lines: [], type: '' },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSaveResult = (result: { success: boolean, allocation: any, error: any }) => {
    if (result.success) {
      this.onPopulate();
      this.onCleanMessage();
      this.setState({
        saving: false,
        disabled: {
          staffDisabling: true,
          category: true,
          variable: true,
        },
        initialised: false,
        initialising: false,
      });

      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    } else {
      this.setState({ saving: false, failed: true });
      setTimeout(() => {
        this.setState({ failed: false });
      }, 8000);
    }
  };

  onSave = (commits: AllocationDto[]) => {
    this.setState({
      saving: true,
      ignoreDuplicateWarning: false,
    });

    //Check if it is a Individual allocation. If linkId exists then treat as a [Linked to Individual] update.
    if (commits.length === 1 && !commits.some((c) => c.linkId != null)) {
      if (this.props.allocation) {
        allocationStore.update(commits[0]).then((data) => this.onSaveResult(data));
      } else {
        allocationStore.add(commits[0]).then((data) => this.onSaveResult(data));
      }
    } else {
      if (this.props.allocation) {
        allocationStore.updateLinked(commits).then((data) => this.onSaveResult(data));
      } else {
        allocationStore.addLinked(commits).then((data) => this.onSaveResult(data));
      }
    }
  };

  renderWorkshare = (content: { staffMember: string, workShare: string }) => {
    const sorted = this.props.data?.staff?.slice().sort(function(a, b) {
      return a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0;
    });
    const shares = this.props.data.workshares.slice().sort(function(a, b) {
      return a.value > b.value ? -1 : b.value > a.value ? 1 : 0;
    });

    const elements = this.state.members.map((member, index) => (
      <div
        key={index}
        className='staff-frame'
      >
        <div className='staff-options'>
          <label className='k-form-field'>
            <span>{content.staffMember}:</span>
            <StaffAutoComplete
              data={sorted}
              onChange={this.onStaffMemberChange}
              index={index}
              rebuildState={this.state.rebuildState}
              rebuildData={this.state.members.map((m) => m.staff)}
              disabling={this.state.disabled.staffDisabling}
              staff={member.staff}
            />
          </label>

          <label className='k-form-field'>
            <span>{content.workShare}:</span>
            <SelectBox
              data={shares}
              onChange={this.onWorkShareChange}
              index={index}
              fields={{ value: 'id', display: 'share' }}
              rebuildState={this.state.rebuildState}
              rebuildData={this.state.members.map((m) => m.share)}
              value={member.share}
            />
          </label>

          {this.props.allocation && (
            <label style={{ flexDirection: 'column' }}>
              <div className='k-form-field staff-hours-label'>
                <span>Hours:</span>
                <div className='add-edit-activity-input-group'>
                  <div className='add-edit-activity-group-input'>
                    <input
                      className='k-text-box'
                      maxLength='4'
                      value={member.calculated}
                      disabled={true}
                      onChange={this.onCalculatedChange}
                      onKeyPress={onNumbersOnly}
                    />
                    <span className='group-input-label'>Calculated</span>
                  </div>
                  <div className='add-edit-activity-group-input'>
                    <input
                      className='k-text-box'
                      maxLength='7'
                      value={member.hours}
                      onChange={(e) => this.onAssignedChange(e.target.value, index)}
                      onBlur={(e) => this.onAssignedLeave(e.target.value, index)}
                      onKeyPress={numericInputHandler}
                    />
                    <span className='group-input-label'>Assigned</span>
                  </div>
                </div>
              </div>
            </label>
          )}
        </div>
        {index > 0 && (
          <div className='staff-remove-button'>
            <Button
              type='button'
              icon={<CloseOutlined />}
              look='flat'
              onClick={() => this.onRemoveStaff(index)}
            ></Button>
          </div>
        )}
      </div>
    ));

    return elements;
  };

  render() {
    const content = DialogContent.StaffActivity;

    return (
      <div className='add-edit-activity-frame'>
        {!this.state.loading && (
          <div ref={this.dialogRef}>
            <Modal
              width={'auto'}
              centered
              open={!this.state.loading}
              footer={null}
              title={
                this.props.title
                  ? this.props.title
                  : this.props.allocation
                  ? 'Edit Activity'
                  : 'Add Activity'
              }
              onCancel={this.onCancel}
            >
              <div className='add-edit-activity-dialog'>
                <form className='form-inline'>
                  <Dropdown
                    title={content.category}
                    value={this.state.category}
                    data={this.props.data.categories}
                    onChange={this.onCategoryChange}
                    fields={{ value: 'id', display: 'category' }}
                    disabled={this.state.disabled.category}
                  />

                  <Dropdown
                    title={content.activity}
                    value={this.state.activity}
                    data={this.state.activityList}
                    onChange={this.onActivityChange}
                    fields={{ value: 'id', display: 'activity' }}
                    prompt={{
                      text: DialogContent.DefaultSelectOption,
                    }}
                  />

                  <Dropdown
                    title={content.variable}
                    value={this.state.variable}
                    data={this.state.variableList}
                    onChange={this.onVariableChange}
                    fields={{ value: 'id', display: 'variable' }}
                    disabled={this.state.disabled.variable}
                    prompt={{
                      text: DialogContent.DefaultSelectOption,
                    }}
                  />

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>
                        {content.number}:
                        <HelpDocument reportName={this.state.fileName} />
                      </span>
                      <input
                        className='k-text-box'
                        value={this.state.number}
                        onChange={this.onNumberChange}
                        onKeyPress={onNumbersOnly}
                        readOnly={this.state.numberReadOnly}
                      />
                    </div>
                  </label>

                  {this.renderWorkshare({
                    staffMember: content.staffMember,
                    workShare: content.workShare,
                  })}

                  <Dropdown
                    title={content.period}
                    value={this.state.period}
                    data={this.props.data.periods}
                    onChange={this.onPeriodChange}
                    fields={{ value: 'id', display: 'period' }}
                    prompt={{
                      text: DialogContent.DefaultSelectOption,
                    }}
                  />

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>{content.note}:</span>
                      <textarea
                        value={this.state.note}
                        onChange={this.onNoteChange}
                        className='textarea-frame'
                      />
                    </div>
                  </label>

                  <div className='k-form-error-field'>
                    {this.state.failed && (
                      <ul className='failed-submit-error-msg'>
                        <li>
                          <span className='k-icon k-i-warning'></span> An error has occured while
                          attempting to submit allocation
                        </li>
                      </ul>
                    )}
                    <MessageBlock
                      visible={this.state.messaging.lines.length > 0}
                      lines={this.state.messaging.lines}
                      type={this.state.messaging.type}
                    >
                      {this.state.messaging.type === 'warning' && (
                        <div className='form-buttons-warning'>
                          <Button
                            id='submitButton'
                            type='submit'
                            onClick={this.onUpdateDuplicateForm}
                            look='outline'
                            className='form-button save'
                          >
                            Continue
                          </Button>
                          <Button
                            id='cancelButton'
                            type='button'
                            onClick={this.onCancelDuplicateForm}
                            look='outline'
                            className='form-button cancel'
                          >
                            Cancel
                          </Button>
                        </div>
                      )}
                    </MessageBlock>
                  </div>

                  {this.state.messaging.type !== 'warning' && (
                    <div className='form-buttons-frame'>
                      <Button
                        type='button'
                        onClick={this.onAddMoreStaff}
                        icon={<PlusOutlined />}
                        look='outline'
                        className='form-button add-staff'
                      >
                        Add More Staff
                      </Button>
                      <Button
                        type='submit'
                        onClick={this.onUpdateForm}
                        look='outline'
                        className='form-button save'
                      >
                        Save
                      </Button>
                      <Button
                        type='button'
                        onClick={this.onCancel}
                        look='outline'
                        className='form-button cancel'
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </form>
              </div>
            </Modal>
          </div>
        )}

        {(this.state.saving || this.state.loading) && (
          <Spinner text={this.state.loading ? 'Loading' : 'Saving'} />
        )}
      </div>
    );
  }
}

export default observer(AddEditStaffActivityDialog);
