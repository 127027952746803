//@flow
import Api from './api';
import { makeAutoObservable } from 'mobx';

export default class ScheduledReportingStore {
  constructor() {
    makeAutoObservable(this);
  }

  async getSummary() {
    return new Promise((resolve, reject) => {
      Api.get('/scheduled/reporting/summary').then(
        (data) => {
          resolve({
            ...data,
            owned: data.owned.map((d) => (d.state === 'processing' ? { ...d, locked: true } : d)),
          });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async getStatus(id: string) {
    return new Promise((resolve, reject) => {
      Api.get(`/scheduled/reporting/status/${id}`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async setCancel(id: string) {
    return new Promise((resolve, reject) => {
      this.getStatus(id).then(
        (status) => {
          if (status !== 'processing') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Api.delete(`/scheduled/reporting/cancel/${id}`).then(
              (_) => {
                resolve();
              },
              (e) => {
                reject(e);
              }
            );
          } else {
            resolve();
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
