// @flow

import React, { Component } from 'react';

import { SearchCheckBox } from '..';
import { CheckBoxDto } from '../../dto/clientDto';
import './search-components.css';

type Props = {
  checkBoxList: CheckBoxDto[],
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCheckBoxChange: Function,
  prompt: string,
  disabled: Boolean
};

class SearchCheckBoxList extends Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCheckBoxChange = (target: any) => {
    this.props.onCheckBoxChange(target);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.checkBoxList !== this.props.checkBoxList) {
      this.setState({
        checkBoxList: this.props.checkBoxList,
      });
    }
  }

  render() {
    const checkBox = this.props.checkBoxList.map((val) => {
      return (
        <SearchCheckBox
          disabled={this.props.disabled}
          key={val.id}
          id={val.id}
          title={val.title}
          check={val.check}
          onChange={this.onCheckBoxChange}
        />
      );
    });

    return (
      <div className='search-detail-body-check-list'>
        {this.props.prompt && <p className='search-check-list-prompt'>{this.props.prompt}</p>}
        {checkBox}
      </div>
    );
  }
}

export default SearchCheckBoxList;
