import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from 'antd';
import { ActionButton } from '../../components';
import { PlusOutlined } from '@ant-design/icons';
import MessageLine from '../../components/message/message-line';
import { AllocationYearDto } from '../../dto';
import './admin.css';

type Props = {
    onDialogChange: Function,
    onSpinnerChange: Function,
};

type State = {
    addingYear: Boolean,
    confirmYear: numberlike,
    importYears: AllocationYearDto[],
    message: {
        task: 'year',
        visible: boolean,
        type: string,
        msg: string,
    },
    pendingYears: AllocationYearDto[],
    removeYear: AllocationYearDto,
    updateYear: AllocationYearDto,
    updatingYear: Boolean,
}

class Year extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.yearStore = this.props.yearStore;
        this.viewStore = this.props.viewStore;
        this.state = {
            addingYear: false,
            confirmYear: null,
            importYears: [],
            message: { visible: false },
            pendingYears: [],
            updateYear: null,
            updatingYear: false,
            removeYear: null,
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        await this.yearStore.getYearStatuses();
        await this.yearStore.getYears(true);
        await this.viewStore.getCurrentYear();

        const _import = this.yearStore.years.filter((y) => y.import);
        const pending = this.yearStore.years.filter((y) => y.pending);
    
        this.setState({
            importYears: _import,
            updateYear: this.viewStore.year,
            pendingYears: pending,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.updateYear == null) {
            this.setState({
                updateYear: this.viewStore.year,
            });
        }
    }

    cleanMessage = () => {
        this.setState({ message: { task: '', visible: false, type: '', msg: '' } });
    };

    onYearChange = (value) => {
        this.setState({
            updateYear: this.yearStore.years.find((y) => y.year == value),
        });
    };

    onRemoveYearChange = (value) => {
        this.setState({
            removeYear: this.yearStore.years.find((y) => y.year == value),
        });
    };

    onYearStatusChange = (event) => {
        const status = this.yearStore.statuses.find((s) => s.id == event.value);
        const update = {
            ...this.state.updateYear,
            closed: false,
            open: false,
            current: false,
            status,
        };
        update[status.code] = true;
        this.setState({ updateYear: update });
    };

    onAddYear = () => {
        const year =
            Math.max.apply(
                Math,
                this.yearStore.years.map((y) => y.year)
            ) + 1;
        this.setState({
            confirmYear: year,
            addingYear: true,
        });
        this.props.onDialogChange(
            {
                visible: true,
                title: `Please Confirm (Add ${year})`,
                lines: [
                    <span key='year'>
                        You are about to add the <span className='important'>{year}</span> Workload Academic
                        Year.
                    </span>,
                    <span key='pending'>
                        The status will default to <span className='important'>Rollover</span> and will then
                        become available for Annual Rollover.
                    </span>,
                    'Do you wish to continue?',
                ],
                callback: (confirmed: boolean) => {
                    const year = this.state.confirmYear;
                    this.props.onDialogChange({});
                    if (this._isMounted) {
                        this.setState({
                            confirmYear: null,
                            addingYear: confirmed,
                        });
                    }

                    if (confirmed) {
                        const newYear = {
                            year,
                            status: {
                                id: this.yearStore.statuses.find((s) => s.code == 'pending').id,
                            },
                        };
                        this.yearStore
                            .setYearAdd(newYear)
                            .then((data) => {
                                this.yearStore.getYears(true, true).then(() => {
                                    this.viewStore.getRefresh().then(() => {
                                        if (this._isMounted) {
                                            this.setState({
                                                updateYear: data.result,
                                                addingYear: false,
                                                message: {
                                                    task: 'year',
                                                    type: data.success ? 'success' : 'warn',
                                                    visible: true,
                                                    msg: data.success
                                                        ? `${year} Successfully Added`
                                                        : `Failed to add the ${year} Academic Year`,
                                                },
                                                pendingYears: this.yearStore.years.filter((y) => y.pending),
                                            },
                                                () => {
                                                    setTimeout(() => {
                                                        this.cleanMessage();
                                                    }, 5000);
                                                }
                                            );
                                        }
                                    });
                                });
                            })
                            .catch(() => {
                                this.setState(
                                    {
                                        addingYear: false,
                                        message: {
                                            task: 'year',
                                            type: 'error',
                                            visible: true,
                                            msg: `An error has occurred while attempting to add the ${year} Academic Year`,
                                        },
                                    },
                                    () => {
                                        setTimeout(() => {
                                            this.cleanMessage();
                                        }, 5000);
                                    }
                                );
                            });
                    }
                },
            },
        );
    };

    onUpdateYear = () => {
        this.setState({ updatingYear: true });
        const updates = [this.state.updateYear];
        if (this.state.updateYear?.current) {
            const status = this.yearStore.statuses.find((s) => s.code == 'open');
            const existing = this.yearStore.years.find((y) => y.current);
            updates.push({ ...existing, open: true, current: false, status });
        }

        this.yearStore
            .setYearUpdates(updates)
            .then((data) => {
                if (this._isMounted) {
                    this.setState((prevState) => {
                        return {
                            importYears: this.yearStore.years.filter((y) => y.import),
                            updatingYear: false,
                            message: {
                                task: 'year',
                                type: data.success ? 'success' : 'warn',
                                visible: true,
                                msg: `${prevState.updateYear?.year} ${data.success ? 'Successfully Updated' : 'Failed to Update'
                                    }`,
                            },
                            pendingYears: this.yearStore.years.filter((y) => y.pending),
                        };
                    },
                        () => {
                            setTimeout(() => {
                                this.cleanMessage();
                            }, 5000);
                        }
                    );
                    if (data.success) {
                        this.viewStore.getCurrentYear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                if (this._isMounted) {
                    this.setState((prevState) => {
                        return {
                            updatingYear: false,
                            message: {
                                task: 'year',
                                type: 'error',
                                visible: true,
                                msg: `An error occurred while attempting to update ${prevState.updateYear?.year}`,
                            },
                        };
                    },
                        () => {
                            setTimeout(() => {
                                this.cleanMessage();
                            }, 5000);
                        }
                    );
                }
            });
    };

    onRemoveYear = () => {
        if(this.YearRemoveEnabled){
            this.props.onDialogChange(
                {
                    visible: true,
                    title: `Please Confirm (Remove ${this.state.removeYear?.year})`,
                    lines: [
                        <span key='dialog'>
                            <span className='caution'>WARNING</span> You are about to remove the{' '}
                            <span className='important'>{this.state.removeYear?.year}</span> Workload Academic Year.
                        </span>,

                        <span className='important' key='note'>
                            Please Note: This action may last several minutes, it will Delete all records associated
                            with this academic year and cannot be undone.
                        </span>,
                        'Do you wish to continue?',
                    ],
                    callback: (confirmed: boolean) => {
                        this.props.onDialogChange({});
                        if (confirmed) {
                            this.props.onSpinnerChange(
                                {
                                    visible: true,
                                    text: `Removing the ${this.state.removeYear?.year} Academic Year`,
                                }
                            );
                            this.yearStore
                                .setYearRemove(this.state.removeYear?.year)
                                .then((data) => {
                                    this.yearStore.getYears(true, true).then(() => {
                                        this.viewStore.getRefresh().then(() => {
                                            if (this._isMounted) {
                                                this.props.onSpinnerChange({ visible: false, text: '' });
                                                if (this._isMounted) {
                                                    this.setState((prevState) => {
                                                        return {
                                                            importYears: this.yearStore.years.filter((y) => y.import),
                                                            updateYear: this.viewStore.year,
                                                            message: {
                                                                task: 'year',
                                                                type: data.success ? 'success' : 'warn',
                                                                visible: true,
                                                                msg: data.success
                                                                    ? `${this.state.removeYear?.year} Successfully Removed`
                                                                    : `Failed to remove the ${prevState.removeYear?.year} Academic Year`,
                                                            },
                                                            pendingYears: this.yearStore.years.filter((y) => y.pending),
                                                            removeYear: null,
                                                        }
                                                    },
                                                        () => {
                                                            setTimeout(() => {
                                                                this.cleanMessage();
                                                            }, 5000);
                                                        }
                                                    );
                                                }
                                            }
                                        });
                                    });
                                })
                                .catch(() => {
                                    this.props.onSpinnerChange({ visible: false, text: '' });
                                    if (this._isMounted) {
                                        this.setState(
                                            {
                                                message: {
                                                    task: 'year',
                                                    type: 'error',
                                                    visible: true,
                                                    msg: `An error has occurred while attempting to remove the ${this.state.removeYear?.year} Academic Year`,
                                                },
                                            },
                                            () => {
                                                setTimeout(() => {
                                                    this.cleanMessage();
                                                }, 5000);
                                            }
                                        );
                                    }
                                });
                        }
                    },
                }
            );
        }
    };

    get YearUpdateEnabled() {
        return (
            (this.state.updateYear?.year != this.viewStore.year.year &&
                !(
                    this.state.updateYear?.current &&
                    !this.yearStore.years.some((y) => y.current && y.year != this.state.updateYear?.year)
                )) ||
            this.state.updateYear?.status != this.viewStore.year.status
        );
    }

    get YearRemoveEnabled() {
        return (
            this.state.pendingYears?.some((y) => y.year == this.state.removeYear?.year) ||
            this.state.importYears?.some((y) => y.year == this.state.removeYear?.year) 
        ) && this.state.removeYear?.year != this.viewStore.year.year;
    }

    render() {
        const { Option } = Select;
        return (
            <div className='management-block'>
                <div className='management-row'>

                    <div className='management-column  right-seperator'>
                        <div className='item-card title'>
                            <div className='title-label'>Add</div>
                        </div>
                        <div className='item-card management-tip'>
                            <p>
                                <span className={'k-icon k-i-information'}></span> Add a new academic year.
                            </p>
                        </div>
                        <div className='item-card'>
                            <ActionButton
                                on={this.onAddYear}
                                active={this.state.addingYear}
                                icon={<PlusOutlined />}
                                className='management-button add-year-button'
                                text={'Add New Academic Year'}
                                enabled={true}
                            />
                        </div>
                    </div>

                    <div className='management-column right-seperator'>
                        <div className='item-card title'>
                            <div className='title-label'>Update</div>
                        </div>
                        <div className='item-card management-tip'>
                            <p>
                                <span className={'k-icon k-i-information'}></span> Manage the status of
                                avaliable academic years.
                            </p>
                        </div>
                        <div className='item-card'>
                            <span className='management-label'>Year:</span>
                            <Select
                                className='management-dropdown year-select'
                                value={this.state.updateYear?.year}
                                style={{ width: 200, boxSizing: 'border-box' }}
                                onChange={this.onYearChange}
                                disabled={this.state.updatingYear}
                            >
                                {this.yearStore.years && this.yearStore.years.map((year) => (
                                    <Option
                                        key={year.year}
                                        value={year.year}
                                    >
                                        {year.year}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className='item-card'>
                            <span className='management-label'>Status:</span>
                            <Select
                                className={`management-dropdown status-select status-${this.state.updateYear?.status?.code}`}
                                labelInValue
                                value={{
                                    value: this.state.updateYear?.status?.id,
                                    label: this.state.updateYear?.status?.status,
                                }}
                                style={{ width: 200, boxSizing: 'border-box' }}
                                onChange={this.onYearStatusChange}
                            >
                                {this.yearStore.statuses && this.yearStore.statuses.map((status) => (
                                    <Option
                                        className={`status-list-item ${status.code}`}
                                        key={status.id}
                                        value={status.id}
                                    >
                                        {status.status}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        <div className='item-card management-action'>
                            <ActionButton
                                on={this.onUpdateYear}
                                active={this.state.updatingYear}
                                className='management-button'
                                text={'Update'}
                                enabled={this.YearUpdateEnabled}
                            />
                        </div>
                    </div>

                    <div className='management-column'>
                        <div className='item-card title'>
                            <div className='title-label'>Remove</div>
                        </div>
                        <div className='item-card management-tip'>
                            <p>
                                <span className={'k-icon k-i-information'}></span> Remove academic year.
                            </p>
                        </div>
                        <div className='item-card'>
                            <span className='management-label'>Year:</span>
                            <Select
                                className='management-dropdown year-select'
                                value={this.state.removeYear?.year}
                                style={{ width: 200, boxSizing: 'border-box' }}
                                onChange={this.onRemoveYearChange}
                            >
                                {this.yearStore.years && this.yearStore.years.map((year) => (
                                    <Option
                                        key={year.year}
                                        value={year.year}
                                    >
                                        {year.year}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        <div className='item-card management-action'>
                            <ActionButton
                                on={this.onRemoveYear}
                                active={this.state.updatingYear}
                                className='management-button caution'
                                text={'Remove Year'}
                                enabled={this.YearRemoveEnabled}
                            />
                        </div>

                        <div className='item-card remove-action-warning'>
                            { (!this.YearRemoveEnabled && this.state.removeYear?.year && this.state.removeYear?.year != this.viewStore.year.year) &&
                            <span>
                                To remove an academic year, it must first have a Rollover status.
                            </span>
                            }
                            { (!this.YearRemoveEnabled && this.state.removeYear?.year == this.viewStore.year.year) &&
                            <span>
                                The currently selected / viewed year cannot be removed.
                            </span>
                            }
                        </div>
                    </div>
                </div>
                <MessageLine
                    visible={this.state.message.visible && this.state.message.task == 'year'}
                    type={this.state.message.type}
                    line={this.state.message.msg}
                />
            </div>
        );
    }
}

export default inject(
    'viewStore',
    'yearStore',
)(observer(Year));