import React, { Component } from 'react';
import { ReportSearchStore } from '../../mobx';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './help-document.css';

class HelpDocument extends Component {
  getLoadNumberHelpFile = async() => {
    const reportSearch = {
      id: 'sys',
      reportName: this.props.reportName,
      model: null,
      owner: null,
      department: null,
      createDate: null,
    };
    const reportSearchStore = new ReportSearchStore();
    let resultString = await reportSearchStore.getReportBlob(reportSearch);

    if (reportSearch?.reportName.slice(-3) === 'pdf') {
      const binaryString = Buffer.from(resultString).toString();
      const binaryLen = binaryString.length;

      let bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      resultString = new Blob([bytes], { type: 'application/pdf' });
    }
    var fileURL = URL.createObjectURL(resultString);
    window.open(fileURL, '_blank');
  };

  render() {
    return (
      <Link
        className='linked-allocation-view'
        to={'#'}
        onClick={this.getLoadNumberHelpFile}
      >
        <InfoCircleOutlined />
      </Link>
    );
  }
}

export default HelpDocument;
