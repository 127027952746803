//@flow
import Api from './api';
import { makeAutoObservable } from 'mobx';
import FileDownload from 'js-file-download';
import { ExportParameterDto } from '../dto';

export default class ExportStore {
  allocatedYears: number[];
  
  constructor() {
    makeAutoObservable(this);
  }

  async init() {
    await this.getAllocatedYears();
  }


  async getDownload(params: { id: string, userId: string }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      Api.blobPost(`/exports/download`, { id: params.id, user: params.userId })
        .then((data) => {
          const blob = new Blob([data.blob], { type: data.type });
          FileDownload(blob, data.fileName, data.mine);
          resolve({ success: true });
        })
        .catch((e) => {
          if(e.response?.status === 404) {
            e.message = 'User not authorized to view this download or the download does not exist';
          }
          reject({ success: false, error: e, message: e.message});
        });
    });
  }

  async getStaffAllocationExport(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { model: any, department: any, criteria: any, ids: string[] },
    year: number,
    emailed = false
  ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobPost(`/exports/allocation/${year}/staff`, data)
          .then((response) => {
            const blob = new Blob([response.blob], { type: response.type });
            FileDownload(blob, response.fileName, response.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.post(`/exports/allocation/${year}/staff?emailed=true`, data).then(() => {
          resolve({ success: true });
        });
      }
    });
  }

  async getStaffIdAllocationExport(id: string, year: number, emailed = false) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobGet(`/exports/allocation/${year}/staff/${id}`)
          .then((response) => {
            const blob = new Blob([response.blob], { type: response.type });
            FileDownload(blob, response.fileName, response.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.get(`/exports/allocation/${year}/staff/${id}?emailed=true`).then(() => {
          resolve({ success: true });
        });
      }
    });
  }

  async getCourseAllocationExport(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { criteria: any, ids: string[] },
    year: number,
    emailed = false
  ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobPost(`/exports/allocation/${year}/course`, data)
          .then((response) => {
            const blob = new Blob([response.blob], { type: response.type });
            FileDownload(blob, response.fileName, response.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.post(`/exports/allocation/${year}/course?emailed=true`, data).then(() => {
          resolve({ success: true });
        });
      }
    });
  }

  async getCourseIdAllocationExport(id: string, year: number, emailed = false) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobGet(`/exports/allocation/${year}/course/${id}`)
          .then((data) => {
            const blob = new Blob([data.blob], { type: data.type });
            FileDownload(blob, data.fileName, data.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.get(`/exports/allocation/${year}/course/${id}?emailed=true`).then(() => {
          resolve({ success: true });
        });
      }
    });
  }

  async getAllocationExportByParameters(params: ExportParameterDto, emailed = false) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobPost(`/exports/allocation/${params.year}/parameters`, params)
          .then((data) => {
            const blob = new Blob([data.blob], { type: data.type });
            FileDownload(blob, data.fileName, data.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.post(`/exports/allocation/${params.year}/parameters`, params).then(() => {
          resolve({ success: true });
        }).catch((e) => {
          reject({ success: false, error: e });
        });
      }
    });
  }

  async getAllocatedYearExport(params: ExportParameterDto, year: number, emailed = false) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) => {
      if (!emailed) {
        Api.blobPost(`/exports/allocation/${year}/all`, params)
          .then((data) => {
            const blob = new Blob([data.blob], { type: data.type });
            FileDownload(blob, data.fileName, data.mine);
            resolve({ success: true });
          })
          .catch((e) => {
            reject({ success: false, error: e });
          });
      } else {
        Api.post(`/exports/allocation/${year}/all?emailed=true`, params).then(() => {
          resolve({ success: true });
        });
      }
    });
  }

  async getAllocatedYears() {
    this.allocatedYears = await Api.get('/exports/allocation/years');
  }
}
