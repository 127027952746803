import React, { Component } from 'react';
import ViewManager from '../../utility/view';
import { inject, observer } from 'mobx-react';
import { Header, BodyLayout, Spinner } from '../../components';
import NoticeBar from '../../components/noticeBar/notice-bar';
import { AuthenticatedUserDto } from '../../dto';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

type State = {
  spinner: { visible: boolean, text: string },
  downloadstate: {
    status: 'success' | 'failed' | 'pending' | 'in_progress',
    text?: string,
    subtext?: string,
  },
  id: string,
  type: 'report',
  user: AuthenticatedUserDto,
};

class Download extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.userStore = props.authUserStore;
    this.reportStore = props.reportStore;
    this.exportStore = props.exportStore;
    this.viewStore = props.viewStore;
    this.state = {
      spinner: { visible: false, text: '' },
      downloadstate: { status: 'pending' },
      user: null,
    };
  }

  componentDidMount() {
    this.setState({ spinner: { visible: true, text: '' } });
    const params = this.props.match?.params;
    if (params.id && params.type) {
      this.setState({ id: params.id, type: params.type });
      const user = this.userStore.currentUser;
      if (user) {
        this.setState({ user });
        this._fetchDownload(user.user, params.id, params.type);
      }
    } else {
      this.setState({
        spinner: { visible: false, text: '' },
        downloadstate: { status: 'failed', text: 'Invalid download url' },
      });
    }
  }

  _fetchDownload = async (user, id, type) => {
    try {
      this.setState({ downloadstate: { status: 'in_progress', text: `Retrieving ${type}` } });

      let result = null;
      if (type === 'report') {
        result = await this.reportStore.getDownload({ userId: user.id, id: id });
      } else if (type === 'export') {
        result = await this.exportStore.getDownload({ userId: user.id, id: id });
      } else {
        this.setState({
          spinner: { visible: false, text: '' },
          downloadstate: { status: 'failed', text: `Invalid download type (${type})` },
        });
        return;
      }

      this.setState({
        spinner: { visible: false, text: '' },
        downloadstate: {
          status: result.success ? 'success' : 'failed',
          text: result.success ? 'Downloaded' : `Failed to retrieve requested ${type}`,
        },
      });
    } catch (e) {
      console.log(e);
      this.setState({
        spinner: { visible: false, text: '' },
        downloadstate: {
          status: 'failed',
          text: `Failed to retrieve requested ${type}`,
          subtext: e.message,
        },
      });
    }
  };

  onActiveYearChange = async () => {
    const { year } = this.viewStore;
    this.setState({ year });
  };

  _icon = (status) => {
    switch (status) {
      case 'success':
        return 'check';
      case 'failed':
        return 'cancel';
      case 'pending':
        return 'info';
      case 'in_progress':
        return 'refresh';
      default:
        return '';
    }
  };

  render() {
    return (
      <div>
        <Header
          navState={'download'}
          onYearChange={this.onActiveYearChange}
        />

        <BodyLayout>
          <NoticeBar
            text={this.state.downloadstate?.text || ''}
            icon={this._icon(this.state.downloadstate?.status || '')}
            subtext={this.state.downloadstate?.subtext}
          />

          {this.state.spinner.visible && (
            <Spinner
              text={this.state.spinner.text}
              lines={this.state.spinner.lines}
            />
          )}
        </BodyLayout>
      </div>
    );
  }
}

export default inject(
  'authUserStore',
  'reportStore',
  'exportStore',
  'viewStore'
)(observer(Download));
