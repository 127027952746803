// @flow

import { makeAutoObservable } from 'mobx';
import { StatusDto, ModelDto } from '../dto';
import Api from './api';

export default class ModelManageStore {
  model: ModelDto;
  statuses: StatusDto[];

  constructor() {
    makeAutoObservable(this);
  }

  async getModelStatuses() {
    const statuses = await Api.get('/statuses/group/model');
    if (statuses != null) {
      return statuses;
    }
  }

  async setModelStatusUpdate(modelid, model) {
    const response = await Api.put(`/models/${modelid}`, model);
    return response;
  }

  async getYearsByModel(modelCode) {
    const years = await Api.get(`/allocation_years/model/${modelCode}`);
    if (years != null) {
      return years;
    }
  }

  async getRolloverYears() {
    const years = await Api.get('/allocation_years/status/pending');
    if (years != null) {
      return years;
    }
  }

  async getOpenYears() {
    const years = await Api.get('/allocation_years/status/open');
    if (years != null) {
      return years;
    }
  }

  async getModelsByStatus(year) {
    const models = await Api.get(`/allocation_years/model/import/${year}`);
    if (models != null) {
      return models;
    }
  }
}
