// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import 'react-tagsinput/react-tagsinput.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  AddEditUser,
  SearchDropdown,
  SearchResultUserGrid,
  ConfirmationDialog,
  Spinner,
  MessageLine,
  SearchUser,
  BulkEditUserStatus,
} from '../../components';
import { ModelDto, SortDto, SearchUserDto, UserDto } from '../../dto';
import './admin.css';
import { StringUtility } from '../../utility';
import { v4 as uuid } from 'uuid';

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  readOnly: boolean,
};

type State = {
  model: ModelDto,
  loading: boolean,
  keywords: Array<UserDto>,
  notify: { state: boolean, msg: string, type: string },
  search: SearchUserDto,
  paging: { limit: number, skip: number },
  spinner: { visible: boolean, text: string },
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  dialog: { response: Function, visible: boolean, title: string, lines: any[] },
  sorting: SortDto[],
  editView: boolean,
  editUser: UserDto,
  keyId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabled: any,
  noModelSelected: boolean,
};
const statuses = [
  { id: 'active', label: 'Active' },
  { id: 'inactive', label: 'Inactive' },
  { id: 'all', label: 'All' },
];

class UserSearch extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.viewStore = this.props.viewStore;
    this.searchStore = this.props.searchStore;
    this.userStore = this.props.userStore;

    this.state = {
      paging: {},
      model: {},
      loading: true,
      spinner: { visible: false, text: null },
      keywords: [],
      notify: {},
      search: {},
      dialog: {
        visible: false,
        lines: [],
        title: '',
        response: null,
      },
      editView: false,
      editUser: {},
      keyId: '',
      disabled: {},
      noModelSelected: false,
      editMultiple: false,
      role: {},
      status: false,
      activeSearch: {},
      searchResultTotal: null,
      userKeywords: false,
    };

    this.onSort = this.onSort.bind(this);
  }

  async componentDidMount() {
    this.onPrepopulate();
    this.onCleanNotification();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.viewStore.year.year !== this.viewStore.year.year) {
      this.onPrepopulate();
      this.onStateReset();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onPrepopulate() {
    this.searchStore.cleanSearchResult();
    this.searchStore.getModels(this.viewStore.year.year).then(() => {
      this.searchStore.models?.push({ model: 'No Model - BPO Search' });
      this.setState({
        loading: false,
        disabled: {
          role: true,
          status: true,
          editMultiple: true,
          userKeywords: true,
        },
      });
    });
  }

  onRoleChange = (target) => {
    this.setState({
      role: target.value.active ? target.value : {},
    });
  };

  onStatusChange = (target) => {
    const statusChange = target.value.active ?? target.value;
    let disableMultipleBtn = false;

    if (statusChange.id === 'all') {
      disableMultipleBtn = true;
    }
    this.setState({
      status: statusChange,
      disabled: { editMultiple: disableMultipleBtn },
    });
  };

  onModelChange = async(target) => {
    this.setState({ keywords: [] });
    if (!target?.value?.id) {
      this.setState({
        disabled: { role: true, status: true, editMultiple: true, userKeywords: false },
        role: null,
        status: null, 
        spinner: { visible: false, text: null },
        noModelSelected: true,
        model: target.value,
      });
    } else {
      this.setState({
        model: target.value.active ? target.value : {},
        spinner: { visible: true, text: 'Loading' },
        noModelSelected: false,
      });
      this.searchStore.getRoles(true).then(() => {
        this.setState({
          role: this.searchStore.roles[0],
          status: statuses[0],
          spinner: { visible: false, text: null },
          disabled: { role: false, status: false, editMultiple: false, userKeywords: false },
        });
      });
    }
    this.searchStore.cleanSearchResult();
  };

  onKeyWordsChange = (keywords) => {
    this.setState({ keywords });
  };

  onSearchRefresh = () => {
    if (typeof this.searchStore.userSearchResult.total !== 'undefined') {
      setTimeout(() => {
        this.onKeyWordsSearch();
      }, 1000);
    }
  };

  validation(search: SearchUserDto) {
    return (
      search.modelCode ||
      search.roleCode ||
      search.statusCode ||
      (search.keywords && search.keywords.length > 0) ||
      search.noModelSelected
    );
  }

  onCleanNotification = () => {
    this.setState({ notify: { state: false, msg: '' }, paging: {} });
  };

  onStateReset = () => {
    this.setState({
      activeSearch: {},
      paging: {},
      model: {},
      loading: true,
      spinner: { visible: false, text: null },
      keywords: [],
      notify: {},
      search: {},
      dialog: {
        visible: false,
        lines: [],
        title: '',
        response: null,
      },
    });
  };

  onPageChange = (limit, skip) => {
    this.setState({ paging: { limit, skip } });
    if (this.validation(this.state.search)) {
      this.setState({ spinner: { visible: true, text: 'Loading' } });
      this.searchStore
        .getUserByKeyWords(this.state.search, limit, skip, true, this.state.sorting)
        .then(() => {
          this.setState({ spinner: { visible: false, text: null } });
        });
    }
  };

  onKeyWordsSearch = () => {
    // Clean result data at here it is necessary
    this.setState({
      editView: false,
      editUser: {},
    });
    this.searchStore.cleanSearchResult();
    this.onCleanNotification();

    const search: SearchUserDto = {
      keywords: this.state.keywords,
      modelCode: this.state.model?.code,
      roleCode: this.state.role?.code,
      statusCode: this.state.status?.id,
      noModelSelected: this.state.noModelSelected,
    };

    if (this.validation(search)) {
      this.setState({
        search: search,
        spinner: { visible: true, text: 'Loading' },
      });
      this.searchStore
        .getUserByKeyWords(search, this.state.paging.limit, 0, true, this.state.sorting)
        .then(() => {
          if (!this.searchStore.userSearchResult.total) {
            this.setState(
              {
                spinner: { visible: false, text: null },
                editMultiple: this.state.noModelSelected,
                notify: {
                  type: 'warning',
                  state: true,
                  msg: 'Could not find any results matching this search request',
                },
              },
              () => {
                setTimeout(() => {
                  this.onCleanNotification();
                }, 5000);
              }
            );
          } else {
            this.setState({
              spinner: { visible: false, text: null },
              activeSearch: { ...search },
              searchResultTotal:  this.searchStore?.userSearchResult?.total,
              editMultiple: !this.state.noModelSelected,
            });
          }
        });
    } else {
      this.setState(
        {
          spinner: { visible: false, text: null },
          notify: {
            type: 'error',
            state: true,
            msg: 'Please either select or enter at least one filter or keyword',
          },
        },
        () => {
          setTimeout(() => {
            this.onCleanNotification();
          }, 5000);
        }
      );
    }
  };

  onBulkStatusSaved = (usersUpdated) => {
    const notificationMessage =
      usersUpdated > 0
        ? `Successfully updated ${usersUpdated} user(s).`
        : 'No users were updated.';
  
    this.setState(
      {
        spinner: { visible: false, text: null },
        notify: {
          type: 'success',
          state: true,
          msg: notificationMessage,
        },
      },
      () => {
        setTimeout(() => {
          this.onCleanNotification();
        }, 5000);
      }
    );
  };

  onSort = (sorting) => {
    this.setState({ sorting: sorting });
    if (this.validation(this.state.search)) {
      this.setState({ spinner: { visible: true, text: 'Loading' } });
      this.searchStore
        .getUserByKeyWords(
          this.state.search,
          this.state.paging.limit,
          this.state.paging.skip,
          sorting
        )
        .then(() => {
          this.setState({ spinner: { visible: false, text: null } });
        });
    }
  };

  onEdit = (data) => {
    this.setState({ editView: true, editUser: data, keyId: uuid() });
  };

  onDelete = (data, paging) => {
    this.setState({
      selected: data,
      paging: { limit: paging.pageSize, skip: paging.skip },
      dialog: {
        lines: [
          <span key='delete'>
            You are about to delete the user <span className='important'>{data.displayName}</span>{' '}
            and their roles.
          </span>,
          <span
            className='important'
            key='delete-note'
          >
            Once deleted this data cannot be recovered.
          </span>,
          'Do you wish to continue?',
        ],
        visible: true,
        title: 'Please Confirm',
        response: this.delete,
      },
    });
  };

  delete = (confirmed: boolean) => {
    this.setState({
      dialog: {
        response: null,
        type: '',
        visible: false,
        lines: [],
        title: '',
      },
    });
    if (confirmed) {
      this.setState({
        spinner: { visible: true, text: 'Deleting User' },
      });

      this.userStore.delete(this.state.selected.id).then(() => {
        this.setState({
          selected: undefined,
          spinner: { visible: true, text: 'Loading' },
        });
        this.searchStore
          .getUserByKeyWords(
            this.state.search,
            this.state.paging.limit,
            this.state.paging.skip,
            true,
            this.state.sorting
          )
          .then(() => {
            this.setState({ spinner: { visible: false, text: null } });
          });
      });
    }
  };

  setCanEditMultiple() {
    const allowedEditStatuses = ['active', 'inactive'];
    let canEditMultiple = false;

    if (this.state.noModelSelected) {
      return false;
    }
    if (allowedEditStatuses.includes(this.state?.activeSearch?.statusCode)) {
      return true;
    }

    return canEditMultiple;
  }

  render() {
    const canEditMultiple = this.setCanEditMultiple();
    const nonBPOUserGroups = this.searchStore.roles?.filter((role) => role.code !== 'bpo');
    return (
      <div>
        <div className='search-detail-body'>
          <div className='search-detail-body-header'>
            <div className='search-title'>
              <p>Search Details</p>
            </div>
            <div className='add-staff-button-action'>
              {!this.props.readOnly && (
                <AddEditUser
                  year={this.viewStore.year.year}
                  onSearchRefresh={this.onSearchRefresh}
                  button={{
                    look: 'outline',
                    icon: <PlusOutlined />,
                    text: 'Add User',
                  }}
                />
              )}
            </div>
          </div>
          <div className='search-detail-body-content'>
            <div className='search-detail-body-dropdown-list'>
              <SearchDropdown
                id={'Model'}
                onChange={this.onModelChange}
                data={this.searchStore.models}
                value={this.state.model}
                placeholder={'Select Model'}
              />
              <SearchDropdown
                title={'Role'}
                id={'Group'}
                onChange={this.onRoleChange}
                data={nonBPOUserGroups}
                value={this.state.role}
                disabled={this.state.disabled.role}
              />
              <SearchDropdown
                title={'Status'}
                id={'Label'}
                onChange={this.onStatusChange}
                data={statuses}
                value={this.state.status}
                disabled={this.state.disabled.status}
              />
              <SearchUser
                id='tags-outlined'
                disabled={this.state.disabled.userKeywords}
                onSearch={this.onKeyWordsSearch}
                onChange={this.onKeyWordsChange}
                value={this.state.keywords}
                modelId={this.state.model?.id}
              />
            </div>
          </div>

          <div>
            {this.state.notify.state ? (
              <MessageLine
                visible={this.state.notify.state}
                line={this.state.notify.msg}
                type={this.state.notify.type}
              />
            ) : null}
          </div>
        </div>

        <div className='search-detail-body-header'>
          <div className='search-title'>
            <p>Search Results</p>
          </div>
          {this.state.editMultiple && (
            <BulkEditUserStatus
                  activeSearch={this.state.activeSearch}
                  searchTotal={this.state.searchResultTotal}
                  onBulkStatusSaved={this.onBulkStatusSaved}
                  disabled={!canEditMultiple}
                  button={{
                    look: 'outline',
                    text: 'Manage User Status (Multiple)',
                  }}
                  onSearchRefresh={this.onSearchRefresh}
             />
          )}
        </div>
        <SearchResultUserGrid
          readOnly={this.props.readOnly}
          data={this.searchStore.userSearchResult}
          onEdit={this.onEdit}
          onDelete={this.onDelete}
          onPageChange={this.onPageChange}
          sortable={true}
          onSort={this.onSort}
          columns={[
            {
              field: 'firstName',
              title: 'First Name',
              dataIndex: ['firstName'],
              width: '120px',
              sorter: {
                multiple: 1,
              },
            },
            {
              field: 'surname',
              title: 'Surname',
              width: '120px',
              dataIndex: ['surname'],
              sorter: {
                multiple: 2,
              },
            },
            {
              field: 'identifier',
              title: 'Username',
              dataIndex: ['identifier'],
              sorter: {
                multiple: 3,
              },
            },
            {
              field: 'groupName',
              title: 'Role',
              width: '200px',
              dataIndex: ['groupName'],
              sorter: {
                multiple: 4,
              },
            },
            {
              field: 'departmentNames',
              title: 'Unit',
              dataIndex: ['departmentNames'],
              sorter: {
                multiple: 5,
              },
            },
            {
              field: 'addedByName',
              title: 'Added By',
              dataIndex: ['addedByName'],
              sorter: {
                multiple: 6,
              },
            },
            {
              field: 'status',
              title: 'User Status',
              dataIndex: ['status'],
              sorter: {
                multiple: 7,
              },
            },
          ]}
        />
        {this.state.editView && (
          <AddEditUser
            year={this.viewStore.year.year}
            user={this.state.editUser}
            keyId={this.state.keyId}
            onSearchRefresh={this.onSearchRefresh}
            model={this.state.model}
          />
        )}

        {this.state.spinner && this.state.spinner.visible && (
          <Spinner text={this.state.spinner.text ? this.state.spinner.text : 'Loading'} />
        )}
        {this.state.dialog.visible && (
          <ConfirmationDialog
            response={this.state.dialog.response}
            title={this.state.dialog.title}
            lines={this.state.dialog.lines}
          />
        )}
      </div>
    );
  }
}

export default inject(
  'viewStore',
  'searchStore',
  'userStore',
)(observer(UserSearch));
