// @flow

import { PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'react-tagsinput/react-tagsinput.css';
import {
  AddEditStaff,
  BodyLayout,
  ConfirmationDialog,
  MessageLine,
  SearchAutoKeyWords,
  SearchCheckBoxList,
  SearchDropdown,
  SearchKeyWordsTips,
  SearchResultGrid,
  Spinner,
  TableActionCellView,
} from '../../components';
import {
  DepartmentDto,
  ExportDto,
  ModelDto,
  ReportDto,
  SearchCourseDto,
  SearchStaffDto,
  StaffContractDto,
  StaffDto,
  StaffTypeDto,
  UserDto,
} from '../../dto';
import { AuthUserStore } from '../../mobx';
import { PermissionsHelper, StringUtility, ViewManager } from '../../utility';
import './staff.css';

type Props = {
  onEdit: () => void,
  year: number,
  userRoleList: string[],
  hideSearch: boolean,
  payload: SearchStaffDto,
  doSort: boolean,
  authUserStore: AuthUserStore,
  searchStore: SearchCourseDto,
  reportStore: ReportDto,
  exportStore: ExportDto,
  viewStore: ModelDto,
  currentUser: UserDto,
  staffStore: StaffDto,
  search: Record<string, string>,
};

type State = {
  staffInfo: StaffDto[],
  departments: DepartmentDto[],
  checkedName: boolean,
  checkedJobTitle: boolean,
  checkedDepartment: boolean,
  checkedEmail: boolean,
  loading: boolean,
  keywordsSearch: { disabled: boolean, keywords: Array<string> },
  notify: { state: boolean, msg: string, type: string },
  search: SearchStaffDto,
  noCodeResult: boolean,
  type: StaffTypeDto,
  contract: StaffContractDto,
  reporting: boolean,
  emailed: boolean,
  paging: { limit: number, skip: number },
  spinner: { visible: boolean, text: string },
  dialog: { response: () => void, visible: boolean, title: string, lines: string[] },
};

class StaffSearch extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.reportStore = this.props.reportStore;
    this.exportStore = this.props.exportStore;
    this.staffStore = this.props.staffStore;
    this.searchStore = this.props.searchStore;
    this.viewStore = this.props.viewStore;
    this.authUserStore = this.props.authUserStore;

    this.searchArgs = this.staffStore.searchArgs;

    this.searchInputRef = React.createRef();

    this.state = {
      staffInfo: [],
      departments: [],
      paging: {},
      checkedName: true,
      checkedJobTitle: true,
      checkedDepartment: true,
      checkedEmail: true,
      type: {},
      contract: {},
      department: {},
      loading: true,
      spinner: { visible: false, text: null },
      keywordsSearch: { disabled: false, keywords: [] },
      notify: {},
      search: {},
      noCodeResult: false,
      reporting: false,
      emailed: false,
      dialog: {
        visible: false,
        lines: [],
        title: '',
        response: null,
      },

      preLoad: false,
    };

    this.onSort = this.onSort.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.searchStore.cleanSearchResult();
    await this.onCleanNotification();
    await this.onASUserRole();
    await this.getDepartments();
    await this.onPrepopulate();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.payload !== this.props.payload && this.props.location.pathname === '/staff') {
      await this.getPersistedSearch(this.props.payload);
      this.searchInputRef.current.input.value = this.props.payload.search?.keywords;
    }
    if (prevProps.year !== this.props.year) {
      await this.onPrepopulate();
      this.onStateReset();
    }
    if (prevProps.viewStore.model.id !== this.viewStore.model.id) {
      await this.getData(this.props.payload);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getPersistedSearch(payload) {
    const { search, paging, sorting } = payload ?? {};
    this.setState({ spinner: { visible: true }, payload });

    if (!this.validation(search)) return;
    await this.searchStore.getStaffByKeyWords(search, paging?.limit, paging?.skip, true, sorting);
    this.setOptions(payload);
    this.setState({ spinner: { visible: false } });
  }

  setOptions({ search, paging, sorting }) {
    const {
      departmentCode = undefined,
      typeId = undefined,
      contractId = undefined,
      departmentCodes = undefined,
      searchName,
      searchJob,
      searchDepartment,
      searchEmail,
    } = search ?? {};

    this.setState({
      ...this.state,
      search,
      paging,
      sorting,
      checkedDepartment: searchDepartment,
      checkedEmail: searchEmail,
      checkedName: searchName,
      checkedJobTitle: searchJob,
      contract: this.searchStore.contracts?.find((c) => c.id === contractId),
      department: this.state.departments?.find((d) => d.code === departmentCode),
      type: this.searchStore.types?.find((t) => t.id === typeId),
      departmentCodes,
    });
  }

  async getData(payload) {
    if (payload !== null && !this.state.preLoad) {
      const { search, paging, sorting } = payload;
      await this.searchStore.getStaffByKeyWords(search, paging.limit, paging.skip, true, sorting);
      this.setState({
        preLoad: true,
      });
    }
  }

  async onASUserRole() {
    if (PermissionsHelper.userHasRole('as', this.authUserStore.currentUser)) {
      const staffInfo = (
        await this.staffStore.getByMasseyId(
          this.props.year,
          this.authUserStore.currentUser.identifier
        )
      ).filter((staff) => staff.active && staff.model.code === this.viewStore.model.code);
      this.setState({ staffInfo });
      if (this.props.userRoleList.length === 1) {
        if (staffInfo?.length > 1) {
          if (this._isMounted) {
            this.setState({
              checkedName: false,
              checkedJobTitle: false,
              checkedDepartment: false,
            });
          }
          const searchPhrase = staffInfo[0].emailAddress;
          this.onKeywordSelectChange([searchPhrase], true);
          this.keyWordsSearch([searchPhrase]);
        } else if (staffInfo?.length === 1) {
          const staffActivityUri = `#/staff/${staffInfo[0].id}`;
          if (window.location.hash !== staffActivityUri) {
            window.location.assign(staffActivityUri);
            window.location.reload();
          }
        } else if (staffInfo?.length === 0) {
          if (this._isMounted) {
            this.setState({
              checkedName: false,
              checkedJobTitle: false,
              checkedDepartment: false,
            });
          }
          const searchPhrase = this.authUserStore.currentUser.user?.email;
          this.onKeywordSelectChange([searchPhrase], true);
          this.keyWordsSearch([searchPhrase]);
        } else {
          window.location.assign('#/noaccess');
        }
      }
    }
    if (this._isMounted) {
      this.setState({ loading: false });
    }
  }

  appendDepartment(departmentsToAdd: DepartmentDto[], departmentsAccumulated: DepartmentDto[]) {
    departmentsToAdd.forEach((departmentToAdd) => {
      if (
        departmentToAdd.id &&
        !departmentsAccumulated.map((d) => d.id).includes(departmentToAdd.id)
      ) {
        departmentsAccumulated.push(departmentToAdd);
      }
    });
  }

  async getDepartments() {
    const user = this.authUserStore.currentUser;
    const year = this.viewStore.year.year;
    const allowedDepartments = [];

    if (PermissionsHelper.userHasRole('as', user)) {
      const staffRecords = this.state.staffInfo;
      const asDepartments = staffRecords.map((staffRecord) => staffRecord.department);
      this.appendDepartment(asDepartments, allowedDepartments);
    }
    if (PermissionsHelper.userHasRole('wm', user)) {
      const wmDepartments = await this.searchStore.getDepartmentsByUser(
        year,
        this.authUserStore.currentUser.id
      );
      this.appendDepartment(wmDepartments, allowedDepartments);
    }
    if (PermissionsHelper.userHasRole(['bpo', 'mm'], user)) {
      const modelCode = this.viewStore.model.code;
      const modelDepartments = await this.searchStore.getDepartmentsByModelCode(year, modelCode);
      this.appendDepartment(modelDepartments, allowedDepartments);
    }
    allowedDepartments.unshift(this.searchStore.allDepartments);
    this.setState({ departments: allowedDepartments });
  }

  async onPrepopulate() {
    this.searchStore.cleanSearchResult();
    this.searchStore.getTypes(this.props.year).then(() => {
      if (this._isMounted) {
        this.setState({
          type: this.searchStore?.types[0],
        });
      }
    });
    this.searchStore.getContracts(this.props.year).then(() => {
      if (this._isMounted) {
        this.setState({
          contract: this.searchStore?.contracts[0],
        });
      }
    });

    const model = this.viewStore.model;
    if (!model) {
      this.searchStore.getModels(this.props.year).then(() => {
        const department = this.state.departments[0] ?? {};
        if (this._isMounted) {
          this.setState({ department });
        }
      });
    } else {
      this.searchStore.setSingleModel(model);
      if (this._isMounted) {
        this.setState((prevstate) => {
          return {
            department: prevstate.departments[0],
          };
        });
      }
    }
  }

  onCheckBoxChange = (target) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;

    this.setState({
      [id]: value,
    });
  };

  onChange = (target) => {
    this.setState({
      [target.props.id]: target.value.active ? target.value : {},
    });
  };

  onStaffUserIdSearch = async (code) => {
    this.setState({
      noCodeResult: false,
      spinner: { visible: true, text: 'Loading' },
    });
    this.searchStore.getStaffByMasseyUserId(this.props.year, code).then((data) => {
      if (this._isMounted) {
        this.setState({
          noCodeResult: data.length === 0,
          spinner: { visible: false, text: null },
        });
      }
    });
  };

  onKeywordSelectChange = (keywords, disabled) => {
    this.setState((prevState) => {
      const newState = {
        keywordsSearch: {
          ...prevState.keywordsSearch,
          keywords,
        },
      };

      if (disabled != null) {
        newState.keywordsSearch.disabled = disabled;
      }
      return newState;
    });
  };

  onKeyWordsSearch = async () => {
    await this.keyWordsSearch();
  };

  saveSearchArgs = (search, paging, sorting) => {
    const searchData = {
      search: search ?? this.state.search,
      paging: paging ?? this.state.paging ?? { limit: 25, skip: 0 },
      sorting: sorting ?? this.state.sorting,
      active: true,
    };

    ViewManager.setStaffSearch(searchData);
  };

  keyWordsSearch = async (searchKeywords) => {
    this.searchStore.cleanSearchResult();
    await this.onCleanNotification();

    if (!searchKeywords) {
      searchKeywords = this.state.keywordsSearch.keywords;
    }
    let wmDepartmentCodes;
    if (
      PermissionsHelper.userHasRole('wm', this.authUserStore.currentUser) &&
      typeof this.state.department?.id === 'undefined'
    ) {
      wmDepartmentCodes = this.state.departments
        .map((d) => d.code)
        .filter((d) => typeof d !== 'undefined');
    }

    const search: SearchStaffDto = {
      departmentCode: this.state.department?.code,
      keywords: searchKeywords,
      keywordsByType: searchKeywords,
      modelId: this.viewStore.model.id,
      modelCode: this.viewStore.model.code,
      typeId: this.state.type.id,
      contractId: this.state.contract.id,
      departmentCodes: wmDepartmentCodes,
      year: this.props.year,
      searchName: this.state.checkedName,
      searchJob: this.state.checkedJobTitle,
      searchDepartment: this.state.checkedDepartment,
      searchEmail: this.state.checkedEmail,
    };

    if (this.validation(search)) {
      const paging = this.state?.paging
        ? { ...this.state.paging, skip: 0 }
        : { limit: 25, skip: 0 };

      this.saveSearchArgs(search, paging, null);
      this.setState({
        search: search,
        paging,
        spinner: { visible: true, text: 'Loading' },
      });
      this.searchStore
        .getStaffByKeyWords(search, this.state.paging.limit, 0, true, this.state.sorting)
        .then(() => {
          if (!this.searchStore.searchResult.total) {
            this.setState(
              {
                spinner: { visible: false, text: null },
                notify: {
                  type: 'warning',
                  state: true,
                  msg: 'Could not find any results matching this search request',
                },
              },
              () => {
                setTimeout(() => {
                  this.onCleanNotification();
                }, 5000);
              }
            );
          } else {
            this.setState({ spinner: { visible: false, text: null } });
          }
        });
    } else {
      this.setState(
        {
          spinner: { visible: false, text: null },
          notify: {
            type: 'error',
            state: true,
            msg: 'Please either select or enter at least one filter or keyword',
          },
        },
        () => {
          setTimeout(() => {
            this.onCleanNotification();
          }, 5000);
        }
      );
    }
  };

  validation(search: SearchStaffDto) {
    return (
      search.year &&
      (search.contractId ||
        search.modelId ||
        search.typeId ||
        search.departmentIds?.length > 0 ||
        ((search.searchName || search.searchJob || search.searchDepartment || search.searchEmail) &&
          search.keywords))
    );
  }

  async onCleanNotification() {
    this.setState({ notify: { state: false, msg: '', type: '' } });
  }

  onStateReset = () => {
    this.setState({
      paging: {},
      checkedName: true,
      checkedJobTitle: true,
      checkedDepartment: true,
      checkedEmail: true,
      type: {},
      contract: {},
      department: {},
      loading: true,
      spinner: { visible: false, text: null },
      keywordsSearch: { disabled: true, keywords: [] },
      notify: {},
      search: {},
      noCodeResult: false,
      reporting: false,
      reportType: null,
      emailed: false,
      dialog: {
        visible: false,
        lines: [],
        title: '',
        response: null,
      },
      payload: null,
    });
  };

  onPageChange = (limit, skip) => {
    const paging = { limit, skip };
    if (this.validation(this.state.search)) {
      this.saveSearchArgs(null, paging, null);
      this.setState({ spinner: { visible: true, text: 'Loading' }, paging });
      this.searchStore
        .getStaffByKeyWords(this.state.search, limit, skip, true, this.state.sorting)
        .then(() => {
          if (this._isMounted) {
            this.setState({ spinner: { visible: false, text: null } });
          }
        });
    }
  };

  onSort = (sorting) => {
    if (this.validation(this.state.search)) {
      this.saveSearchArgs(null, null, sorting);
      this.setState({ spinner: { visible: true, text: 'Loading' }, sorting });
      this.searchStore
        .getStaffByKeyWords(
          this.state.search,
          this.state.paging.limit,
          this.state.paging.skip,
          true,
          sorting
        )
        .then(() => this.setState({ spinner: { visible: false, text: null } }));
    }
  };

  onEdit = (data) => {
    this.props.onEdit(data);
  };

  onDelete = (data, paging) => {
    this.setState({
      selected: data,
      paging: { limit: paging.pageSize, skip: paging.skip },
      dialog: {
        lines: [
          <span key='dialog1'>
            You are about to delete the staff member{' '}
            <span className='important'>{data.displayName}</span> and all their allocations.
          </span>,
          <span
            className='important'
            key='dialog2'
          >
            You are advised to reassign any existing allocations prior to doing this.
          </span>,
          <span
            className='important'
            key='dialog3'
          >
            Once deleted this data cannot be recovered.
          </span>,
          'Do you wish to continue?',
        ],
        visible: true,
        title: 'Please Confirm',
        response: this.delete,
      },
    });
  };

  onReport = (type: string) => {
    this.setState({
      dialog: {
        lines: [
          <span
            className='important'
            key='dialog'
          >
            As this {type} may take some time to generate, it will be emailed to you once completed.
          </span>,
          'Do you wish to continue?',
        ],
        visible: true,
        title: `Please Confirm (Email ${StringUtility.capitalize(type)})`,
        response: this.report,
      },
      reportType: type,
      emailed: true,
    });
  };

  getDepartmentsParam = () => {
    let departmentsParam = [];
    if (this.state.department && this.state.department?.code !== 'all') {
      departmentsParam.push(this.state.department?.department);
    } else {
      departmentsParam = this.state.departments?.filter((d) => d.id).map((d) => d.id);
    }
    return departmentsParam;
  };

  getReportResultMessage(success) {
    return {
      reporting: false,
      reportType: null,
      notify: {
        type: success ? 'info' : 'error',
        state: true,
        msg: success
          ? `${StringUtility.capitalize(this.state.reportType)} Requested`
          : `Failed to Request ${StringUtility.capitalize(this.state.reportType)}`,
      },
    };
  }

  report = (confirmed: boolean) => {
    const viaEmail = this.state.emailed;

    this.setState({
      dialog: {
        response: null,
        type: '',
        visible: false,
        lines: [],
        title: '',
      },
      emailed: false,
    });
    if (confirmed) {
      this.setState({ reporting: true });

      let wmDepartmentIds;
      if (
        PermissionsHelper.userHasRole('wm', this.authUserStore.currentUser) &&
        typeof this.state.department.id === 'undefined'
      ) {
        wmDepartmentIds = this.state.departments
          .map((d) => d.department)
          .filter((d) => typeof d !== 'undefined' && d !== 'All Units');
      }
      if (Object.keys(this.state.search).length === 0) {
        this.setState(this.getReportResultMessage(false), () => {
          setTimeout(() => {
            this.onCleanNotification();
          }, 5000);
        });
        return null;
      }
      this.searchStore.getStaffByKeyWordsRaw(this.state.search).then(async (results) => {
        const criteria = {
          keywords:
            this.state.keywordsSearch.keywords?.length > 0
              ? this.state.keywordsSearch.keywords?.toString()
              : undefined,
          model: this.viewStore.model?.model,
          department: this.getDepartmentsParam(),
          type: this.state.type?.type,
          contract: this.state.contract?.contract,
          year: this.props.year,
          departments: wmDepartmentIds,
        };

        const result =
          this.state.reportType === 'export'
            ? await this.exportStore.getStaffAllocationExport(
                {
                  model: this.viewStore.model.id,
                  department: this.state.department.id,
                  criteria,
                  ids: results.data,
                },
                this.props.year,
                viaEmail
              )
            : await this.reportStore.getStaffAllocationSummaryReport(
                { criteria, ids: results.data },
                this.props.year,
                viaEmail
              );

        if (viaEmail) {
          this.setState(this.getReportResultMessage(result.success), () => {
            setTimeout(() => {
              this.onCleanNotification();
            }, 5000);
          });
        } else {
          this.setState({ reporting: false, reportType: null });
        }
      });
    }
  };

  delete = (confirmed: boolean) => {
    this.setState({
      dialog: {
        response: null,
        type: '',
        visible: false,
        lines: [],
        title: '',
      },
    });
    if (confirmed) {
      this.setState({
        spinner: { visible: true, text: 'Deleting Staff Member' },
      });
      this.staffStore.delete(this.state.selected.id).then(() => {
        if (this._isMounted) {
          this.setState({
            selected: undefined,
            spinner: { visible: true, text: 'Loading' },
          });
        }
        this.keyWordsSearch();
      });
    }
  };

  get canGenerateAllocationReport() {
    const user = this.authUserStore.currentUser;
    return PermissionsHelper.userHasRole(['bpo', 'mm', 'wm'], user);
  }

  render() {
    const searchParams = ViewManager.getStaffSearch();

    // TOOD: remove this later
    // console.log('%c state paging', 'color: orange; ', this.state?.paging?.limit, this.state?.paging?.skip);
    // console.log('%c saved paging', 'color: orange; ', searchParams?.paging.limit, searchParams?.paging.skip);
    // console.log('%c paging', 'color: fuchsia; ', this.props.searchArg);
    // console.log('%c state sorting', 'color: #bada55; ', this.state?.sorting?.[0]);
    // console.log('%c saved sorting', 'color: #bada55; ', searchParams?.sorting?.[0]);
    // console.log('%c compare', 'color: lime; ', objCompare(this.state?.search, { ...searchParams?.search, departmentCodes: undefined }));
    // console.log('%c keywords', 'color: lime; ', this.state.search?.keywords, searchParams?.search.keywords);
    // console.log('%c state obj', 'color: cyan; ', this.state?.search);
    // console.log('%c search obj', 'color: cyan; ', searchParams?.search);

    return (
      <div>
        {this.state.loading && <Spinner text={'Loading'} />}
        {!this.state.loading && this.props.hideSearch && (
          <BodyLayout>
            <div className='search-detail-body'>
              <div className='search-detail-body-header'>
                <div className='search-title'>
                  <p>Search Details</p>
                </div>
                <div className='add-staff-button-action'>
                  {!PermissionsHelper.isRestrictedAccess({
                    user: this.authUserStore.currentUser,
                    model: this.viewStore.model,
                    year: this.viewStore.year,
                    allowedRoles: ['bpo', 'mm'],
                  }) && (
                    <AddEditStaff
                      year={this.props.year}
                      button={{
                        look: 'outline',
                        icon: <PlusOutlined />,
                        text: 'Add Staff',
                      }}
                    />
                  )}
                </div>
              </div>
              <div className='search-detail-body-content'>
                <div className='search-detail-body-dropdown-list'>
                  <div className='card-item'>
                    <p className='search-sub-title'>Model:</p>
                    <Input
                      className='model-item'
                      value={this.viewStore.model.model}
                      disabled={true}
                    ></Input>
                  </div>

                  <SearchDropdown
                    id={'department'}
                    title={'Unit'}
                    onChange={this.onChange}
                    data={this.state.departments}
                    value={this.state.department}
                  />

                  <SearchDropdown
                    id={'type'}
                    title={'Type'}
                    onChange={this.onChange}
                    data={this.searchStore.types}
                    value={this.state.type}
                  />

                  <SearchDropdown
                    id={'contract'}
                    title={'Contract'}
                    onChange={this.onChange}
                    data={this.searchStore.contracts}
                    value={this.state.contract}
                  />

                  <div className='card-item'>
                    <SearchAutoKeyWords
                      disabled={this.state.keywordsSearch.disabled}
                      //defaultValue={this.state.keywordsSearch.keywords.join(';')}
                      initialValue={this.state.keywordsSearch.keywords.join(';')}
                      onKeywordSelectChange={this.onKeywordSelectChange}
                      ref={this.searchInputRef}
                    />
                    <Button
                      className='search-button'
                      primary='true'
                      look='outline'
                      onClick={this.onKeyWordsSearch}
                    >
                      Search
                    </Button>
                  </div>
                  <SearchKeyWordsTips />
                </div>

                <SearchCheckBoxList
                  disabled={this.state.keywordsSearch.disabled}
                  checkBoxList={[
                    { id: 'checkedName', title: 'Staff Name', check: this.state.checkedName },
                    {
                      id: 'checkedJobTitle',
                      title: 'Staff Job Title',
                      check: this.state.checkedJobTitle,
                    },
                    {
                      id: 'checkedDepartment',
                      title: 'Staff Unit',
                      check: this.state.checkedDepartment,
                    },
                    { id: 'checkedEmail', title: 'Staff Email', check: this.state.checkedEmail },
                  ]}
                  onCheckBoxChange={this.onCheckBoxChange}
                  prompt={'Search Keywords in the following'}
                />
              </div>

              <div>
                {this.state.notify.state ? (
                  <MessageLine
                    visible={this.state.notify.state}
                    line={this.state.notify.msg}
                    type={this.state.notify.type}
                  />
                ) : null}
              </div>
            </div>

            <SearchResultGrid
              data={this.searchStore.searchResult}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              onPageChange={this.onPageChange}
              columns={[
                {
                  field: 'displayName',
                  title: 'Staff Role Description',
                  dataIndex: 'displayName',
                  action: 'view',
                  width: '350px',
                  sorter: {
                    multiple: 1,
                  },
                  render: (text, record) => (
                    <TableActionCellView
                      view={this.onEdit}
                      text={text}
                      dataItem={record}
                    />
                  ),
                },
                {
                  field: 'jobTitle.title',
                  title: 'Job Title',
                  dataIndex: ['jobTitle', 'title'],
                  width: '180px',
                  sorter: {
                    multiple: 2,
                  },
                },
                {
                  field: 'department.department',
                  title: 'Unit',
                  dataIndex: ['department', 'department'],
                  sorter: {
                    multiple: 3,
                  },
                },
                {
                  field: 'model.model',
                  title: 'Model',
                  dataIndex: ['model', 'model'],
                  sorter: {
                    multiple: 4,
                  },
                },
              ]}
              sortable={true}
              onSort={this.onSort}
              export={() => this.onReport('export')}
              exporting={this.state.reporting}
              print={() => this.onReport('report')}
              canGenerateAllocationReport={this.canGenerateAllocationReport}
              printing={this.state.reporting}
              sorting={searchParams?.sorting}
            />

            {this.state.spinner && this.state.spinner.visible && (
              <Spinner text={this.state.spinner.text ? this.state.spinner.text : 'Loading'} />
            )}
            {this.state.dialog.visible && (
              <ConfirmationDialog
                response={this.state.dialog.response}
                title={this.state.dialog.title}
                lines={this.state.dialog.lines}
              />
            )}
          </BodyLayout>
        )}
      </div>
    );
  }
}

export default inject(
  'staffStore',
  'searchStore',
  'viewStore',
  'reportStore',
  'exportStore',
  'authUserStore'
)(observer(StaffSearch));
