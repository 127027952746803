// @flow

import React, { Component } from 'react';
import { Table } from 'antd';

import {
  CollapsePanel,
  NavButton,
  SpinnerBasic,
  TableStyleCellDiffByTolerance,
} from '../../components';
import TeachingSummary from './teaching-summary';
import './staff';

const { Column } = Table;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  title: string,
  category: string,
  staffId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history?: any,
  loading?: boolean,
  readOnly?: boolean,
};
// eslint-disable-next-line @typescript-eslint/ban-types
type State = {};

class Summary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.loading,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  assignSummaryOrder(data, index) {
    if (data.category !== undefined) {
      switch (data.category.code) {
        case 'research_enterprise':
          data['sortOrder'] = 0;
          break;
        case 'academic_citizenship':
          data['sortOrder'] = 1;
          break;
        case 'teaching_supervision':
          data['sortOrder'] = 2;
          break;
        case 'clinical_professional_practice':
          data['sortOrder'] = 3;
          break;
        default:
          data['sortOrder'] = index;
      }
    } else {
      data['sortOrder'] = index;
    }
    data['key'] = `${data.sortOrder}-${index}`;
  }

  sortLines(a, b) {
    return a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0;
  }

  getDataAndTotal() {
    const data = this.props.data
      ? this.props.data
      : {
          key: 'emptyRow',
          lines: [],
          total: { calculated: 0, hours: 0, hoursS1: 0, hoursS2: 0, hoursSS: 0 },
        };

    data.lines.forEach(this.assignSummaryOrder);

    const SUMMARY_ROW_TITLE = 'TOTAL';

    const total = Object.assign(data.total, {
      activity: { activity: SUMMARY_ROW_TITLE },
      category: { category: SUMMARY_ROW_TITLE },
      course: { code: SUMMARY_ROW_TITLE, course: '' },
      model: { staffVariance: data?.lines[0]?.model?.staffVariance },
      totalView: true,
      key: 'total',
    });

    return { data, total };
  }

  getSource() {
    const { data, total } = this.getDataAndTotal();
    const { lines } = data;

    return [...lines.sort(this.sortLines), total];
  }

  renderContent() {
    const source = this.getSource();

    if (this.props.category === 'overall') {
      return (
        <Table
          dataSource={source}
          bordered={true}
          size='small'
          tableLayout='fixed'
          pagination={false}
        >
          <Column
            field='category.category'
            title='Category'
            key='Category'
            dataIndex={['category', 'category']}
          />
          <Column
            field='hoursS1'
            title='S1'
            key='S1'
            dataIndex='hoursS1'
            width='120px'
            align='right'
          />
          <Column
            field='hoursS2'
            title='S2'
            key='S2'
            dataIndex='hoursS2'
            width='120px'
            align='right'
          />
          <Column
            field='hoursSS'
            title='SS'
            key='SS'
            dataIndex='hoursSS'
            width='120px'
            align='right'
          />
          <Column
            field='calculated'
            title='Calculated'
            key='Calculated'
            dataIndex='calculated'
            width='120px'
            align='right'
            render={(text, record) => (
              <TableStyleCellDiffByTolerance
                record={record}
                field={'calculated'}
                type={'staff'}
              />
            )}
          />
          <Column
            field='hours'
            title='Assigned'
            key='Assigned'
            dataIndex='hours'
            align='right'
            width='120px'
            render={(text, record) => (
              <TableStyleCellDiffByTolerance
                record={record}
                field={'hours'}
                type={'staff'}
              />
            )}
          />
          <Column
            field='target'
            align='right'
            title='Target'
            key='Target'
            dataIndex='target'
            width='120px'
          />
          <Column
            field='profile'
            align='right'
            title='Profile %'
            key='Profile'
            dataIndex='profilePercentage'
            width='120px'
            render={(value) => {
              return <span>{value}%</span>;
            }}
          />
        </Table>
      );
    } else if (this.props.category === 'teaching_supervision') {
      return <TeachingSummary data={source} />;
    } else if (
      [
        'clinical_professional_practice',
        'service',
        'academic_citizenship',
        'research_enterprise',
      ].includes(this.props.category)
    ) {
      return (
        <Table
          dataSource={source}
          bordered={true}
          size='small'
          tableLayout='fixed'
          pagination={false}
        >
          <Column
            field='activity.activity'
            title='Activity'
            key='Activity'
            dataIndex={['activity', 'activity']}
          />
          <Column
            field='hoursS1'
            title='S1'
            key='Activity S1'
            width='120px'
            align='right'
            dataIndex='hoursS1'
          />
          <Column
            field='hoursS2'
            title='S2'
            key='Activity S2'
            width='120px'
            align='right'
            dataIndex='hoursS2'
          />
          <Column
            field='hoursSS'
            title='SS'
            align='right'
            key='Activity SS'
            width='120px'
            dataIndex='hoursSS'
          />
          <Column
            field='calculated'
            title='Calculated'
            key='Activity Calculated'
            width='140px'
            align='right'
            dataIndex='calculated'
          />
          <Column
            field='hours'
            title='Assigned'
            key='Activity Assigned'
            width='140px'
            align='right'
            dataIndex='hours'
          />
        </Table>
      );
    }
  }

  render() {
    const state = {
      id: this.props.staffId,
      category: this.props.category,
      title: this.props.title,
      // eslint-disable-next-line react/prop-types
      categoryId: this.props.categoryId,
      readOnly: this.props.readOnly,
    };

    return (
      <CollapsePanel
        expanded={true}
        title={this.props.title}
        tabIndex={1}
      >
        {this.props.category === 'overall' ? (
          ''
        ) : (
          <div className='panel-button-group link-button-group'>
            <NavButton
              disabled={this.state.loading}
              title={'View'}
              url={'/staff/activity'}
              state={state}
              history={this.props.history}
            />
          </div>
        )}

        <div className='grid-frame-staff-allocation'>
          {this.state.loading ? (
            <div className='load-frame'>
              <SpinnerBasic />
            </div>
          ) : (
            this.renderContent()
          )}
        </div>

        {this.props.category === 'overall' ? (
          ''
        ) : (
          <div className='panel-button-group link-button-group'>
            <NavButton
              disabled={this.state.loading}
              title={'View'}
              url={'/staff/activity'}
              state={state}
              history={this.props.history}
            />
          </div>
        )}
      </CollapsePanel>
    );
  }
}

export default Summary;
