//@flow

import { ReportOutput, ReportType } from '../types';

export default class ReportParameterDto {
  output: ReportOutput;

  type: ReportType;

  model: ?string;

  owner: ?string;

  report: ?string;

  department: ?string;

  staffId: ?string;

  year: ?number;

  emailed: ?boolean;

  courseId: ?string;

  constructor(params: {
    emailed: boolean,
    type: ReportType,
    output: ReportOutput,
    year: number,
    model: ?string,
    owner: ?string,
    report: ?string,
    department: ?string,
    staffId: ?string,
    courseId: ?string,
  }) {
    this.emailed = params ? params.emailed : false;
    this.type = params ? params.type : null;
    this.output = params ? params.output : 'pdf';
    this.year = params ? params.year : null;
    this.model = params ? params.model : null;
    this.owner = params ? params.owner : null;
    this.report = params ? params.report : null;
    this.department = params ? params.department : null;
    this.staffId = params ? params.staffId : null;
    this.courseId = params ? params.courseId : null;
  }
}
