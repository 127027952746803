// @flow

import React, { Component } from 'react';


import './state-bar.css';

type Props = {}

class StateBarActions extends Component<Props> {

    render() {
        return (
            <div className="state-bar-actions">
                {this.props.children}
            </div>
        );
    }
}

export default StateBarActions;
