import React, { useEffect, useState } from "react";
import { BodyLayout, NoticeBar } from "../../components";
import { ViewManager } from "../../utility";

const InvalidUser = () => {
    const [hasAttemptedRefresh, setHasAttemptedRefresh] = useState(false);

    const attemptRefresh = () => {
        ViewManager.setInvalidUserAttemptedRefresh(true);
        window.location.reload();
    }

    useEffect(() => {
        const _hasAttemptedRefresh = ViewManager.hasInvalidUserAttemptedRefresh();

        if (!_hasAttemptedRefresh) {
            return attemptRefresh();
        }
        setHasAttemptedRefresh(true);
    }, []);

    return hasAttemptedRefresh ?
        (
            <div>
                <BodyLayout>
                    <NoticeBar
                        text={
                            'Sorry, you are not currently a recognised WAT user, please contact your Model Manager'
                        }
                        icon={'warning'}
                    />
                </BodyLayout>
            </div>
        )
        :
        (<></>)
}


export default InvalidUser;