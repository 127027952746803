// @flow

import { makeAutoObservable } from 'mobx';

import { OwnerDto, ModelDto, DepartmentDto, SearchResultDto, GeneratedReportsDto } from '../dto';
import Api from './api';

export default class ReportSearchStore {
  courseReportResult: SearchResultDto;
  staffReportResult: SearchResultDto;
  searchResult: SearchResultDto;
  models: ModelDto[];
  owners: OwnerDto[];
  departments: DepartmentDto[];

  constructor() {
    this.cleanSearchResult();
    makeAutoObservable(this);
  }

  cleanSearchResult() {
    this.unitReportResult = {};
    this.courseReportResult = {};
    this.staffReportResult = {};
    this.dataExportResult = {};
    this.blobResult = {};
  }

  async getDataExport(data: GeneratedReportsDto, limit = 25, skip = 0) {
    this.dataExportResult = await Api.post(
      `/generatedReport/export?limit=${limit}&skip=${skip}`,
      data
    );
  }

  async getUnitSummaryReport(data: GeneratedReportsDto, limit = 25, skip = 0) {
    this.unitReportResult = await Api.post(
      `/generatedReport/unit?limit=${limit}&skip=${skip}`,
      data
    );
  }

  async getCourseAllocationReport(data: GeneratedReportsDto, limit = 25, skip = 0) {
    this.courseReportResult = await Api.post(
      `/generatedReport/course?limit=${limit}&skip=${skip}`,
      data
    );
  }

  async getStaffAllocationReport(data: GeneratedReportsDto, limit = 25, skip = 0) {
    this.staffReportResult = await Api.post(
      `/generatedReport/staff?limit=${limit}&skip=${skip}`,
      data
    );
  }

  // eslint-disable-next-line no-undef
  async getReportBlob(data: GeneratedReportsDto) {
    const apiResult = await Api.post('/generatedReport/blob', data);
    return apiResult;
  }
}
