//@flow
import { ModelDto, CourseDto, PeriodDto, StaffDto, CourseTypeDto, OwnerDto } from '.';

export default class OfferingDto {
    id: string;

    offering: string;

    owner: OwnerDto;

    deliveryMode: string;

    deliverySite: string;

    period: PeriodDto;

    credits: number;

    outcomes: number;

    level: number;

    levelAggregate: string;

    location: number;

    courseCode: string;

    course: CourseDto;

    efts: number;

    enrolments: number;

    courseType: CourseTypeDto;

    coordinator: StaffDto;

    pbrf: string;

    model: ModelDto;

    dateAdded: Date;

    active: boolean;
}
