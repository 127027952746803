const under10k = (e) => {
    let numVal = 0;
    try {
      numVal = parseFloat(e.target.value + String.fromCharCode(e.which));
      console.log('numVal', numVal, parseInt(e.target.value));
    } catch {}
    if (numVal >= 10000) {
      e.preventDefault();
    }
  };

const onNumbersOnly = (e) => {
    const code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57) && code !== 46) {
      e.preventDefault();
    }
  };

const numericInputHandler = (e) => {
  onNumbersOnly(e);
  under10k(e);
}

export {numericInputHandler, onNumbersOnly}