import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import '@progress/kendo-theme-default/dist/all.css';
import Dashboard from '../pages/dashboard';
import Course from '../pages/course';
import Report from '../pages/report';
import Download from '../pages/download';
import Admin from '../pages/admin';
import Model from '../pages/model';
import Staff from '../pages/staff';
import StaffActivity from '../pages/staffActivity';
import NotFound from '../pages/notFound';
import Unauthorized from '../pages/unauthorized';
import NoAccess from '../pages/noAccess';
import InvalidUser from '../pages/invalidUser';
import { Spinner } from '../components/spinner';
import { PermissionsHelper, ViewManager } from '../utility';
import { inject, observer } from 'mobx-react';

const Main = (props) => {
  const [loading, setLoading] = useState(true);
  const [userIsValid, setUserIsValid] = useState(false);
  let location = useLocation();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      checkForUpdates();
    }
    setCount(count + 1);
  }, [location]);

  useEffect(() => {
    setLoading(true);
    const init = async () => {
      await props.authUserStore.init();
      if (props.authUserStore.currentUser) {
        await props.viewStore.init(props.authUserStore.currentUser.id);
        await props.exportStore.init();
        setUserIsValid(true);
        ViewManager.removeInvalidUserAttemptedRefresh();
      }

      setLoading(false);
    };
    init();
  }, [props.authUserStore, props.viewStore, props.exportStore]);

  const canAccess = () => {
    const user = props.authUserStore.currentUser;
    const year = props.viewStore.year;
    const model = props.viewStore.model;
    if (
      (model?.status?.code === 'import' || year?.status?.code === 'pending') &&
      !PermissionsHelper.userHasRole(['bpo'], user)
    ) {
      window.location.assign('#/noaccess');
      return false;
    } else {
      return true;
    }
  };

  const hasPermission = (requiredRoles) => {
    if (requiredRoles != null && requiredRoles.length > 0) {
      const user = props.authUserStore.currentUser;
      if (PermissionsHelper.userHasRole(requiredRoles, user)) {
        return true;
      } else {
        window.location.assign('#/unauthorized');
        return false;
      }
    } else {
      // No required roles specified, allow access
      return true;
    }
  };

  const canAccessReportPage = () => {
    const { model, year } = props.viewStore;
    const user = props.authUserStore.currentUser;

    const yearIsOpenOrClosed = ['open', 'closed'].includes(year.status.code);
    const modelIsVerified = model.status.code === 'verified';

    if (PermissionsHelper.userHasRole(['bpo', 'mm', 'wm'], user)) {
      return true;
    }
    if (PermissionsHelper.userHasRole('as', user) && yearIsOpenOrClosed && modelIsVerified) {
      return true;
    }
    window.location.assign('#/unauthorized');
    return false;
  };

  const clearUrlString = (url) => {
    const cleanedUrl = url.replace(/\?reload=\d{13}/, '');
    return cleanedUrl;
  };

  const checkForUpdates = () => {
    const updateSettings = ViewManager.getVersionUpdate();

    if (updateSettings == null) {
      return;
    }

    const { needsUpdate } = updateSettings;
    const currentLocation = window.location.href;
    const isAlreadyReloading = currentLocation.includes('?reload');
    if (needsUpdate) {
      if (!isAlreadyReloading) {
        const baseUrl = currentLocation.split('/')[0];
        window.location.href = baseUrl + '?reload=' + new Date().getTime();
      } else {
        ViewManager.updateVersionUpdate({ needsUpdate: false });
        //currentlocation without reload request param
        window.location.href = clearUrlString(currentLocation);
      }
    } else {
      if (isAlreadyReloading) {
        window.location.href = clearUrlString(currentLocation);
      }
    }
  };

  if (!loading && !userIsValid) {
    return <InvalidUser />;
  }

  return loading ? (
    <Spinner text='Loading' />
  ) : (
    <Switch>
      <Route
        exact
        path='/'
        render={(props) => {
          if (canAccess()) {
            return <Dashboard {...props} />;
          }
        }}
      />
      <Route
        path='/dashboard'
        render={(props) => {
          if (canAccess()) {
            return <Dashboard {...props} />;
          }
        }}
      />
      <Route
        exact
        path='/course'
        render={(props) => {
          if (canAccess()) {
            return <Course {...props} />;
          }
        }}
      />
      <Route
        path='/course/:id'
        render={(props) => {
          if (canAccess()) {
            return <Course {...props} />;
          }
        }}
      />
      <Route
        path='/admin'
        render={(props) => {
          if (hasPermission('bpo') && canAccess()) {
            return <Admin {...props} />;
          }
        }}
      />
      <Route
        path='/model'
        render={(props) => {
          if (hasPermission(['bpo', 'mm']) && canAccess()) {
            return <Model {...props} />;
          }
        }}
      />
      <Route
        path='/report'
        render={(props) => {
          if (canAccess() && canAccessReportPage()) {
            return <Report {...props} />;
          }
        }}
      />
      <Route
        path='/download/:type/:id'
        component={Download}
      />
      <Route
        exact
        path='/staff'
        render={(props) => {
          if (canAccess()) {
            return <Staff {...props} />;
          }
        }}
      />
      <Route
        exact
        path='/staff/:id'
        render={(props) => {
          if (canAccess()) {
            return <Staff {...props} />;
          }
        }}
      />
      <Route
        path='/staff/:staffId/activity/:categoryId/:readOnly'
        render={(props) => {
          if (canAccess()) {
            return <StaffActivity {...props} />;
          }
        }}
      />
      <Route
        path='/staff/:staffId/activity/:categoryId'
        render={(props) => {
          if (canAccess()) {
            return <StaffActivity {...props} />;
          }
        }}
      />
      <Route
        path='/unauthorized'
        component={Unauthorized}
      />
      <Route
        path='/noaccess'
        component={NoAccess}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default inject('authUserStore', 'viewStore', 'exportStore')(observer(Main));
