// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { UserDto, ModelDto, AllocationYearDto } from '../../dto';
import HeaderNav from '../headerNav';
import logo from '../../assets/images/Massey-University.png';
import UserCard from './userCard';
import HelpFile from './helpFile';
import ReportsOnlineIndicator from './reportsOnlineIndicator';
import YearModelSelector from './yearModelSelector';

import './header.css';

type Props = {
  navState: string,
  currentUser: UserDto,
  showUser: boolean,
  allowYearChange: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subfooter: any,
  // eslint-disable-next-line @typescript-eslint/ban-types
  userMenuItems: { text: string, className: string, callback: Function }[],
  models: ModelDto[],
  setRefresh: Function,
};

type State = {
  yearModelEditor: { show: Boolean, cancellable: Boolean },
  year: AllocationYearDto,
  model: ModelDto
}

class Header extends Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  constructor(props) {
    super(props);
    this.viewStore = props.viewStore;
    this.state = {
      yearModelEditor: { show: false, cancellable: true },
      year: this.viewStore.year,
      model: this.viewStore.model
    };
    this.reactions = [];
  }

  getYearAndModel = async () => {
    if(this._isMounted) {
      await this.viewStore.syncYearAndModel();
    }
    if(this._isMounted) {
      await this.viewStore.getYears(true);
    }
    const { year, model } = this.viewStore;
    if(this._isMounted) {
      this.setState({ year, model });
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    this.getYearAndModel();
    this.reactions = [
      reaction(
        () => this.props.viewStore.refreshed,
        (refreshed) => {
          if(refreshed) {
            this.setState({ yearModelEditor: { show: true, cancellable: false } });
          }
        }
      ),
    ]
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.reactions.forEach((dispose) => dispose());
  }

  onYearEditOpen = () => {
    this.setState({ yearModelEditor: { show: true, cancellable: true }, });
  };

  onYearEditClose = async () => {
    if(this._isMounted) {
      this.setState({ yearModelEditor: { show: false, cancellable: true }, });
      await this.getYearAndModel();
    }
    if(this._isMounted) {
      this.props.setRefresh && this.props.setRefresh();
    }

  };

  render() {
    const { year, model } = this.state;
    const yearStatus = year?.status;
    const modelStatus = model?.status;
    let environment = process.env.REACT_APP_ENV;
    let applicationTitle = process.env.REACT_APP_APPLICATION_NAME;
    let showIndicator = environment !== null && environment !== '' && environment !== 'production';
    let showUser = this.props?.showUser ?? true;
    let allowYearChange = this.props?.allowYearChange ?? true;
    return (
      <header className='header-frame'>
        <div className='header-title'>
          {showIndicator && (
            <div className='header-environment-indicator'>
              <span className='k-icon k-i-information'></span> {environment.toUpperCase()}{' '}
              ENVIRONMENT
            </div>
          )}

          <div className='header-logo-frame'>
            <img
              src={logo}
              className='header-logo'
              alt='logo'
            />
          </div>

          <div className='header-title-frame'>
            <p className='header-title-main'>{applicationTitle}</p>
          </div>

          <div className='header-user-frame'>{showUser && <UserCard />}</div>
        </div>
        <div>
          <HelpFile />
        </div>
        <div className='header-footer'>
          {this.props.navState && <HeaderNav navState={this.props.navState} />}
          {!this.props.navState && <div />}
          {!yearStatus?.id && (
            <div className='header-allocation-year'>
              <div className='header-allocation-label'>
                Currently no Open Year is available, please select other Year & Model.
                {allowYearChange && (
                  <span className='change-year-label'>
                    <a
                      onClick={this.onYearEditOpen}
                      className='change-allocation-year'
                    >
                      Change
                    </a>
                    .
                  </span>
                )}
              </div>
            </div>
          )}
          {yearStatus?.id &&
            /^\d{4}$/.test(year?.year) && (
              <div className='header-allocation-year'>
                <div className='header-allocation-label'>
                  You are currently viewing the <span>{model?.model ?? ''}</span>{' '}
                  <span className='emphasise'>{year?.year}</span> information
                  <span className='currentmodelstatus'>
                    {model
                      ? ` which is ${yearStatus?.status} / ${modelStatus?.status}`
                      : null}
                  </span>
                  .
                  {allowYearChange && (
                    <span className='change-year-label'>
                      <a
                        onClick={this.onYearEditOpen}
                        className='change-allocation-year'
                      >
                        Change
                      </a>
                      .
                    </span>
                  )}
                </div>
              </div>
            )}
        </div>
        <div className='header-sub-footer'>
          <ReportsOnlineIndicator />
          {this.props.subfooter && this.props.subfooter}
        </div>
        {this.state.yearModelEditor.show && (
          <YearModelSelector
            showYearEditor={this.state.yearModelEditor.show}
            isCancellable={this.state.yearModelEditor.cancellable}
            onYearEditClose={this.onYearEditClose}
          />
        )}
      </header>
    );
  }
}

export default inject('viewStore')(observer(Header));
