

import React, { Component } from 'react';

import './search-components.css';

class SearchPopMsg extends Component {

    render() {
        return (
            this.props.info.type === 'error' ?
                <div className='search-error-msg-frame'>
                    <p><span className="k-icon k-i-warning"></span> {this.props.info.msg}</p>
                </div>
                :
                <div className='search-warnning-msg-frame'>
                    <p><span className="k-icon k-i-information"></span> {this.props.info.msg}</p>
                </div>
        );
    }
}

export default SearchPopMsg;