// @flow

import React, { Component } from 'react';
import { Button } from 'antd';
import { Spinner } from '../spinner';
import { LoadingOutlined } from '@ant-design/icons';

import './actions.css';

type Props = {
  text: string,
  enabled: boolean,
  active: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  on: Function,
  spinner: boolean,
  spinnerText: string,
  className: string,
  look: string,
  icon: string,
};

class ActionButton extends Component<Props> {
  render() {
    return (
      <div className='action-button-action'>
        <Button
          type='button'
          onClick={this.props.on}
          icon={this.props.active ? <LoadingOutlined /> : this.props.icon || null}
          className={`action-button ${this.props.className || ''}`}
          look={this.props.look || 'outline'}
          primary='true'
          disabled={!this.props.enabled || this.props.active}
        >
          {this.props.text}
        </Button>
        {this.props.spinner && this.props.active && (
          <Spinner text={this.props.spinnerText || 'Processing'} />
        )}
      </div>
    );
  }
}

export default ActionButton;
