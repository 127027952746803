// @flow

import React, { Component } from 'react';

import './collapse-panel.css';
import { Collapse } from 'antd';
import { ControlOutlined } from '@ant-design/icons';
import { _allowStateChangesInsideComputed } from 'mobx';

type Props = {
  expanded: boolean,
  title: string,
  children: any,
  onSelect: Function,
};

type State = { expanded: boolean, focused: boolean };

class CollapsePanel extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.expanded ? 1 : 0,
      focused: false,
    };
  }

  onSelect = (e: any) => {
    const expanded = !this.state.expanded;
    this.setState({ expanded });
    if (this.props.onSelect != null) {
      this.props.onSelect({ ...e, expanded });
    }
  };

  handleFocus = (e) => {
    const firstChildNode = this.divRef.current.querySelector('div.ant-collapse-header:first-child');

    if (e.target === firstChildNode) {
      this.setState({ focused: true });
    } else {
      this.setState({ focused: false });
    }
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  divRef = React.createRef();

  render() {
    let panelBarClassName = 'panel-bar';
    if (this.state.focused) {
      panelBarClassName += ' panel-bar-focused';
    }

    return (
      <div
        className='panel-bar-frame'
        ref={this.divRef}
        tabIndex={this.props.tabIndex}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        <Collapse
          onSelect={this.onSelect}
          defaultActiveKey={this.state.expanded}
        >
          <Collapse.Panel
            header={this.props.title}
            style={panelBar}
            className={panelBarClassName}
            key={1}
          >
            {this.props.children}
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}

const panelBar = {
  minWidth: window.innerWidth / 2.1 + 'px',
};

export default CollapsePanel;
