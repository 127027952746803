// @flow

import React, { Component } from 'react';

import './error-msg.css';

type Props = {
    errorInfo: {
        state: boolean,
        msg: string,
    }
}

class ErrorMsg extends Component<Props> {

    setErrorMsg() {
        return this.props.errorInfo.msg.map(msg => {
            return <li key={msg}><span className="k-icon k-i-warning"></span> {msg}</li>
        })
    }

    render() {
        return (
            this.props.errorInfo.state
            &&
            <ul className="error-msg-frame">
                {this.setErrorMsg()}
            </ul>
        );
    }
}

export default ErrorMsg;