// @flow

import React, { Component } from 'react';
import { Table } from 'antd';

import { CollapsePanel, SpinnerBasic, TableStyleCellDiffByTolerance } from '../../components';
import './staff-activity';

const { Column } = Table;

type Props = {
  title: string,
  category: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  loading: boolean,
  categoryPercentage: number,
};

type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
};

class Overall extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: this.props.loading,
      category: null,
      categoryPercentage: 0,
    };
  }

  componentDidMount() {
    if (this.props.data.lines && this.props.data.lines.length > 0) {
      const displayData = this.props.data.lines;
      displayData.forEach((data, index) => {
        data['key'] = index;
      });
      this.setCategoryPercentage();
      this.setState({ data: displayData, category: this.props.category });
    } else {
      this.setState({
        data: [
          {
            key: this.props.category,
            category: {
              category: this.props.category,
            },
          },
        ],
        category: this.props.category,
      });
    }
  }

  setCategoryPercentage() {
    if (this.props.category === 'Academic') {
      this.setState({
        categoryPercentage: this.props.data.lines[0].staff?.citizenshipPercentage * 100,
      });
    }
    if (this.props.category === 'Research') {
      this.setState({
        categoryPercentage: this.props.data.lines[0].staff?.researchPercentage * 100,
      });
    }
    if (this.props.category === 'Teaching') {
      this.setState({
        categoryPercentage: this.props.data.lines[0].staff?.teachingPercentage * 100,
      });
    }
    if (this.props.category === 'Clinical') {
      this.setState({
        categoryPercentage: this.props.data.lines[0].staff?.clinicalPercentage * 100,
      });
    }
    if (this.props.category === 'Service') {
      this.setState({
        categoryPercentage: this.props.data.lines[0].staff?.servicePercentage * 100,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
    if (prevProps.data !== this.props.data) {
      if (this.props.data.lines.length > 0) {
        const displayData = this.props.data.lines;
        displayData.forEach((data, index) => {
          data['key'] = index;
        });
        this.setState({ data: displayData });
        this.setCategoryPercentage();
      } else {
        this.setState({
          data: [
            {
              key: this.props.category,
              category: {
                category: this.props.category,
              },
            },
          ],
        });
      }
    }
  }

  render() {
    return (
      <CollapsePanel
        expanded={true}
        title={this.props.title}
        tabIndex={1}
      >
        <div className='grid-frame'>
          {this.state.loading ? (
            <div className='load-frame'>
              <SpinnerBasic />
            </div>
          ) : (
            <Table
              style={{ minHeight: '70px' }}
              dataSource={this.state.data}
              bordered={true}
              size='small'
              tableLayout='fixed'
              pagination={false}
            >
              <Column
                field='category.category'
                title='Category'
                key='Category'
                dataIndex={['category', 'category']}
              />
              <Column
                field='hoursS1'
                title='S1'
                key='S1'
                dataIndex='hoursS1'
                width='140px'
                align='right'
              />
              <Column
                field='hoursS2'
                title='S2'
                key='S2'
                dataIndex='hoursS2'
                width='140px'
                align='right'
              />
              <Column
                field='hoursSS'
                title='SS'
                key='SS'
                dataIndex='hoursSS'
                width='140px'
                align='right'
              />
              <Column
                field='calculated'
                title='Calculated'
                key='Calculated'
                dataIndex='calculated'
                width='140px'
                align='right'
                render={(text, record) => (
                  <TableStyleCellDiffByTolerance
                    record={record}
                    field={'calculated'}
                    type={'staff'}
                  />
                )}
              />
              <Column
                field='hours'
                title='Assigned'
                key='Assigned'
                dataIndex='hours'
                align='right'
                width='140px'
                render={(text, record) => (
                  <TableStyleCellDiffByTolerance
                    record={record}
                    field={'hours'}
                    type={'staff'}
                  />
                )}
              />
              <Column
                field='target'
                title='Target'
                align='right'
                key='Target'
                dataIndex='target'
                width='140px'
              />
              <Column
                field='profile'
                align='right'
                title='Profile %'
                key='Profile'
                width='120px'
                render={() => {
                  return <span>{this.state.categoryPercentage}%</span>;
                }}
              />
            </Table>
          )}
        </div>
      </CollapsePanel>
    );
  }
}

export default Overall;
