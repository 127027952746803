// @flow

import React, { Component } from 'react';
import { Button } from 'antd';
import { Spinner } from '../spinner';

import './actions.css';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

type Props = {
  text: string,
  enabled: boolean,
  exporting: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  on: Function,
  spinner: boolean,
  spinnerText: string,
};

class ExportButton extends Component<Props> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='export-button-action'>
        <Button
          onClick={this.props.on}
          icon={this.props.exporting ? <LoadingOutlined /> : <DownloadOutlined />}
          className='export-button'
          look='outline'
          primary='true'
          disabled={!this.props.enabled || this.props.exporting}
        >
          {this.props.text}
        </Button>
        {this.props.spinner && this.props.exporting && (
          <Spinner text={this.props.spinnerText || 'Exporting'} />
        )}
      </div>
    );
  }
}

export default ExportButton;
