import React, { Component } from 'react';
import { Button } from 'antd';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

import './search-components.css';

class SearchKeyWords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.tags ? this.props.tags : [],
    };
  }

  onKeyWordsChange = (tags) => {
    this.setState({ tags });
    this.props.onChange(tags);
  };

  onKeyWordsSearch = () => {
    this.props.onSearch();
  };

  render() {
    return (
      <div className='search-keywords-tags-input card-item'>
        <p className='search-sub-title'>Keywords:</p>
        <TagsInput
          value={this.state.tags}
          onChange={this.onKeyWordsChange}
          addOnBlur={true}
          addKeys={[13, 188]}
        />
        <Button
          className='search-button'
          primary='true'
          look='outline'
          onClick={this.onKeyWordsSearch}
        >
          Search
        </Button>
      </div>
    );
  }
}

export default SearchKeyWords;
