// @flow

import { action, makeAutoObservable, runInAction } from 'mobx';
import { AllocationYearDto, ModelDto, UserModelGroupDto } from '../dto';
import { ViewManager } from '../utility';
import Api from './api';

export default class ViewStore {
  year: AllocationYearDto;
  years: AllocationYearDto[];
  model: ModelDto;
  models: ModelDto[];
  userModelGroup: UserModelGroupDto;
  userModelGroups: UserModelGroupDto[];
  _currentUserId: string;
  refreshed = false;

  constructor() {
    makeAutoObservable(this);
  }

  async init(currentUserId) {
    this._currentUserId = currentUserId;

    await this.initYearAndModel();
    await this.getYears();
  }

  setRefreshed = (refreshed) => {
    this.refreshed = refreshed;
  };

  async getRefresh() {
    await this.getYears(true);
    await this.getModels(true);
    if (!this.years.map((y) => y.year).includes(this.year.year)) {
      this.setRefreshed(true);
    }
  }

  async getLatestValidYearForUser(refresh = false) {
    if (this.year == null || refresh) {
      const year = await Api.get('/allocation_years/status/latest-valid/year');
      this.setYear(year);
    }
    return this.year;
  }

  async getCurrentYear() {
    if (this.year == null) {
      await this.getLatestValidYearForUser();
    } else {
      const year = await Api.get(`/allocation_years/${this.year.year}`);
      this.setYear(year);
    }
    return this.year;
  }

  async getYears(refresh = false) {
    if (this.years == null || refresh) {
      this.years = await Api.get(`/allocation_years?includeSystem=${true}`);
    }

    return this.years;
  }

  async getCurrentModel() {
    if (this.model == null) {
      await this.getModel();
    } else {
      this.model = await Api.get(`/models/${this.model?.id}`);
    }
    return this.model;
  }

  async getModels(refresh = false) {
    if (this.models == null || refresh) {
      const userModelGroups = await this.getUserModel(this._currentUserId, this.year.year);
      this.models = userModelGroups.models;
      if (this.model && this.models && !this.models.some((m) => m.id == this.model.id)) {
        const lookup = this.models.find((m) => m.code == this.model.code);
        if (lookup) {
          this.model = lookup;
        }
      }
    }
    return this.models;
  }

  async getModel(refresh = false) {
    if (this.model == null || refresh) {
      const models = await this.getModels(refresh);
      this.model = models[0]; // TODO Hardcoded - to change
    }
    return this.model;
  }

  async getUserModel(id: string, year: string) {
    this.userModelGroup = await Api.get(`/usermodelgroups/${year}/user/${id}`);
    return this.userModelGroup.length > 0 ? this.userModelGroup[0] : null;
  }

  async getUserModels(id: string) {
    this.userModelGroups = await Api.get(`/usermodelgroups/user/${id}`);
    return this.userModelGroups;
  }

  async syncYearAndModel() {
    const yearFromApi = await this.getCurrentYear();
    const modelFromApi = await this.getCurrentModel();
    ViewManager.setAllocationYear(yearFromApi);
    ViewManager.setAllocationModel(modelFromApi);
  }

  setYear(year: AllocationYearDto) {
    if (year) {
      this.year = year;
      Api.setYear(year.year);
    }
  }

  async initYearAndModel() {
    let yearFromSession = ViewManager.getAllocationYear();
    let modelFromSession = ViewManager.getAllocationModel();
    if (yearFromSession == null || modelFromSession == null) {
      await this.syncYearAndModel();
    } else {
      this.setYear(yearFromSession);
      this.model = modelFromSession;
    }
  }

  updateYearAndModel(newYear: AllocationYearDto, newModel: ModelDto) {
    if (newYear == null && newModel == null) {
      newYear = this.year;
      newModel = this.model;
    }
    const differentModelYears = newYear.year !== newModel.year.year;
    if (differentModelYears) {
      return false;
    }
    ViewManager.setAllocationYear(newYear);
    ViewManager.setAllocationModel(newModel);
    ViewManager.clearSearches();
    this.setYear(newYear);
    this.model = newModel;

    return true;
  }
}
