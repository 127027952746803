import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

const ReportsOnlineIndicator = (props) => {
  const { versionStore } = props;
  const environment = process.env.REACT_APP_ENV;
  const [displayMessage, setDisplayMessage] = useState(false);

  const reportOffline = () => {
    setDisplayMessage(true);
  };

  useEffect(() => {
    const isNotDevelopment = environment && environment !== 'development';

    if (isNotDevelopment) {
      versionStore.getReporting().catch(reportOffline);
    }
  }, [versionStore]);

  return (
    displayMessage && (
      <div className='report-offline-label'>
        <span className='k-icon k-i-warning'></span> Reporting Service is Currently Offline
      </div>
    )
  );
};

export default inject('versionStore')(observer(ReportsOnlineIndicator));
