// @flow

import { makeAutoObservable } from 'mobx';

import Api from './api';
import { AuthenticatedUserDto } from '../dto';

export default class AuthUserStore {
  currentUser: AuthenticatedUserDto;

  constructor() {
    makeAutoObservable(this);
  }

  async init() {
    await this.getCurrentUser();
  }

  async getCurrentUser() {
    try {
      if (this.currentUser == null) {
        const userObj = await Api.get('/authenticated/user');
        this.currentUser = new AuthenticatedUserDto(userObj);
      }
    } finally {
      return this.currentUser;
    }
  }
}
