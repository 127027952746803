// @flow

import React, { Component } from 'react';

import './checkbox-list.css';

type Props = {
    title: string,
    id: string,
    onChange: Function,
    checked: boolean,
    className: string,
    disabled: boolean,
};

type State = {
    value: boolean,
}


class CheckBox extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.checked != null ? this.props.checked : true,
        };
    }

    onChange = (event: any) => {
        const target = event.target;
        const value = target.type == 'checkbox' ? target.checked : target.value;
        this.props.onChange(target);
        this.setState({
            value: value
        });
    };


    render() {
        return (
            <div className={`checkbox-list-check-item${this.props.className ? ` ${this.props.className}` : ''}`}>
                <input type="checkbox" id={this.props.id} className="k-checkbox" checked={this.state.value} disabled={this.props.disabled} onChange={this.onChange} />
                <label className="k-checkbox-label" htmlFor={this.props.id}>{this.props.title}</label>
            </div>
        );
    }
}

export default CheckBox;