// @flow

import React, { Component } from 'react';
import { Button } from 'antd';

import { SearchPopMsg } from '..';
import { SearchErrorDto } from '../../dto/clientDto';
import './search-components.css';

type Props = {
  title: string,
  label: string,
  noResult: SearchErrorDto,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCodeSearch: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCodeValidate: Function,
};

type State = {
  code: string,
  error: {
    type: string,
    state: boolean,
    msg: string,
  },
};

class SearchCodePanel extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      error: {
        type: 'error',
        state: false,
        msg: 'Invalid Value',
      },
    };
  }

  onCleanError = () => {
    this.setState({
      error: {
        ...this.state.error,
        state: false,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCodeChange = (event: any) => {
    event.preventDefault();
    this.setState({
      code: event.target.value,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation(data: any) {
    if (isNaN(data)) {
      return false;
    } else {
      return true;
    }
  }

  onCodeSearch = () => {
    if (this.props.onCodeValidate == null || this.props.onCodeValidate(this.state.code)) {
      this.props.onCodeSearch(this.state.code);
    } else {
      this.setState({
        error: {
          type: 'error',
          state: true,
          msg: `Invalid ${this.props.label}`,
        },
      });
      setTimeout(() => {
        this.onCleanError();
      }, 8000);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidUpdate(prevProps: any) {
    if (prevProps.noResult !== this.props.noResult && this.props.noResult) {
      this.setState({
        error: {
          type: 'warnning',
          state: true,
          msg: 'Could not find any results matching this search request',
        },
      });
      setTimeout(() => {
        this.onCleanError();
      }, 5000);
    }
  }

  render() {
    return (
      <div className='card'>
        <div className='card-body'>
          <p className='search-title'>Search {this.props.title}</p>
          <div className='card-item'>
            <p className='search-sub-title'>{this.props.label}:</p>
            <input
              value={this.state.code}
              onChange={this.onCodeChange}
              style={inputStyle}
            />
            <Button
              className='search-button'
              primary='true'
              look='outline'
              onClick={this.onCodeSearch}
            >
              Go
            </Button>
          </div>
        </div>

        <div>{this.state.error.state ? <SearchPopMsg info={this.state.error} /> : null}</div>
      </div>
    );
  }
}

const inputStyle = {
  width: '288px',
  height: '32px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: 3,
  borderColor: '#bbc2cc',
  paddingLeft: '10px',
};

export default SearchCodePanel;
