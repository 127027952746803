// @flow

import React, { Component } from 'react';
import {
  StateBarFrame,
  StateBarActions,
  StateBarDetails,
  PrintButton,
  ExportButton,
  DropListButton,
} from '../../components';
import './state-bar.css';
import StateBarMessage from './state-bar-message';
import StateBarRow from './state-bar-row';
import { FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import { PermissionsHelper } from '../../utility';
import { inject, observer } from 'mobx-react';

type Props = {
  title: string,
  text: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  print: Function,
  printing: boolean,
  printable: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  export: Function,
  exporting: boolean,
  exportable: boolean,
  canGenerateAllocationReport: Boolean,
  inStaffTab: Boolean,
  inCourseTab: Boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any,
};

type State = {
  allowedReportTypes: any[],
  inStaffTab: Boolean,
  inCourseTab: Boolean
}

class StateBar extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.authUserStore = props.authUserStore;
    this.viewStore = props.viewStore;

    this.state = {
      allowedReportTypes: [],
      inStaffTab: props.inStaffTab && !props.inCourseTab,
      inCourseTab: props.inCourseTab && !props.inStaffTab
    }
  }

  staffAllocationReportTypes = [
    {
      text: 'Export to XLSX',
      icon: <FileTextOutlined />,
      on: this.props.export,
      allowedRoles: ['bpo', 'mm', 'wm', 'as']
    },
    {
      text: 'Export to PDF',
      icon: <FilePdfOutlined />,
      disabled: false,
      on: this.props.print,
      allowedRoles: ['bpo', 'mm', 'wm', 'as']
    },
  ];

  courseAllocationReportTypes = [
    {
      text: 'Export to XLSX',
      icon: <FileTextOutlined />,
      on: this.props.export,
      allowedRoles: ['bpo', 'mm', 'wm', 'as']
    },
    {
      text: 'Export to PDF',
      icon: <FilePdfOutlined />,
      disabled: false,
      on: this.props.print,
      allowedRoles: ['bpo', 'mm', 'wm', 'as']
    },
  ];

  filterAllowedReportTypes = (reportType) => {
    const { allowedRoles } = reportType;
    const user = this.authUserStore.currentUser;
    return PermissionsHelper.userHasRole(allowedRoles, user);
  }

  componentDidMount() {
    const allowedStaffAllocationReportTypes = this.staffAllocationReportTypes.filter(this.filterAllowedReportTypes);
    const allowedCourseAllocationReportTypes = this.courseAllocationReportTypes.filter(this.filterAllowedReportTypes);
    let allowedReportTypes = [];

    if(this.state.inStaffTab) {
      allowedReportTypes = allowedStaffAllocationReportTypes;
    }

    if(this.state.inCourseTab) {
      allowedReportTypes = allowedCourseAllocationReportTypes;
    }

    if(allowedReportTypes.length > 0) {
      this.setState({ allowedReportTypes });
    }
  }

  render() {
    return (
      <StateBarFrame>
        <StateBarRow>
          <StateBarDetails
            title={this.props.title}
            info={this.props.text}
          >
            <StateBarActions>
              {this.props.actions && (
                <div className='state-bar-additional-actions'>{this.props.actions}</div>
              )}

              {this.props.export != null && this.props.print == null && (
                <ExportButton
                  className='state-bar-additional-actions'
                  on={this.props.export}
                  printing={this.props.exporting}
                  enabled={
                    !this.props.exporting &&
                    (this.props.exportable || this.props.exportable == null)
                  }
                  spinner={true}
                  spinnerText={'Compiling Export'}
                  text={'Export'}
                />
              )}
              {this.props.print != null && this.props.export == null && (
                <PrintButton
                  on={this.props.print}
                  printing={this.props.printing}
                  enabled={
                    !this.props.printing && (this.props.printable || this.props.printable == null)
                  }
                  spinner={true}
                  spinnerText={'Compiling Report'}
                  text={'Print'}
                />
              )}
              {this.props.print != null &&
                this.props.export != null &&
                this.props.canGenerateAllocationReport && (
                  <DropListButton
                    text='Generate Report'
                    items={this.state.allowedReportTypes}
                    enabled={
                      (!this.props.printing &&
                        (this.props.printable || this.props.printable == null)) ||
                      (!this.props.exporting &&
                        (this.props.exportable || this.props.exportable == null))
                    }
                    spinner={true}
                    spinnerText={'Compiling'}
                    active={this.props.exporting || this.props.printing}
                    look='outline'
                  />
                )}
            </StateBarActions>
            {this.props.children}
          </StateBarDetails>
        </StateBarRow>
        <StateBarMessage>{this.props.message && this.props.message}</StateBarMessage>
      </StateBarFrame>
    );
  }
}

export default inject(
  'authUserStore',
  'viewStore'
)(observer(StateBar));