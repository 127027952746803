import Joi from 'joi';

export const StaffSchema = Joi.object()
  .keys({
    id: Joi.string().uuid({ version: 'uuidv4' }).trim(),

    code: Joi.string().max(250).required(),

    firstName: Joi.string().max(250).required().trim(),

    surname: Joi.string().max(250).required().trim(),

    displayName: Joi.string().max(250).required().trim(),

    efte: Joi.number().required().min(0.1).max(1),

    teachingPercentage: Joi.number().required().min(0).max(1),

    researchPercentage: Joi.number().required().min(0).max(1),

    citizenshipPercentage: Joi.number().required().min(0).max(1),

    clinicalPercentage: Joi.number().required().min(0).max(1),

    servicePercentage: Joi.number().required().min(0).max(1),

    courseCoordinatedCount: Joi.number().required(),

    masseyUserId: Joi.string().max(250).required().trim(),

    workProfile: Joi.string().max(250).allow(null),

    jobTitle: Joi.object()
      .keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
      })
      .required(),

    emailAddress: Joi.string().email({ tlds: { allow: false } }).required().error( err => {err.forEach(e => e.message = `Email must be valid`); return err; } ),

    newToMassey: Joi.boolean().required(),

    model: Joi.object()
      .keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
      })
      .required(),

    sltGroup: Joi.string().max(250).required(),

    department: Joi.object()
      .keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
      })
      .required(),

    masseyStaffId: Joi.string().trim().required().length(6),

    contract: Joi.object()
      .keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
      })
      .required(),

    staffType: Joi.object()
      .keys({
        id: Joi.string().uuid({ version: 'uuidv4' }).required(),
      })
      .required(),

    workloadAllocation: Joi.boolean().required(),
  })
  .unknown(true);
