import React, { Component } from 'react';

import './error-overlay.css';
import { Modal } from 'antd';
type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: Function,
  lines: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exception: any,
  visible: boolean,
};

type State = {
  exception: boolean,
};

class ErrorOverlay extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      exception: false,
    };
  }

  onAction = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  onDetails = () => {
    const exception = !this.state.exception;
    this.setState({ exception });
  };

  render() {
    const lines = this.props.lines != null ? this.props.lines : [''];
    return (
      this.props.visible && (
        <div>
          <Modal
            width={'auto'}
            open={true}
            footer={null}
            closable={false}
            centered
            className='error-overlay'
            transitionName='none'
            maskTransitionName='none'
            maskStyle={{ backgroundColor: '#FFFFFF44' }}
          >
            <div className='error-overlay-icon'>
              <span className='k-icon k-i-error'></span>
            </div>
            {lines.map((line, index) => (
              <p
                className='error-overlay-line'
                key={index}
              >
                {line}
              </p>
            ))}
            {this.props.exception && (
              <div className='error-overlay-exception'>
                <div className='error-overlay-exception-title'>
                  <span
                    className='error-overlay-link'
                    onClick={this.onDetails}
                  >
                    {'show exception details'}
                  </span>
                </div>
                {this.state.exception && (
                  <div className='error-overlay-exception-content'>
                    <Modal
                      centered
                      open={true}
                      footer={null}
                      transitionName='none'
                      maskTransitionName='none'
                      onCancel={this.onDetails}
                      title={'Exception Details'}
                    >
                      {JSON.stringify(this.props.exception)}
                    </Modal>
                  </div>
                )}
              </div>
            )}
            {this.props.onClose && (
              <p className='error-overlay-action'>
                <span
                  className='error-overlay-link'
                  onClick={this.onAction}
                >
                  {'continue'}
                </span>
              </p>
            )}
          </Modal>
        </div>
      )
    );
  }
}

export default ErrorOverlay;
