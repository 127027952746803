// @flow

import React, { Component } from 'react';
import UserSearch from './userSearch';
import { GridRemoveDialog } from '../../components';
import { inject, observer } from 'mobx-react';
import { PageSettingDto } from '../../dto/clientDto';
import { UserDto, PagerDto } from '../../dto';

type Props = {
  gridData: PagerDto,
  readOnly: boolean,
};

type State = {
  data: UserDto[],
  removal: UserDto,
  pageSetting: PageSettingDto,
  visible: boolean,
  editUserView: boolean,
};

class ManageUsers extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.userStore = this.props.userStore;
    this.state = {
      data: this.props.gridData ? this.props.gridData.data.slice(0) : [],
      pageSetting: this.createState(
        this.props.gridData ? this.props.gridData.skip : 0,
        this.props.gridData ? this.props.gridData.limit : 0
      ),
      removal: null,
      visible: false,
      editUserView: false,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.gridData !== this.props.gridData) {
      this.setState({ data: this.props.gridData.data });
    }

    if (prevState.data !== this.state.data) {
      this.setState({
        pageSetting: {
          ...this.state.pageSetting,
          items: this.state.data.slice(),
          total: this.props.gridData.total,
        },
      });
    }
  }

  createState = (skip = 0, take = 25) => {
    return {
      items: this.props.gridData ? this.props.gridData.data.slice(skip, skip + take) : [],
      total: this.props.gridData ? this.props.gridData.total : 0,
      skip: skip,
      pageSize: take,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [25, 50, 75],
        previousNext: true,
      },
    };
  };

  onRemoveUser(data, skip, limit) {
    this.userStore.setUserRemove(data, skip, limit);
  }

  onConfirmRemove = (confirmed: boolean) => {
    this.setState({ visible: false });
    if (confirmed) {
      let item = this.state.removal;
      item.inEdit = undefined;
      this.update(this.state.data, item, true);

      this.setState((prevState) => {
        return (
          {
            removal: null,
            data: prevState.data.slice(),
          },
          () => {
            this.onRemoveUser(item.id, prevState.pageSetting.skip, prevState.pageSetting.pageSize);
          }
        );
      });
    }
  };

  update(data: UserDto[], item: UserDto, remove?: boolean) {
    let updated;
    let index = data.findIndex((p) => p.id === item.id);
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    } else {
      let id = 1;
      data.forEach((p) => {
        id = Math.max(p.id + 1, id);
      });
      updated = Object.assign({}, item, { id: id });
      data.unshift(updated);
      index = 0;
    }
    if (remove) {
      data = data.splice(index, 1);
    }

    return data[index];
  }

  render() {
    return (
      <>
        <div className='grid-frame'>
          <UserSearch readOnly={this.props.readOnly} />
          {this.state.visible && (
            <GridRemoveDialog
              response={this.onConfirmRemove}
              lines={['Do you wish to remove the selected user?']}
            />
          )}
        </div>
      </>
    );
  }
}

export default inject('userStore')(observer(ManageUsers));
