// @flow

import { Button, Modal } from 'antd';
import { MessageBlock } from '..';
import { inject, observer } from 'mobx-react';
import { Spinner } from '..';
import * as mobx from 'mobx';
import React, { Component } from 'react';

import { User as Validator } from '../../validations';
import { ModelDto, DepartmentDto, UserGroupDto, UserDto } from '../../dto';
import Dropdown from '../ui/drop-down';
import { Select } from 'antd';
import './add-edit-user.css';

type Props = {
  title: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSubmit: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSearchRefresh: Function,
  user: UserDto,
  button: {
    text: string,
    icon: string,
    look: string,
  },
  year: number,
  model: ModelDto,
  keyId: string,
};

type State = {
  visible: boolean,
  failed: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  disabled: any,
  user: UserDto,
  messaging: { lines: string[], type: string },
  saving: boolean,
  loading: boolean,
  validating: boolean,
  initialised: boolean,
  initialising: boolean,
  departments: DepartmentDto[],
  groups: UserGroupDto[],
  currentModel: ModelDto,
  userData: UserDto,
  selectedUser: UserDto,
  editView: boolean,
  models: ModelDto[],
};

const statuses = [
  { id: 'active', label: 'Active' },
  { id: 'inactive', label: 'Inactive' },
];
const initialStatus = statuses[0];
class AddEditUser extends Component<Props, State> {
  onSubmit: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialogRef: any;

  empty = {
    firstName: '',
    surname: '',
    identifier: '',
    email: '',
    group: null,
    model: null,
    departments: [],
    status: 'active',
  };

  constructor(props) {
    super(props);
    this.userStore = this.props.userStore;
    this.viewStore = this.props.viewStore;
    this.dictionary = this.props.dictionary;
    this.state = {
      user: this.props.user ? mobx.toJS(this.props.user) : { ...this.empty, year: this.props.year },
      visible: false,
      failed: false,
      disabled: {},
      messaging: { lines: [], type: '' },
      saving: false,
      loading: false,
      validating: false,
      initialised: false,
      initialising: false,
      departments: [],
      currentModel: null,
      groups: [],
      userData: null,
      userModelGroup: null,
      tags: [],
      selectedUser: null,
      editView: false,
      models: [],
      selectedStatus: initialStatus,
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    this.dialogRef = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onToggleDialog = () => {
    this.onCleanMessages();
    this.setState({
      disabled: {
        department: true,
        title: !this.props.user,
        model: this.props.user,
        group: this.props.user,
      },
      user: this.props.user ? mobx.toJS(this.props.user) : { ...this.empty, year: this.props.year },
      visible: !this.state.visible,
      editView: !this.state.editView,
      selectedUser: null,
      initialised: false,
      initialising: false,
      loading: !this.state.initialised && this.props.user,
    });
  };

  onCleanMessages = () => {
    this.setState({ messaging: { lines: [], type: '' } });
  };

  editUserRole = () => {
    if (this.props.user) {
      const userProps = mobx.toJS(this.props.user);
      this.setState(
        {
          selectedUser: userProps,
          user: userProps,
          selectedStatus: statuses.find((s) => s.id === (userProps?.status)),
          editView: true,
          visible: true,
        },
        () => {
          const tags = this.state?.selectedUser?.departments
            ? this.state.selectedUser?.departments.map((d) => d.code)
            : [];
          const deptDisabled = this.state?.selectedUser?.group?.code !== 'wm';
          this.setState({
            disabled: {
              department: deptDisabled,
            },
            tags: tags,
            user: { ...this.state.user, departments: tags },
          });
        }
      );
    }
  };

  async componentDidMount() {
    this.editUserRole();
    this.onCleanMessages();
    const year = this.viewStore.year;
    if (year.status.id != null) {
      this.getModels(year.year);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.keyId !== this.props.keyId) {
      this.editUserRole();
    }
    if (this.dialogRef.current && !this.state.initialised && !this.state.initialising) {
      this.setState({ initialising: true });
      const nodes = document
        .querySelector('.add-edit-user-dialog')
        .querySelectorAll('button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const focusables = Array.from(nodes).filter((e) => !e.disabled);
      var first = focusables[0];
      var last = focusables[focusables.length - 1];

      first.focus();

      last.onkeydown = (e) => {
        if (e.which === 9 && !e.shiftKey) {
          e.preventDefault();
          first.focus();
        }
      };
      first.onkeydown = (e) => {
        if (e.which === 9 && e.shiftKey) {
          e.preventDefault();
          last.focus();
        }
      };
      this.setState({
        currentModel: this.props.model,
        groups: (await this.userStore.getRoles(true)).map((d) => mobx.toJS(d)),
      });
      if (this.props.model) {
        this.userStore.getDepartments(this.props.year, this.props.model?.id).then((data) => {
          this.setState({ departments: data.map((d) => mobx.toJS(d)) });
        });
      }
      if (this.state.user.group === 'wm') {
        this.setState({
          disabled: {
            ...this.state.disabled,
            department: this.state.currentModel === [],
          },
        });
      }
      const departmentCodes = this.state.user.departments;
      this.setState({
        user: {
          ...this.state.user,
          model: this.state.currentModel,
          departments: departmentCodes,
        },
        initialised: true,
        initialising: false,
        loading: false,
      });
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  async onSubmit(event) {
    this.setState({ validating: true });

    if (this.props.user != null) {
      this.empty['id'] = null;
    }

    event.preventDefault();
    this.onCleanMessages();

    const user = {};
    for (const key of Object.keys(this.empty)) {
      if (this.state.user[key] !== undefined) {
        if (['model', 'departments', 'group'].includes(key)) {
          user[key] =
            key === 'departments'
              ? this.state.user[key]
              : key === 'model'
              ? this.state.user[key]
                ? {
                    id: this.state.user[key].id,
                    year: this.state.user[key].year,
                    code: this.state.user[key].code,
                  }
                : undefined
              : this.state.user[key]
              ? { id: this.state.user[key].id, code: this.state.user[key].code }
              : undefined;
        } else {
          user[key] = this.state.user[key];
        }
      }
    }
    const msg = await Validator.validate(user);
    this.setState({ validating: false });
    if (msg.length > 0) {
      this.setState({
        messaging: {
          lines: msg.filter((m, p, s) => s.indexOf(m) === p),
          type: 'error',
        },
      });
    } else {
      this.onSave(user);
    }
  }

  onSave = (data) => {
    const userData = {
      id: data.id,
      identifier: data.identifier,
      email: data?.email,
      firstName: data.firstName,
      surname: data.surname,
      status: data.status,
    };

    this.setState({
      userData,
      saving: true,
    });
    if (data.id) {
      this.userStore
        .updateUser(userData)
        .then(async(result) => {
          if (result) {
            const userId = data.id;
            const userModelGroupsForUpsert = this.createUserModelGroups(data, userId);
            const umResult = await this.userStore.updateUserModelGroup(userModelGroupsForUpsert);
            if (umResult) {
              this.setState({ saving: false });
              this.onToggleDialog();
              if (this.props.onSubmit) {
                this.props.onSubmit();
              }
            }
          } else {
            this.setState({ saving: false, failed: true });
            setTimeout(() => {
              this.setState({ failed: false });
            }, 8000);
          }
        })
        .finally(() => {
          this.props.onSearchRefresh();
        });
    } else {
      this.userStore
        .addUser(userData)
        .then(async(result) => {
          if (result) {
            const userId = result.id;
            const userModelGroupsForUpsert = this.createUserModelGroups(data, userId);
            const umResult = await this.userStore.addUserModelGroup(userModelGroupsForUpsert);
            if (umResult) {
              this.setState({ saving: false });
              this.onToggleDialog();
              if (this.props.onSubmit) {
                this.props.onSubmit();
              }
            }
          } else {
            this.setState({ saving: false, failed: true });
            setTimeout(() => {
              this.setState({ failed: false });
            }, 8000);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((error) => {
          this.setState({ saving: false, failed: true });
        })
        .finally(() => {
          this.props.onSearchRefresh();
        });
    }
  };

  createUserModelGroups = (data, userId) => {
    const userModelGroupsForUpsert = [];
    if (data?.departments?.length > 0) {
      // For WM
      data.departments.forEach((deptCode) => {
        const userModelGroupData = {
          user: { id: userId },
          modelCode: data.model?.code,
          userGroup: data.group,
          departmentCode: deptCode,
        };
        userModelGroupsForUpsert.push(userModelGroupData);
      });
    } else if (data.group.code !== 'bpo') {
      // For MM or AS
      const userModelGroupData = {
        user: { id: userId },
        modelCode: data.model?.code,
        userGroup: data.group,
      };
      userModelGroupsForUpsert.push(userModelGroupData);
    } else {
      // For BPO, need to drop the model code
      const userModelGroupData = {
        user: { id: userId },
        modelCode: null,
        userGroup: data.group,
      };
      userModelGroupsForUpsert.push(userModelGroupData);
    }
    return userModelGroupsForUpsert;
  };

  onModelChange = (value) => {
    this.userStore.getDepartments(this.props.year, value.id).then((data) => {
      this.setState({ departments: data.map((d) => mobx.toJS(d)) });
    });
    if (this.state.user.group === 'wm') {
      this.setState({
        disabled: {
          ...this.state.disabled,
          department: value == [],
        },
      });
    }
    this.setState({
      user: {
        ...this.state.user,
        model: value,
        departments: [],
      },
    });
  };

  onGroupChange = (value) => {
    if (value.code !== 'wm') {
      this.setState({
        disabled: {
          department: true,
        },
        tags: [],
        user: { ...this.state.user, group: value, departments: [] },
      });
    } else {
      this.setState({
        disabled: {
          department: false,
        },
        user: { ...this.state.user, group: value },
      });
    }
  };

  onDepartmentChange = (value) => {
    this.setState({ user: { ...this.state.user, departments: value } });
  };

  onMasseyIdChange = (event) => {
    this.setState({
      user: { ...this.state.user, masseyStaffId: event.target.value },
    });
  };

  onFirstNameChange = (event) => {
    this.setState({
      user: { ...this.state.user, firstName: event.target.value },
    });
  };

  onSurnameChange = (event) => {
    this.setState({
      user: { ...this.state.user, surname: event.target.value },
    });
  };

  onIdentifierChange = (event) => {
    this.setState({ user: { ...this.state.user, identifier: event.target.value } });
  };

  onEmailChange = (event) => {
    this.setState({ user: { ...this.state.user, email: event.target.value } });
  };

  onUnitChange = (tags) => {
    this.setState({
      tags,
      user: { ...this.state.user, departments: tags },
    });
  };

  onUserStatusChange = (value) => {
    this.setState({
      user: { ...this.state.user, status: value?.id },
      selectedStatus: value
    });
  };

  getModels = (year) => {
    return this.dictionary.getModels(year).then((models) => {
      this.setState({ models });
    });
  };

  get canEditModel() {
    const startedAsBPO = this.state.selectedUser?.group?.code === 'bpo';

    if (startedAsBPO) {
      return true;
    }

    return this.state.currentModel ? false : true;
  }

  render() {
    const filteredOptions = this.state.departments.filter((o) => !this.state.tags.includes(o));
    return (
      <div className='add-edit-user-frame'>
        {!this.state.editView && (
          <Button
            onClick={this.onToggleDialog}
            icon={this.props.button ? this.props.button.icon || '' : ''}
            look={this.props.button ? this.props.button.look || 'default' : 'default'}
            primary='true'
          >
            {this.props.button ? this.props.button.text || '' : ''}
          </Button>
        )}
        {this.state.visible && (
          <div ref={this.dialogRef}>
            <Modal
              width={'auto'}
              centered
              open={this.state.visible}
              title={
                this.props.title
                  ? this.props.title
                  : this.props.user
                  ? 'Edit User Role'
                  : 'Add User Role'
              }
              onCancel={this.onToggleDialog}
              footer={null}
            >
              <div className='add-edit-user-dialog'>
                <form className='form-inline'>
                  {this.state.user.group?.code === 'bpo' ? (
                    <label style={{ flexDirection: 'column' }}>
                      <div className='k-form-field'>
                        <span>Model:</span>
                        <input
                          className='k-text-box'
                          defaultValue='No Model - BPO User'
                          disabled={true}
                        />
                      </div>
                    </label>
                  ) : (
                    <Dropdown
                      title={'Model'}
                      value={this.state.currentModel}
                      data={this.state.models}
                      onChange={this.onModelChange}
                      fields={{ value: 'id', display: 'model' }}
                      prompt={{ text: 'Please Select' }}
                      disabled={!this.canEditModel}
                    />
                  )}

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>First Name:</span>
                      <input
                        className='k-text-box'
                        defaultValue={
                          this.state.selectedUser
                            ? this.state.selectedUser.firstName
                            : this.state.firstName
                        }
                        value={this.state.firstName}
                        onChange={this.onFirstNameChange}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Last Name:</span>
                      <input
                        className='k-text-box'
                        defaultValue={
                          this.state.selectedUser
                            ? this.state.selectedUser.surname
                            : this.state.surname
                        }
                        value={this.state.surname}
                        onChange={this.onSurnameChange}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Username</span>
                      <input
                        className='k-text-box'
                        defaultValue={
                          this.state.selectedUser
                            ? this.state.selectedUser.identifier
                            : this.state.identifier
                        }
                        value={this.state.identifier}
                        onChange={this.onIdentifierChange}
                      />
                    </div>
                  </label>

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Email:</span>
                      <input
                        className='k-text-box'
                        defaultValue={
                          this.state.selectedUser ? this.state.selectedUser.email : this.state.email
                        }
                        value={this.state.email}
                        onChange={this.onEmailChange}
                      />
                    </div>
                  </label>

                  <Dropdown
                    title={'Role'}
                    value={this.state.selectedUser ? this.state.selectedUser.group : undefined}
                    data={this.state.groups}
                    disabled={this.state.disabled.group}
                    onChange={this.onGroupChange}
                    fields={{ value: 'id', display: 'group' }}
                    prompt={{
                      text: 'Please Select',
                      hidden: true,
                    }}
                  />

                  <label style={{ flexDirection: 'column' }}>
                    <div className='k-form-field'>
                      <span>Unit:</span>
                      <Select
                        allowClear
                        mode='multiple'
                        placeholder='Please Select'
                        onChange={this.onUnitChange}
                        style={selectStyle}
                        disabled={this.state.disabled.department}
                        value={this.state.tags}
                      >
                        {filteredOptions.map((option) => (
                          <Select.Option key={option.code}>{option.department}</Select.Option>
                        ))}
                      </Select>
                    </div>
                  </label>

                  <Dropdown
                    title={'User Status'}
                    value={this.state.selectedStatus}
                    data={statuses}
                    onChange={this.onUserStatusChange}
                    fields={{ value: 'id', display: 'label' }}
                  />

                  <p className='search-tips'>
                    <span className='k-icon k-i-information'></span> Unit is enabled only when a{' '}
                    <span className='emphasise'>model</span> is selected or role is{' '}
                    <span className='emphasise'>Workload Manager</span>.
                  </p>

                  <label
                    className='k-form-error-field'
                    onClick={() =>
                      this.setState({
                        failed: false,
                        messaging: { lines: [], type: '' },
                      })
                    }
                  >
                    {this.state.failed && (
                      <ul className='failed-submit-error-msg'>
                        <li>
                          <span className='k-icon k-i-warning'></span> An error has occured while
                          attempting to save user
                        </li>
                      </ul>
                    )}
                    <MessageBlock
                      visible={this.state.messaging.lines.length > 0}
                      lines={this.state.messaging.lines}
                      type={this.state.messaging.type}
                    ></MessageBlock>
                  </label>

                  <div className='form-buttons-frame'>
                    <Button
                      type='submit'
                      onClick={this.onSubmit}
                      look='outline'
                      className='sub-button save'
                    >
                      Save
                    </Button>
                    <Button
                      type='button'
                      onClick={this.onToggleDialog}
                      look='outline'
                      className='sub-button cancel'
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        )}

        {(this.state.saving || this.state.loading || this.state.validating) && (
          <Spinner
            text={this.state.loading ? 'Loading' : this.state.validating ? 'Validating' : 'Saving'}
          />
        )}
      </div>
    );
  }
}
const selectStyle = { width: '450px', borderRadius: 3, borderColor: '#bbc2cc' };

export default inject('viewStore', 'userStore', 'dictionary')(observer(AddEditUser));
