import React from 'react';

function TableCommandCellEditDelete({ onEdit, onDelete, dataItem }) {
  function onEditClick() {
    onEdit(dataItem);
  }

  function onDeleteClick() {
    if (onDelete != null) {
      onDelete(dataItem);
    }
  }

  return (
    <div>
      <button
        className='k-primary k-button k-grid-edit-command'
        onClick={onEditClick}
      >
        Edit
      </button>
      {onDelete != null && (
        <button
          className='k-button k-grid-remove-command'
          onClick={onDeleteClick}
        >
          Delete
        </button>
      )}
    </div>
  );
}

export default TableCommandCellEditDelete;
