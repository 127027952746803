// @flow

import { makeAutoObservable } from 'mobx';
import { AllocationYearDto, StatusDto } from '../dto';
import Api from './api';

export default class YearStore {
  year: AllocationYearDto;
  years: AllocationYearDto[];
  statuses: StatusDto[];

  constructor() {
    makeAutoObservable(this);
  }

  async getYear(refresh = false) {
    if (this.year == null || refresh) {
      this.year = await Api.get('/allocation_years/status/current/year');
    }
    return this.year;
  }

  async getYears(system = true, refresh = false) {
    if (this.years == null || refresh) {
      const years = await Api.get(`/allocation_years?includeSystem=${system}`);
      this.years = years;
    }
    return this.years;
  }

  async getYearStatuses(refresh = false) {
    if (this.statuses == null || refresh) {
      const statuses = await Api.get('/statuses/group/allocation_year');
      this.statuses = statuses;
    }
    return this.statuses;
  }

  async setYearUpdate(year: AllocationYearDto) {
    return new Promise((resolve, reject) => {
      Api.put(`/allocation_years/${year.year}`, year)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async setYearUpdates(years: AllocationYearDto[]) {
    return new Promise((resolve, reject) => {
      Api.put('/allocation_years/updates', years)
        .then((data) => {
          this.years = data;
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async setYearAdd(year: AllocationYearDto) {
    return new Promise((resolve, reject) => {
      Api.post('/allocation_years', year)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async setYearRemove(year: number) {
    return new Promise((resolve, reject) => {
      Api.delete(`/allocation_years/${year}`)
        .then((data) => {
          resolve({ success: true, result: data, error: null });
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }
}
