//@flow
import UserDto from './user';
import UserModelGroupDto from './userModelGroup';

export default class AuthenticatedUserDto {
  user: UserDto;

  userModelGroups: UserModelGroupDto[];

  constructor(obj) {
    this.user = obj.user;
    this.userModelGroups = obj.userModelGroups;
  }

  get identifier(): string {
    return this.user.identifier;
  }

  get id(): string {
    return this.user.id;
  }
}
