import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import { loginRequest, tokenRequest } from '../../authConfig';

export function RequestInterceptor({ children }) {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config) => {
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await instance.acquireTokenSilent({
      ...tokenRequest,
      account,
    });

    const bearer = `Bearer ${response.accessToken}`;

    config.headers.Authorization = bearer;
    //config.headers = {'x-access-token': response.accessToken};

    return config;

    //return {'x-access-token': response.accessToken};
  });
  /* eslint-enable no-param-reassign */

  return (
    <>
      {children}
    </>
  );
};

export default RequestInterceptor;
