// @flow

import { makeAutoObservable } from 'mobx';

import { CourseDto, SummaryDto } from '../dto';
import { SearchResultDto } from '../dto/clientDto';
import Api from './api';

export default class CourseStore {
  year: number;
  searchResult: SearchResultDto;
  course: CourseDto;
  courseOfferingSummary: SummaryDto;
  staffInvolvedSummary: SummaryDto;
  calculatedSummary: SummaryDto;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchOfferingSummary(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/course/${id}/offering`).then((data) => {
        this.courseOfferingSummary = data;
        resolve(data);
      });
    });
  }

  async fetchStaffInvolvedSummary(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/course/${id}/staff`).then((data) => {
        this.staffInvolvedSummary = data;
        resolve(data);
      });
    });
  }

  async fetchCalculatedSummary(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/course/${id}`).then((data) => {
        this.calculatedSummary = data;
        resolve(data);
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fetchCourseDetails(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/courses/${id}`).then((data) => {
        this.course = data;
        resolve(data);
      });
    });
  }

  async getCoursesByOwnerId(ownerId: ?string, year: ?number) {
    return await Api.get(
      ownerId && ownerId !== 'all' ? `/courses/owner/${ownerId}` : `/courses/${year}`
    );
  }

  async getCoursesByOwnerCodes({ ownerCodes, year }: { ownerCodes: String[], year?: Number }) {
    return await Api.post(`/courses/ownerCodes/year/${year}`, { ownerCodes });
  }

  async getCoursesByStaffId(staffId: string) {
    return await Api.get(`/courses/staff/${staffId}`);
  }

  // ****** Get Course ******

  async getCourseById(id) {
    return new Promise((resolve, reject) => {
      Api.get(`/courses/${id}`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async delete(id: string) {
    return new Promise((resolve, reject) => {
      Api.put(`/courses/delete/${id}`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
