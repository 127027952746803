/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import './search-components.css';

const SearchAutoKeyWords = React.forwardRef(function SearchAutoKeyWords(props, ref) {
  const { onKeywordSelectChange, initialValue, disabled } = props;
  const [inputedKeywords, setInputedKeywords] = useState(initialValue);

  useEffect(() => {
    const searchWords = [];
    if (inputedKeywords != null) {
      const lowerSearchValue = inputedKeywords.trim().toLowerCase();
      if (lowerSearchValue !== '') {
        const lowerKeywords = lowerSearchValue;
        let words;
        words = lowerKeywords.split(';');
        words.forEach((word) => {
          word = word.trim();
          if (word.length > 0) {
            searchWords.push(word);
          }
        });
      }
    }
    onKeywordChangeEvent(searchWords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputedKeywords]);

  async function onTextChange(keywords) {
    setInputedKeywords(keywords.target.value);
  }

  async function onKeywordChangeEvent(keywords) {
    onKeywordSelectChange(keywords);
  }

  return (
    <div className='search-keywords-tags-input card-item'>
      <p className='search-sub-title'>Keywords:</p>
      <Input
        disabled={disabled}
        className='search-input'
        value={inputedKeywords}
        onChange={onTextChange}
        ref={ref}
      ></Input>
    </div>
  );
});
export default SearchAutoKeyWords;
