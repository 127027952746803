import React, { Component } from 'react';

import './spinner.css';
import { Modal, Spin } from 'antd';

type Props = {
  text?: string,
  lines?: string[],
};

class Spinner extends Component<Props> {
  render() {
    return (
      <div>
        <Modal
          width={'auto'}
          open={true}
          footer={null}
          closable={false}
          centered
          className='spinner-dialog'
          transitionName='none'
          maskTransitionName='none'
          maskStyle={{ backgroundColor: '#FFFFFF44' }}
        >
          <Spin
            className='spinner-frame'
            size='large'
          />
          <div className='spinner-text'>{this.props.text && <p>{this.props.text}</p>}</div>
          <div className='spinner-lines'>
            {this.props.lines &&
              this.props.lines.map((line) => {
                return <div>{line}</div>;
              })}
          </div>
        </Modal>
      </div>
    );
  }
}

export default Spinner;
