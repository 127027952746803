// @flow

import { makeAutoObservable } from 'mobx';
import { DepartmentDto, JobTitleDto, ModelDto, StaffContractDto, StaffDto, StaffTypeDto } from '../dto';
import Api from './api';

export default class StaffStore {
  currentStaff: StaffDto;
  departments: DepartmentDto[];
  titles: JobTitleDto[];
  models: ModelDto[];
  types: StaffTypeDto[];
  contracts: StaffContractDto[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overviewSummary: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  teachingSummary: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  researchSummary: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citizenshipSummary: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clinicalSummary: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceSummary: any;

  constructor() {
    makeAutoObservable(this);
  }

  async delete(id: string) {
    return new Promise((resolve, reject) => {
      Api.put(`/staff/delete/${id}`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  get(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      if (id != null) {
        Api.get(`/staff/${id}`).then((data) => {
          resolve(data);
        });
      } else {
        resolve(null);
      }
    });
  }

  async add(staff: StaffDto) {
    return new Promise((resolve, reject) => {
      Api.post('/staff', staff).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async update(staff: StaffDto) {
    return new Promise((resolve, reject) => {
      Api.put(`/staff/${staff.id}`, staff).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async getByName(name: string, model: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.post('/staff/name', { name, model }).then((data) => {
        resolve(data);
      });
    });
  }

  async getByMasseyId(year: number, masseyId: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/staff/${year}/massey/user/id/${masseyId}`).then((data) => {
        resolve(data);
      });
    });
  }

  async getValidateNew(staff: StaffDto) {
    return new Promise((resolve, reject) => {
      Api.post('/staff/validation/duplicate', staff).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async getInOtherModels(staff: StaffDto) {
    return new Promise((resolve, reject) => {
      Api.post('/staff/validation/otherModels', staff).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async getValidateUsername(staff: StaffDto) {
    return new Promise((resolve, reject) => {
      Api.post('/staff/validation/username', staff).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async getTitles(year: number, modelId: ?string) {
    this.titles = await Api.get(modelId ? `/jobtitles/model/${modelId}` : `/jobtitles/${year}?active=true`);
    return this.titles;
  }

  async getDepartments(year: number, modelId: ?string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      this.departments = await Api.get(modelId ? `/departments/model/${modelId}` : `/departments/${year}?active=true`);
      resolve(this.departments);
    });
  }

  async getModels(year: number) {
    this.models = await Api.get(`/models/${year}`);
    return this.models;
  }

  async getTypes(year: number) {
    this.types = await Api.get(`/stafftypes/${year}`);
    return this.types;
  }

  async getContracts(year: number) {
    this.contracts = await Api.get(`/contracts/${year}`);
    return this.contracts;
  }

  async getStaffsByDepartmentId(departmentId: ?string, year: ?number) {
    return await Api.get(departmentId && departmentId !== 'all' ? `/staff/department/${departmentId}` : `/staff/${year}?active=true`);
  }

  async getStaffsByDepartmentCodes({ departmentCodes, year }: { departmentCodes: string[], year: ?number }) {
    return await Api.post(`/staff/departmentCodes/year/${year}`, { departmentCodes });
  }

  getCategoryById(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/categories/${id}`).then((data) => {
        resolve(data);
      });
    });
  }

  getCategoryList(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/categories/model/${id}`).then((data) => {
        resolve(data);
      });
    });
  }

  fetchCurrent(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/staff/${id}`).then((data) => {
        this.currentStaff = data;
        resolve(data);
      });
    });
  }

  async fetchOverview(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/overview`).then((data) => {
        this.overviewSummary = data;
        resolve(data);
      });
    });
  }

  async fetchTeaching(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/teaching`).then((data) => {
        this.teachingSummary = data;
        resolve(data);
      });
    });
  }

  async fetchResearch(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/research`).then((data) => {
        this.researchSummary = data;
        resolve(data);
      });
    });
  }

  async fetchCitizenship(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/citizenship`).then((data) => {
        this.citizenshipSummary = data;
        resolve(data);
      });
    });
  }

  async fetchClinical(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/clinical`).then((data) => {
        this.clinicalSummary = data;
        resolve(data);
      });
    });
  }

  async fetchService(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/service`).then((data) => {
        this.serviceSummary = data;
        resolve(data);
      });
    });
  }

  async fetchSummary(id: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let overviewSummary = new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/overview`).then((data) => {
        this.overviewSummary = data;
        resolve(data);
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let teachingSummary = new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/teaching`).then((data) => {
        this.teachingSummary = data;
        resolve(data);
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let researchSummary = new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/research`).then((data) => {
        this.researchSummary = data;
        resolve(data);
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let citizenshipSummary = new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/citizenship`).then((data) => {
        this.citizenshipSummary = data;
        resolve(data);
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let clinicalSummary = new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/clinical`).then((data) => {
        this.clinicalSummary = data;
        resolve(data);
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let serviceSummary = new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/service`).then((data) => {
        this.serviceSummary = data;
        resolve(data);
      });
    });

    //return Promise.all([staff, overviewSummary, teachingSummary, researchSummary, citizenshipSummary, clinicalSummary, serviceSummary])
    return Promise.all([overviewSummary, teachingSummary, researchSummary, citizenshipSummary, clinicalSummary, serviceSummary]);
  }

  getOverallSummary(id: string, category: string, year: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      Api.get(`/summary/${year}/staff/${id}/category/${category}`).then((data) => {
        resolve(data);
      });
    });
  }
}
