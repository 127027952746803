// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'antd';

import {
  AddEditStaff,
  BodyLayout,
  DropListButton,
  Header,
  Spinner,
  StateBar,
} from '../../components';
import {
  AllocationYearDto,
  ExportParameterDto,
  ModelDto,
  OwnerDto,
  ReportDto,
  SearchResultDto,
  SearchStaffDto,
  StaffDto,
  UserDto,
} from '../../dto';
import { PermissionsHelper, ViewManager } from '../../utility';
import StaffSearch from './search';
import Summary from './summary';
import moment from 'moment';
import './staff.css';
import { ArrowLeftOutlined, FileTextOutlined } from '@ant-design/icons';

type Props = {
  match: any,
  location: Record<string, string>,
  authUserStore: UserDto,
  viewStore: any,
  searchStore: SearchResultDto,
  staffStore: StaffDto,
  reportStore: ReportDto,
  allocationStore: AllocationYearDto,
  exportStore: ExportParameterDto,
  navStore: OwnerDto,
  history: Record<string, string | Record<string, string>>,
};

type State = {
  year: AllocationYearDto,
  staff: StaffDto,
  loading: boolean,
  spinnerLoading: boolean,
  refreshing: boolean,
  reporting: boolean,
  readOnlyView: boolean | null,
  message: { visible: boolean, line: string },
  loadings: {
    clinical: boolean,
    service: boolean,
    citizenship: boolean,
    research: boolean,
    teaching: boolean,
  },
  model: ModelDto,
  userCanViewSearch: boolean,
  searchResultHide: boolean,
  payload: SearchStaffDto,
  previousLocation: string,
  doSort: false,
};

class Staff extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.authUserStore = this.props.authUserStore;
    this.viewStore = this.props.viewStore;
    this.allocationStore = this.props.allocationStore;
    this.searchStore = this.props.searchStore;
    this.staffStore = this.props.staffStore;
    this.reportStore = this.props.reportStore;
    this.exportStore = this.props.exportStore;
    this.navStore = this.props.navStore;

    this.state = {
      year: null,
      staff: {},
      loading: true,
      spinnerLoading: true,
      refeshing: false,
      categoryList: [],
      userRoleList: [],
      reporting: false,
      readOnlyView: null,
      message: {},
      loadings: {
        clinical: true,
        service: true,
        citizenship: true,
        research: true,
        teaching: true,
        pageset: true,
      },
      model: {},
      searchResultHide: true,
      userCanViewSearch: false,
      updateDate: null,
      updateRole: null,
      payload: null,
      previousLocation: null,
    };
  }

  async componentDidMount() {
    const { model, year } = this.viewStore;
    const user = this.authUserStore.currentUser;

    if (model) {
      this.setState({
        model,
      });
    }
    let roleList = [];

    const staffInfo = (
      await this.props.staffStore.getByMasseyId(year.year, user.identifier)
    ).filter((info) => info.active);

    const userCanViewSearch =
      staffInfo.length > 1 || PermissionsHelper.userHasRole(['bpo', 'mm', 'wm'], user);
    this.setState({ userCanViewSearch });
    user.userModelGroups.forEach((umg) => {
      if (umg.userGroup.code === 'bpo') {
        roleList.push('bpo');
      } else if (umg.modelCode === model.code) {
        roleList.push(umg.userGroup.code);
      }
    });

    if (this.props.location?.pathname !== '/staff') {
      this.setState({ searchResultHide: false });
    } else {
      ViewManager.clearSearches();
      this.navStore.setPreviousLocation('');
      this.navStore.setReadOnly(false);
    }
    const isReadOnly = await this.isReadOnly();
    this.setState({
      spinnerLoading:
        this.props.location?.state || Object.keys(this.props.match?.params || {}).length !== 0
          ? this.state.spinnerLoading
          : false,
      readOnlyView: isReadOnly,
      year: year,
      userRoleList: this.getUnique(roleList),
    });

    // get staff data by user click on staff name on course/offering grid at course page
    // this.props.location.state will be saved after first time nav;
    if (this.props.location?.state) {
      const { staffId, prevLocation } = this.props.location.state;
      this.setState({ loading: true, spinnerLoading: true, previousLocation: prevLocation });
      await this.setSummary(staffId);
      await this.getLastModifyInfo(this.state.model.id, staffId);
    }
    // If this.props.match exist, set allocations
    else if (Object.keys(this.props.match?.params || {}).length !== 0) {
      this.setState({ loading: true, spinnerLoading: true });
      await this.setSummary(this.props.match.params.id);
      await this.getLastModifyInfo(this.state.model.id, this.props.match.params.id);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location?.pathname !== this.props.location?.pathname &&
      this.props.location?.pathname === '/staff'
    ) {
      const isReadOnly = await this.isReadOnly();
      this.setState({
        searchResultHide: true,
        loading: true,
        spinnerLoading: false,
        message: {},
        readOnlyView: isReadOnly,
      });
    }

    if (
      prevProps.location?.pathname !== this.props.location?.pathname &&
      this.props.location?.pathname !== '/staff'
    ) {
      this.setState({ searchResultHide: false });
    }
    if (prevState.staff !== this.state.staff && this.props.location?.pathname !== '/staff') {
      await this.getLastModifyInfo(this.state.model.id, this.state.staff?.id);
    }
  }

  isReadOnly = async () => {
    if (this.state.readOnlyView != null) {
      return this.state.readOnlyView;
    }

    const { model, year } = this.viewStore;
    const staffId = this.props.match.params.id;
    const user = this.authUserStore.currentUser;

    const departments = await this.searchStore.getDepartmentsByUser(year.year, user.id, model.id);
    const staff = await this.getStaff(staffId);

    if (staff == null || departments == null) {
      return PermissionsHelper.isRestrictedAccess({
        user,
        year,
        model,
        departments: staff?.departments,
        staff,
      });
    } else {
      const search: SearchStaffDto = {
        keywordsByType: [staff?.emailAddress],
        modelId: model.id,
        modelCode: model.code,
        departmentCodes: departments.filter((d) => d.code != null).map((d) => d.code),
        searchEmail: true,
        year: this.viewStore.year.year,
      };

      const staffResults = await this.searchStore.getStaffByKeyWords(
        search,
        10,
        0,
        true,
        null,
        false
      );

      const staffResult = staffResults?.data?.filter((s) => s.id === staff.id)[0];
      const isReadOnly = staffResult?.readOnly ?? true;

      this.setState({
        readOnlyView: isReadOnly,
      });

      return isReadOnly;
    }
  };

  isReadOnlyBanner = (record, current) => (
    <span>
      <strong>Read-Only</strong>. You are viewing a <span className='emphasise'>{record}</span>{' '}
      record but your current Workload Year is set as <span className='emphasise'>{current}</span>.
    </span>
  );

  async getStaff(staffId) {
    if (this.state.staff?.id === staffId) {
      return this.state.staff;
    }

    const staff = await this.staffStore.get(staffId);

    this.setState({ staff });

    return staff;
  }

  setLoading(newLoading) {
    return (prevState) => {
      return {
        loadings: {
          ...prevState.loadings,
          ...newLoading,
        },
      };
    };
  }

  async setSummary(id: string, year: ?number) {
    // Did not set spinnerLoading as true after click, because it will rerender search page
    // the search result will be cleaned
    this.setState({
      spinnerLoading: true,
      loadings: {
        clinical: true,
        service: true,
        citizenship: true,
        research: true,
        teaching: true,
      },
    });

    try {
      const staff = await this.getStaff(id);

      const currentYear = year ? year : staff.year;

      this.isReadOnly().then((isReadOnly) => {
        this.setState(
          {
            staff,
            message:
              this.state.year.year !== staff.year
                ? {
                    visible: true,
                    line: this.isReadOnlyBanner(staff.year, this.state.year.year),
                  }
                : {},
            readOnlyView: isReadOnly,
          },
          () => this.getCategoryList(staff.model.id)
        );
      });

      await this.staffStore.fetchOverview(id, currentYear);

      this.staffStore.fetchTeaching(id, currentYear).then(() => {
        this.setState(this.setLoading({ teaching: false }));
      });
      this.staffStore.fetchResearch(id, currentYear).then(() => {
        this.setState(this.setLoading({ research: false }));
      });
      this.staffStore.fetchClinical(id, currentYear).then(() => {
        this.setState(this.setLoading({ clinical: false }));
      });
      this.staffStore.fetchService(id, currentYear).then(() => {
        this.setState(this.setLoading({ service: false }));
      });
      this.staffStore.fetchCitizenship(id, currentYear).then(() => {
        this.setState(this.setLoading({ citizenship: false }));
      });
    } catch (e) {
      this.setState({
        loading: false,
        spinnerLoading: false,
        refreshing: false,
      });
    }
  }

  getCategoryList(id) {
    this.staffStore.getCategoryList(id).then((data) => {
      this.setState({
        categoryList: data,
        loading: false,
        spinnerLoading: false,
        refreshing: false,
      });
    });
  }

  getUnique(array) {
    return Array.from(new Set(array));
  }

  async getLastModifyInfo(modelId, staffId) {
    const updateDate = await this.allocationStore.getLastModifyInfo(modelId, staffId);
    this.setState({
      updateDate: updateDate?.lastUpdateDate,
      updateRole: updateDate?.userRole,
    });
  }

  setAllocations = async (staff) => {
    this.setState({ readOnlyView: staff?.readOnly });
    await this.setSummary(staff.id);
    this.props.history.push(`/staff/${staff.id}`);
    await this.getLastModifyInfo(this.state.model.id, staff.id);
  };

  goBack = async () => {
    const previousSearch = ViewManager.getStaffSearch();

    if (previousSearch !== null) {
      this.setState((prevState) => ({ ...prevState, previousSearch }));
      this.props.history.push('/staff');
      return;
    }

    if (this.state.previousLocation) {
      this.props.history.push(this.state.previousLocation);
    } else if (this.navStore.getPreviousLocation() !== '') {
      const previousLocation = this.navStore.getPreviousLocation();
      this.props.history.push(previousLocation);
    } else if (this.state.userCanViewSearch) {
      this.props.history.push('/staff');
    } else {
      this.setState({ loading: true, spinnerLoading: false });
      this.props.history.push('/dashboard');
      await this.getLastModifyInfo(this.state.model.id, this.state.staff.id);
    }
  };

  getCategoryId(code) {
    const category = this.state.categoryList.find((e) => e.code === code);
    return category ? category.id : null;
  }

  onReport = (type: string) => {
    this.setState({ reporting: true });
    switch (type) {
      case 'report':
        this.reportStore
          .getStaffAllocationSummaryReport(this.state.staff.id, this.state.staff.year)
          .then(() => {
            this.setState({ reporting: false });
          });
        break;
      case 'export':
      default:
        this.exportStore
          .getStaffIdAllocationExport(this.state.staff.id, this.state.staff.year)
          .then(() => {
            this.setState({ reporting: false });
          });
    }
  };

  onStaffChangeReport = () => {
    this.setState({ reporting: true });
    const parameters = new ExportParameterDto({
      emailed: false,
      type: 'staff_change_report',
      output: 'xlsx',
      year: this.state.year.year,
      model: this.state.model?.id,
      courseId: null,
      department: [this.state.staff?.department.id],
      staffId: this.state.staff.id,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.exportStore.getAllocationExportByParameters(parameters, false).then((result) => {
      this.setState({ reporting: false });
    });
  };

  onStaffSubmit = (present, previous) => {
    this.setState({ refreshing: true });
    if (
      present.efte !== previous.efte ||
      present.teachingPercentage !== previous.teachingPercentage ||
      present.researchPercentage !== previous.researchPercentage ||
      present.citizenshipPercentage !== previous.citizenshipPercentage ||
      present.clinicalPercentage !== previous.clinicalPercentage ||
      present.servicePercentage !== previous.servicePercentage
    ) {
      this.allocationStore.calculateByStaff(this.state.year.year, present.id).then(() => {
        this.staffStore
          .fetchCurrent(present.id)
          .then((staff) => {
            this.staffStore
              .fetchSummary(present.id, staff.year)
              .then(() => {
                this.setState({ staff }, () => this.getCategoryList(staff.model.id));
              })
              .catch(() => {
                this.setState({ refreshing: false });
              });
          })
          .catch(() => {
            this.setState({ refreshing: false });
          });
      });
    } else {
      this.staffStore
        .fetchCurrent(present.id)
        .then((staff) => {
          this.staffStore
            .fetchSummary(present.id, staff.year)
            .then(() => {
              this.setState({ staff }, () => this.getCategoryList(staff.model.id));
            })
            .catch(() => {
              this.setState({ refreshing: false });
            });
        })
        .catch(() => {
          this.setState({ refreshing: false });
        });
    }
  };

  get isReadOnlyView() {
    if (this.state.readOnlyView == null) {
      return true;
    }

    return this.state.readOnlyView;
  }

  get isPlaceholder() {
    return this.state.staff.placeholder;
  }

  render() {
    return (
      <div>
        <Header
          navState={'staff'}
          subfooter={
            this.state.message.visible ? (
              <div className='header-message'>{this.state.message.line}</div>
            ) : null
          }
        />
        {this.state.year &&
          (this.props.location.pathname === '/staff' || this.state.userCanViewSearch) && (
            <StaffSearch
              title={'Staff Name'}
              type={'staff'}
              onEdit={this.setAllocations}
              year={this.state.year.year}
              userRoleList={this.state.userRoleList}
              hideSearch={this.state.searchResultHide}
              location={this.props.location}
              history={this.props.history}
              payload={this.state.previousSearch}
            />
          )}
        {this.state.loading && this.state.spinnerLoading && <Spinner text={'Loading'} />}

        {!this.state.loading && !this.state.spinnerLoading && !this.state.searchResultHide && (
          <BodyLayout>
            <StateBar
              title={'Manage Staff Allocation: '}
              text={`${this.state.staff?.firstName} ${this.state.staff?.surname}`}
              print={() => this.onReport('report')}
              printing={this.state.reporting}
              export={() => this.onReport('export')}
              exporting={this.state.reporting}
              canGenerateAllocationReport={true}
              inStaffTab={true}
              actions={
                <>
                  {!this.isReadOnlyView &&
                  !this.isPlaceholder &&
                  !PermissionsHelper.isRestrictedAccess({
                    year: this.viewStore.year,
                    model: this.viewStore.model,
                    user: this.authUserStore.currentUser,
                  }) ? (
                    <div className='state-bar-additional-actions'>
                      <AddEditStaff
                        year={this.state.year.year}
                        button={{
                          look: 'outline',
                          text: 'Edit Staff',
                        }}
                        staff={this.state.staff}
                        onSubmit={this.onStaffSubmit}
                      />
                    </div>
                  ) : null}
                  <DropListButton
                    text='Generate Staff Change Report'
                    items={[
                      {
                        text: 'Export to XLSX',
                        icon: <FileTextOutlined />,
                        on: this.onStaffChangeReport,
                      },
                    ]}
                    enabled={true}
                    spinner={this.state.reporting}
                    spinnerText={'Compiling'}
                    active={this.state.reporting}
                    look='outline'
                  />
                </>
              }
            >
              <div className='state-bar-item-line'>
                {this.state.staff?.jobTitle != null && (
                  <label className='state-bar-item'>
                    <span className='state-bar-item-title-key'>Role:</span>
                    <span className='state-bar-item-title-value'>
                      {this.state.staff?.jobTitle?.title}
                    </span>
                  </label>
                )}
                {this.state.staff?.department != null && (
                  <label className='state-bar-item'>
                    <span className='state-bar-item-title-key'>Unit:</span>
                    <span className='state-bar-item-title-value'>
                      {this.state.staff?.department?.department}
                    </span>
                  </label>
                )}
                {this.state.staff?.workProfile != null && (
                  <label className='state-bar-item'>
                    <span className='state-bar-item-title-key'>Profile:</span>
                    <span className='state-bar-item-title-value'>
                      {this.state.staff?.workProfile}
                    </span>
                  </label>
                )}
                {this.state.staff?.efte != null && (
                  <label className='state-bar-item'>
                    <span className='state-bar-item-title-key'>Staff FTE:</span>
                    <span className='state-bar-item-title-value'>
                      {this.state.staff?.efte.toFixed(2)}
                    </span>
                  </label>
                )}
              </div>
              <div>
                {this.state.updateRole != null && this.state.updateDate != null && (
                  <span className='state-bar-item-modify'>
                    Last Modified by {this.state.updateRole} on{' '}
                    {moment(this.state.updateDate).format('D/MM/YYYY')}
                  </span>
                )}
              </div>
            </StateBar>

            <div className='top-button-group'>
              <Button
                style={{ width: '110px' }}
                onClick={this.goBack}
                icon={<ArrowLeftOutlined />}
                look='outline'
                primary='true'
              >
                Go Back
              </Button>
            </div>

            <Summary
              data={this.staffStore.overviewSummary}
              staffId={this.state.staff?.id}
              category={'overall'}
              title={'Overall Summary'}
            />

            {this.getCategoryId('research_enterprise') != null && (
              <Summary
                loading={this.state.loadings.research}
                data={this.staffStore.researchSummary}
                staffId={this.state.staff?.id}
                category={'research_enterprise'}
                title={'Research and Enterprise'}
                categoryId={this.getCategoryId('research_enterprise')}
                history={this.props.history}
                readOnly={this.isReadOnlyView}
              />
            )}

            {this.getCategoryId('academic_citizenship') != null && (
              <Summary
                loading={this.state.loadings.citizenship}
                data={this.staffStore.citizenshipSummary}
                staffId={this.state.staff?.id}
                category={'academic_citizenship'}
                title={'Academic Citizenship Summary'}
                categoryId={this.getCategoryId('academic_citizenship')}
                history={this.props.history}
                readOnly={this.isReadOnlyView}
              />
            )}

            {this.getCategoryId('teaching_supervision') != null && (
              <Summary
                loading={this.state.loadings.teaching}
                data={this.staffStore.teachingSummary}
                staffId={this.state.staff?.id}
                category={'teaching_supervision'}
                title={'Teaching and Supervision Summary'}
                categoryId={this.getCategoryId('teaching_supervision')}
                history={this.props.history}
                readOnly={this.isReadOnlyView}
              />
            )}

            {this.getCategoryId('service') != null && (
              <Summary
                loading={this.state.loadings.service}
                data={this.staffStore.serviceSummary}
                staffId={this.state.staff?.id}
                category={'service'}
                title={'Service Summary'}
                categoryId={this.getCategoryId('service')}
                history={this.props.history}
                readOnly={this.isReadOnlyView}
              />
            )}

            {this.getCategoryId('clinical_professional_practice') != null && (
              <Summary
                loading={this.state.loadings.clinical}
                data={this.staffStore.clinicalSummary}
                staffId={this.state.staff?.id}
                category={'clinical_professional_practice'}
                title={'Clinical Practice Summary'}
                categoryId={this.getCategoryId('clinical_professional_practice')}
                history={this.props.history}
                readOnly={this.isReadOnlyView}
              />
            )}

            <div className='bottom-button-group'>
              <Button
                style={{ width: '200px' }}
                onClick={this.goBack}
                icon={<ArrowLeftOutlined />}
                look='outline'
                primary='true'
              >
                Go Back
              </Button>
            </div>

            {this.state.refreshing && <Spinner text={'Updating'} />}
          </BodyLayout>
        )}
      </div>
    );
  }
}

export default inject(
  'authUserStore',
  'allocationStore',
  'searchStore',
  'staffStore',
  'reportStore',
  'exportStore',
  'navStore',
  'viewStore'
)(observer(Staff));
