import React, { Component } from 'react';
import { Header, BodyLayout } from '../../components';
import NoticeBar from '../../components/noticeBar/notice-bar';

class NoAccess extends Component {
  render() {
    return (
      <div>
        <Header />

        <BodyLayout>
          <NoticeBar
            text={
              'Workload information is not accessible at this time due to administrative operations in progress. Contact the system administrator  for further information.'
            }
            icon={'warning'}
          />
        </BodyLayout>
      </div>
    );
  }
}

export default NoAccess;
