import { ModelDto } from ".";

export default class RolloverDto {

    source: number;

    models: ModelDto[];

    destination: number;

    allocations: boolean;

    result: { success: boolean, data: any, message: string, error: any };

    constructor(params: ?{ source: number, destination: ?number, allocations: ?boolean, models: ?ModelDto[] }) {
        this.source = params ? params.source : null;
        this.destination = params ? params.destination : null;
        this.allocations = params ? params.allocations||true : true;
        this.models = params ? params.models : null;
    }
}
