// @flow

import FileDownload from 'js-file-download';
import { makeObservable } from 'mobx';
import { ImportDto } from '../dto';
import Api from './api';

export default class ImportStore {
  types: { name: string, code: string }[];

  async import(importing: ImportDto) {
    return new Promise((resolve, reject) => {
      Api.post(`/imports/${importing.year}`, importing)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async importForModel(importing: ImportDto) {
    return new Promise((resolve, reject) => {
      Api.post(`/imports/${importing.modelId}`, importing)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async commit(modelId: string, byLoggedInUser: Boolean = false) {
    return new Promise((resolve, reject) => {
      let url = `/imports/commit/${modelId}`;

      if (byLoggedInUser) {
        url = url + '?byLoggedInUser=true';
      }
      Api.get(url)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async recalculate() {
    return new Promise((resolve, reject) => {
      Api.get('/imports/recalculate')
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async cancel() {
    return new Promise((resolve, reject) => {
      Api.get('/imports/cancel')
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async complete(params: { status: string, modelId: string }) {
    let paramsString = '';

    if (params?.status) {
      paramsString += `?status=${params.status}`;
    }
    if (params?.modelId) {
      if (paramsString.length > 0) {
        paramsString += `&modelId=${params.modelId}`;
      } else {
        paramsString += `?modelId=${params.modelId}`;
      }
    }

    return new Promise((resolve, reject) => {
      Api.get(`/imports/complete${paramsString}`)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject({ success: false, result: null, error: e });
        });
    });
  }

  async report() {
    return new Promise((resolve, reject) => {
      Api.blobGet('/imports/report')
        .then((data) => {
          const blob = new Blob([data.blob], { type: data.type });
          FileDownload(blob, data.fileName, data.mine);
          resolve({ success: true });
        })
        .catch((e) => {
          reject({ success: false, error: e });
        });
    });
  }

  async enrolmentReport(modelId: string) {
    return new Promise((resolve, reject) => {
      Api.blobGet(`/imports/enrolmentReport/${modelId}`)
        .then((data) => {
          const blob = new Blob([data.blob], { type: data.type });
          FileDownload(blob, data.fileName, data.mine);
          resolve({ success: true });
        })
        .catch((e) => {
          reject({ success: false, error: e });
        });
    });
  }

  async getImportTypes(refresh = false) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise(async (resolve, reject) => {
      if (this.types == null || refresh) {
        this.types = await Api.get('/imports/types');
      }
      resolve(this.types);
    });
  }
}

makeObservable(ImportStore, {});
