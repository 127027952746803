/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  CollapsePanel,
  MessageLine,
  ActionButton,
  CheckBox,
  ConfirmationDialog,
  Spinner,
} from '../../components';
import { MessageConfirmationBlock } from '../../components/message';
import { inject, observer } from 'mobx-react';
import { RolloverDto } from '../../dto';
import { Input, Select } from 'antd';
import ManageImport from './manage-import';
import './model.css';
import { PermissionsHelper } from '../../utility';

function ManageRolloverAndImport(props) {
  const { model, year } = props;
  const rolloverStore = props.rolloverStore;
  const yearStore = props.yearStore;
  const viewStore = props.viewStore;
  const modelManageStore = props.modelManageStore;
  const [sourceSelected, setSourceSelected] = useState(null);
  const [sourceEnabled, setSourceEnabled] = useState(false);
  const [destinationSelected, setDestinationSelected] = useState(null);
  const [destinationEnabled, setDestinationEnabled] = useState(false);
  const [rolloverEnabled, setRolloverEnabled] = useState(false);
  const [rolloverTipEnabled, setRolloverTipEnabled] = useState(false);
  const [noteEnabled, setNoteEnabled] = useState(false);
  const [modelSourceYears, setModelSourceYears] = useState([]);
  const [rolloverYears, setRolloverYears] = useState([]);
  const [rollover, setRollover] = useState(new RolloverDto());
  const [message, setMessage] = useState({ visible: false, type: '', msg: '' });
  const [messageBlock, setMessageBlock] = useState({
    visible: false,
    title: null,
    lines: [],
    actions: [],
  });
  const [dialog, setDialog] = useState(null);

  const canImport = model?.status?.status === 'Import' ? true : false;
  const yearClosed = PermissionsHelper.yearIsInvalid(year);
  useEffect(() => {
    const getYearsByModel = async() => {
      const yearsResponse = await modelManageStore.getYearsByModel(model.code);
      if (yearsResponse && !yearClosed) {
        setModelSourceYears(yearsResponse);
        setSourceEnabled(true);
      } else if (yearClosed) {
        setSourceEnabled(false);
        setDestinationEnabled(false);
        setRolloverEnabled(false);
      }
    };
    if (model?.code != null) {
      setRollover({ ...rollover, models: [model] });
      getYearsByModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    if (sourceSelected != null) {
      const getRolloverYears = async () => {
        const yearsResponse = await modelManageStore.getRolloverYears();
        if (yearsResponse) {
          setRolloverYears(yearsResponse);
        }
      };
      getRolloverYears();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceSelected]);

  function onRolloverSourceChange(selectedSourceId) {
    setSourceSelected(selectedSourceId);
    setRollover({ ...rollover, source: selectedSourceId });
    if (destinationSelected !== selectedSourceId) {
      setDestinationEnabled(true);
      setNoteEnabled(false);
      if (destinationSelected != null) {
        setRolloverEnabled(true);
      }
    } else {
      setNoteEnabled(true);
      setRolloverEnabled(false);
    }
  }

  function onRolloverDestinationChange(selectedDestinationId) {
    setDestinationSelected(selectedDestinationId);
    setRollover({ ...rollover, destination: selectedDestinationId });
    if (sourceSelected !== selectedDestinationId) {
      setRolloverEnabled(true);
      setNoteEnabled(false);
    } else {
      setNoteEnabled(true);
      setRolloverEnabled(false);
    }
  }

  function onRolloverAllocationChange(target) {
    setRollover({ ...rollover, allocations: target.checked });
  }

  function onRollover() {
    setMessageBlock({ visible: false, title: null, lines: [], actions: [] });
    setDialog({
      title: 'Please Confirm',
      lines: [
        <span key='rolloverConfirm'>
          Please confirm you wish to process the <span className='important'>{sourceSelected}</span>{' '}
          to <span className='important'>{destinationSelected}</span> Rollover?
        </span>,
        <span key='rolloverNote'>
          <span className='caution'>Note:</span> Continuing will{' '}
          <span className='caution'>delete</span> any existing {destinationSelected} records.
        </span>,
      ],
      callback: async (confirmed) => {
        setDialog(null);
        if (confirmed) {
          setRolloverTipEnabled(true);
          setRolloverEnabled(false);
          try {
            const rolloverResponse = await rolloverStore.process(rollover);
            if (rolloverResponse) {
              const yearResponse = await yearStore.getYears(true, true);
              await viewStore.getRefresh();
              await viewStore.updateYearAndModel();
              if (yearResponse) {
                setMessageBlock(getMessageBlock('rollover_success'));
                setDestinationSelected(null);
                setRollover({ ...rollover, destination: destinationSelected });
                setRolloverTipEnabled(false);
              }
            }
          } catch (error) {
            setMessageBlock(getMessageBlock('rollover_error'));
            setRolloverTipEnabled(false);
            setDestinationSelected(null);
          }
        }
      },
    });
  }

  function cleanMessage() {
    setTimeout(() => {
      setMessage({ visible: false, type: '', msg: '' });
    }, 5000);
  }

  function onSetMessageEvent(message) {
    setMessage(message);
    cleanMessage();
  }

  function getMessageBlock(stage) {
    const close = () => {
      setMessageBlock({ visible: false, title: null, lines: [], actions: [] });
    };

    if (stage === 'rollover_success') {
      return {
        visible: true,
        type: 'success',
        task: 'rollover',
        title: (
          <span className={'import-line success'}>
            <span className={'k-icon k-i-check'}></span>Successfully completed the {sourceSelected}{' '}
            / {destinationSelected} Rollover
          </span>
        ),
        actions: [{ text: 'Close', callback: () => close() }],
      };
    } else if (stage === 'rollover_error') {
      return {
        visible: true,
        type: 'error',
        task: 'rollover',
        title: (
          <span className={'import-line error'}>
            <span className={'k-icon k-i-warning'}></span>An error has occurred while attempting the{' '}
            {sourceSelected} / {destinationSelected} Rollover
          </span>
        ),
        actions: [{ text: 'Close', callback: () => close() }],
      };
    }
  }

  return (
    <div>
      <CollapsePanel
        expanded={false}
        title='Manage Annual Rollover & Import'
        tabIndex={1}
      >
        <div className='management-block'>
          <div className='management-row'>
            <div className='management-column right-seperator'>
              <div className='item-card title'>
                <div className='title-label'>Rollover</div>
              </div>
              <div className='item-card management-tip'>
                <p>
                  <span className={'k-icon k-i-information'}></span>
                  For an Academic Year to be available as a destination, the Academic Year status
                  must be set to Rollover. When the Rollover is complete, a Model state will be
                  generated in the Destination year and set to Import.
                </p>
              </div>
              <div className='item-card'>
                <span className='management-label'>Model:</span>
                <Input
                  className='input-model'
                  value={model.model}
                  disabled={true}
                ></Input>
              </div>
              <div className='item-card'>
                <span className='management-label'>Source:</span>
                <Select
                  className='model-drop-down-style'
                  value={sourceSelected}
                  onChange={onRolloverSourceChange}
                  disabled={!sourceEnabled}
                >
                  {modelSourceYears.map((modelSourceYear) => (
                    <Select.Option
                      key={modelSourceYear.year}
                      value={modelSourceYear.year}
                    >
                      {modelSourceYear.year}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className='item-card'>
                <span className='management-label'>Destination:</span>
                <Select
                  className='model-drop-down-style'
                  value={destinationSelected}
                  onChange={onRolloverDestinationChange}
                  disabled={!destinationEnabled}
                >
                  {rolloverYears.map((rolloverYear) => (
                    <Select.Option
                      key={rolloverYear.year}
                      value={rolloverYear.year}
                    >
                      {rolloverYear.year}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className='item-card'>
                <span className='management-label'>Allocations:</span>
                <CheckBox
                  key={'managementCheckAllocation'}
                  id={'management-check-allocation'}
                  title={'Include'}
                  onChange={onRolloverAllocationChange}
                  checked={rollover.allocations}
                  disabled={yearClosed}
                />
              </div>
              <div>
                {noteEnabled && (
                  <div className='item-card'>
                    <span className='quick-note status-change'>
                      Rollover Source and Destination should be different.
                    </span>
                  </div>
                )}
              </div>
              <div className='item-card management-action'>
                <ActionButton
                  on={onRollover}
                  className='management-button'
                  text={'Rollover'}
                  enabled={rolloverEnabled}
                />
              </div>
            </div>

            <ManageImport
              canImport={canImport}
              model={model}
              setMessageEvent={onSetMessageEvent}
              disabled={yearClosed}
            />
          </div>
          <MessageConfirmationBlock
            visible={messageBlock.visible}
            title={messageBlock.title}
            type={messageBlock.type}
            lines={messageBlock.lines}
            actions={messageBlock.actions}
          />
          <MessageLine
            visible={message.visible}
            type={message.type}
            line={message.msg}
          />
        </div>
      </CollapsePanel>
      {dialog && (
        <ConfirmationDialog
          response={dialog.callback}
          title={dialog.title}
          lines={dialog.lines}
        />
      )}
      {rolloverTipEnabled && <Spinner text='The Rollover is in process, please wait.' />}
    </div>
  );
}

export default inject(
  'rolloverStore',
  'yearStore',
  'viewStore',
  'modelManageStore'
)(observer(ManageRolloverAndImport));
