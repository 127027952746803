export default class StringUtility {


    static capitalize = (s: string, exclusions: string[] = []) => {
        if (typeof s !== 'string') return '';
        return s.toLowerCase().split(' ').map((w) => (exclusions.includes(w) ? w : w.charAt(0).toUpperCase() + w.slice(1))).join(' ');
    }


    static titleCase = (s: string, exclusions: string[] = []) => {
        if (typeof s !== 'string') return '';
        return s.toLowerCase().split(' ').map((w) => (exclusions.includes(w) ? w : w.charAt(0).toUpperCase() + w.slice(1))).join(' ');
    }


    static joinByCommaAnd = (s: string[], capitalize: boolean = false, exclusions: string[] = []) => {
        if (!s || s.length === 0) return '';
        const _s = capitalize ? s.map((i) => i.toLowerCase().split(' ').map((w) => (exclusions.includes(w) ? w : w.charAt(0).toUpperCase() + w.slice(1))).join(' ')) : [...s];
        return _s.reduce((a, b, i, array) => a + (i < array.length - 1 ? ', ' : ' and ') + b);
    }

    static removeExtraSpace = (value: string) => {
        if (!value || typeof value !== 'string') return value;
        return value.trim().replace(/ +/g, ' ');
    }

    static removeSpecialCharacters = (value: string) => {
        if (!value || typeof value !== 'string') return value;
        return value.trim().replace(/[^\u0100|\u0101|\u0112|\u0113|\u012a|\u012b|\u014c|\u014d|\u016a|\u016b|a-zA-Z0-9]/g, ' ');
    }

    static removeCharacters = (value: string, characters: string[] | RegExp[]) => {
        if (!value || typeof value !== 'string') return value;
        value = value.trim();
        characters.forEach((c) => value.replace(c, ' '));
        return value;
    }

    static replaceCharacters = (value: string, replacements: { from: string | RegExp, to: string }) => {
        if (!value || typeof value !== 'string' || !replacements || replacements.length === 0) return value;

        replacements.forEach((r) => { if (r.from != null && r.to != null) { value = value.replace(r.from, r.to); } });

        return value;
    }

    static formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

}