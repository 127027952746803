import React, { useState, useCallback, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from 'antd';
import {
  MessageLine,
  ActionButton,
  CheckBoxList,
  ConfirmationDialog,
  UploadDrop,
  Spinner,
} from '../../components';
import { PermissionsHelper, StringUtility } from '../../utility';
import ImportMessageBlock from './import-message-block';

const IMPORT_TASKS = ['insert', 'update', 'delete'];

const ChangeFileImporter = (props) => {
  const yearSelected = props.model?.year?.year;
  const { model, setMessageEvent } = props;
  const [typeSelected, setTypeSelected] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [tasksSelected, setTasksSelected] = useState([]);
  const [dialog, setDialog] = useState(null);
  const [importTypes, setImportTypes] = useState([]);

  const [result, setResult] = useState(null);
  const yearClosed = PermissionsHelper.yearIsInvalid(props.year);
  const [importData, setImportData] = useState({
    stage: '',
    data: null,
  });

  const courseOnly = props.courseOnlyImport;

  const [doingImport, setDoingImport] = useState(false);

  useEffect(() => {
    if (courseOnly) {
      setImportTypes([
        {
          value: 'courses_and_offerings',
          label: 'Courses and Offerings',
        },
      ]);
    } else {
      setImportTypes([
        {
          value: 'staff',
          label: 'Staff',
        },
        {
          value: 'courses_and_offerings',
          label: 'Courses and Offerings',
        },
      ]);
    }
  }, [courseOnly]);

  const importStore = props.importStore;
  const uploadStore = props.uploadStore;

  const canImport = typeSelected && tasksSelected.length > 0;
  const uploadEnabled = canImport && uploadFile != null && !isProcessing && !doingImport;

  const onTypeChange = (selectedType) => {
    setTypeSelected(selectedType);
    setUploadFile(null);
    setProgress(0);
  };

  const onImportTaskChanged = (target) => {
    const tasks = [...tasksSelected];
    const ref = String(target.id).replace('import_task_', '');
    const currentTaskIndex = tasks.indexOf(ref);
    if (target.checked) {
      if (currentTaskIndex === -1) {
        tasks.push(ref);
      }
    } else {
      tasks.splice(currentTaskIndex, 1);
    }
    setTasksSelected(tasks);
  };

  const onUploadAdd = (file) => {
    setUploadFile(file);
    setProgress(0);
    setResult(null);
  };

  const onUploadRemove = () => {
    setUploadFile(null);
    setProgress(0);
    setResult(null);
    setDoingImport(false);
    setImportData({
      stage: 'clear',
      data: null,
    });
  };

  function onUploadClicked() {
    setDialog({
      visible: true,
      title: 'Please Confirm',
      lines: [
        <span key='dataChangeConfirm'>
          Please confirm you wish to execute the import process for the{' '}
          <span className='important'>{`${yearSelected} ${typeSelected}`}</span>
        </span>,
        <span
          key='dataChangeNote'
          className='caution'
        >
          The following task{tasksSelected.length > 1 ? 's' : ''} will be applied against existing{' '}
          {yearSelected} records;
        </span>,
        <div
          key='dataChangeList'
          className='dialog-import-task-list'
        >
          <ol>
            {tasksSelected.map((task, index) => {
              return (
                <li key={index}>
                  <span>
                    <span className='caution upper'>{task}</span>
                  </span>
                </li>
              );
            })}
          </ol>
        </div>,
        'Do you wish to continue?',
      ],
      callback: (confirmed) => {
        setDialog(null);
        if (confirmed) {
          setIsProcessing(true);
          execUpload();
        }
      },
    });
  }

  const execUpload = async () => {
    setIsProcessing(true);
    setDoingImport(true);
    const response = await uploadStore.upload(
      {
        type: typeSelected,
        file: uploadFile,
        formData: {
          modelId: model.id,
        },
      },
      (progress) => {
        const percentage = Math.round((100 * progress.loaded) / progress.total);
        setProgress(percentage);
      }
    );
    try {
      setResult({
        success: response.success,
        message: `${response.message}: ${JSON.stringify(response.result)}`,
        viewable: true,
      });
      setMessageEvent({
        visible: true,
        type: 'information',
        msg: `${response.success ? 'Successfully uploaded and processed' : 'Failed to upload'} ${
          uploadFile.name
        }`,
      });
      if (response.success) {
        onUploadSuccess();
      }
    } catch (error) {
      setResult({ success: false, message: JSON.stringify(error) });
      setMessageEvent({
        visible: true,
        type: 'error',
        msg: `Failed to upload ${uploadFile.name}`,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  function onUploadSuccess() {
    let processText = '';
    tasksSelected.forEach((task, index) => {
      if (index > 0) {
        processText += index === tasksSelected.length - 1 ? ' and ' : ', ';
      }
      processText += `${task}`;
    });
    setDialog({
      visible: true,
      title: 'Please Confirm',
      lines: [
        <span key='dataChangeConfirm'>
          Please confirm you wish to continue the import process for the{' '}
          <span className='important'>{`${model.year.year} -  ${model.model}`}</span>
        </span>,
        <span
          key='dataChangeCanEdit'
          className='caution'
        >
          This process can {processText} records.
        </span>,
        <span
          key='dataChangeNote'
          className='caution'
        >
          The following tasks will be applied against existing{' '}
          {`${model.year.year} -  ${model.model}`} records;
        </span>,
        <div
          key='dataChangeList'
          className='dialog-import-task-list'
        >
          {
            <ol>
              {tasksSelected.map((task, index) => {
                return (
                  <li key={index}>
                    <span>
                      <span className='caution upper'>{task}</span>
                    </span>
                  </li>
                );
              })}
            </ol>
          }
        </div>,
        'Do you wish to continue?',
      ],
      callback: (confirmed) => {
        setDialog(null);
        if (confirmed) {
          setIsProcessing(true);
          importDataChange();
        } else {
          setDoingImport(false);
          setProgress(0);
        }
      },
    });
  }

  async function importDataChange() {
    const dataImport = { modelId: model.id, types: [typeSelected], tasks: tasksSelected };
    try {
      const response = await importStore.importForModel(dataImport);
      setImportData({
        stage: 'import_warning',
        data: response,
      });
    } catch (error) {
      setImportData({
        stage: 'import_error',
        data: error,
      });
    } finally {
      setIsProcessing(false);
    }
  }

  const onImportCommit = useCallback(async () => {
    setIsProcessing(true);
    const response = await importStore.commit(model.id, true);
    try {
      if (response.success) {
        const stage = response.result.warnings ? 'commit_with_warnings' : 'commit_success';
        setImportData({
          stage,
          data: response,
        });
      } else {
        setImportData({
          stage: 'commit_failed',
          data: response,
        });
      }
    } catch (error) {
      setImportData({
        stage: 'commit_failed',
        data: error,
      });
    } finally {
      setIsProcessing(false);
    }
  }, [importStore, model.id]);

  const onImportReport = useCallback(async() => {
  setIsProcessing(true);

  setMessageEvent({
    visible: true,
    type: 'information',
    msg: 'Import Report has been requested.',
  });      
  
  try {
    const response = await importStore.report();
    if (response.success) {
      setImportData({
        stage: 'report_success',
        data: response,
      });
    } else {
      setImportData({
        stage: 'report_error',
        data: response,
      });
    }
  } catch (error) {
    setImportData({
      stage: 'report_error',
      data: error,
    });
  } finally {
    setIsProcessing(false);
  }
}, [importStore, setMessageEvent]);


  const onImportCancel = useCallback(() => {
    setImportData({
      stage: 'clear',
      data: null,
    });
    setProgress(0);
    setResult(null);
    setDoingImport(false);
    setUploadFile(null);
  }, []);

    const onEnrolmentReport = useCallback(async() => {
      setIsProcessing(true);
      const modelId = props.model.id;

      setMessageEvent({
        visible: true,
        type: 'information',
        msg: 'Enrolment Impact Report has been requested.',
      });      
      
      try {
        const response = await importStore.enrolmentReport(modelId);
        if (response.success) {
          setImportData({
            stage: 'enrolment_report_success',
            data: response,
          });
        } else {
          setImportData({
            stage: 'enrolment_report_error',
            data: response,
          });
        }
      } catch (error) {
        setImportData({
          stage: 'enrolment_report_error',
          data: error,
        });
      } finally {
        setIsProcessing(false);
      }
    }, [importStore, props, setMessageEvent]);

  return (
    <>
      <div className='item-card'>
        <span className='management-label'>Type:</span>
        <Select
          className='management-dropdown type'
          value={typeSelected}
          onChange={onTypeChange}
          options={importTypes}
          disabled={yearClosed}
        ></Select>
      </div>
      <div className='item-card checklist-import-tasks'>
        <span className='management-label'>Tasks:</span>
        <CheckBoxList
          emptyText={'Not Available'}
          items={IMPORT_TASKS.map((i) => {
            return {
              id: `import_task_${i}`,
              //className: `${i} capitalize`,
              title: i,
              checked: tasksSelected.includes(i),
            };
          })}
          onCheckChange={onImportTaskChanged}
          disabled={yearClosed}
        />
      </div>

      <div>
        {tasksSelected.includes('delete') && (
          <div className='item-card'>
            <span className='quick-note delete'>
              Please Note the Delete task will remove all {yearSelected} records not found in the
              import but currently reside in the system.
            </span>
          </div>
        )}
      </div>

      {canImport && (
        <div className='item-card upload'>
          <UploadDrop
            hint={'Drag and Drop file here, or Click to Select'}
            note={
              <span>
                Only <span className='emphasise'>CSV</span> files allowed with a max size of{' '}
                <span className='emphasise'>50MB</span>
              </span>
            }
            width={500}
            height={100}
            maxSize={52428800}
            allows={['csv']}
            uploadIcon={'k-icon k-i-upload'}
            removeIcon={'k-icon k-i-delete'}
            file={uploadFile}
            onAdd={onUploadAdd}
            onRemove={onUploadRemove}
            progress={progress}
            result={result}
            disabled={yearClosed}
          ></UploadDrop>
        </div>
      )}
      <div className='item-card management-action'>
        <ActionButton
          on={onUploadClicked}
          className='management-button import'
          text={'Import'}
          disabled={yearClosed}
          enabled={uploadEnabled}
        />
      </div>
      {dialog && (
        <ConfirmationDialog
          response={dialog.callback}
          title={dialog.title}
          lines={dialog.lines}
        />
      )}
      <ImportMessageBlock
        importData={importData}
        context='change_file_importer'
        onImportCommit={onImportCommit}
        onImportReport={onImportReport}
        onImportCancel={onImportCancel}
        onEnrolmentReport={onEnrolmentReport}
      />

      {isProcessing && <Spinner text='Processing - Please wait' />}
    </>
  );
};

export default inject('importStore', 'uploadStore')(observer(ChangeFileImporter));
